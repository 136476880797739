/* eslint-disable no-restricted-globals */

import React from "react";

function NavItem({ label, path, onClick }) {
  return (
    <div
      className={`flex justify-center items-center gap-2.5 self-stretch my-auto cursor-pointer${location.pathname.includes(path) ? "text-indigo-600 border-b-4 border-indigo-600" : ""
        } whitespace-nowrap min-h-[80px]`}
      onClick={onClick}
    >
      {label}
    </div>
  );
}

export default NavItem;
