import React, { useState } from "react";
import ImageGrid from "./ImageGrid";
import Pagination from "./Pagination";
import PageTitle from "./PageTitle";
import TabNavigation from "./TabNavigation";
import ImageComponent from "../../components/FooterBanner";
import {
    businessCardImages,
    logoImages,
} from "../../components/common/dataFake/imageData";
import Header from "../LayoutCommon/Header";
import Footer from "../LayoutCommon/Footer";
import { useGetBusinessCardsImagesQuery } from "../../services/apiSlice";
import PaginationComponent from "../../components/common/Pagination/PaginationComponent";

const ViewPage = () => {
    const [activeTab, setActiveTab] = useState("logo");
    const [currentPage, setCurrentPage] = useState(1);
    const imagesPerPage = 16;
    const order = "DESC";
    const imageType = activeTab === "logo" ? "logo" : "business-card";

    const {
        data: fetchedImages,
        isLoading,
        error,
    } = useGetBusinessCardsImagesQuery({
        order,
        page: currentPage,
        take: imagesPerPage,
        imageType,
    });

    const currentImages = fetchedImages?.data || [];
    const totalPages = fetchedImages ? fetchedImages?.meta?.pageCount : 1;

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        setCurrentPage(1); 
    };

    return (
        <div className="flex min-h-screen flex-col items-center justify-center overflow-hidden bg-white">
            <main className="flex w-full flex-col items-center">
                <PageTitle
                    title="둘러보기"
                    description="로고로지에서 제작한 다양한 로고디자인과 명함디자인을 둘러보세요."
                />
                <TabNavigation
                    activeTab={activeTab}
                    setActiveTab={handleTabChange} 
                />
                <div className="mt-10 flex w-full flex-col items-center self-center max-md:max-w-full">
                    <ImageGrid images={currentImages} />
                </div>

                <PaginationComponent
                    className="mt-10"
                    count={totalPages}
                    page={currentPage}
                    onPageChange={handlePageChange}
                    showFirstButton={true}
                    showLastButton={true}
                />
            </main>
            <ImageComponent
                src={
                    activeTab === "logo"
                        ? "https://cdn.builder.io/api/v1/image/assets/TEMP/c209f3e08e4acaede76a3b53902c38a48239925ef7f405809c2e24ed01651b64?placeholderIfAbsent=true&apiKey=59b2e63425054a16a2965abdd74cb952"
                        : "https://cdn.builder.io/api/v1/image/assets/TEMP/20538a1793d4e6100f28d9733b057ef544adba217d5c07fcd388df59ceea2490?placeholderIfAbsent=true&apiKey=59b2e63425054a16a2965abdd74cb952"
                }
            />
        </div>
    );
};

export default ViewPage;
