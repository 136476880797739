import React, { useState } from "react";
import Copyright from "./Copyright";
import images from "../../images";
import ModalComponent from "../../components/common/Modals/ModalComponent";
import { termsOfUse, privacyPolicy } from "../../components/common/data/Data";
import { useNavigate } from "react-router-dom";

function Footer() {
    const navigate = useNavigate();

    const initModal = {
        status: false,
        title: "",
        content: "",
    };
    const [modal, setModal] = useState(initModal);

    const handleLink = ({ title }) => {
        const value = { status: false, title, content: "" };
        switch (title) {
            case "공지사항":
                navigate("/notification");
                break;
            case "이용 및 가격안내":
                navigate("");
                break;
            case "이용약관":
                value.status = true;
                value.content = termsOfUse;
                break;
            case "개인정보 처리방침":
                value.status = true;
                value.content = privacyPolicy;
                break;
            default:
                break;
        }
        console.log(value);

        setModal(value);
    };
    const renderFooterLink = ({ title }) => {
        return (
            <div
                className="mt-4 flex cursor-pointer items-start gap-1.5 whitespace-nowrap first:mt-0"
                onClick={() => {
                    handleLink({ title });
                }}
            >
                <div>{title}</div>
                <img
                    loading="lazy"
                    src={images.arrowTRIcon}
                    alt=""
                    className="aspect-square w-4 shrink-0 object-contain"
                />
            </div>
        );
    };

    return (
        <footer className="flex w-full flex-col bg-neutral-900 px-20 py-16 max-md:max-w-full max-md:px-5">
            <div className="flex w-full flex-wrap justify-between gap-5 self-center max-md:max-w-full">
                <div className="flex flex-col max-md:max-w-full">
                    <div className="flex max-w-full flex-col text-base">
                        <div className="font-bold leading-none text-indigo-500">
                            logee designworks
                        </div>
                        <div className="mt-3 text-stone-300">
                            <span className="font-bold">로고로지에서</span> AI로
                            쉽고 빠르게 <br />
                            <span className="">
                                완벽한 디자인 매칭을 경험해 보세요.
                            </span>
                        </div>
                    </div>
                    <div className="mt-6 text-sm text-neutral-400 max-md:max-w-full">
                        <span className="font-bold text-neutral-400">
                            상호명
                        </span>{" "}
                        : 로지디자인웍스{" "}
                        <span className="font-bold text-neutral-400">
                            문의전화
                        </span>{" "}
                        : 010-2539-6442{" "}
                        <span className="font-bold text-neutral-400">
                            대표자
                        </span>{" "}
                        : 김은향{" "}
                        <span className="font-bold text-neutral-400">주소</span>{" "}
                        : 경기도 성남시 수정구 위례순환로 53, 3601동 1층
                        9호(창곡동, 성남창업센터 정글ON) <br />
                        <span className="font-bold text-neutral-400">
                            {" "}
                            사업자 등록 번호{" "}
                        </span>{" "}
                        : 690-35-01390{" "}
                        <span className="font-bold text-neutral-400">
                            문의 메일{" "}
                        </span>{" "}
                        : logee@logologee.com
                    </div>
                </div>
                <nav className="mb-4 flex flex-col items-end self-start text-base leading-none text-stone-300 max-md:w-full">
                    {renderFooterLink({ title: "공지사항" })}
                    {renderFooterLink({ title: "이용 및 가격안내" })}
                    {renderFooterLink({ title: "이용약관" })}
                    {renderFooterLink({ title: "개인정보 처리방침" })}
                </nav>
            </div>
            <Copyright />
            <ModalComponent
                className="h-[700px] w-[800px]"
                title={modal.title}
                open={modal.status}
                content={
                    <div className="h-[580px] overflow-y-auto">
                        {modal.content}
                    </div>
                }
                hideIconClose={false}
                onCloseIcon={() => setModal(initModal)}
            />
        </footer>
    );
}

export default Footer;
