import React from 'react';

const ColorSelector = ({ label, colors, selectedColor, onSelectColor }) => {
  return (
    <div className="flex flex-col mt-10 w-full font-bold whitespace-nowrap max-md:max-w-full">
      <div className="text-base text-neutral-700 max-md:max-w-full">{label}</div>
      <div className="flex flex-wrap gap-3 items-center mt-4 w-full text-sm text-zinc-800 max-md:max-w-full">
        {colors.map(({ name, hex }) => (
          <button
            key={name}
            className={`flex gap-2.5 items-center self-stretch px-5 py-3.5 my-auto bg-white rounded-md border ${
              name === selectedColor
                ? 'border-2 border-indigo-600 border-solid'
                : 'border-solid border-stone-300'
            }`}
            onClick={() => onSelectColor({ name, hex })}
          >
            <div
              className="flex shrink-0 self-stretch my-auto w-4 h-4 rounded-sm"
              style={{ backgroundColor: hex }}
            />
            <div className="self-stretch my-auto">{name}</div>
          </button>
        ))}
      </div>
    </div>
  );
};

export default ColorSelector;
