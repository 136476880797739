import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ProductImage from "./ProductImage";
import LogoInputInfo from "./LogoInputInfo";
import FileFormatSelection from "./FileFormatSelection";
import FileFormatRecommendation from "./FileFormatRecommendation";
import PaymentMethod from "../../../components/common/PaymentMethod/PaymentMethod";
import TotalPayment from "../../../components/common/PaymentMethod/TotalPayment";
import PurchaseInfo from "./PurchaseInfo";
import {
    usePostKakaoInitializeMutation,
    usePostNaverPayInitializeMutation,
    usePostOrdersLogosMutation,
    usePostTossPaymentInitializeMutation,
} from "../../../services/apiSlice";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import PaymentMethodEnum from "../../../utils/constants/enum/paymentMethodEnum";
import { toast } from "react-toastify";
import ModalComponent from "../../../components/common/Modals/ModalComponent";

function PurchasePage() {
    const navigate = useNavigate();

       // Modal
       const initModal = {
        status: false,
        title: "",
        content: "",
    };
    const [modal, setModal] = useState(initModal);

    const [postKakaoInitialize, { data: dataKakao }] =
        usePostKakaoInitializeMutation();

    const [postTossPaymentInitialize, { data: dataTossPayment }] =
        usePostTossPaymentInitializeMutation();

    const [postNaverPayInitialize, { data: dataNaverPay }] =
        usePostNaverPayInitializeMutation();

    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
    const params = new URLSearchParams(window.location.search);
    const resultCode = params.get("resultCode");
    const resultMessage = params.get("resultMessage");
    const resultStatus = params.get("resultStatus");
    const { id } = useParams();
    const cardId = id;
    const { userData } = useSelector((state) => state.common);
    const userId = userData?.userID || null;

    const [postOrdersLogosMutation, { loading, error: errorPostOrder, data }] =
        usePostOrdersLogosMutation();
    const [selectedFileFormat, setSelectedFileFormat] = useState(null);

    const infoItems = [
        { label: "작업명", value: data?.logosEntity?.businessName || "N/A" },
        { label: "회사명", value: data?.logosEntity?.companyName || "N/A" },
        { label: "브랜드 네임", value: data?.logosEntity?.brandDescription || "N/A" },
        { label: "슬로건", value: data?.logosEntity?.slogan || "N/A" },
    ];

    useEffect(() => {
        if (resultCode === 'fail' && resultMessage) {
            setModal({
                status: true,
                title: "",
                content: (
                    <div className="text-center">
                        <p className="text-lg">{resultMessage}</p>
                        <p className="text-xs mt-1">{resultStatus}</p>
                    </div>
                ),
            });
        }
    }, [resultCode, resultMessage, resultStatus]);

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://nsp.pay.naver.com/sdk/js/naverpay.min.js";
        script.async = true;

        script.onload = () => {
            window.oPay = window.Naver.Pay.create({
                mode: "development",
                clientId: "HN3GGCMDdTgGUfl0kFCo",
                chainId: "UHJBaHJXTk4rZDd",
            });
        };

        document.body.appendChild(script);

        postOrdersLogosMutation({
            userId: userId,
            productId: Number(cardId),
        });
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const handlePaymentSelect = (paymentMethod) => {
        setSelectedPaymentMethod(paymentMethod);
    };
    const handleFileFormatSelect = (selectedData) => {
        setSelectedFileFormat(selectedData);
        
    };

    const handleBuyClick = async () => {
        if (!selectedPaymentMethod?.name) {
            toast.error("결제수단을 선택해주세요");
            return;
        }

        const body = {
            orderId: data?.order?.id,
            userId,
            amount: 1000,
            paymentMethod: "",
            paymentStatus: "PENDING",
        };

        const paymentActions = {
            [PaymentMethodEnum.NAVER_PAY]: async () => {
                body.paymentMethod = "NAVER_PAY";
                const resNaver = await postNaverPayInitialize(body);
                if (!window.oPay) {
                    alert("Naver Pay SDK is not loaded yet.");
                    return;
                }
                if (resNaver?.data.id) {
                    window.oPay.open({
                        merchantPayKey: "6931022d-b25b-4233-89d9-9188c434aa2f",
                        productName: "Card",
                        productCount: "1",
                        totalPayAmount: body.amount,
                        taxScopeAmount: body.amount,
                        taxExScopeAmount: "0",
                        returnUrl: `${process.env.REACT_APP_BASE_URL}/payments/naver-pay/callback?orderId=${body.orderId}`,
                    });
                }
            },
            [PaymentMethodEnum.REAL_TIME_ACCOUNT_TRANSFER]: async () => {
                body.paymentMethod = "TOSS_PAYMENT_CARD";
                body.tossPaymentMethod = "CARD";
                const resTossCard = await postTossPaymentInitialize(body);
                const url = resTossCard?.data?.url;

                if (url) {
                    window.location.href = url;
                } else {
                    console.error("No URL returned from Toss Payment.");
                }
            },
            [PaymentMethodEnum.GENERAL_CARD_PAYMENT]: async () => {
                body.paymentMethod = "TOSS_PAYMENT_TRANSFER";
                body.tossPaymentMethod = "TRANSFER";
                const resTossTransfer = await postTossPaymentInitialize(body);
                const urlTransfer = resTossTransfer?.data?.url;

                if (urlTransfer) {
                    window.location.href = urlTransfer;
                } else {
                    console.error(
                        "No URL returned from Toss Payment Transfer.",
                    );
                }
            },
            [PaymentMethodEnum.KAKAO_PAY]: async () => {
                body.paymentMethod = "KAKAO_PAY";
                const resKakao = await postKakaoInitialize(body);
                const urlKakao = resKakao?.data?.next_redirect_pc_url;

                if (urlKakao) {
                    window.location.href = urlKakao;
                } else {
                    console.error("No URL returned from Kakao Pay.");
                }
            },
        };

        const action = paymentActions[selectedPaymentMethod.name];
        if (action) {
            await action();
        } else {
            console.error("Invalid payment method selected.");
        }
    };

    const handleBackClick = () => {
        window.history.back();
    };

    return (
        <main className="rounded-none px-20 py-10 max-md:px-5">
            <div className="flex gap-5 max-md:flex-col">
                <section className="flex w-[63%] flex-col max-md:ml-0 max-md:w-full">
                    <div className="flex grow flex-col max-md:mt-10 max-md:max-w-full">
                        <div className="flex w-full flex-col max-md:max-w-full">
                            <h2 className="w-full border-b-2 border-neutral-800 pb-4 text-2xl font-bold leading-none text-zinc-800 max-md:max-w-full">
                                구매 상품 정보
                            </h2>
                            <ProductImage
                                imageProduct={data?.logosEntity?.filePath}
                            />
                        </div>

                        <LogoInputInfo infoItems={infoItems} />
                        <FileFormatSelection
                            fileFormats={data?.paymentInfo?.formatOptions || []}
                            onSelect={handleFileFormatSelect}
                        />
                        <FileFormatRecommendation />
                        <PaymentMethod onPaymentSelect={handlePaymentSelect} />
                        <TotalPayment amount={selectedFileFormat?.price ?? 0} />
                    </div>
                </section>

                <aside className="flex w-[37%] flex-col justify-between py-10 max-md:ml-0 max-md:w-full">
                    <div className="sticky top-10">
                        <PurchaseInfo
                            onBuyClick={handleBuyClick}
                            onBackClick={handleBackClick}
                        />
                    </div>

                
                </aside>
            </div>
            <ModalComponent
                title={modal.title}
                open={modal.status}
                content={modal.content}
                hideIconClose={false}
                onCloseIcon={() => setModal(initModal)}
            />
        </main>
    );
}

export default PurchasePage;
