import React from 'react';

const ConsultationRequestCard = ({ tab, requestType, dateTime, requestDetails, companyName, contactPerson, email, phoneNumber }) => {

    return (
        <article className="flex flex-col flex-1 shrink justify-center self-stretch p-6 my-auto bg-white rounded-md border border-gray-200 border-solid basis-0 min-w-[240px] w-[488px] max-md:px-5 max-md:max-w-full">
            <div className="flex flex-col w-full max-md:max-w-full">
                <header className="flex gap-10 justify-between items-center w-full max-md:max-w-full">
                    <div className="self-stretch px-2.5 py-2 my-auto text-sm font-bold text-white bg-indigo-600 rounded-md">
                        {requestType}
                    </div>
                    <time className="self-stretch my-auto text-base text-neutral-700">
                        {dateTime}
                    </time>
                </header>
                <div className="flex flex-col mt-6 w-full max-md:max-w-full">
                    <div className="flex flex-col px-2.5 w-full max-md:max-w-full">
                        <h2 className="w-full text-base font-bold text-zinc-800">
                            {tab === 0 ? '요청사항' : '프로젝트 의뢰'}
                        </h2>
                        <p className="mt-2 text-xl text-neutral-700">
                            {requestDetails}
                        </p>
                    </div>
                    <div className="flex gap-6 items-start px-5 py-4 mt-5 w-full text-sm rounded-md bg-slate-50 max-md:max-w-full">
                        <div className="flex flex-col w-[189px]">
                            <div className="flex items-center w-full">
                                <dl className="flex flex-col justify-center self-stretch my-auto font-bold text-zinc-800 w-[50px]">
                                    <dt>회사</dt>
                                    <dd className="mt-3">담당자</dd>
                                </dl>
                                <dl className="flex flex-col justify-center self-stretch my-auto text-neutral-700 w-[70px]">
                                    <dd>{companyName}</dd>
                                    <dd className="mt-3">{contactPerson}</dd>
                                </dl>
                            </div>
                        </div>
                        <div className="flex flex-col w-44">
                            <div className="flex items-center w-full">
                                <dl className="flex flex-col justify-center self-stretch my-auto font-bold text-zinc-800 w-[50px]">
                                    <dt>이메일</dt>
                                    <dd className="mt-3">연락처</dd>
                                </dl>
                                <dl className="flex flex-col justify-center self-stretch my-auto text-neutral-700 w-[126px]">
                                    <dd>{email}</dd>
                                    <dd className="mt-3">{phoneNumber}</dd>
                                </dl>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </article>
    );
};

export default ConsultationRequestCard;