import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    statusLoading: false,
    accessToken: sessionStorage.getItem("accessToken") || null,

};

export const commonSlice = createSlice({
    name: "common",
    initialState,
    reducers: {
        setStatusLoading: (state, action) => {
            state.statusLoading = action.payload;
        },
        setAccessToken: (state, action) => {
            state.accessToken = action.payload;
            if (action.payload) {
                sessionStorage.setItem("accessToken", action.payload); 
            } else {
                sessionStorage.removeItem("accessToken"); 
            }
        },
        clearAccessToken: (state) => {
            state.accessToken = null;
            sessionStorage.removeItem("accessToken");
        },
    },
});

export const { setStatusLoading, setAccessToken, clearAccessToken } = commonSlice.actions;

export default commonSlice.reducer;
