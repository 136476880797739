import React from "react";
import images from "../../images";

const NotificationItem = ({ title, content, date, iconSrc, isRead }) => {
    return (
        <article
            className={`mt-2.5 flex w-full gap-2.5 overflow-hidden rounded-xl border border-solid border-gray-200 bg-white px-5 py-6 max-md:max-w-full`}
        >
            <div className="flex w-full min-w-[240px] flex-1 shrink basis-0 flex-col max-md:max-w-full">
                <div className="flex w-full flex-wrap items-center justify-between gap-10 text-base font-semibold leading-none text-zinc-800 max-md:max-w-full">
                    <div className="my-auto flex w-[474px] min-w-[240px] items-center gap-2.5 self-stretch max-md:max-w-full">
                        <img
                            loading="lazy"
                            src={iconSrc}
                            alt=""
                            className="my-auto aspect-square w-6 shrink-0 self-stretch rounded-none object-contain"
                        />
                        <h2
                            data-layername="알림타이틀이이곳에출력됩니다"
                            className="my-auto self-stretch"
                        >
                            {title}
                        </h2>
                    </div>
                    <img
                        loading="lazy"
                        src={images.closetIcon}
                        alt=""
                        className="my-auto aspect-square w-2 shrink-0 cursor-pointer self-stretch rounded-none object-contain"
                    />
                </div>
                <div className="mt-1.5 flex w-full flex-col px-9 max-md:max-w-full max-md:px-5">
                    <p
                        data-layername="안내내용이이곳에출력됩니다클릭시관련페이지로이동됩니다"
                        className="text-base leading-6 text-neutral-600"
                    >
                        {content}
                    </p>
                    <time
                        data-layername="202406150000"
                        className="mt-4 text-sm leading-none text-zinc-500"
                    >
                        {date}
                    </time>
                </div>
            </div>
        </article>
    );
};

export default NotificationItem;
