import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import InfoSection from "../../../components/common/InfoSection/InfoSection";
import ProductImage from "./ProductImage";
import FileFormatSelectionBusinessCard from "./FileFormatSelectionBusinessCard";
import PaymentMethod from "../../../components/common/PaymentMethod/PaymentMethod";
import TotalPayment from "../../../components/common/PaymentMethod/TotalPayment";
import PurchaseInfo from "../../Logo/BuyLogo/PurchaseInfo";
import PaymentMethodEnum from "../../../utils/constants/enum/paymentMethodEnum";

import {
    useGetBusinessCardsByIdQuery,
    usePostBusinessCardsOrdersMutation,
    usePostKakaoInitializeMutation,
    usePostNaverPayInitializeMutation,
    usePostTossPaymentInitializeMutation,
} from "../../../services/apiSlice";
import { toast } from "react-toastify";
import ModalComponent from "../../../components/common/Modals/ModalComponent";
const BusinessCardPurchase = () => {
    const params = new URLSearchParams(window.location.search);
    const resultCode = params.get("resultCode");
    const resultMessage = params.get("resultMessage");
    const resultStatus = params.get("resultStatus");
    const { id: cardId } = useParams();
    const navigate = useNavigate();
    const [postKakaoInitialize, { data: dataKakao }] =
        usePostKakaoInitializeMutation();

    const [postTossPaymentInitialize, { data: dataTossPayment }] =
        usePostTossPaymentInitializeMutation();

    const [postNaverPayInitialize, { data: dataNaverPay }] =
        usePostNaverPayInitializeMutation();

    // Redux state
    const { userData } = useSelector((state) => state.common);
    const userId = userData?.userID || null;

    // Queries and Mutations
    const { data, isLoading } = useGetBusinessCardsByIdQuery({ cardId });
    const [postOrders] = usePostBusinessCardsOrdersMutation();

    // Local state
    const [dataOrderRespone, setDataOrderRespon] = useState([]);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
    const [selectedOption, setSelectedOption] = useState(null);

    const initModal = {
        status: false,
        title: "",
        content: "",
    };
    const [modal, setModal] = useState(initModal);

    // Extracted data
    const imageBussinessCardFront = data?.filePathFront;
    const imageBussinessCardBack = data?.filePathBack;

    const infoItems = [
        dataOrderRespone?.data?.businessCardEntity?.businessCardTypeTitle && {
            label: "명함 타입",
            value: dataOrderRespone?.data?.businessCardEntity
                ?.businessCardTypeTitle,
        },

        dataOrderRespone?.data?.businessCardEntity?.workName && {
            label: "작업명",
            value: dataOrderRespone?.data?.businessCardEntity?.workName,
        },

        dataOrderRespone?.data?.businessCardEntity?.name && {
            label: "이름",
            value: dataOrderRespone?.data?.businessCardEntity?.name,
        },

        dataOrderRespone?.data?.businessCardEntity?.position && {
            label: "직책",
            value: dataOrderRespone?.data?.businessCardEntity?.position,
        },

        dataOrderRespone?.data?.businessCardEntity?.email && {
            label: "이메일",
            value: dataOrderRespone?.data?.businessCardEntity?.email,
        },

        dataOrderRespone?.data?.businessCardEntity?.phone && {
            label: "전화번호",
            value: dataOrderRespone?.data?.businessCardEntity?.phone,
        },

        dataOrderRespone?.data?.businessCardEntity?.fax && {
            label: "팩스",
            value: dataOrderRespone?.data?.businessCardEntity?.fax,
        },

        //==========
        dataOrderRespone?.data?.businessCardEntity?.snsCreatorName && {
            label: "크리에이터명",
            value: dataOrderRespone?.data?.businessCardEntity?.snsCreatorName,
        },

        dataOrderRespone?.data?.businessCardEntity?.snsPhoneNumber && {
            label: "전화번호",
            value: dataOrderRespone?.data?.businessCardEntity?.snsPhoneNumber,
        },

        dataOrderRespone?.data?.businessCardEntity?.snsAddress && {
            label: "SNS 주소",
            value: dataOrderRespone?.data?.businessCardEntity?.snsAddress,
        },

        //==========

        dataOrderRespone?.data?.businessCardEntity?.storeName && {
            label: "매장",
            value: dataOrderRespone?.data?.businessCardEntity?.storeName,
        },

        dataOrderRespone?.data?.businessCardEntity?.storePhoneNumber && {
            label: "기업 전화번호",
            value: dataOrderRespone?.data?.businessCardEntity?.storePhoneNumber,
        },

        dataOrderRespone?.data?.businessCardEntity?.storeSnsLink && {
            label: "기업 SNS 링크",
            value: dataOrderRespone?.data?.businessCardEntity?.storeSnsLink,
        },
        dataOrderRespone?.data?.businessCardEntity?.storeAddress && {
            label: "매장 주소",
            value: dataOrderRespone?.data?.businessCardEntity?.storeAddress,
        },
    ];

    useEffect(() => {
        if (resultCode === 'fail' && resultMessage) {
            setModal({
                status: true,
                title: "",
                content: (
                    <div className="text-center">
                        <p className="text-lg">{resultMessage}</p>
                        <p className="text-xs mt-1">{resultStatus}</p>
                    </div>
                ),
            });
        }
    }, [resultCode, resultMessage, resultStatus]);

    const handleCreateOrders = useCallback(async () => {
        if (userId && data) {
            try {
                const response = await postOrders({
                    userId,
                    productId: data?.id,
                });
                setDataOrderRespon(response);
            } catch (error) {
                console.error("Error creating order:", error);
            }
        }
    }, [userId, data, postOrders]);

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://nsp.pay.naver.com/sdk/js/naverpay.min.js";
        script.async = true;

        script.onload = () => {
            window.oPay = window.Naver.Pay.create({
                mode: "development",
                clientId: "HN3GGCMDdTgGUfl0kFCo",
                chainId: "UHJBaHJXTk4rZDd",
            });
        };

        // NAVER_SANDBOX_MERCHANT_ID=np_lhbcj393424
        // NAVER_SANDBOX_CLIENT_ID=HN3GGCMDdTgGUfl0kFCo
        // NAVER_SANDBOX_CLIENT_SECRET=ftZjkkRNMR
        // NAVER_SANDBOX_CHAIN_ID=UHJBaHJXTk4rZDd

        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    useEffect(() => {
        handleCreateOrders();
    }, [handleCreateOrders]);

    const handleBuyClick = async () => {
        if (!selectedPaymentMethod?.name) {
            toast.error("결제수단을 선택해주세요");
            return;
        }

        const body = {
            orderId: dataOrderRespone?.data?.order?.id,
            userId,
            amount: selectedOption?.price,
            paymentMethod: "",
            paymentStatus: "PENDING",
        };

        const paymentMethods = {
            [PaymentMethodEnum.NAVER_PAY]: async () => {
                body.paymentMethod = "NAVER_PAY";
                const resNaver = await postNaverPayInitialize(body);
                if (!window.oPay && resNaver?.data.id) {
                    alert("Naver Pay SDK is not loaded yet.");
                    return;
                }
                window.oPay.open({
                    merchantPayKey: "6931022d-b25b-4233-89d9-9188c434aa2f",
                    productName: "Card",
                    productCount: "1",
                    totalPayAmount: body.amount,
                    taxScopeAmount: body.amount,
                    taxExScopeAmount: "0",
                    returnUrl: `${process.env.REACT_APP_BASE_URL}/payments/naver-pay/callback?paymentId=${resNaver?.id}`,
                });
            },
            [PaymentMethodEnum.GENERAL_CARD_PAYMENT]: async () => {
                body.paymentMethod = "TOSS_PAYMENT_CARD";
                body.tossPaymentMethod = "CARD";
                const resTossCard = await postTossPaymentInitialize(body);
                handleOpenNewTab(resTossCard?.data?.url);
            },
            [PaymentMethodEnum.REAL_TIME_ACCOUNT_TRANSFER]: async () => {
                body.paymentMethod = "TOSS_PAYMENT_TRANSFER";
                body.tossPaymentMethod = "TRANSFER";
                const resTossTransfer = await postTossPaymentInitialize(body);
                handleOpenNewTab(resTossTransfer?.data?.url);
            },
            [PaymentMethodEnum.KAKAO_PAY]: async () => {
                body.paymentMethod = "KAKAO_PAY";
                const resKakao = await postKakaoInitialize(body);
                handleOpenNewTab(resKakao?.data?.next_redirect_pc_url);
            },
        };

        const handleOpenNewTab = (url) => {
            if (url) {
                window.open(url, "_blank");
            } else {
                console.error("No URL returned from the response");
            }
        };

        try {
            const paymentAction = paymentMethods[selectedPaymentMethod.name];
            if (paymentAction) {
                await paymentAction();
            } else {
                console.error("Invalid payment method");
            }
        } catch (error) {
            console.error("Payment process error:", error);
        }
    };
    const handleBackClick = () => {
        window.history.back();
        // alert("이전으로 버튼을 클릭했습니다!");
    };

    return (
        <main className="rounded-none px-20 pb-10 max-md:px-5">
            <div className="flex w-full flex-col justify-center px-72 py-14 text-center text-3xl font-bold leading-none text-black max-md:max-w-full max-md:px-5 max-md:py-0">
                <div className="flex w-[878px] max-w-full flex-col items-center">
                    <h1>구매하기</h1>
                </div>
            </div>
            <div className="flex gap-5 max-md:flex-col">
                <section className="flex w-[63%] flex-col max-md:ml-0 max-md:w-full">
                    <div className="flex grow flex-col max-md:mt-10 max-md:max-w-full">
                        <div className="flex w-full flex-col max-md:max-w-full">
                            <h2 className="w-full border-b-2 border-neutral-800 pb-4 text-2xl font-bold leading-none text-zinc-800 max-md:max-w-full">
                                구매 상품 정보
                            </h2>
                            <ProductImage
                                imageBussinessCardFront={
                                    imageBussinessCardFront
                                }
                                imageBussinessCardBack={imageBussinessCardBack}
                            />
                        </div>

                        <InfoSection
                            title="명함 입력 정보"
                            infoItems={infoItems}
                        />
                        <FileFormatSelectionBusinessCard
                            onOptionSelect={setSelectedOption}
                        />
                        <PaymentMethod
                            onPaymentSelect={setSelectedPaymentMethod}
                        />
                        <TotalPayment amount={selectedOption?.price || 0} />
                    </div>
                </section>

                <aside className="flex w-[37%] flex-col justify-between py-10 max-md:ml-0 max-md:w-full">
                    <div className="sticky top-10">
                        <PurchaseInfo
                            onBuyClick={handleBuyClick}
                            onBackClick={handleBackClick}
                        />
                    </div>
                </aside>
            </div>
            <ModalComponent
                title={modal.title}
                open={modal.status}
                content={modal.content}
                hideIconClose={false}
                onCloseIcon={() => setModal(initModal)}
            />
        </main>
    );
};

export default BusinessCardPurchase;
