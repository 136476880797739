import * as React from "react";
import ImageListItem from "./ImageListItem";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

const listData = [
    {
        id: 1,
        imageSrc: "https://cdn.builder.io/api/v1/image/assets/608912cecb5144cf887238b12afdb74b/bb77dcf20769236a140914416e09f5df3bbbabd68d11f5c3dffa21747b00d6de?apiKey=608912cecb5144cf887238b12afdb74b&",
        title: "KASCA 최종 로고",
        date: "2024-09-25",
        extension: "JPG"
    },
    {
        id: 2,
        imageSrc: "https://cdn.builder.io/api/v1/image/assets/608912cecb5144cf887238b12afdb74b/55d4a7c392f0c1459f3da93a4238a18a98d0d207cc2904af35467c05b6da3442?apiKey=608912cecb5144cf887238b12afdb74b&",
        title: "OOP 로고",
        date: "2024-09-25",
        extension: "PNG"
    },
    {
        id: 3,
        imageSrc: "https://cdn.builder.io/api/v1/image/assets/608912cecb5144cf887238b12afdb74b/192b860d072d1ae329845152f7b35a6582d32dfbf38acdf2cbe7b72b41fdd48d?apiKey=608912cecb5144cf887238b12afdb74b&",
        title: "더블체크 아카데미 로고 005",
        date: "2024-09-25",
        extension: "JPG"
    }
];

function ImageList() {
    const renderRadio = () => {
        return (
            <Radio sx={{
                color: '#3533FF',
                '&.Mui-checked': {
                    color: '#3533FF',
                },
            }} />
        )
    }

    const renderContentRadio = (imageSrc, title, date, extension) => {
        return (
            <div className="flex gap-5 items-center self-stretch my-auto text-base leading-none min-w-[240px] text-zinc-800">
                <img
                    src={imageSrc}
                    alt={title}
                    className="object-contain shrink-0 self-stretch my-auto rounded aspect-[1.33] w-[120px]"
                />
                <div className="flex gap-5 items-center self-stretch my-auto">
                    <div className="flex flex-col justify-center self-stretch my-auto font-bold">
                        <h3>작업명</h3>
                        <p className="mt-4">구매 날짜</p>
                        <p className="mt-4">확장자</p>
                    </div>
                    <div className="flex flex-col justify-center self-stretch my-auto">
                        <p>{title}</p>
                        <p className="mt-4">{date}</p>
                        <p className="mt-4">{extension}</p>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <section className="flex flex-col max-w-[450px] mt-5">
            <FormLabel id="demo-radio-buttons-group-label" className='text-base font-bold leading-none text-neutral-700'>로고 파일 등록하기</FormLabel>

            <FormControl>
                <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue={1}
                    name="radio-buttons-group"
                >
                    {listData.map((item, index) => (
                        <FormControlLabel className='mt-4' value={item.id} control={renderRadio()} label={renderContentRadio(item.imageSrc, item.title, item.date, item.extension)} />
                    ))}
                </RadioGroup>
            </FormControl>

        </section>
    );
}

export default ImageList;