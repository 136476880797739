import React from "react";
import AIGeneratedLogoDesign from "./AIGeneratedLogoDesign";
import ProfessionalDesignSection from "./ProfessionalDesignSection";

const UsageAndPriceGuideLayout = () => {
  return (
    <main className="flex overflow-hidden flex-col bg-white">
      <AIGeneratedLogoDesign />
      <ProfessionalDesignSection />
    </main>
  );
};

export default UsageAndPriceGuideLayout;
