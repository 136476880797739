import React, { useState } from "react";
import InfoSection from "../../SNSInfomation/InfoSection";
import InputField from "../../../components/common/Input/InputField";
import Button from "../../../components/common/Button/Button";
import images from "../../../images";

const MyInfomation = () => {
    const handleSubmit = (e) => {
        e.preventDefault();
    };

    return (
        <main className="align-items-center flex flex-col rounded-none leading-none">
            <form
                onSubmit={handleSubmit}
                className="flex w-full flex-col items-center justify-center px-14 py-10"
            >
                <div className="flex max-w-full flex-col gap-3 p-10">
                    <InfoSection
                        title="SNS 가입 정보"
                        email="user1234@naver.com"
                        iconSrc={images.naverLogoG}
                    />
                    <InputField
                        label="이름"
                        description="상담 진행 또는 택배 배송 시 확인할 성함을 기재해주세요."
                        placeholder="홍길동"
                    />

                    <InputField
                        label="휴대전화 번호"
                        placeholder="01012345678"
                    />

                    <Button
                        label="인증 번호 받기"
                        className="mt-2.5 bg-zinc-800 text-white"
                    />
                    <div className="mx-auto mt-10">
                        <a className="border-b-2 border-[#555]" href="#">
                            회원탈퇴하기
                        </a>
                    </div>
                </div>
            </form>
        </main>
    );
};

export default MyInfomation;
