import React from "react";
import RequestInfo from "./RequestInfo";
import DetailContent from "./DetailContent";
import AttachmentList from "./AttachmentList";
import Button from "../../../../components/common/Button/Button";

function ConsultationRequestDetail() {
    return (
        <main className="flex flex-col items-center rounded-none">
            <header className="flex flex-col justify-center self-stretch px-72 py-14 w-full text-3xl font-bold leading-none text-center text-black min-h-[139px] max-md:px-5 max-md:max-w-full">
                <h1>상담 요청 상세</h1>
            </header>
            <section className="flex flex-col px-12 py-10 w-full bg-white border border-solid border-neutral-200 max-w-[1000px] max-md:px-5 max-md:max-w-full">
                <RequestInfo
                    requestType="전문가 상담요청"
                    date="2024-09-28 12:00"
                    briefRequest="컬러수정, 위치변경"
                    company="(주)어텐션"
                    contactPerson="홍길동"
                    email="user1234@email.com"
                    phone="01088888888"
                />
                <DetailContent
                    content={`안녕하세요. \n컬러 수정이랑 위치변경 요청 드립니다. \n\n첨부한 로고에서 사용된 레드컬러를 #BC0000 색상으로 변경하고 싶습니다. \n위치는 첨부파일 1번 사항 처럼 옮겨주세요. \n그리고 다른 색상도 추가하고 싶은데, 비용이 어떻게 되는지 궁금합니다.`}
                />
                <AttachmentList
                    files={[
                        { name: "logo001.png", url: "https://cdn.builder.io/api/v1/image/assets/608912cecb5144cf887238b12afdb74b/74b7356f771d5316f8ccdb802cc71d0a23220f02b4cf6549e9b87bd4e537e9b0?apiKey=608912cecb5144cf887238b12afdb74b&" },
                        { name: "logo002.png", url: "https://cdn.builder.io/api/v1/image/assets/608912cecb5144cf887238b12afdb74b/74b7356f771d5316f8ccdb802cc71d0a23220f02b4cf6549e9b87bd4e537e9b0?apiKey=608912cecb5144cf887238b12afdb74b&" },
                        { name: "logo003.png", url: "https://cdn.builder.io/api/v1/image/assets/608912cecb5144cf887238b12afdb74b/74b7356f771d5316f8ccdb802cc71d0a23220f02b4cf6549e9b87bd4e537e9b0?apiKey=608912cecb5144cf887238b12afdb74b&" },
                    ]}
                />
            </section>
            <div className="flex justify-center  mb-20">
                <Button
                    className="gap-2.5 self-stretch px-4 mt-10 w-80 max-w-full text-base font-bold leading-none text-center whitespace-nowrap bg-white rounded-md border border-solid border-neutral-300 min-h-[50px] text-zinc-800"
                    label="목록으로"
                />
            </div>
        </main>
    );
}

export default ConsultationRequestDetail;