import React from 'react';

const ImageCard = ({ src, isSpecial }) => {
  if (isSpecial) {
    return (
      <div className="flex flex-col my-auto rounded min-w-[240px] w-[292px]">
        <div className="flex flex-col px-7 bg-red-500 rounded max-md:px-5">
          <img
            loading="lazy"
            src={src}
            alt="Special Logo Design"
            className="object-contain w-full rounded aspect-[1.05]"
          />
        </div>
      </div>
    );
  }

  return (
    <img
      loading="lazy"
      src={src}
      alt="Logo Design"
      className="object-contain flex-1 shrink rounded aspect-[1.33] basis-0 min-w-[240px] w-[292px]"
    />
  );
};

export default ImageCard;