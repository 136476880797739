import React from 'react';

const PageTitle = ({ title, description }) => (
  <section className="flex flex-col items-center justify-center px-4 py-14 w-full leading-none text-center min-h-[177px] max-md:px-5 max-md:max-w-full">
    <div className="flex flex-col items-center w-full max-w-[878px]">
      <h1 className="text-3xl font-bold text-black whitespace-nowrap">
        {title}
      </h1>
      <p className="mt-5 text-lg text-neutral-700 max-md:max-w-full">
        {description}
      </p>
    </div>
  </section>
);

export default PageTitle;
