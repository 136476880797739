/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import InputField from "./../../components/common/Input/InputField";
import InputCountdown from "./../../components/common/Input/InputCountdown";
import Button from "./../../components/common/Button/Button";
import InfoSection from "./InfoSection";
import { formatDuration } from "./../../utils/helper/date.helper";
import images from "../../images";
import { toast } from "react-toastify"; // Import toast

import ModalComponent from "../../components/common/Modals/ModalComponent";
import {
    useGenerateOTPMutation,
    useVerifyOTPMutation,
} from "../../services/apiSlice";

const SNSInfomation = () => {
    const navigate = useNavigate();

    const [state, setState] = useState({
        showVerificationInput: false,
        verificationCode: "",
        phoneNumber: "",
        name: "",
        duration: 0,
        email: "",
        startCountdown: false,
    });
    const initModal = {
        status: false,
        type: "",
        title: "",
        content: "",
    };
    const [modal, setModal] = useState(initModal);

    const [generateOTP] = useGenerateOTPMutation();
    const [verifyOTP, { loading, error, data }] = useVerifyOTPMutation();

    useEffect(() => {
        if (data && data?.otp !== "" && !modal.status) {
            setModal({
                ...modal,
                type: "verify",
                status: true,
                title: "가입완료",
                content:
                    "환영합니다!\n회원가입이 완료되었습니다.\n디자인 고민은 로고로지에게 맡기고,\n아이디어를 현실로 만들어 보세요!",
            });
        }
    }, [data]);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const emailFromURL = params.get("email");
        if (emailFromURL) {
            setState((prevState) => ({
                ...prevState,
                email: emailFromURL,
            }));
        }
    }, []);

    const handleVerificationClick = async (e) => {
        e.preventDefault();

        try {
            const response = await generateOTP({
                email: state.email,
                phone: state.phoneNumber,
                user_name: state.name,
            }).unwrap();

            localStorage.setItem("dataGenerateOTP", JSON.stringify(response));

            setState((prevState) => ({
                ...prevState,
                showVerificationInput: true,
                startCountdown: true,
                duration: 180,
            }));

            const timer = setInterval(() => {
                setState((prevState) => {
                    const newDuration = prevState.duration - 1;
                    if (newDuration <= 0) {
                        clearInterval(timer);
                        return { ...prevState, duration: 0 };
                    }
                    return { ...prevState, duration: newDuration };
                });
            }, 1000);
        } catch (error) {
            console.error("Error generating OTP:", error);
        }
    };

    const handleVerifyClick = async () => {
        if (!state.verificationCode) return;

        try {
            const response = await verifyOTP({
                email: state.email,
                otp: state.verificationCode,
            });

            if (response.data && response.data.verified === true) {
                toast.success("OTP Verified Successfully!");
                setState((prevState) => ({
                    ...prevState,
                    duration: 0,
                    startCountdown: false,
                }));
            } else {
                toast.error("OTP Verification Failed!");
            }
        } catch (err) {
            console.error("Error verifying OTP", err);
        }
    };

    const signUpcomplete = () => {
        navigate("/login");
    };

    const handlePhoneNumberChange = (e) => {
        setState((prevState) => ({
            ...prevState,
            phoneNumber: e.target.value,
        }));
    };

    const handleNameChange = (e) => {
        setState((prevState) => ({
            ...prevState,
            name: e.target.value,
        }));
    };

    const handleInputChange = (e) => {
        setState((prevState) => ({
            ...prevState,
            verificationCode: e.target.value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    const renderBtnSubmit = (type) => {
        let value = {};

        switch (type) {
            case "verify":
                value = {
                    buttonClass:
                        "bg-main-color text-white px-4 py-2 rounded w-full",
                    label: "로고로지 시작하기",
                    onSubmit: () => {
                        setModal(initModal);
                    },
                };
                break;

            default:
                break;
        }

        return value;
    };

    const isInputFilled = state.verificationCode.length > 0;

    return (
        <main className="align-items-center flex flex-col rounded-none leading-none">
            <header className="mt-10 flex w-full flex-col self-center text-center">
                <h1 className="flex max-w-full flex-col self-center font-bold">
                    <span className="text-base text-main-color">회원가입</span>
                    <span className="mt-2.5 self-center text-3xl text-zinc-800">
                        추가 정보 입력하기
                    </span>
                </h1>
                <p className="mt-4 text-base text-neutral-700">
                    가입 정보 확인 후 사용하실 <strong>이름</strong>과{" "}
                    <strong>휴대전화번호</strong>를 입력해주세요.
                </p>
            </header>
            <form
                onSubmit={handleSubmit}
                className="flex w-full flex-col items-center justify-center px-14 py-10"
            >
                <div className="flex max-w-full flex-col rounded-md border border-solid border-gray-200 p-10">
                    <InfoSection
                        title="SNS 가입 정보"
                        email={state.email}
                        iconSrc={images.naverLogoG}
                    />
                    <InputField
                        label="이름"
                        description="상담 진행 또는 택배 배송 시 확인할 성함을 기재해주세요."
                        placeholder="홍길동"
                        value={state.name}
                        onChange={handleNameChange}
                    />

                    <InputCountdown
                        value={state.phoneNumber}
                        duration={formatDuration(state.duration)}
                        onChange={handlePhoneNumberChange}
                        label="휴대전화 번호"
                        placeholder="휴대전화 번호를 입력해주세요."
                        startCountdown={state.startCountdown}
                    />
                    <Button
                        label="인증 번호 받기"
                        className="mt-2.5 bg-zinc-800 text-white"
                        onClick={handleVerificationClick}
                    />
                    {state.showVerificationInput && (
                        <>
                            <div className="mt-2.5 text-xs leading-none text-blue-600">
                                인증 번호를 전송하였습니다.
                            </div>
                            <InputField
                                label=""
                                placeholder="인증 번호를 입력해주세요."
                                className="mt-0"
                                value={state.verificationCode}
                                onChange={handleInputChange}
                            />
                            <Button
                                label="인증 확인"
                                onClick={handleVerifyClick}
                                disabled={!isInputFilled || loading}
                                className={`mt-2.5 ${
                                    isInputFilled
                                        ? "bg-zinc-800 text-white"
                                        : "bg-[#E8E8E8] text-black"
                                }`}
                            />
                        </>
                    )}
                    <Button
                        label="가입 완료"
                        onClick={signUpcomplete}
                        className="mt-9 bg-indigo-600 text-white"
                    />
                </div>
            </form>
            <ModalComponent
                title={modal.title}
                titleClass="flex justify-center"
                open={modal.status}
                content={modal.content}
                contentClass="text-center"
                buttonSubmit={renderBtnSubmit("verify")}
            />
        </main>
    );
};

export default SNSInfomation;
