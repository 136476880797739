import React, { useEffect, useState } from "react";
import Logo from "./Logo";
import ButtonComponent from "../../components/common/Button/ButtonComponent";
import images from "../../images";
import Copyright from "../LayoutCommon/Copyright";
import { setStatusLoading } from "../../redux/featuresSlice/commonSlice";
import { useDispatch } from "react-redux";

const Login = () => {
    // const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = new URLSearchParams(window.location.search);
    const cardId = params.get("productId");
    const orderType = params.get("productId");
    const handleLogin = async (platform) => {
        dispatch(setStatusLoading(true));
        let loginUrl =
            `${process.env.REACT_APP_BASE_URL}/auth/${platform}${cardId ? `?productId=${cardId}&orderType=${orderType}` : ""}` ??
            "";

        window.location.href = loginUrl;
    };

    return (
        <div className="flex flex-col items-center bg-white">
            <Logo />
            {/* <Content /> */}
            <div className="flex w-full min-w-[280px] max-w-[320px] flex-col items-center">
                <ButtonComponent
                    icon={images.naverLogo}
                    variant="naver"
                    className="mt-4"
                    onClick={() => handleLogin("naver")}
                >
                    네이버 로그인
                </ButtonComponent>
                <ButtonComponent
                    icon={images.kakaoLogo}
                    variant="kakao"
                    className="mt-4"
                    onClick={() => handleLogin("kakao")}
                >
                    카카오톡 로그인
                </ButtonComponent>
                <ButtonComponent
                    icon={images.googleLogo}
                    variant="google"
                    className="mt-4"
                    onClick={() => handleLogin("google")}
                >
                    Google로 로그인
                </ButtonComponent>
            </div>
            <div className="my-10">
                <Copyright fontSize="text-xs" />
            </div>
        </div>
    );
};

export default Login;
