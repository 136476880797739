// --notes
// l:left, r:right, b:bottom, t:top, w:white, b:black
// import use images
// import images from "../../images"

// ---
// Import IMAGES
import bannerImg from "./image/banner.png";
import ordersImg from "./image/orders.png";
import logo from "./image/logo.png";
import imgLogin from "./image/img-login.png";

// Import ICONS
import arrowLWIcon from "./icon/arrow-l-w.png";
import arrowBWIcon from "./icon/arrow-b-w.png";
import arrowTWIcon from "./icon/arrow-t-w.png";
import arrowCircleB from "./icon/arrow-circle-b.png";
import penCil from "./icon/penci.svg";
import arrowRWIcon from "./icon/icon-r-blue.svg";
import arrowTRIcon from "./icon/arrow-t-r.svg";
import heartIcon from "./icon/heart.svg";
import naverLogoG from "./icon/naverLogoG.svg";
import naverLogo from "./icon/naver-logo.svg";
import kakaoLogo from "./icon/kakao-logo.svg";
import googleLogo from "./icon/google-logo.svg";
import notiIcon from "./icon/noti-icon.svg";
import profileIcon from "./icon/profile-icon.svg";
import logoutIcon from "./icon/logout-icon.svg";
import closeIcon from "./icon/close-icon.svg";
import arrowTopIcon from "./icon/arrowTopIcon.svg";
import arrowBottomIcon from "./icon/arrowBottomIcon.svg";
import startIcon from "./icon/startIcon.svg";
import arrowLeftIcon from "./icon/arrowLeftIcon.svg";
import bulletPointBIcon from "./icon/bullet-point-b-icon.svg";
import bulletPointWIcon from "./icon/bullet-point-w-icon.svg";
import closeIconB from "./icon/close-icon-b.svg";

//Make a business card - Check the applied business card draft
import businessCarFrontPreview from "./../images/businessCardImage/business_card_front_preview.png";
import businessCarBackPreview from "./../images/businessCardImage/business_card_back_preview.png";
import businessCardIcon from "./../images/businessCardImage/business_car_icon.png";
import businessCardPurchaseIcon from "./../images/businessCardImage/business_card_purchase_icon.png";
import businessCardPurchaseFooter from "./../images/businessCardImage/business_card_purchase_footer.png";
import purchaseCompleteFooter from "./../images/businessCardImage/purchase_complete_footer.png";
import businessCardLayoutFooter from "./../images/businessCardImage/business_card_layout_footer.png";

//Select print type - General business card

import cardPreviewBig from "./businessCardImage/card_preview_big.png";
import cardPreviewSmall from "./businessCardImage/card_preview_small.png";

import InquiryImage from "./image/inquiry_image.png";

import businessCardListTop from "./image/business-card-product.png";
import businessCardListBottom from "./image/business-card-product-b.png";

import GeneralImageBig from "./businessCardImage/generalImageBig.png";
import GeneralImageSmall from "./businessCardImage/generalImageSmall.png";
import GeneralImageSmall2 from "./businessCardImage/generalImageSmall2.png";
import GeneralImageSmall3 from "./businessCardImage/generalImageSmall3.png";
import GeneralImageSmall4 from "./businessCardImage/generalImageSmall4.png";

//Business card making
import Corporate1 from "./businessCardMaking/Corporate1.png";
import Corporate2 from "./businessCardMaking/Corporate2.png";
import Corporate3 from "./businessCardMaking/Corporate3.png";
import Corporate4 from "./businessCardMaking/Corporate4.png";
import Corporate5 from "./businessCardMaking/Corporate5.png";
import Corporate6 from "./businessCardMaking/Corporate6.png";
import CreatorFront1 from "./businessCardMaking/CreatorFront1.png";
import CreatorFront2 from "./businessCardMaking/CreatorFront2.png";
import CreatorFront3 from "./businessCardMaking/CreatorFront3.png";
import CreatorBack1 from "./businessCardMaking/CreatorBack1.png";
import CreatorBack2 from "./businessCardMaking/CreatorBack2.png";
import CreatorBack3 from "./businessCardMaking/CreatorBack3.png";
import StoreFront1 from "./businessCardMaking/StoreFront1.png";
import StoreFront2 from "./businessCardMaking/StoreFront2.png";
import StoreFront3 from "./businessCardMaking/StoreFront3.png";
import StoreBack1 from "./businessCardMaking/StoreBack1.png";
import StoreBack2 from "./businessCardMaking/StoreBack2.png";
import StoreBack3 from "./businessCardMaking/StoreBack3.png";

// Export your image
const images = {
    //images
    bannerImg,
    ordersImg,
    logo,
    imgLogin,
    //icons
    arrowLWIcon,
    arrowBWIcon,
    arrowTWIcon,
    arrowTRIcon,
    penCil,
    arrowCircleB,
    heartIcon,
    naverLogoG,
    naverLogo,
    kakaoLogo,
    googleLogo,
    notiIcon,
    profileIcon,
    logoutIcon,
    closeIcon,
    arrowBottomIcon,
    arrowTopIcon,
    startIcon,
    arrowLeftIcon,
    bulletPointBIcon,
    bulletPointWIcon,
    closeIconB,
    //
    businessCarFrontPreview,
    businessCarBackPreview,
    businessCardIcon,
    businessCardPurchaseIcon,
    businessCardPurchaseFooter,
    purchaseCompleteFooter,
    cardPreviewBig,
    cardPreviewSmall,
    businessCardLayoutFooter,
    InquiryImage,
    arrowRWIcon,
    businessCardListTop,
    businessCardListBottom,
    GeneralImageBig,
    GeneralImageSmall,
    GeneralImageSmall2,
    GeneralImageSmall3,
    GeneralImageSmall4,
    //Business card making

    Corporate1,
    Corporate2,
    Corporate3,
    Corporate4,
    Corporate5,
    Corporate6,
    CreatorFront1,
    CreatorFront2,
    CreatorFront3,
    CreatorBack1,
    CreatorBack2,
    CreatorBack3,
    StoreFront1,
    StoreFront2,
    StoreFront3,
    StoreBack1,
    StoreBack2,
    StoreBack3,
};

export default images;
