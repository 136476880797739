import React from "react";
import Button from "../../../components/common/Button/Button";

function InquiryQuestionItem({ id, title, date, status, handleClick }) {
  const statusClass =
    status === "waiting"
      ? "text-white bg-indigo-600"
      : "bg-slate-200 text-slate-500";

  return (
    <article className="flex flex-wrap gap-8 items-center p-8 w-full border-b border-neutral-200 max-md:px-5 max-md:max-w-full">
      <h2 className="self-stretch my-auto text-xl font-bold text-indigo-950">
        {id}
      </h2>
      <div className="flex flex-wrap flex-1 shrink gap-2.5 justify-between items-center self-stretch my-auto text-base basis-0 min-w-[240px] max-md:max-w-full">
        <div className="flex flex-col self-stretch my-auto min-w-[240px] w-[800px] max-md:max-w-full">
          <h3 className="gap-1.5 self-start font-medium text-zinc-800">
            {title}
          </h3>
          <time className="mt-2.5 text-neutral-700">{date}</time>
        </div>

        <Button
          onClick={handleClick}
          label={status === "waiting" ? "답변 대기" : "답변 완료"}
          className={`gap-2.5 self-stretch px-3 py-2.5 my-auto font-medium rounded ${statusClass}`}
        />
      </div>
    </article>
  );
}

export default InquiryQuestionItem;
