import React, { useState } from 'react';
import LogoItem from './LogoItem';

const LogoMatchingGrid = ({ logoItems, onSelect }) => {
  const [selectedLogoIndex, setSelectedLogoIndex] = useState(null);

  const handleLogoSelect = (index, logoData) => {
    setSelectedLogoIndex(index);
    onSelect(logoData);
  };

  return (
    <div className="flex flex-col self-center mt-5 w-full max-md:max-w-full ">
    <div className="grid grid-cols-2 md:grid-cols-4 gap-4 items-center w-full min-h-[219px] max-md:mt-10 max-md:max-w-full">
      {logoItems.map((item, index) => (
        <LogoItem
          key={index}
          src={item.filePath}
          alt={item.alt}
          isChecked={selectedLogoIndex === index}
          onChange={() => handleLogoSelect(index, item)}
        />
      ))}
    </div>
  </div>
  
  );
};

export default LogoMatchingGrid;


