import React, { useState } from 'react';
import industriesData from './../../../utils/constants/industries.json';

const IndustrySelector = ({ label, onSelect ,error}) => {
  const [selectedIndustries, setSelectedIndustries] = useState([]);

  const handleCheckboxChange = (key) => {
    const updatedSelection = selectedIndustries.includes(key)
      ? selectedIndustries.filter((industry) => industry !== key)
      : [...selectedIndustries, key];

    setSelectedIndustries(updatedSelection);

    onSelect(updatedSelection);
  };

  return (
    <div className="flex flex-col mt-10 w-full text-neutral-700 max-md:max-w-full">
      <div className="text-base font-bold max-md:max-w-full">{label}</div>
      <div className="flex flex-col p-8 mt-4 w-full text-sm whitespace-nowrap rounded-md border border-solid border-zinc-100 max-md:px-5 max-md:max-w-full">
        <div className="flex flex-wrap  items-center w-full max-md:max-w-full gap-8">
          {industriesData.industries.map((industry) => (
            <div key={industry.key} className="flex gap-1 items-center my-auto min-w-[80px]">
              <input
                type="checkbox"
                id={industry.key}
                value={industry.key}
                checked={selectedIndustries.includes(industry.key)}
                onChange={() => handleCheckboxChange(industry.key)}
                className="w-4 h-4 text-main-color bg-gray-100 border-gray-300 rounded"
              />
              <label htmlFor={industry.key} className="text-sm font-medium text-gray-900">
                {industry.value}
              </label>
            </div>
          ))}
        </div>
      </div>
      {error && <div className="text-red-600 mt-2">{error}</div>}
    </div>
  );
};

export default IndustrySelector;
