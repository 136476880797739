import { createSlice } from "@reduxjs/toolkit";

const safeGetFromStorage = (storage, key, parse = false) => {
    try {
        const value = storage.getItem(key);
        return parse ? JSON.parse(value) : value;
    } catch {
        return parse ? null : null;
    }
};

const safeSetToStorage = (storage, key, value) => {
    try {
        storage.setItem(key, value);
    } catch {
        console.warn(`Failed to set key "${key}" in storage.`);
    }
};

const safeRemoveFromStorage = (storage, key) => {
    try {
        storage.removeItem(key);
    } catch {
        console.warn(`Failed to remove key "${key}" from storage.`);
    }
};

const initialState = {
    statusLoading: false,
    accessToken: safeGetFromStorage(sessionStorage, "accessToken"),
    refreshToken: safeGetFromStorage(sessionStorage, "refreshToken"),
    userData: safeGetFromStorage(localStorage, "userData", true),
    imageTypesData: [], 
};

// Redux Slice
export const commonSlice = createSlice({
    name: "common",
    initialState,
    reducers: {
        // Set loading status
        setStatusLoading: (state, action) => {
            state.statusLoading = action.payload;
        },

        // Set access token
        setAccessToken: (state, action) => {
            state.accessToken = action.payload;
            if (action.payload) {
                safeSetToStorage(sessionStorage, "accessToken", action.payload);
            } else {
                safeRemoveFromStorage(sessionStorage, "accessToken");
            }
        },

        // Set refresh token
        setRefreshToken: (state, action) => {
            state.refreshToken = action.payload;
            if (action.payload) {
                safeSetToStorage(sessionStorage, "refreshToken", action.payload);
            } else {
                safeRemoveFromStorage(sessionStorage, "refreshToken");
            }
        },

        // Set user data
        setUserData: (state, action) => {
            state.userData = action.payload;
            if (action.payload) {
                safeSetToStorage(localStorage, "userData", JSON.stringify(action.payload));
            } else {
                safeRemoveFromStorage(localStorage, "userData");
            }
        },

        // Clear all session data
        clearAccessToken: (state) => {
            state.accessToken = null;
            state.refreshToken = null;
            state.userData = null;
            safeRemoveFromStorage(sessionStorage, "accessToken");
            safeRemoveFromStorage(sessionStorage, "refreshToken");
            safeRemoveFromStorage(localStorage, "userData");
        },

        // Set image types data
        setImageTypesData: (state, action) => {
            state.imageTypesData = action.payload || [];
        },

        // Clear image types data
        clearImageTypesData: (state) => {
            state.imageTypesData = [];
        },
    },
});

// Export actions
export const {
    setStatusLoading,
    setAccessToken,
    setRefreshToken,
    setUserData,
    clearAccessToken,
    setImageTypesData,
    clearImageTypesData,
} = commonSlice.actions;

// Export reducer
export default commonSlice.reducer;
