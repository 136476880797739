import React from 'react'
import TableCommon from '../../../components/common/CommonTable/TableCommon';
import Button from '../../../components/common/Button/Button';

const LogoDesignData = () => {
    const columns = [
        { label: "결제일", field: "date" },
        { label: "결제 상품 정보", field: "productInfo" },
        { label: "확장자", field: "fileType" },
        { label: "결제금액", field: "price" },
        { label: "결제취소", field: "cancelable" },
      ];

    
    const logoDesignData = [
        {
          date: '2024-09-20 10:00',
          productInfo:  '로고 - PNG 로고이미지001' ,
          fileType: 'PNG',
          price: '29,900원',
          cancelable: false
        },
        {
          date: '2024-09-20 10:00',
          productInfo:  '로고 - JPG 로고005' ,
          fileType: 'JPG',
          price: '29,900원',
          cancelable: false
        },
        {
          date: '2024-09-20 10:00',
          productInfo:  '로고 - 벡터 파일 (.ai) OOP 로고' ,
          fileType: 'ai',
          price: '79,900원',
          cancelable: true
        },
        {
          date: '2024-09-20 10:00',
          productInfo:  '로고 - 벡터 파일 (.ai) OOP 로고' ,
          fileType: 'ai',
          price: '79,900원',
          cancelable: false
        },
        {
          date: '2024-09-20 10:00',
          productInfo:  '로고 - 디자인 리터치 벡터 파일 (.ai) OOP 로고' ,
          fileType: 'ai',
          price: '165,000원',
          cancelable: true
        },
        {
          date: '2024-09-20 10:00',
          productInfo:  '로고 - 디자인 리터치 벡터 파일 (.ai) OOP 로고' ,
          fileType: 'ai',
          price: '165,000원',
          cancelable: false
        }
      ];
  return (
  <>
    <div>
        <TableCommon columns={columns} 
        
        data={logoDesignData.map((item) => ({
            ...item,
            cancelable: (
              <>
                {item.cancelable === true ? (
                  <Button
                    label={"결제 취소"}
                  className="gap-1 self-stretch max-w-full bg-violet-100 rounded-md border border-solid border-slate-200 min-h-[40px] w-[110px] text-indigo-600 font-semibold"
                  />
                ) : (
                  <Button
                    label={"결제 취소"}
                   className="gap-1 self-stretch max-w-full rounded-md border border-solid bg-neutral-200 border-stone-300 min-h-[40px] w-[110px] text-stone-500"
                  />
                )}
              </>
            ),
          }))}
        />
    </div>
  </>
  )
}

export default LogoDesignData