import React from "react";
import InquiryDetailHeader from "./InquiryDetailHeader";
import Button from "../../../../components/common/Button/Button";
import InquiryContent from "./InquiryContent";
import InquiryResponse from "./InquiryResponse";
import images from "../../../../images";
import InquiryAnswer from "./InquiryAnswer";
import { useLocation } from "react-router-dom";

function InquiryPage() {
    const location = useLocation();
    const question = location.state?.question;

    return (
        <main className="flex flex-col rounded-none px-20 max-md:px-5">
            <article className="flex w-full flex-col gap-2 border border-solid border-gray-200 px-12 py-10 max-md:max-w-full max-md:px-5">
                <InquiryDetailHeader
                    title="[1:1 문의]"
                    subtitle="이미지를 많이 넣고 싶은 데, 추가 이미지를 삽입할 수 있을까요?"
                    status="답변 대기"
                    date="2024-09-20 10:00"
                    statusClassName="bg-slate-200 text-slate-500"
                />
                <InquiryContent
                    imageSrc={images.InquiryImage}
                    imageAlt="문의 관련 이미지"
                    text={[
                        "안녕하세요.",
                        "로고로지를 이용하면서 궁금한 점이 있어서 문의드립니다.",
                        "ai 벡터파일로 주문한 로고가 이메일로 오지않았습니다.",
                        "내 제작물 목록에서는 완료상태로 떠서 확인 부탁드립니다.",
                    ]}
                />

                {question.status === "waiting" ? (
                    <InquiryResponse />
                ) : (
                    <InquiryAnswer
                        answerTextLines={[
                            "안녕하세요.",
                            "질문 남겨주신 사항에 대해 답변드립니다.",
                            "해당 사항은 신속히 조치하여 해결하도록 하겠습니다.",
                            "",
                            "로고로지를 이용해주셔서 감사합니다.",
                        ]}
                        imageSrc={images.logo}
                        imageAlt="Company logo"
                        date="2024-09-20 10:00"
                    />
                )}
                {/* */}
            </article>
            <Button
                className="mb-20 mt-12 min-h-[50px] w-80 max-w-full gap-2.5 self-center whitespace-nowrap rounded-md border border-solid border-neutral-300 bg-white px-4 text-center text-base font-bold leading-none text-zinc-800 max-md:mt-10"
                label={"목록으로 돌아가기"}
            />
        </main>
    );
}

export default InquiryPage;
