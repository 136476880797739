import React, { useState } from "react";
import PurchaseCompleteTitle from "./../../Logo/PurchaseComplete/PurchaseCompleteTitle";
import PurchaseInfo from "./../../Logo/PurchaseComplete/PurchaseInfo";
import Button from "../../../components/common/Button/Button";
import images from "../../../images";

const PurchaseCompleteBusinessCard = () => {
  const [state, setState] = useState(1);

  return (
    <div className="flex flex-col justify-center align-items-center text-center">
      <main className="max-w-[500px] mx-auto max-md:px-5">
        <PurchaseCompleteTitle />
        <PurchaseInfo />
        <div className="flex flex-col justify-center mt-9 max-w-full text-base font-bold leading-none text-center ">
          <div className="flex flex-wrap gap-2.5 items-start w-full max-md:max-w-full">
            <Button
              label={"결제 내역 확인"}
              className="grow shrink gap-2.5 self-stretch px-4 bg-white rounded-md border border-solid border-neutral-300 min-h-[50px] min-w-[240px] text-zinc-800 w-[196px]"
            />
            <Button
              label={"파일 다운로드"}
              className="grow shrink gap-2.5 self-stretch px-4 text-white bg-indigo-600 rounded-md min-h-[50px] min-w-[240px] w-[196px]"
            />
          </div>
          <a href="#" className="self-center mt-9 text-zinc-800 ">
            메인으로
          </a>
        </div>

        <section className="flex flex-col px-9 py-9 mt-16 max-w-full leading-none text-white bg-indigo-600 rounded-md  max-md:px-5 max-md:mt-10">
          <h2 className="self-start text-2xl font-semibold leading-none">
            명함 인쇄 도움이 필요하세요?
          </h2>
          <p className="self-start mt-2 text-xl">
            로고로지에서 명함 인쇄 출력까지 연결해드립니다.
          </p>
          {state == 1 ? (
            <>
              <p className="mt-5 text-sm leading-none text-violet-200 max-md:max-w-full">
                ・{" "}
                <span className="font-bold text-violet-200">
                  [마이페이지- 내 제작물 목록]
                </span>
                에서 인쇄 주문 요청이 가능 합니다.
              </p>
            </>
          ) : (
            <>
              <p className="mt-5 text-sm text-start leading-none text-violet-200 max-md:max-w-full">
                ・벡터파일을 요청하신 경우, 작업이 완료된 후 <br />
                <span className="font-bold text-violet-200">
                  [마이페이지- 내 제작물 목록]
                </span>
                에서 인쇄 주문 요청이 가능합니다.
              </p>
            </>
          )}

          <Button
            label={"바로 주문하기"}
            className="gap-2.5 self-stretch px-4 mt-12 text-base font-bold text-center text-main-color bg-white rounded-md border border-white border-solid min-h-[50px] max-md:mt-10 max-md:mr-1"
          />
        </section>
      </main>
      <img
        loading="lazy"
        src={images.businessCardPurchaseFooter}
        alt=""
        className="object-contain self-stretch mt-24 w-full aspect-[7.19] max-md:mt-10 max-md:max-w-full"
      />

      {/* </div> */}
    </div>
  );
};

export default PurchaseCompleteBusinessCard;
