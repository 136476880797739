import React from 'react';

const Pagination = ({ currentPage, totalPages, onPageChange }) => (
  <nav className="flex justify-center items-center self-center mt-8" aria-label="Pagination">
    {/* Nút chuyển đến trang đầu tiên */}
    {/* <button
      aria-label="First page"
      onClick={() => onPageChange(1)}
      disabled={currentPage === 1}
      className="self-stretch px-4 py-3.5 my-auto w-10 text-base font-extrabold leading-none text-center whitespace-nowrap rounded-md"
    >
      First
    </button> */}

    {/* Nút Previous */}
    <button
      aria-label="Previous page"
      onClick={() => onPageChange(currentPage - 1)}
      disabled={currentPage === 1}
    >
      <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.88284 1.13017C4.72663 0.956608 4.47337 0.956608 4.31716 1.13017L1.11716 4.68573C0.960948 4.8593 0.960948 5.1407 1.11716 5.31427L4.31716 8.86982C4.47337 9.04339 4.72663 9.04339 4.88284 8.86982C5.03905 8.69626 5.03905 8.41485 4.88284 8.24129L1.96569 5L4.88284 1.75871C5.03905 1.58515 5.03905 1.30374 4.88284 1.13017Z" fill="#9A9A9A" stroke="#9A9A9A" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </button>

    {/* Các nút trang */}
    {[...Array(totalPages)].map((_, index) => (
      <button
        key={index}
        className={`self-stretch px-4 py-3.5 my-auto w-10 text-base font-extrabold leading-none text-center whitespace-nowrap rounded-md ${currentPage === index + 1 ? 'text-main-color' : 'text-zinc-400'
          }`}
        onClick={() => onPageChange(index + 1)}
      >
        {index + 1}
      </button>
    ))}

    {/* Nút Next */}
    <button
      aria-label="Next page"
      onClick={() => onPageChange(currentPage + 1)}
      disabled={currentPage === totalPages}
    >
      <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.11716 1.13017C1.27337 0.956608 1.52663 0.956608 1.68284 1.13017L4.88284 4.68573C5.03905 4.8593 5.03905 5.1407 4.88284 5.31427L1.68284 8.86982C1.52663 9.04339 1.27337 9.04339 1.11716 8.86982C0.960948 8.69626 0.960948 8.41485 1.11716 8.24129L4.03431 5L1.11716 1.75871C0.960948 1.58515 0.960948 1.30374 1.11716 1.13017Z" fill="#9A9A9A" stroke="#9A9A9A" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </button>

    {/* Nút chuyển đến trang cuối cùng */}
    <button
      aria-label="Last page"
      onClick={() => onPageChange(totalPages)}
      disabled={currentPage === totalPages}
      className="flex"
      style={{ marginLeft: "10px" }}
    >
      <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.11716 1.13017C1.27337 0.956608 1.52663 0.956608 1.68284 1.13017L4.88284 4.68573C5.03905 4.8593 5.03905 5.1407 4.88284 5.31427L1.68284 8.86982C1.52663 9.04339 1.27337 9.04339 1.11716 8.86982C0.960948 8.69626 0.960948 8.41485 1.11716 8.24129L4.03431 5L1.11716 1.75871C0.960948 1.58515 0.960948 1.30374 1.11716 1.13017Z" fill="#9A9A9A" stroke="#9A9A9A" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
      <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.11716 1.13017C1.27337 0.956608 1.52663 0.956608 1.68284 1.13017L4.88284 4.68573C5.03905 4.8593 5.03905 5.1407 4.88284 5.31427L1.68284 8.86982C1.52663 9.04339 1.27337 9.04339 1.11716 8.86982C0.960948 8.69626 0.960948 8.41485 1.11716 8.24129L4.03431 5L1.11716 1.75871C0.960948 1.58515 0.960948 1.30374 1.11716 1.13017Z" fill="#9A9A9A" stroke="#9A9A9A" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </button>

  </nav>
);

export default Pagination;
