import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/common/Button/Button";
import FileFormatOption from "./../../components/common/FileFormatOption/FileFormatOption";
import { useGetBusinessCardTypeQuery } from "../../services/apiSlice";
// import fileFormats from "./../../utils/constants/fileFormatOptionBusinessCard.json";

const ChoseBusinessCardType = () => {
    const navigate = useNavigate();
    const { data, isLoading, error } = useGetBusinessCardTypeQuery();
    const [selectedOption, setSelectedOption] = useState(0);
    const [fileFormats, setFileFormats] = useState([]);
    const [idType, setIdType] = useState(1);

    useEffect(() => {
        if (data) {
            setFileFormats(data);
        }
    }, [data]);
    const handleOptionChange = (index, id) => {
        setIdType(id);
        setSelectedOption(index);
    };
    const goToBusinessCardLayout = () => {
        navigate(`/card/format/${idType}`);
    };

    return (
        <div className="mb-40 flex flex-col bg-white px-20 py-10 max-md:mb-10 max-md:px-5">
            <main>
                <section className="flex min-h-[177px] w-full flex-col justify-center py-14 text-center leading-none max-md:max-w-full max-md:px-5 max-md:py-0">
                    <div className="flex max-w-full flex-col">
                        <h1 className="self-center text-3xl font-bold text-black">
                            명함 타입 선택하기
                        </h1>
                        <p className="mt-5 text-lg text-neutral-700 max-md:max-w-full">
                            로고로지에서 각 타입별 명함 템플릿을 통해 빠르게
                            명함을 제작해 보세요.
                        </p>
                    </div>
                    <div className="mt-5 flex w-full flex-col max-md:max-w-full">
                        <div className="fle-row flex w-full gap-5 max-md:flex max-md:max-w-full max-md:flex-wrap max-md:justify-start">
                            {fileFormats.map((option, index) => (
                                <div
                                    key={index}
                                    className="w-full max-md:w-full max-md:justify-items-center"
                                >
                                    <FileFormatOption
                                        subtitle={option.title}
                                        title={option.type}
                                        features={option.description}
                                        isSelected={selectedOption === index}
                                        onSelectionChange={() =>
                                            handleOptionChange(
                                                index,
                                                option.businessCardsTypeId,
                                            )
                                        }
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
                <div className="flex justify-center">
                    <Button
                        className="mt-8 min-h-[50px] w-80 max-w-full gap-2.5 self-center rounded-md bg-zinc-800 px-4 text-center text-base font-bold leading-none text-white"
                        label={"선택한 타입 샘플 확인하기"}
                        onClick={goToBusinessCardLayout}
                    />
                </div>
            </main>
        </div>
    );
};

export default ChoseBusinessCardType;
