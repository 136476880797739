import React, { useState, useEffect } from "react";
import TableCommon from "../../../components/common/CommonTable/TableCommon";
import Button from "../../../components/common/Button/Button";
import images from "../../../images";
import PaginationComponent from "../../../components/common/Pagination/PaginationComponent";
import {
    useGetListHistoryOrderBusinessCardMutation,
    usePostKakaoInitializeMutation,
    usePostNaverPayInitializeMutation,
    usePostTossPaymentInitializeMutation,
} from "../../../services/apiSlice";
import { useSelector } from "react-redux";
import { extractQuantity, formatDateTime } from "../../../utils/helper/formatters";
import { addDays } from "../../../utils/helper/date.helper";
import { OrderLogoStatus } from "../../../utils/constants/enum/logoStatusEnum";
import { useNavigate } from "react-router-dom";

const MyBusinessCardProductList = () => {
    const [page, setPage] = useState(1);
        const navigate = useNavigate();
    
    const { userData, accessToken } = useSelector((state) => state.common);
    const userId = userData?.userID || null;
    const columns = [
        { label: "작업물 이미지", field: "image" },
        { label: "작업물 정보", field: "date" },
        { label: "확장자", field: "fileType" },
        { label: "다운로드", field: "downloadPeriod" },
        { label: "상태", field: "status" },
        { label: "인쇄", field: "print" },
    ];

    const getStatusColor = (status) => {
        switch (status) {
            case "취소":
                return "#6b7280";
            case "진행중":
                return "#4f46e5";
            case "접수확인중":
                return "#dc2626";
            case "완료":
                return "#1f2937";
            default:
                return "#1f2937";
        }
    };

    const [getListOrderBusinessCard, { data }] =
        useGetListHistoryOrderBusinessCardMutation();

    const [postKakaoInitialize, { data: dataKakao }] =
        usePostKakaoInitializeMutation();

    const [postTossPaymentInitialize, { data: dataTossPayment }] =
        usePostTossPaymentInitializeMutation();

    const [postNaverPayInitialize, { data: dataNaverPay }] =
        usePostNaverPayInitializeMutation();
    useEffect(() => {
        const fetchLogosList = async () => {
            await getListOrderBusinessCard({
                page: page,
                userId: userId,
            });
        };
        fetchLogosList();
    }, [getListOrderBusinessCard, page]);

    const totalPages = data ? data?.meta?.pageCount : 1;

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setPage(newPage);
        }
    };

    const handleDownloadAll = () => {
        const files = [
            data?.data?.businessCard?.filePathFront,
            data?.data?.businessCard?.filePathBack,
        ];

        files.forEach((fileUrl) => {
            if (fileUrl) {
                const link = document.createElement("a");
                link.href = fileUrl;
                link.download = fileUrl.split("/").pop(); // Đặt tên file từ URL
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        });
    };
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://nsp.pay.naver.com/sdk/js/naverpay.min.js";
        script.async = true;

        script.onload = () => {
            window.oPay = window.Naver.Pay.create({
                mode: "development",
                clientId: "HN3GGCMDdTgGUfl0kFCo",
                chainId: "UHJBaHJXTk4rZDd",
            });
        };

        // NAVER_SANDBOX_MERCHANT_ID=np_lhbcj393424
        // NAVER_SANDBOX_CLIENT_ID=HN3GGCMDdTgGUfl0kFCo
        // NAVER_SANDBOX_CLIENT_SECRET=ftZjkkRNMR
        // NAVER_SANDBOX_CHAIN_ID=UHJBaHJXTk4rZDd

        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const repurchaseBusinessCard = async (item) => {
        if (!item) {
            console.error("Item data is missing.");
            return;
        }


        const paymentMethodData = item?.payment?.paymentMethod;
        if (!paymentMethodData) {
            console.error("No payment method found for this item.");
            return;
        }

        const body = {
            orderId: item?.order?.id,
            userId,
            amount: extractQuantity(item?.payment?.amount),
            paymentMethod: "",
            paymentStatus: "PENDING",
        };


        try {
            switch (paymentMethodData) {
                case "NAVER_PAY": {
                    body.paymentMethod = "NAVER_PAY";
                    const resNaver = await postNaverPayInitialize(body);
                    if (!window.oPay || !resNaver?.data?.id) {
                        alert("Naver Pay SDK is not loaded yet.");
                        return;
                    }
                    window.oPay.open({
                        merchantPayKey: "6931022d-b25b-4233-89d9-9188c434aa2f",
                        productName: "Card",
                        productCount: "1",
                        totalPayAmount: body.amount,
                        taxScopeAmount: body.amount,
                        taxExScopeAmount: "0",
                        returnUrl: `${process.env.REACT_APP_BASE_URL}/payments/naver-pay/repurchase?paymentId=${resNaver?.data?.id}`,
                    });
                    break;
                }
                case "TOSS_PAYMENT_CARD": {
                    body.paymentMethod = "TOSS_PAYMENT_CARD";
                    body.tossPaymentMethod = "CARD";
                    const resTossCard = await postTossPaymentInitialize(body);
                    handleOpenNewTab(resTossCard?.data?.url);
                    break;
                }
                case "TOSS_PAYMENT_TRANSFER": {
                    body.paymentMethod = "TOSS_PAYMENT_TRANSFER";
                    body.tossPaymentMethod = "TRANSFER";
                    const resTossTransfer =
                        await postTossPaymentInitialize(body);
                    handleOpenNewTab(resTossTransfer?.data?.url);
                    break;
                }
                case "KAKAO_PAY": {
                    body.paymentMethod = "KAKAO_PAY";
                    const resKakao = await postKakaoInitialize(body);
                    handleOpenNewTab(resKakao?.data?.next_redirect_pc_url);
                    break;
                }
                default:
                    console.error(
                        "Invalid or unsupported payment method:",
                        paymentMethodData,
                    );
            }
        } catch (error) {
            console.error("Payment process error:", error);
        }
    };

    const handleOpenNewTab = (url) => {
        if (url) {
            window.open(url, "_blank");
        } else {
            console.error("No URL returned from the response");
        }
    };
    return (
        <>
            <div className="w-full pb-10">
                <TableCommon
                    columns={columns}
                    data={(data?.data || []).map((item) => ({
                        ...item,

                        image: (
                            <>
                                <div className="flex w-[110px] max-w-full flex-col items-center justify-center">
                                    <img
                                        src={item?.businessCard?.filePathFront}
                                        alt="Product"
                                        className="mt-2.5 aspect-[1.8] w-full object-contain"
                                        style={{ width: 50, height: 50 }}
                                    />
                                    <img
                                        className="mt-2.5 aspect-[1.8] w-full object-contain"
                                        src={item?.businessCard?.filePathBack}
                                        alt="Product"
                                        style={{ width: 50, height: 50 }}
                                    />
                                </div>
                            </>
                        ),

                        date: (
                            <>
                                <div className="flex flex-col">
                                    <span>
                                        {item?.businessCard?.workName ?? ""}
                                    </span>
                                    <div className="flex flex-row">
                                        <div className="min-w-24">구매일</div>{" "}
                                        <div>
                                            {formatDateTime(
                                                item?.order?.createdAt,
                                            )}
                                        </div>
                                    </div>
                                    <div className="flex flex-row">
                                        <div className="min-w-24">
                                            다운로드 기간
                                        </div>{" "}
                                        <div>
                                            {formatDateTime(
                                                item?.order?.createdAt,
                                            )}

                                            {item?.order?.createdAt && (
                                                <>
                                                    ~{" "}
                                                    {formatDateTime(
                                                        addDays(
                                                            item?.order
                                                                ?.createdAt,
                                                            3,
                                                        ),
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </>
                        ),
                        fileType: (
                            <>
                                <span>{item?.order?.receivedOrderType}</span>
                            </>
                        ),

                        downloadPeriod: (
                            <>
                                {new Date() >
                                addDays(item?.businessCard?.createdAt, 3) ? (
                                    <Button
                                        label={"재구매하기"}
                                        onClick={() =>
                                            repurchaseBusinessCard(item)
                                        }
                                        className={`my-auto min-w-[110px] gap-2.5 self-stretch rounded border border-indigo-600 bg-white px-3 py-2.5 font-medium text-indigo-600`}
                                    />
                                ) : (
                                    <div
                                        onClick={handleDownloadAll}
                                        className={`my-auto min-w-[110px] gap-2.5 self-stretch rounded bg-[#E8E8FF] px-3 py-2.5 text-center font-medium text-indigo-600`}
                                    >
                                        {/* <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download={item?.businessCard?.filePath}
                                        
                                        href={item?.businessCard?.filePath}
                                    > */}
                                        다운로드
                                        {/* </a> */}
                                    </div>
                                )}
                            </>
                        ),
                        status: (
                            <span
                                style={{
                                    color: getStatusColor(
                                        OrderLogoStatus[
                                            item?.order?.orderStatus
                                        ],
                                    ),
                                }}
                            >
                                {OrderLogoStatus[item?.order?.orderStatus]}
                            </span>
                        ),
                        print: (
                            <Button
                                onClick={() => navigate(`/card-sprint/${item?.order?.productId}`)}
                                disabled={item?.order?.orderStatus !== 'SUCCESS'}
                                label={"인쇄주문"}
                                className={`my-auto min-w-[110px] gap-2.5 self-stretch rounded border border-[#333333] bg-white px-3 py-2.5 font-medium text-[#333333]`}
                            />
                        ),
                    }))}
                />
            </div>

            <div className="mb-24 flex justify-center">
                <PaginationComponent
                    className="mt-10"
                    count={totalPages}
                    page={page}
                    onPageChange={handlePageChange}
                    showFirstButton={true}
                    showLastButton={true}
                />
            </div>
        </>
    );
};

export default MyBusinessCardProductList;
