import React from "react";

function RequestInfo({
    requestType,
    date,
    briefRequest,
    company,
    contactPerson,
    email,
    phone,
}) {
    return (
        <div className="flex flex-col w-full max-md:max-w-full">
            <div className="flex flex-wrap gap-10 justify-between items-center w-full leading-none max-md:max-w-full">
                <div className="self-stretch px-2.5 py-2 my-auto text-sm font-bold text-white bg-indigo-600 rounded-md">
                    {requestType}
                </div>
                <time className="self-stretch my-auto text-base text-neutral-700">
                    {date}
                </time>
            </div>
            <div className="flex flex-col mt-9 w-full max-md:max-w-full">
                <div className="flex flex-col justify-center w-full leading-none max-md:max-w-full">
                    <div className="flex flex-col px-2.5 w-full max-md:max-w-full">
                        <h2 className="text-base font-bold text-zinc-800 max-md:max-w-full">
                            간단 요청 사항
                        </h2>
                        <p className="self-start mt-4 text-2xl text-center text-neutral-700">
                            {briefRequest}
                        </p>
                    </div>
                    <div className="flex flex-wrap gap-6 justify-center items-center p-6 mt-5 w-full text-base whitespace-nowrap rounded-md bg-slate-50 max-md:px-5 max-md:max-w-full">
                        <div className="flex flex-col flex-1 shrink self-stretch my-auto basis-0 min-w-[240px]">
                            <div className="flex items-center w-full">
                                <div className="flex flex-col justify-center self-stretch my-auto font-bold text-zinc-800 w-[65px]">
                                    <div>회사</div>
                                    <div className="mt-3">담당자</div>
                                </div>
                                <div className="flex flex-col justify-center self-stretch my-auto text-neutral-700 w-[63px]">
                                    <div>{company}</div>
                                    <div className="mt-3">{contactPerson}</div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col flex-1 shrink self-stretch my-auto basis-0 min-w-[240px]">
                            <div className="flex items-center w-full">
                                <div className="flex flex-col justify-center self-stretch my-auto font-bold text-zinc-800 w-[65px]">
                                    <div>이메일</div>
                                    <div className="mt-3">연락처</div>
                                </div>
                                <div className="flex flex-col justify-center self-stretch my-auto text-neutral-700 w-[145px]">
                                    <div>{email}</div>
                                    <div className="mt-3">{phone}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RequestInfo;