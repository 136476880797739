import React from "react";
import images from "../../images";

function LoginButton() {
    return (
        <div className="my-auto flex items-center gap-1 whitespace-nowrap text-right text-base font-medium text-neutral-700">
            <img
                loading="lazy"
                src={images.heartIcon}
                alt=""
                className="my-auto aspect-square w-[18px] shrink-0 self-stretch object-contain"
            />
            <a className="my-auto self-stretch hover:underline" href="/login">
                로그인/회원가입
            </a>
        </div>
    );
}

export default LoginButton;
