import React, { useState, useEffect, useRef } from "react";
import paymentOptionsData from "../../../utils/constants/paymentOptions.json";
// import { CheckoutTossPayment } from "../../../layouts/Tosspayment/Checkout";

function PaymentMethod({ onPaymentSelect }) {
    const [paymentOptions, setPaymentOptions] = useState([]);
    // const tossRef = useRef();

    useEffect(() => {
        setPaymentOptions(
            paymentOptionsData.map((option) => ({
                ...option,
                isSelected: false,
            })),
        );
    }, []);

    // const handleParentClick = () => {
    //   // Gọi hàm trong Child thông qua ref
    //   if (childRef.current) {
    //     childRef.current.handleChildAction();
    //   }
    // };

    const handleOptionClick = (index) => {
        setPaymentOptions((prevOptions) =>
            prevOptions.map((option, i) => ({
                ...option,
                isSelected: i === index,
            })),
        );

        onPaymentSelect(paymentOptions[index]);
    };

    return (
        <section className="mt-24 flex w-full flex-col max-md:mt-10 max-md:max-w-full">
            <h2 className="flex w-full flex-col text-2xl font-bold leading-none text-neutral-700 max-md:max-w-full">
                <div className="gap-2.5 self-start">결제 수단</div>
                <div className="mt-4 flex min-h-[2px] w-full bg-neutral-600 max-md:max-w-full" />
            </h2>
            <div className="mt-5 flex w-full flex-col max-md:max-w-full">
                <div className="flex w-full flex-wrap items-start gap-5 max-md:max-w-full">
                    {/* <div className="w-full max-md:max-w-full grid grid-cols-2 gap-4 place-content-start max-md:flex max-md:flex-wrap max-md:justify-start"> */}

                    {paymentOptions.map((option, index) => (
                        <div
                            key={index}
                            className={`flex max-w-[234px] flex-col items-start justify-center rounded-md border p-5 ${
                                option.isSelected
                                    ? "border-2 border-indigo-600"
                                    : "border-gray-200"
                            } w-full border-solid`}
                            onClick={() => handleOptionClick(index)}
                        >
                            <div className="flex items-center gap-2.5">
                                {option.isSelected ? (
                                    <img
                                        loading="lazy"
                                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/e90c38000c84cfcda576a3fd1b3ecc49cefad2fe3a245a4fc45ef6ea6f52ac91?placeholderIfAbsent=true&apiKey=3ac17b61e0c94c2b87d841ac8b4d3afd"
                                        alt="Selected radio button"
                                        className="my-auto aspect-square w-4 shrink-0 self-stretch rounded-[50px] object-contain"
                                    />
                                ) : (
                                    <div className="my-auto flex w-4 flex-col self-stretch rounded-[50px]">
                                        <div className="flex h-4 shrink-0 rounded-[50px] border border-solid border-zinc-500" />
                                    </div>
                                )}
                                <div className="my-auto flex items-center gap-2 self-stretch whitespace-nowrap text-base leading-none text-zinc-800">
                                    {option.logo && (
                                        <img
                                            loading="lazy"
                                            src={option.logo}
                                            alt={option.logoAlt}
                                            className="my-auto aspect-[4.05] w-[81px] shrink-0 self-stretch rounded-sm object-contain"
                                        />
                                    )}
                                    <div className="my-auto self-stretch">
                                        {option.name}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {/* <CheckoutTossPayment ref={tossRef} /> */}
        </section>
    );
}

export default PaymentMethod;
