import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import LogoUploader from "./LogoUploader";
import BusinessCardForm from "./BusinessCardForm";
import { usePostBusinessCardsMutation } from "../../services/apiSlice";
import ImageComponent from "../../components/FooterBanner";
import Button from "../../components/common/Button/Button";
import LogoType from "../../utils/constants/enum/LogoTypeEnum";

export default function CardCreate() {
    const navigate = useNavigate();
    const { userData, accessToken } = useSelector((state) => state.common);
    const { id } = useParams();
    const userId = userData?.userID || null;
    const location = useLocation();
    const { businessCardsTypeId } = location.state || {};
    const imageTypesData = useSelector(
        (state) => state.common.imageTypesData || [],
    );

    const getImageByType = (type) => {
        return Array.isArray(imageTypesData)
            ? imageTypesData.filter((image) => image.imageType === type)
            : [];
    };

    const [state, setState] = useState({
        selectedTab: 0,
        selectedFile: null,
        workName: "",
        registerLogoType: "",
        fileFormatsAvailable: "",
        formData: {
            name: "",
            position: "",
            email: "",
            phone: "",
            fax: "",
            snsCreatorName: "",
            snsPhoneNumber: "",
            snsAddress: "",
            storeName: "",
            storePhoneNumber: "",
            storeSnsLink: "",
            storeAddress: "",
        },
        formErrors: {},
        workNameErrors: {},
        images: { cardLayoutFooterImage: null },
    });


    const [logoOptionId, setLogoOptionId]= useState(null)
    const [postBusinessCards] = usePostBusinessCardsMutation();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setState((prev) => ({
            ...prev,
            formData: {
                ...prev.formData,
                [name]: value,
            },
            
        })
    
    );

        

        
    };

    const validateFields = (fields) => {
        const errors = {};
        fields.forEach((field) => {
            if (
                !state.formData[field.name] ||
                (field.regex && !field.regex.test(state.formData[field.name]))
            ) {
                errors[field.name] = field.errorMessage;
            }
        });
        return errors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        const registerLogoType =
            state.selectedTab === 0
                ? LogoType.IMPORT_LOGO
                : state.selectedTab === 1
                  ? LogoType.ATTACH_FILE
                  : LogoType.WITHOUT_LOGO;


                  
        const validationFields = getValidationFields(businessCardsTypeId);

        if (!state.workName) {
            setState((prev) => ({
                ...prev,
                workNameErrors: { workName: "워크명을 입력해주세요." },
            }));
        }

        const errors = validateFields(validationFields);

        if (Object.keys(errors).length > 0) {
            setState((prev) => ({ ...prev, formErrors: errors }));
            return;
        }

        const filteredFormData = getFilteredFormData(businessCardsTypeId);


      
        const body = {
            ...state?.formData,
            workName: state.workName,
            businessCardsTypeId: Number(businessCardsTypeId),
            fileFormatId: Number(id),
            logoOptionId: logoOptionId != null ? Number(logoOptionId) : null,
            userId: userId,
            registerLogoType: registerLogoType,
            fileFormatsAvailable: state.selectedFile ?? "",
        };


        
        if (errors === undefined) {
            setState((prev) => ({
                ...prev,
                formErrors: {},
            }));
        }

        try {
            const response = await postBusinessCards(body).unwrap();
            const businessCardId = response?.id;
            navigate(`/card/review/${businessCardId}`);
        } catch (err) {
            console.error("Error:", err);
        }
    };

    const getValidationFields = (businessCardsTypeId) => {
        if (businessCardsTypeId == 1) {
            return [
                { name: "name", errorMessage: "이름은 필수 항목입니다." },
                { name: "position", errorMessage: "직책을 입력해주세요." },
                {
                    name: "email",
                    errorMessage: "유효한 이메일 주소를 입력해주세요.",
                },
                {
                    name: "phone",
                    errorMessage: "전화번호는 10-11자리 숫자여야 합니다.",
                },
                {
                    name: "fax",
                    errorMessage: "팩스 번호는 10-11자리 숫자여야 합니다.",
                },
            ];
        } else if (businessCardsTypeId == 2) {
            return [
                {
                    name: "snsCreatorName",
                    errorMessage: "크리에이터명을 입력해주세요.",
                },
                {
                    name: "snsPhoneNumber",
                    errorMessage: "전화번호는 10-11자리 숫자여야 합니다.",
                },
                {
                    name: "snsAddress",
                    errorMessage: "유효한 SNS 주소를 입력해주세요.",
                },
            ];
        } else if (businessCardsTypeId == 3) {
            return [
                // {
                //     name: "storeName",
                //     errorMessage: "매장명은 필수 항목입니다.",
                // },
                {
                    name: "storePhoneNumber",
                    errorMessage: "전화번호는 10-11자리 숫자여야 합니다.",
                },
                {
                    name: "storeSnsLink",
                    errorMessage: "유효한 매장 SNS 링크를 입력해주세요.",
                },
                {
                    name: "storeAddress",
                    errorMessage: "매장 주소는 필수 항목입니다.",
                },
            ];
        }
        return [];
    };

    const getFilteredFormData = (businessCardsTypeId) => {
        if (businessCardsTypeId === 1) {
            return {
                ...state.formData,
                snsCreatorName: "",
                snsPhoneNumber: "",
                snsAddress: "",
                storeName: "",
                storePhoneNumber: "",
                storeSnsLink: "",
                storeAddress: "",
            };
        } else if (businessCardsTypeId === 2) {
            return {
                ...state.formData,
                name: "",
                position: "",
                email: "",
                phone: "",
                fax: "",
                storeName: "",
                storePhoneNumber: "",
                storeSnsLink: "",
                storeAddress: "",
            };
        } else if (businessCardsTypeId === 3) {
            return {
                ...state.formData,
                name: "",
                position: "",
                email: "",
                phone: "",
                fax: "",
                snsCreatorName: "",
                snsPhoneNumber: "",
                snsAddress: "",
            };
        }
        return {};
    };

    useEffect(() => {
        if (!accessToken) {
            console.error("Access token missing!");
        }
    }, [accessToken]);

    useEffect(() => {
        if (Array.isArray(imageTypesData) && imageTypesData.length > 0) {
            const createCardImageFooter = getImageByType(
                "create-business-card-footer",
            );
            if (createCardImageFooter.length > 0) {
                setState((prevState) => ({
                    ...prevState,
                    images: {
                        ...prevState.images,
                        cardLayoutFooterImage:
                            createCardImageFooter[0]?.filePath,
                    },
                }));
            }
        }
    }, [imageTypesData]);


    useEffect(() => {
        if (!businessCardsTypeId) {
            navigate(`/card`);
        }
    }, []);

    const handleIdFromImageList = (id) => {
        setLogoOptionId(id)
    };
    const handleBack =()=>{
        navigate(`/card`);
    }

    
    return (
        <div className="flex min-h-screen flex-col">
            <main className="flex-1">
                <div className="flex min-h-[139px] w-full flex-col justify-center px-72 py-14 text-center text-3xl font-bold leading-none text-black max-md:max-w-full max-md:px-5">
                    <div className="flex w-[878px] max-w-full flex-col items-center">
                        <h1>기업형 타입 : 명함 정보 입력</h1>
                    </div>
                </div>

                <div className="flex w-full flex-col self-center px-20 max-md:max-w-full max-md:px-5">
                    <div className="flex flex-col justify-center self-start leading-none">
                        <div className="text-2xl font-bold text-main-color">
                            STEP 1.
                        </div>
                        <div className="mt-2.5 text-xl font-semibold text-neutral-700">
                            명함에 들어갈 로고를 첨부해주세요.
                        </div>
                    </div>

                    <LogoUploader
                        onInputChange={(val) =>
                            setState((prev) => ({
                                ...prev,
                                workName: val,
                                workNameErrors: {},
                            }))
                        }
                        selectedTab={state.selectedTab}
                        setSelectedTab={(tab) =>
                            setState((prev) => ({ ...prev, selectedTab: tab }))
                        }
                        onFileSelect={(file) =>
                            setState((prev) => ({
                                ...prev,
                                selectedFile: file,
                            }))
                        }
                        formErrors={state.workNameErrors}
                        disableTab={!accessToken}
                        handleIdFromImageList={handleIdFromImageList}
                        // handleSelectedId={handleSelectedId}
                    />
                    <div className="mt-10 flex flex-col justify-center self-start leading-none">
                        <div className="text-2xl font-bold text-main-color">
                            STEP 2.
                        </div>
                        <div className="mt-2.5 text-xl font-semibold text-neutral-700">
                            명함에 들어갈 정보를 입력해주세요.
                        </div>
                    </div>
                    <BusinessCardForm
                        selectedTab={businessCardsTypeId}
                        formData={state.formData}
                        handleInputChange={handleInputChange}
                        formErrors={state.formErrors}
                    />

                    <div className="mt-12 flex w-[500px] max-w-full flex-wrap items-start gap-2.5 self-center text-center text-base font-bold leading-none max-md:mt-10">
                        <Button
                            className="min-h-[50px] w-[196px] min-w-[240px] shrink grow gap-2.5 self-stretch whitespace-nowrap rounded-md border border-solid border-neutral-300 bg-white px-4 py-4 text-zinc-800 transition-colors hover:bg-gray-50"
                            label={"이전으로"}
                            onClick={handleBack}
                        />
                        <Button
                            onClick={handleSubmit}
                            className="min-h-[50px] w-[196px] min-w-[240px] shrink grow gap-2.5 self-stretch rounded-md bg-indigo-600 px-4 py-4 text-white transition-colors hover:bg-indigo-700"
                            label={"시안에 정보 적용하기"}
                        />
                    </div>
                </div>
                <div className="mt-24 cursor-pointer" onClick={() => navigate('/experts/consultation')}>
                    <ImageComponent src={state.images.cardLayoutFooterImage} />
                </div>
            </main>
        </div>
    );
}
