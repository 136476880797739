import React, { useState, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import InputField from "../../../components/common/Input/InputField";
import OptionSelector from "../../../components/common/OptionSelect/OptionSelector";
import ColorSelector from "../../../components/common/OptionSelect/ColorSelector";
import IndustrySelector from "../../../components/common/OptionSelect/IndustrySelector";
import Button from "../../../components/common/Button/Button";
import ImageComponent from "../../../components/FooterBanner";
import {
    businessCardImages,
    logoImages,
} from "../../../components/common/dataFake/imageData";
import colorsData from "../../../utils/constants/colors.json";
import logoOptions from "../../../utils/constants/logoOptions.json";
import { useNavigate } from "react-router-dom";
import StepHeader from "../../LayoutCommon/StepHeader";
import { usePostLogosMutation } from "../../../services/apiSlice";
import { toast } from "react-toastify";

const CreateLogo = () => {
    const navigate = useNavigate();
    const [postLogos, { loading, error, data }] = usePostLogosMutation();
    const { userData, imageTypesData } = useSelector((state) => state.common);

    const getImageByType = (type) => {
        return Array.isArray(imageTypesData)
            ? imageTypesData.filter((image) => image.imageType === type)
            : [];
    };

    const [formState, setFormState] = useState({
        activeTab: "logo",
        selectedColor: "Orange",
        selectedHex: "#F59E0B",
        selectedOption: "콤비네이션 마크",
        selectedIndustries: [],
        images: {
            createLogoFooterImage: null,
        },

        workName: "",
        companyName: "",
        brandName: "",
        slogan: "",
        keyword: "",
        errors: {
            workName: "",
            companyName: "",
            brandName: "",
            slogan: "",
            keyword: "",
            selectedIndustries: "",
        },
    });
    const validate = () => {
        const errors = {};

        if (!formState.workName.trim()) {
            errors.workName = "작업명은 필수 항목입니다.";
        }

        if (!formState.companyName.trim()) {
            errors.companyName = "회사명은 필수 항목입니다.";
        }

        if (!formState.brandName.trim()) {
            errors.brandName = "브랜드 네임은 필수 항목입니다.";
        }

        if (!formState.slogan.trim()) {
            errors.slogan = "슬로건 필수 항목입니다.";
        }

        if (!formState.keyword.trim()) {
            errors.keyword = "키워드 필수 항목입니다.";
        }

        if (formState.selectedIndustries.length === 0) {
            errors.selectedIndustries = "업종을 선택해주세요.";
        }
        setFormState((prevState) => ({
            ...prevState,
            errors,
        }));

        return Object.keys(errors).length === 0;
    };

    const goToCreateLogo = async () => {
        if (!validate()) {
            return;
        }
        try {
            const response = await postLogos({
                userId: userData?.userID,
                businessName: formState.workName || "",
                companyName: formState.companyName || "",
                slogan: formState.slogan || "",
                brandDescription: formState.brandName || "",
                colors: formState.selectedHex || "",
                logoStyle: formState.selectedOption || "",
                logoIndustry: formState.selectedIndustries.join(", ") || "",
                keywords: formState.keyword || "",
                fileFormatsAvailable: "",
                filePath: "",
                purchaseStatus: "",
                price: 0,
            });

            if (response.data && response.data) {
                toast.success("Created Successfully!");
                setTimeout(() => {
                    navigate(`/logo/ai/${response.data.id}`);
                }, 1000);
            } else {
                toast.error("로고 생성 실패");
            }
        } catch (err) {
            console.error("E로고 생성 실패", err);
        }
    };

    const fieldLabels = {
        workName: "작업명",
        companyName: "회사명",
        brandName: "브랜드 네임",
        slogan: "슬로건",
        keyword: "키워드",
    };
    const handleInput = (e) => {
        const { value, name } = e.target;

        const label = fieldLabels[name] || name;
        const errorMessage = value.trim()
            ? ""
            : `${label} 은(는) 필수 항목입니다.`;

        setFormState((prevState) => ({
            ...prevState,
            [name]: value,
            errors: {
                ...prevState.errors,
                [name]: errorMessage,
            },
        }));
    };

    const handleSelectColor = ({ name, hex }) => {
        setFormState((prevState) => ({
            ...prevState,
            selectedColor: name,
            selectedHex: hex,
        }));
    };
    const handleSelectOption = (option) => {
        setFormState((prevState) => ({
            ...prevState,
            selectedOption: option,
        }));
    };

    const handleSelectIndustries = (selectedIndustries) => {
        setFormState((prevState) => ({
            ...prevState,
            selectedIndustries: selectedIndustries,
            errors: {
                ...prevState.errors,
                selectedIndustries:
                    selectedIndustries.length === 0
                        ? "업종을 선택해주세요."
                        : "",
            },
        }));
    };

    const allImages =
        formState.activeTab === "logo" ? logoImages : businessCardImages;

    useEffect(() => {
        if (Array.isArray(imageTypesData) && imageTypesData.length >= 0) {
            const createCardImageFooter = getImageByType("create-logo-footer");
            if (createCardImageFooter.length > 0) {
                setFormState((prevState) => ({
                    ...prevState,
                    images: {
                        ...prevState.images,
                        createLogoFooterImage:
                            createCardImageFooter[0]?.filePath,
                    },
                }));
            }
        }
    }, [imageTypesData]);

    return (
        <div>
            <main className="flex flex-col rounded-none px-20 max-md:px-5">
                <header className="flex min-h-[177px] w-full flex-col justify-center px-72 py-14 text-center leading-none max-md:max-w-full max-md:px-5">
                    <div className="flex max-w-full flex-col">
                        <h1 className="self-center text-3xl font-bold text-black">
                            로고 정보 입력하기
                        </h1>
                        <p className="mt-5 text-lg text-neutral-700 max-md:max-w-full">
                            디자인으로 머리 아프게 고민하셨다면 AI가 제안하는
                            시안으로 빠르고 간편하게 디자인 매칭을 경험해
                            보세요.
                        </p>
                    </div>
                </header>
                <section className="flex w-full flex-col self-center leading-none max-md:max-w-full">
                    <StepHeader
                        step="1"
                        title="로고에 들어갈 정보를 입력하세요."
                    />
                    <form className="mt-5 flex flex-col justify-center rounded-md border border-solid border-neutral-200 px-12 py-10 text-base max-md:max-w-full max-md:px-5">
                        <InputField
                            label="작업명"
                            description="작업명은 로고 구매 시, 구매하신 제작물을 구분하기 위해 등록됩니다."
                            placeholder="작업명을 입력해주세요. (최대 글자수 nn자 까지 입력가능)"
                            value={formState.workName}
                            onChange={handleInput}
                            name="workName"
                        />
                        {formState.errors.workName && (
                            <div className="mt-2 text-red-600">
                                {formState.errors.workName}
                            </div>
                        )}

                        <div className="mt-10 flex w-full flex-col flex-wrap items-start gap-9 max-md:max-w-full md:flex-row">
                            <div className="flex-1">
                                <InputField
                                    label="회사명"
                                    placeholder="회사명을 입력해주세요. (최대 글자수 nn자 까지 입력가능)"
                                    className="min-w-[240px] flex-1 shrink basis-0 max-md:max-w-full"
                                    value={formState.companyName}
                                    onChange={handleInput}
                                    name="companyName"
                                />
                                {formState.errors.companyName && (
                                    <div className="mt-2 text-red-600">
                                        {formState.errors.companyName}
                                    </div>
                                )}
                            </div>

                            <div className="flex-1">
                                <InputField
                                    label="브랜드 네임"
                                    placeholder="브랜드 네임을 입력해주세요. (최대 글자수 nn자 까지 입력가능)"
                                    className="min-w-[240px] flex-1 shrink basis-0 max-md:max-w-full"
                                    value={formState.brandName}
                                    onChange={handleInput}
                                    name="brandName"
                                />
                                {formState.errors.brandName && (
                                    <div className="mt-2 text-red-600">
                                        {formState.errors.brandName}
                                    </div>
                                )}
                            </div>
                        </div>

                        <InputField
                            label="슬로건"
                            placeholder="슬로건을 입력해주세요. (최대 글자수 nn자 까지 입력가능)"
                            className="mt-10 w-full max-md:max-w-full"
                            value={formState.slogan}
                            onChange={handleInput}
                            name="slogan"
                        />
                        {formState.errors.slogan && (
                            <div className="mt-2 text-red-600">
                                {formState.errors.slogan}
                            </div>
                        )}
                    </form>
                    <StepHeader
                        step="2"
                        title="로고 디자인에 들어갈 옵션을 선택하세요."
                        className="mt-14 max-md:mt-10"
                    />
                    <div className="mt-5 flex w-full flex-col rounded-md border border-solid border-neutral-200 px-12 pb-12 pt-10 max-md:max-w-full max-md:px-5">
                        <OptionSelector
                            label="로고 타입 선택"
                            options={logoOptions.logoTypes.map(
                                (item) => item.value,
                            )}
                            selectedOption={formState.selectedOption}
                            onSelectOption={handleSelectOption}
                        />
                        <ColorSelector
                            label="색상"
                            colors={colorsData}
                            selectedColor={formState.selectedColor}
                            onSelectColor={handleSelectColor}
                        />
                        <IndustrySelector
                            label="업종 선택"
                            onSelect={handleSelectIndustries}
                            error={formState.errors.selectedIndustries} // lỗi khi không chọn ngành nghề
                        />

                        <InputField
                            label="키워드"
                            placeholder="키워드를 입력해주세요."
                            className="mt-10 w-full max-md:max-w-full"
                            value={formState.keyword}
                            onChange={handleInput}
                            name="keyword"
                        />
                        {formState.errors.keyword && (
                            <div className="mt-2 text-red-600">
                                {formState.errors.keyword}
                            </div>
                        )}
                    </div>

                    <div className="flex justify-center">
                        <Button
                            label="로고 만들기"
                            className={`mt-12 w-[320px] bg-zinc-800 text-white max-md:mt-10`}
                            onClick={goToCreateLogo}
                        />
                    </div>
                </section>
            </main>
            <div onClick={() => navigate(`/customer-faq`)} className="cursor-pointer relative mt-24 flex w-full flex-col max-md:mt-10 max-md:max-w-full">
                <ImageComponent src={formState.images.createLogoFooterImage} />
            </div>
        </div>
    );
};

export default CreateLogo;
