import React from 'react';

export default function CardSimple({ title, description, frontImage, backImage }) {
  return (
    <article className="flex gap-5 max-md:flex-col mt-10">
      <div className="flex flex-col w-[67%] max-md:ml-0 max-md:w-full">
        <div className="grow max-md:mt-10 max-md:max-w-full">
          <div className="flex gap-5 max-md:flex-col">
            <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
              <div className="flex flex-col grow justify-center items-center px-2.5 py-3.5 w-full bg-neutral-900 min-h-[220px] max-md:mt-2.5">
                <img src={frontImage} alt={`${title} front design`} className="object-contain max-w-full aspect-[1.81] w-[347px]" />
              </div>
            </div>
            <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
              <div className="flex flex-col grow justify-center items-center px-2.5 py-3.5 w-full bg-neutral-900 min-h-[220px] max-md:mt-2.5">
                <img src={backImage} alt={`${title} back design`} className="object-contain max-w-full aspect-[1.81] w-[347px]" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col ml-5 w-[33%] max-md:ml-0 max-md:w-full">
        <div className="flex flex-col self-stretch my-auto w-full text-base leading-none text-zinc-800 max-md:mt-10">
          <h2 className="self-start text-2xl font-bold">{title}</h2>
          <p className="mt-3.5 leading-6">{description}</p>
          <button className="gap-2.5 self-stretch px-4 mt-5 text-center text-white bg-indigo-600 rounded-md min-h-[50px]">
            선택한 샘플로 시안 만들기
          </button>
        </div>
      </div>
    </article>
  );
}