import React from 'react';

function LogoInputInfo() {
  const infoItems = [
    { label: '작업명', value: '새로운 로고' },
    { label: '회사명', value: '뉴소셜미디어' },
    { label: '브랜드 네임', value: '브랜드 네임' },
    { label: '슬로건', value: 'NewDesign' },
  ];

  return (
    <section className="flex flex-col justify-center mt-24 w-full leading-none text-neutral-700 max-md:mt-10 max-md:max-w-full">
      <h3 className="flex flex-col w-full text-2xl font-bold max-md:max-w-full">
        <div className="gap-2.5 self-start">로고 입력 정보</div>
        <div className="flex mt-4 w-full bg-neutral-600 min-h-[2px] max-md:max-w-full" />
      </h3>
      <div className="flex gap-9 items-center self-start mt-5 text-base">
        <div className="flex flex-col self-stretch my-auto font-bold w-[73px]">
          {infoItems.map((item, index) => (
            <div key={index} className={index > 0 ? 'mt-4' : ''}>
              {item.label}
            </div>
          ))}
        </div>
        <div className="flex flex-col self-stretch my-auto w-[97px]">
          {infoItems.map((item, index) => (
            <div key={index} className={index > 0 ? 'mt-4' : ''}>
              {item.value}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default LogoInputInfo;