import React from "react";
import InputField from "../../components/common/Input/InputField";

const Step1Form = () => {
    return (
        <div className="grid w-full grid-cols-2 items-start gap-9 max-md:max-w-full max-md:grid-cols-1">
            <InputField
                label="회사명"
                placeholder="회사명을 입력해주세요."
            />
            <InputField
                label="이메일"
                placeholder="이메일 주소를 입력해주세요."
                type="email"
            />
            <InputField
                label="담당자 성함"
                placeholder="담당자 성함을 입력해주세요."
            />
            <InputField
                label="연락처"
                placeholder="연락처를 입력해주세요."
                type="tel"
            />
        </div>
    );
};

export default Step1Form;
