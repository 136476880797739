import * as React from "react";

export default function Input({
  type = "text",
  id = "",
  placeholder = "",
  value = "",
  onChange,
  ...props
}) {
  return (
    <input
      type={type}
      id={id}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      className="gap-2.5 self-stretch px-4 mt-2.5 w-full rounded-md border border-solid bg-stone-50 border-stone-50 min-h-[50px] text-zinc-500 max-md:max-w-full"
      {...props}
    />
  );
}
