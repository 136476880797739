import React from 'react';
import Button from '../../../components/common/Button/Button';
import images from '../../../images';

const OrderCard = ({ orderDate, trackingInfo, recipientInfo ,imageUrl1,imageUrl2}) => {
  return (
    <div className="flex flex-col grow justify-center p-6 w-full bg-white rounded-md border border-gray-200 border-solid max-md:px-5 max-md:mt-8 max-md:max-w-full">
      <div className="flex flex-col w-full max-md:max-w-full">
        <div className="flex flex-wrap gap-10 justify-between items-center w-full font-bold leading-none max-md:max-w-full">
          <div className="self-stretch my-auto text-lg text-zinc-800">
            주문일자 <span>{orderDate}</span>
          </div>
          <Button className="flex gap-2 items-center self-stretch my-auto text-sm text-indigo-600 whitespace-nowrap" label={'주문상세'} icon={images.arrowRWIcon}/>
        </div>
        <div className="flex flex-wrap gap-4 p-4 mt-5 w-full rounded-md bg-slate-50 max-md:max-w-full">
          <div className="flex flex-col grow shrink justify-center items-center my-auto w-[88px]">
            <img loading="lazy" src={imageUrl1} alt="Product image 1" className="object-contain aspect-[1.8] w-[90px]" />
            <img loading="lazy" src={imageUrl2} alt="Product image 2" className="object-contain mt-2.5 aspect-[1.8] w-[90px]" />
          </div>
          <div className="flex flex-col grow shrink justify-center text-sm leading-none min-w-[240px] w-[360px]">
            <div className="flex flex-1 gap-2.5 items-center size-full">
              <div className="flex flex-col justify-center self-stretch my-auto font-bold whitespace-nowrap text-neutral-700 w-[73px]">
                <div>배송정보</div>
                <div className="mt-3.5">수령인</div>
                <div className="mt-3.5">주소</div>
                <div className="mt-3.5">연락처</div>
              </div>
              <div className="flex flex-col justify-center self-stretch my-auto min-w-[240px] w-[299px]">
                <div className="flex flex-col justify-center self-start">
                  <div className="flex gap-2.5 items-center">
                    <div className="self-stretch my-auto font-semibold text-neutral-700">
                      {trackingInfo.courier}
                    </div>
                    <div className="self-stretch my-auto text-indigo-600">
                      {trackingInfo.trackingNumber}
                    </div>
                  </div>
                </div>
                <div className="mt-3.5 text-neutral-700">{recipientInfo.name}</div>
                <div className="mt-3.5 text-neutral-700">{recipientInfo.address}</div>
                <div className="mt-3.5 text-neutral-700">{recipientInfo.phone}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderCard;