import React from "react";

const sidebarItems = [
    { label: "전체", category: "전체" , value:'all'},
    { label: "로고디자인", category: "로고디자인" , value:'logo_design'},
    { label: "명함디자인", category: "명함디자인" , value:'business_card_design'},
    { label: "인쇄문의", category: "인쇄문의", value:'print_consulting' },
    { label: "디자인상담문의", category: "디자인상담문의" , value:'design_consulting'},
];


function FAQSidebar({ activeCategory, onCategoryChange }) {
    return (
        <nav className="mb-5 w-full md:mb-0 md:w-[26%]">
            <ul className="flex w-full flex-col justify-center whitespace-nowrap text-lg leading-none text-neutral-500 max-md:!flex-row">
                {sidebarItems.map((item, index) => (
                    <li
                        key={index}
                        className={`w-full cursor-pointer self-stretch py-5 pl-6 max-md:border-l-0 max-md:border-b-2 max-md:py-3 max-md:pl-0 max-md:text-center max-md:text-sm ${
                            activeCategory === item.value
                                ? "border-l-4 border-indigo-600 font-bold text-zinc-800"
                                : "border-l-4 border-neutral-200"
                        } `}
                        onClick={() => onCategoryChange(item.value)}
                    >
                        {item.label}
                    </li>
                ))}
            </ul>
        </nav>
    );
}

export default FAQSidebar;
