export const logoImages = [
    {
        src: "https://cdn.builder.io/api/v1/image/assets/TEMP/988d99a74199ff452d95b8b7ff90aee25fce302a2513d855a7fb1449d5a0ced5?apiKey=59b2e63425054a16a2965abdd74cb952&",
    },
    {
        src: "https://cdn.builder.io/api/v1/image/assets/TEMP/0cc81f8020b4bafc25609fcf3f2c0b84720a7b454cf787a13f9fa7ed5a02b423?apiKey=59b2e63425054a16a2965abdd74cb952&",
        isSpecial: true,
    },
    {
        src: "https://cdn.builder.io/api/v1/image/assets/TEMP/b9be1c018bf3a057f6d4de29eee0c03bc896fec12032975cde646d68ea3d5507?apiKey=59b2e63425054a16a2965abdd74cb952&",
    },
    {
        src: "https://cdn.builder.io/api/v1/image/assets/TEMP/988d99a74199ff452d95b8b7ff90aee25fce302a2513d855a7fb1449d5a0ced5?apiKey=59b2e63425054a16a2965abdd74cb952&",
    },
    {
        src: "https://cdn.builder.io/api/v1/image/assets/TEMP/0cc81f8020b4bafc25609fcf3f2c0b84720a7b454cf787a13f9fa7ed5a02b423?apiKey=59b2e63425054a16a2965abdd74cb952&",
        isSpecial: true,
    },
    {
        src: "https://cdn.builder.io/api/v1/image/assets/TEMP/b9be1c018bf3a057f6d4de29eee0c03bc896fec12032975cde646d68ea3d5507?apiKey=59b2e63425054a16a2965abdd74cb952&",
    },
    {
        src: "https://cdn.builder.io/api/v1/image/assets/TEMP/4608d6556658cfaf4acbf5600dc1fd9c869475e17a35cc5d9cd3a426058be057?apiKey=59b2e63425054a16a2965abdd74cb952&",
    },
    {
        src: "https://cdn.builder.io/api/v1/image/assets/TEMP/988d99a74199ff452d95b8b7ff90aee25fce302a2513d855a7fb1449d5a0ced5?apiKey=59b2e63425054a16a2965abdd74cb952&",
    },
    {
        src: "https://cdn.builder.io/api/v1/image/assets/TEMP/0cc81f8020b4bafc25609fcf3f2c0b84720a7b454cf787a13f9fa7ed5a02b423?apiKey=59b2e63425054a16a2965abdd74cb952&",
        isSpecial: true,
    },
    {
        src: "https://cdn.builder.io/api/v1/image/assets/TEMP/b9be1c018bf3a057f6d4de29eee0c03bc896fec12032975cde646d68ea3d5507?apiKey=59b2e63425054a16a2965abdd74cb952&",
    },
    {
        src: "https://cdn.builder.io/api/v1/image/assets/TEMP/4608d6556658cfaf4acbf5600dc1fd9c869475e17a35cc5d9cd3a426058be057?apiKey=59b2e63425054a16a2965abdd74cb952&",
    },
    {
        src: "https://cdn.builder.io/api/v1/image/assets/TEMP/988d99a74199ff452d95b8b7ff90aee25fce302a2513d855a7fb1449d5a0ced5?apiKey=59b2e63425054a16a2965abdd74cb952&",
    },
    {
        src: "https://cdn.builder.io/api/v1/image/assets/TEMP/0cc81f8020b4bafc25609fcf3f2c0b84720a7b454cf787a13f9fa7ed5a02b423?apiKey=59b2e63425054a16a2965abdd74cb952&",
        isSpecial: true,
    },
    {
        src: "https://cdn.builder.io/api/v1/image/assets/TEMP/b9be1c018bf3a057f6d4de29eee0c03bc896fec12032975cde646d68ea3d5507?apiKey=59b2e63425054a16a2965abdd74cb952&",
    },
    {
        src: "https://cdn.builder.io/api/v1/image/assets/TEMP/988d99a74199ff452d95b8b7ff90aee25fce302a2513d855a7fb1449d5a0ced5?apiKey=59b2e63425054a16a2965abdd74cb952&",
    },
    {
        src: "https://cdn.builder.io/api/v1/image/assets/TEMP/0cc81f8020b4bafc25609fcf3f2c0b84720a7b454cf787a13f9fa7ed5a02b423?apiKey=59b2e63425054a16a2965abdd74cb952&",
        isSpecial: true,
    },
    {
        src: "https://cdn.builder.io/api/v1/image/assets/TEMP/b9be1c018bf3a057f6d4de29eee0c03bc896fec12032975cde646d68ea3d5507?apiKey=59b2e63425054a16a2965abdd74cb952&",
    },
    {
        src: "https://cdn.builder.io/api/v1/image/assets/TEMP/4608d6556658cfaf4acbf5600dc1fd9c869475e17a35cc5d9cd3a426058be057?apiKey=59b2e63425054a16a2965abdd74cb952&",
    },
    {
        src: "https://cdn.builder.io/api/v1/image/assets/TEMP/988d99a74199ff452d95b8b7ff90aee25fce302a2513d855a7fb1449d5a0ced5?apiKey=59b2e63425054a16a2965abdd74cb952&",
    },
    {
        src: "https://cdn.builder.io/api/v1/image/assets/TEMP/0cc81f8020b4bafc25609fcf3f2c0b84720a7b454cf787a13f9fa7ed5a02b423?apiKey=59b2e63425054a16a2965abdd74cb952&",
        isSpecial: true,
    },
    {
        src: "https://cdn.builder.io/api/v1/image/assets/TEMP/b9be1c018bf3a057f6d4de29eee0c03bc896fec12032975cde646d68ea3d5507?apiKey=59b2e63425054a16a2965abdd74cb952&",
    },
    {
        src: "https://cdn.builder.io/api/v1/image/assets/TEMP/4608d6556658cfaf4acbf5600dc1fd9c869475e17a35cc5d9cd3a426058be057?apiKey=59b2e63425054a16a2965abdd74cb952&",
    },
    //... (add more images)
];

export const businessCardImages = [
    {
        src: "https://cdn.builder.io/api/v1/image/assets/TEMP/3dd4ce5886749a5b1a563009cc15d1adb8a0279e417a0f17a047c7a4f04bec9a?placeholderIfAbsent=true&apiKey=59b2e63425054a16a2965abdd74cb952",
    },
    {
        src: "https://cdn.builder.io/api/v1/image/assets/TEMP/3dd4ce5886749a5b1a563009cc15d1adb8a0279e417a0f17a047c7a4f04bec9a?placeholderIfAbsent=true&apiKey=59b2e63425054a16a2965abdd74cb952",
    },
    {
        src: "https://cdn.builder.io/api/v1/image/assets/TEMP/3dd4ce5886749a5b1a563009cc15d1adb8a0279e417a0f17a047c7a4f04bec9a?placeholderIfAbsent=true&apiKey=59b2e63425054a16a2965abdd74cb952",
    },
    {
        src: "https://cdn.builder.io/api/v1/image/assets/TEMP/3dd4ce5886749a5b1a563009cc15d1adb8a0279e417a0f17a047c7a4f04bec9a?placeholderIfAbsent=true&apiKey=59b2e63425054a16a2965abdd74cb952",
    },
    {
        src: "https://cdn.builder.io/api/v1/image/assets/TEMP/3dd4ce5886749a5b1a563009cc15d1adb8a0279e417a0f17a047c7a4f04bec9a?placeholderIfAbsent=true&apiKey=59b2e63425054a16a2965abdd74cb952",
    },
    {
        src: "https://cdn.builder.io/api/v1/image/assets/TEMP/3dd4ce5886749a5b1a563009cc15d1adb8a0279e417a0f17a047c7a4f04bec9a?placeholderIfAbsent=true&apiKey=59b2e63425054a16a2965abdd74cb952",
    },
    {
        src: "https://cdn.builder.io/api/v1/image/assets/TEMP/3dd4ce5886749a5b1a563009cc15d1adb8a0279e417a0f17a047c7a4f04bec9a?placeholderIfAbsent=true&apiKey=59b2e63425054a16a2965abdd74cb952",
    },
    {
        src: "https://cdn.builder.io/api/v1/image/assets/TEMP/3dd4ce5886749a5b1a563009cc15d1adb8a0279e417a0f17a047c7a4f04bec9a?placeholderIfAbsent=true&apiKey=59b2e63425054a16a2965abdd74cb952",
    },
    {
        src: "https://cdn.builder.io/api/v1/image/assets/TEMP/3dd4ce5886749a5b1a563009cc15d1adb8a0279e417a0f17a047c7a4f04bec9a?placeholderIfAbsent=true&apiKey=59b2e63425054a16a2965abdd74cb952",
    },
    {
        src: "https://cdn.builder.io/api/v1/image/assets/TEMP/3dd4ce5886749a5b1a563009cc15d1adb8a0279e417a0f17a047c7a4f04bec9a?placeholderIfAbsent=true&apiKey=59b2e63425054a16a2965abdd74cb952",
    },
    {
        src: "https://cdn.builder.io/api/v1/image/assets/TEMP/3dd4ce5886749a5b1a563009cc15d1adb8a0279e417a0f17a047c7a4f04bec9a?placeholderIfAbsent=true&apiKey=59b2e63425054a16a2965abdd74cb952",
    },
    {
        src: "https://cdn.builder.io/api/v1/image/assets/TEMP/3dd4ce5886749a5b1a563009cc15d1adb8a0279e417a0f17a047c7a4f04bec9a?placeholderIfAbsent=true&apiKey=59b2e63425054a16a2965abdd74cb952",
    },
    {
        src: "https://cdn.builder.io/api/v1/image/assets/TEMP/3dd4ce5886749a5b1a563009cc15d1adb8a0279e417a0f17a047c7a4f04bec9a?placeholderIfAbsent=true&apiKey=59b2e63425054a16a2965abdd74cb952",
    },
    {
        src: "https://cdn.builder.io/api/v1/image/assets/TEMP/3dd4ce5886749a5b1a563009cc15d1adb8a0279e417a0f17a047c7a4f04bec9a?placeholderIfAbsent=true&apiKey=59b2e63425054a16a2965abdd74cb952",
    },
    {
        src: "https://cdn.builder.io/api/v1/image/assets/TEMP/3dd4ce5886749a5b1a563009cc15d1adb8a0279e417a0f17a047c7a4f04bec9a?placeholderIfAbsent=true&apiKey=59b2e63425054a16a2965abdd74cb952",
    },
    {
        src: "https://cdn.builder.io/api/v1/image/assets/TEMP/3dd4ce5886749a5b1a563009cc15d1adb8a0279e417a0f17a047c7a4f04bec9a?placeholderIfAbsent=true&apiKey=59b2e63425054a16a2965abdd74cb952",
    },
    {
        src: "https://cdn.builder.io/api/v1/image/assets/TEMP/3dd4ce5886749a5b1a563009cc15d1adb8a0279e417a0f17a047c7a4f04bec9a?placeholderIfAbsent=true&apiKey=59b2e63425054a16a2965abdd74cb952",
    },
    {
        src: "https://cdn.builder.io/api/v1/image/assets/TEMP/3dd4ce5886749a5b1a563009cc15d1adb8a0279e417a0f17a047c7a4f04bec9a?placeholderIfAbsent=true&apiKey=59b2e63425054a16a2965abdd74cb952",
    },
    {
        src: "https://cdn.builder.io/api/v1/image/assets/TEMP/3dd4ce5886749a5b1a563009cc15d1adb8a0279e417a0f17a047c7a4f04bec9a?placeholderIfAbsent=true&apiKey=59b2e63425054a16a2965abdd74cb952",
    },
    {
        src: "https://cdn.builder.io/api/v1/image/assets/TEMP/3dd4ce5886749a5b1a563009cc15d1adb8a0279e417a0f17a047c7a4f04bec9a?placeholderIfAbsent=true&apiKey=59b2e63425054a16a2965abdd74cb952",
    },
    {
        src: "https://cdn.builder.io/api/v1/image/assets/TEMP/3dd4ce5886749a5b1a563009cc15d1adb8a0279e417a0f17a047c7a4f04bec9a?placeholderIfAbsent=true&apiKey=59b2e63425054a16a2965abdd74cb952",
    },
    {
        src: "https://cdn.builder.io/api/v1/image/assets/TEMP/3dd4ce5886749a5b1a563009cc15d1adb8a0279e417a0f17a047c7a4f04bec9a?placeholderIfAbsent=true&apiKey=59b2e63425054a16a2965abdd74cb952",
    },
    {
        src: "https://cdn.builder.io/api/v1/image/assets/TEMP/3dd4ce5886749a5b1a563009cc15d1adb8a0279e417a0f17a047c7a4f04bec9a?placeholderIfAbsent=true&apiKey=59b2e63425054a16a2965abdd74cb952",
    },
    {
        src: "https://cdn.builder.io/api/v1/image/assets/TEMP/3dd4ce5886749a5b1a563009cc15d1adb8a0279e417a0f17a047c7a4f04bec9a?placeholderIfAbsent=true&apiKey=59b2e63425054a16a2965abdd74cb952",
    },
    //... (add more images)
];

export const consultationRequests = [
    {
        requestType: "전문가 상담요청",
        dateTime: "2024-09-28 12:00",
        requestDetails: "컬러수정",
        companyName: "(주)어텐션",
        contactPerson: "홍길동",
        email: "user1234@email.com",
        phoneNumber: "01088888888",
    },
    {
        requestType: "전문가 상담요청",
        dateTime: "2024-09-28 12:00",
        requestDetails: "위치변경",
        companyName: "(주)어텐션",
        contactPerson: "홍길동",
        email: "user1234@email.com",
        phoneNumber: "01088888888",
    },
    {
        requestType: "전문가 상담요청",
        dateTime: "2024-09-28 12:00",
        requestDetails: "컬러수정, 위치변경",
        companyName: "(주)어텐션",
        contactPerson: "홍길동",
        email: "user1234@email.com",
        phoneNumber: "01088888888",
    },
    {
        requestType: "전문가 상담요청",
        dateTime: "2024-09-28 12:00",
        requestDetails: "벡터파일, 기타 요청 (상담 후 결정)",
        companyName: "(주)어텐션",
        contactPerson: "홍길동",
        email: "user1234@email.com",
        phoneNumber: "01088888888",
    },
    {
        requestType: "전문가 상담요청",
        dateTime: "2024-09-28 12:00",
        requestDetails: "벡터파일",
        companyName: "(주)어텐션",
        contactPerson: "홍길동",
        email: "user1234@email.com",
        phoneNumber: "01088888888",
    },
    {
        requestType: "전문가 상담요청",
        dateTime: "2024-09-28 12:00",
        requestDetails: "기타 요청 (상담 후 결정)",
        companyName: "(주)어텐션",
        contactPerson: "홍길동",
        email: "user1234@email.com",
        phoneNumber: "01088888888",
    },
];

export const consultationRequestsTab2 = [
    {
        requestType: "전문가 고용상담",
        dateTime: "2024-09-28 12:00",
        requestDetails: "전문 브랜딩 로고",
        companyName: "(주)어텐션",
        contactPerson: "홍길동",
        email: "user1234@email.com",
        phoneNumber: "01088888888",
    },
    {
        requestType: "전문가 고용상담",
        dateTime: "2024-09-28 12:00",
        requestDetails: "패키지 디자인",
        companyName: "(주)어텐션",
        contactPerson: "홍길동",
        email: "user1234@email.com",
        phoneNumber: "01088888888",
    },
    {
        requestType: "전문가 고용상담",
        dateTime: "2024-09-28 12:00",
        requestDetails: "기타 요청 (상담 후 결정)",
        companyName: "(주)어텐션",
        contactPerson: "홍길동",
        email: "user1234@email.com",
        phoneNumber: "01088888888",
    },
    {
        requestType: "전문가 고용상담",
        dateTime: "2024-09-28 12:00",
        requestDetails: "전문 브랜딩 로고, 기타 요청 (상담 후 결정)",
        companyName: "(주)어텐션",
        contactPerson: "홍길동",
        email: "user1234@email.com",
        phoneNumber: "01088888888",
    },
    {
        requestType: "전문가 고용상담",
        dateTime: "2024-09-28 12:00",
        requestDetails: "전문 브랜딩 로고, 패키지 디자인",
        companyName: "(주)어텐션",
        contactPerson: "홍길동",
        email: "user1234@email.com",
        phoneNumber: "01088888888",
    },
    {
        requestType: "전문가 고용상담",
        dateTime: "2024-09-28 12:00",
        requestDetails: "패키지 디자인, 기타 요청 (상담 후 결정)",
        companyName: "(주)어텐션",
        contactPerson: "홍길동",
        email: "user1234@email.com",
        phoneNumber: "01088888888",
    },
];

export const fakeDataNoti = [
    {
        id: 1,
        title: "알림 타이틀이 이곳에 출력됩니다.",
        content:
            "안내 내용이 이곳에 출력됩니다.\n클릭 시, 관련 페이지로 이동됩니다.",
        date: "2024.06.15 00:00",
        iconSrc:
            "https://cdn.builder.io/api/v1/image/assets/608912cecb5144cf887238b12afdb74b/17dc106560d8f2dbba35419a6da7e4e97a21786445a56291ba28cd38ded24768?apiKey=608912cecb5144cf887238b12afdb74b&",
        isRead: false,
    },
    {
        id: 2,
        title: "두 번째 알림 타이틀",
        content:
            "두 번째 알림의 내용입니다.\n자세한 정보를 확인하려면 클릭하세요.",
        date: "2024.06.14 15:30",
        iconSrc:
            "https://cdn.builder.io/api/v1/image/assets/608912cecb5144cf887238b12afdb74b/587f130b68c2eec067af1cb26dc614c10eb9103617cb868735d670a9dfd0975c?apiKey=608912cecb5144cf887238b12afdb74b&",
        isRead: true,
    },
    {
        id: 3,
        title: "세 번째 알림 제목",
        content:
            "세 번째 알림에 대한 상세 내용입니다.\n추가 정보를 보려면 클릭하세요.",
        date: "2024.06.13 09:45",
        iconSrc:
            "https://cdn.builder.io/api/v1/image/assets/608912cecb5144cf887238b12afdb74b/17dc106560d8f2dbba35419a6da7e4e97a21786445a56291ba28cd38ded24768?apiKey=608912cecb5144cf887238b12afdb74b&",
        isRead: false,
    },
    {
        id: 4,
        title: "네 번째 알림 헤더",
        content: "네 번째 알림의 세부 사항입니다.\n더 알아보려면 클릭해주세요.",
        date: "2024.06.12 18:20",
        iconSrc:
            "https://cdn.builder.io/api/v1/image/assets/608912cecb5144cf887238b12afdb74b/587f130b68c2eec067af1cb26dc614c10eb9103617cb868735d670a9dfd0975c?apiKey=608912cecb5144cf887238b12afdb74b&",
        isRead: true,
    },
    {
        id: 5,
        title: "다섯 번째 공지사항",
        content:
            "다섯 번째 알림에 대한 주요 내용입니다.\n자세히 보려면 클릭하세요.",
        date: "2024.06.11 11:10",
        iconSrc:
            "https://cdn.builder.io/api/v1/image/assets/608912cecb5144cf887238b12afdb74b/17dc106560d8f2dbba35419a6da7e4e97a21786445a56291ba28cd38ded24768?apiKey=608912cecb5144cf887238b12afdb74b&",
        isRead: false,
    },
    {
        id: 6,
        title: "알림 타이틀이 이곳에 출력됩니다.",
        content:
            "안내 내용이 이곳에 출력됩니다.\n클릭 시, 관련 페이지로 이동됩니다.",
        date: "2024.06.15 00:00",
        iconSrc:
            "https://cdn.builder.io/api/v1/image/assets/608912cecb5144cf887238b12afdb74b/17dc106560d8f2dbba35419a6da7e4e97a21786445a56291ba28cd38ded24768?apiKey=608912cecb5144cf887238b12afdb74b&",
        isRead: false,
    },
    {
        id: 7,
        title: "두 번째 알림 타이틀",
        content:
            "두 번째 알림의 내용입니다.\n자세한 정보를 확인하려면 클릭하세요.",
        date: "2024.06.14 15:30",
        iconSrc:
            "https://cdn.builder.io/api/v1/image/assets/608912cecb5144cf887238b12afdb74b/587f130b68c2eec067af1cb26dc614c10eb9103617cb868735d670a9dfd0975c?apiKey=608912cecb5144cf887238b12afdb74b&",
        isRead: true,
    },
    {
        id: 8,
        title: "세 번째 알림 제목",
        content:
            "세 번째 알림에 대한 상세 내용입니다.\n추가 정보를 보려면 클릭하세요.",
        date: "2024.06.13 09:45",
        iconSrc:
            "https://cdn.builder.io/api/v1/image/assets/608912cecb5144cf887238b12afdb74b/17dc106560d8f2dbba35419a6da7e4e97a21786445a56291ba28cd38ded24768?apiKey=608912cecb5144cf887238b12afdb74b&",
        isRead: false,
    },
    {
        id: 9,
        title: "네 번째 알림 헤더",
        content: "네 번째 알림의 세부 사항입니다.\n더 알아보려면 클릭해주세요.",
        date: "2024.06.12 18:20",
        iconSrc:
            "https://cdn.builder.io/api/v1/image/assets/608912cecb5144cf887238b12afdb74b/587f130b68c2eec067af1cb26dc614c10eb9103617cb868735d670a9dfd0975c?apiKey=608912cecb5144cf887238b12afdb74b&",
        isRead: true,
    },
    {
        id: 10,
        title: "다섯 번째 공지사항",
        content:
            "다섯 번째 알림에 대한 주요 내용입니다.\n자세히 보려면 클릭하세요.",
        date: "2024.06.11 11:10",
        iconSrc:
            "https://cdn.builder.io/api/v1/image/assets/608912cecb5144cf887238b12afdb74b/17dc106560d8f2dbba35419a6da7e4e97a21786445a56291ba28cd38ded24768?apiKey=608912cecb5144cf887238b12afdb74b&",
        isRead: false,
    },
];
