import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import CardTypeSelect from "./CardTypeSelect";
import CardQuantitySelector from "./CardQuantitySelector";
import ImageComponent from "./../../../components/FooterBanner";


function GeneralBusinessCard() {
    const { id } = useParams();
    const navigate = useNavigate();

    const [selectedCardType, setSelectedCardType] = useState({
        name: "일반명함",
        icon: "ext_5-",
        active: true,
        id: 1,
        type: "GENERAL",
    });
  
    const [productIdCard, setProductIdCard] =useState(id)


    const imageTypesData = useSelector(
        (state) => state.common.imageTypesData || []
    );

    const getImageByType = (type) => {
        return Array.isArray(imageTypesData)
            ? imageTypesData.filter((image) => image.imageType === type)
            : [];
    };

    const [images, setImages] = useState({
        images: { generalBusinnessCardImageFooter: null },
    });

    useEffect(() => {
        if (Array.isArray(imageTypesData) && imageTypesData.length > 0) {
            const imageFooter = getImageByType(
                "create-business-card-below-footer"
            );
            setImages((prevState) => ({
                images: {
                    generalBusinnessCardImageFooter: imageFooter[0]?.filePath || null,
                },
            }));
        }
    }, [imageTypesData]);

    const handleCardTypeSelect = (selectedType) => {
        setSelectedCardType(selectedType); 
    };
    useEffect(() => {
    }, [selectedCardType]);
    
    return (
        <div>
            <main className="flex flex-col overflow-hidden bg-white">
                <section className="flex min-h-[139px] w-full flex-col justify-center px-72 py-14 text-center text-3xl font-bold leading-none text-black max-md:max-w-full max-md:px-5">
                    <h1 className="flex w-[878px] max-w-full flex-col items-center">
                        인쇄 주문하기
                    </h1>
                </section>
                <div className="flex w-full max-w-[1240px] flex-col self-center max-md:max-w-full">
                    <CardTypeSelect
                        onSelectType={handleCardTypeSelect} 
                        selectedCardType={selectedCardType} 
                    />
                    <hr className="mt-14 flex h-px shrink-0 border border-solid border-gray-200 max-md:mt-10 max-md:max-w-full" />
                    <CardQuantitySelector selectedCardType={selectedCardType} productIdCard={productIdCard}/>
                </div>
            </main>

            <div className="w-full cursor-pointer" onClick={() => navigate('/experts/consultation')}>
                <ImageComponent
                    src={images?.images.generalBusinnessCardImageFooter}
                />
            </div>
        </div>
    );
}

export default GeneralBusinessCard;
