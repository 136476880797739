import React from "react";
import Copyright from "./Copyright";
import images from "../../images";

function Footer() {
    return (
        <footer className="flex w-full flex-col bg-neutral-900 px-20 py-16 max-md:mt-10 max-md:max-w-full">
            <div className="flex w-full flex-wrap justify-between gap-5 self-center max-md:max-w-full">
                <div className="flex flex-col max-md:max-w-full">
                    <div className="flex max-w-full flex-col text-base">
                        <div className="font-bold leading-none text-indigo-500">
                            logee designworks
                        </div>
                        <div className="mt-3 text-stone-300">
                            <span className="font-bold">로고로지에서</span> AI로
                            쉽고 빠르게 <br />
                            <span className="">
                                완벽한 디자인 매칭을 경험해 보세요.
                            </span>
                        </div>
                    </div>
                    <div className="mt-6 text-sm text-neutral-400 max-md:max-w-full">
                        <span className="font-bold text-neutral-400">
                            대표자
                        </span>{" "}
                        : 김은향{" "}
                        <span className="font-bold text-neutral-400">주소</span>{" "}
                        : 경기도 성남시 수정구 위례순환로 53, 3601동 1층
                        9호(창곡동, 성남창업센터 정글ON) <br />
                        <span className="font-bold text-neutral-400">
                            {" "}
                            사업자 등록 번호{" "}
                        </span>{" "}
                        : 690-35-01390{" "}
                        <span className="font-bold text-neutral-400">
                            문의 메일{" "}
                        </span>{" "}
                        : logee@logologee.com
                    </div>
                </div>
                <nav className="flex flex-col items-end self-start text-base leading-none text-stone-300">
                    <FooterLink text="공지사항" />
                    <FooterLink text="이용 및 가격안내" />
                    <FooterLink text="이용약관" />
                    <FooterLink text="개인정보 처리방침" />
                </nav>
            </div>
            <div className="mb-5 mt-6 flex min-h-[1px] w-full bg-neutral-800 max-md:max-w-full" />
            <Copyright />
        </footer>
    );
}

function FooterLink({ text }) {
    return (
        <div className="mt-4 flex items-start gap-1.5 whitespace-nowrap first:mt-0">
            <div>{text}</div>
            <img
                loading="lazy"
                src={images.arrowTRIcon}
                alt=""
                className="aspect-square w-4 shrink-0 object-contain"
            />
        </div>
    );
}

export default Footer;
