import React, { useEffect } from "react";
import CardPreview from "./CardPreview";
import CardSpecifications from "./CardSpecifications";
import CardSpecificationsTypeSecond from "./CardSpecificationsTypeSecond";
import CardSpecificationTypeThird from "./CardSpecificationTypeThird";

export default function CardQuantitySelector({ selectedCardType }) {
    const options = [
        { label: "크라프트 명함" },
        { label: "블랙 명함" },
        { label: "벨벳 명함" },
        { label: "투명 명함" },
        { label: "고급 용지 명함" },
    ];

    const specifications = {
        "크라프트 명함": [
            { label: "용지", value: "크라프트 보드 300g" },
            { label: "인쇄도수", value: "4도 인쇄 + 양면" },
            { label: "코팅", value: "무광 코팅" },
            { label: "규격", value: "90X50" },
            {
                label: "후가공",
                value: "후가공 추가를 원하실 경우 전문가 상담을 통해 진행해 주세요.",
            },
            {
                label: "수량",
                value: ["100매", "200매", "300매"],
                type: "select",
            },
        ],
        "블랙 명함": [
            { label: "용지", value: "크라프트 보드 300g" },
            {
                label: "수량",
                value: ["앙면은박", "양면금박"],
                type: "select",
            },
            { label: "코팅", value: "-" },
            { label: "규격", value: "90X50" },
            {
                label: "후가공",
                value: "후가공 추가를 원하실 경우 전문가 상담을 통해 진행해 주세요.",
            },
            {
                label: "수량",
                value: [
                    "200매 - 8,900원",
                    "400매 - 16,900원",
                    "600매 - 24,800원",
                ],
                type: "select",
            },
        ],
        "벨벳 명함": [
            { label: "용지", value: "크라프트 보드 359g" },
            { label: "인쇄도수", value: "4도 인쇄 + 양면" },
            { label: "코팅", value: "-" },
            { label: "규격", value: "90X50" },
            {
                label: "수량",
                value: [
                    " 벨벳 명함 - 후가공 (선택)",
                    "금박 (유광)",
                    "금박 (무광)",
                    "은박 (유광)",
                    "은박 (무광)",
                    "청박 (유광)",
                    "적박 (유광)",
                    "녹박 (유광)",
                    "먹박 (유광)",
                ],
                type: "select",
            },
            {
                label: "수량",
                value: "200매",
                type: "text",
            },
        ],
        "투명 명함": [
            { label: "용지", value: "크라프트 보드 300g" },
            {
                label: "수량",
                value: ["앙면은박", "양면금박"],
                type: "select",
            },
            { label: "코팅", value: "-" },
            { label: "규격", value: "90X50" },
            {
                label: "후가공",
                value: "후가공 추가를 원하실 경우 전문가 상담을 통해 진행해 주세요.",
            },
            {
                label: "수량",
                value: [
                    "200매 - 8,900원",
                    "400매 - 16,900원",
                    "600매 - 24,800원",
                ],
                type: "select",
            },
        ],
        "고급 용지 명함": [
            { label: "용지", value: "크라프트 보드 300g" },
            {
                label: "수량",
                value: ["앙면은박", "양면금박"],
                type: "select",
            },
            { label: "코팅", value: "-" },
            { label: "규격", value: "90X50" },
            {
                label: "후가공",
                value: "후가공 추가를 원하실 경우 전문가 상담을 통해 진행해 주세요.",
            },
            {
                label: "수량",
                value: [
                    "200매 - 8,900원",
                    "400매 - 16,900원",
                    "600매 - 24,800원",
                ],
                type: "select",
            },
        ],
    };

    const specs = [
        { label: "용지", value: "스노우지 250g", type: "text" },
        { label: "인쇄도수", value: "4도 인쇄 + 양면", type: "text" },
        { label: "코팅", value: "무광 코팅", type: "text" },
        { label: "규격", value: "90X50", type: "text" },
        { label: "후가공", value: "-", type: "text" },
        {
            label: "수량",
            value: ["100매", "200매", "300매"],
            type: "select",
        },
    ];

    const totalAmount = {
        label: "총 결제 금액",
        value: "49,000원",
    };

    useEffect(() => {}, [selectedCardType]);

    return (
        <section className="flex flex-col max-md:px-5">
            <div className="mt-12 flex flex-col justify-center self-start leading-none max-md:mt-10">
                <h2 className="text-2xl font-bold text-main-color">STEP 2.</h2>
                <p className="mt-2.5 text-xl font-semibold text-neutral-700">
                    명함의 수량을 선택하세요!
                </p>
            </div>
            <div className="mt-8 flex items-start justify-evenly gap-5 rounded-md border border-solid border-gray-200 py-16 max-md:mx-auto max-md:mt-0 max-md:max-w-[400px] max-md:w-full max-md:flex-col max-md:border-none max-md:px-2">
                <div className="max-md:mx-auto max-md:mb-5 max-md:w-full">
                    <CardPreview />
                </div>
                {selectedCardType?.id === 1 && (
                    <div className="max-w-[60%] max-md:w-full max-md:max-w-[100%]">
                        <CardSpecifications
                            specifications={specs}
                            totalAmount={totalAmount}
                        />
                    </div>
                )}
                {selectedCardType?.id === 2 && (
                    <div className="max-w-[60%] max-md:w-full max-md:max-w-[100%]">
                        <CardSpecificationsTypeSecond
                            options={options}
                            specsData={specifications}
                            totalAmount={totalAmount}
                        />
                    </div>
                )}
                {selectedCardType?.id === 3 && (
                    <div className="max-w-[60%] max-md:w-full max-md:max-w-[100%]">
                        <CardSpecificationTypeThird />
                    </div>
                )}
            </div>
        </section>
    );
}
