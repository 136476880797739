import React from "react";
import HeroSection from "./HeroSection";
import LogoSection from "./LogoSection";
import FAQSection from "./FAQSection";
import images from "../../images";
import { useNavigate } from "react-router-dom";

function MainLayout() {
    const navigate = useNavigate();

    return (
        <main>
            <HeroSection />
            <LogoSection />
            <div className="mt-20 flex min-h-[1px] w-full bg-neutral-200 max-md:mt-10" />
            <section className="mt-20 flex w-full flex-col items-start self-center px-20 leading-none max-md:mt-10 max-md:max-w-full max-md:px-5">
                <h2 className="text-2xl text-neutral-700 max-md:max-w-full">
                    {/* 를 통해 궁금하신 사항들을 확인해보세요. */}
                    <span className="font-bold text-zinc-800">FAQ</span>를 통해{" "}
                    <span className="font-bold text-main-color">
                        궁금하신 사항
                    </span>{" "}
                    {/* <span className="font-bold text-main-color">로고디자인</span>을 */}
                    들을 확인해보세요.
                </h2>
                <div className="mt-5 flex items-center gap-4 text-base font-bold text-zinc-800">
                    <div className="my-auto self-stretch">
                        더 많은 질문 보러가기
                    </div>
                    <img
                        loading="lazy"
                        src={images.arrowCircleB}
                        alt=""
                        onClick={() => navigate("/customer-faq")}
                        className="my-auto aspect-square w-6 shrink-0 cursor-pointer self-stretch object-contain"
                    />
                </div>
            </section>
            <FAQSection />
        </main>
    );
}

export default MainLayout;
