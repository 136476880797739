import React from "react";
import images from "../../images";

function LogoSection() {
    return (
        <section className="mt-20 flex w-full flex-col items-start self-center px-20 leading-none max-md:mt-10 max-md:max-w-full max-md:px-5">
            <h2 className="text-2xl text-neutral-700 max-md:max-w-full">
                <span className="font-bold text-zinc-800">로고로지</span>에서{" "}
                <br />
                <span className="font-bold text-main-color">다양한</span>{" "}
                <span className="font-bold text-main-color">로고디자인</span>을
                확인해보세요.
            </h2>
            <div className="mt-5 flex items-center gap-4 text-base font-bold text-zinc-800">
                <div className="my-auto self-stretch">
                    더 많은 샘플 이미지 보러가기
                </div>
                <img
                    loading="lazy"
                    src={images.arrowCircleB}
                    alt=""
                    className="my-auto aspect-square w-6 shrink-0 self-stretch object-contain"
                />
            </div>
            <img
                loading="lazy"
                src={images.ordersImg}
                alt="Logo samples"
                className="mt-8 aspect-[1.69] w-full self-stretch object-contain max-md:max-w-full"
            />
        </section>
    );
}

export default LogoSection;
