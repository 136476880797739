import React, { useState, useEffect } from "react";
import FAQSidebar from "./FAQSidebar";
import FAQItem from "./FAQItem";
import { useGetFAQALLQuery } from "../../../services/apiSlice";
import PaginationComponent from "../../../components/common/Pagination/PaginationComponent"; // Import Pagination

function CustomerSupportFAQSection() {
    const [activeCategory, setActiveCategory] = useState("all");
    const [faqData, setFaqData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 6; 

    const { data: fetchedFAQs, isLoading, error } = useGetFAQALLQuery();

    useEffect(() => {
        if (fetchedFAQs) {
            const initializedFAQs = fetchedFAQs.map((category) => ({
                ...category,
                faqs: category.faqs.map((faq) => ({
                    ...faq,
                    isOpen: false,
                })),
            }));
            setFaqData(initializedFAQs);
        }
    }, [fetchedFAQs]);

    const handleCategoryChange = (category) => {
        setActiveCategory(category);
        setCurrentPage(1);
    };

    const toggleFAQ = (faqIndex) => {
        setFaqData((prevFaqData) =>
            prevFaqData.map((category) =>
                category.category === activeCategory
                    ? {
                          ...category,
                          faqs: category.faqs.map((faq, index) =>
                              index === faqIndex ? { ...faq, isOpen: !faq.isOpen } : faq
                          ),
                      }
                    : category
            )
        );
    };

    const currentCategory = faqData.find((cat) => cat.category === activeCategory);
    const currentFAQs = currentCategory ? currentCategory.faqs : [];

    const totalPages = Math.ceil(currentFAQs.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = currentFAQs.slice(indexOfFirstItem, indexOfLastItem);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    return (
        <main className="mb-40 mt-20 flex flex-col gap-5 px-20 max-md:px-5 md:flex-row">
            {/* Sidebar */}
            <FAQSidebar activeCategory={activeCategory} onCategoryChange={handleCategoryChange} />

            <section className="flex w-full flex-col md:ml-5">
                <div className="flex max-w-full grow flex-col">
                    {currentItems.length > 0 ? (
                        currentItems.map((item, index) => (
                            <FAQItem
                                key={index}
                                {...item}
                                isOpen={item.isOpen}
                                onClick={() => toggleFAQ(indexOfFirstItem + index)}
                            />
                        ))
                    ) : (
                        <p className="text-center text-gray-500"></p>
                    )}
                </div>

                {totalPages > 1 && (
                    <div className="mt-10 flex justify-center">
                        <PaginationComponent
                            count={totalPages}
                            page={currentPage}
                            onPageChange={handlePageChange}
                            showFirstButton
                            showLastButton
                        />
                    </div>
                )}
            </section>
        </main>
    );
}

export default CustomerSupportFAQSection;
