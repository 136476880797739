import React from "react";
import images from "../../images";
import Button from "../../components/common/Button/Button";
import { useNavigate } from "react-router-dom";

function HeroSection() {
    const navigate = useNavigate();

    return (
        <section>
            <div className="relative">
                <Button
                    className="absolute left-[10%] top-[76%] flex h-[54px] !w-[160px] max-w-full justify-center gap-2 rounded-[100%] bg-main-color !px-0 py-2 text-base font-bold leading-none text-white max-md:left-[10%] max-md:top-[72%] max-md:!h-[25px] max-md:min-h-[25px] max-md:!w-[80px] max-md:px-1 max-md:text-[8px]"
                    label="로고 만들기"
                    icon={images.arrowLWIcon}
                    widthIcon={8}
                    onClick={() => {
                        navigate("/logo");
                    }}
                />
                <img
                    loading="lazy"
                    src={images.bannerImg}
                    alt="bannerImg"
                    className="aspect-[2.5] w-full rounded-none object-contain max-md:max-w-full"
                />
            </div>
        </section>
    );
}

export default HeroSection;
