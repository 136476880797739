import React, { useState } from "react";

function CardSpecifications({ specifications, totalAmount }) {
  const [selectedQuantity, setSelectedQuantity] = useState(
    specifications.find((spec) => spec.label === "수량")?.value[0] || ""
  );

  const handleSelectChange = (event) => {
    setSelectedQuantity(event.target.value);
  };

  return (
    <div className="flex flex-col min-w-[240px] w-[600px] max-md:max-w-full">
      <div className="flex flex-col w-full max-md:max-w-full">
        <h3 className="text-2xl font-bold leading-none text-main-color">
          일반명함
        </h3>
        <p className="mt-2.5 text-base leading-6 text-neutral-700">
          일반적으로 많이 사용되는 명함의 종이와 인쇄방법으로
          <br />
          실용적이고 경제적인 선택으로, 기본적인 정보 전달에 적합합니다.
        </p>
        <div className="flex flex-col mt-8 w-full text-base leading-none">
          <hr className="flex w-full bg-gray-200 rounded-lg min-h-[1px]" />
          <div className="flex flex-col px-5 py-8 w-full rounded">
            {specifications.map((spec, index) => (
              <div
                key={index}
                className="flex flex-wrap gap-8 items-center mt-2 first:mt-0 w-full min-h-[30px]"
              >
                <div className="self-stretch my-auto font-bold text-neutral-700 w-[60px]">
                  {spec.label}
                </div>
                <div className="self-stretch my-auto w-[180px]">
                  {spec.type === "select" ? (
                    <select
                      value={selectedQuantity}
                      onChange={handleSelectChange}
                      className="border border-gray-300 rounded p-2"
                    >
                      {spec.value.map((option, idx) => (
                        <option key={idx} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <span className="text-neutral-600">{spec.value}</span>
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className="flex flex-wrap gap-10 justify-between items-center px-5 mt-8 w-full text-lg font-bold leading-none rounded-md bg-stone-50 min-h-[50px] max-md:max-w-full">
            <div className="self-stretch my-auto text-zinc-800">
              {totalAmount.label}
            </div>
            <div className="self-stretch my-auto text-right text-main-color">
              {totalAmount.value}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardSpecifications;
