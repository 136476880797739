// src/services/endpoints/dataEndpoints.js
import { ENDPOINTS } from "../config/apiconfig";
import { setStatusLoading } from "./../../redux/featuresSlice/commonSlice"; 

export const dataEndpoints = (builder) => ({
    getBusinessCards: builder.query({
        query: () => ({
            url: ENDPOINTS.BUSINESS_CARDS,
            method: "GET",
        }),
    }),

    getBusinessCardsImages: builder.query({
        query: ({ order = "DESC", page = 1, take = 10, imageType = "business-card" }) => ({
            url: ENDPOINTS.BUSINESS_CARDS_IMAGE,
            method: "GET",
            params: { order, page, take, imageType },
        }),
        async onQueryStarted(arg, { dispatch, queryFulfilled }) {
            try {
                dispatch(setStatusLoading(true));
                await queryFulfilled; 
            } catch (error) {
                console.error("Error fetching business cards images:", error);
            } finally {
                dispatch(setStatusLoading(false));
            }
        },
    }),
});
