import React, { useEffect, useState } from "react";
import images from "../../images";
import Button from "../../components/common/Button/Button";
import { useNavigate } from "react-router-dom";
import { useGetImageListByTypeMutation } from "../../services/apiSlice";
import { useSelector, useDispatch } from "react-redux";
import {
    setImageTypesData,
    setStatusLoading,
} from "../../redux/featuresSlice/commonSlice";

function HeroSection() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [imageSlideShow, setImageSlideShow] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [getImageListByType] = useGetImageListByTypeMutation();

    useEffect(() => {
        if (imageSlideShow.length > 0) {
            const interval = setInterval(() => {
                setCurrentIndex((prev) => 
                    (prev + 1) % imageSlideShow.length
                );
            }, 3000); 
            
            return () => clearInterval(interval);
        }
    }, [imageSlideShow]);

    const imageTypesData = useSelector(
        (state) => state.common.imageTypesData || [],
    );

    const getImageByType = (type) => {
        return Array.isArray(imageTypesData)
            ? imageTypesData.filter((image) => image.imageType === type)
            : [];
    };

    useEffect(() => {
        if (Array.isArray(imageTypesData) && imageTypesData.length > 0) {
            const createCardImageFooter = getImageByType(
                "dashboard-header",
            );
            
            if (createCardImageFooter.length > 0) {
                setImageSlideShow(createCardImageFooter);
            }
        }
    }, [imageTypesData]);

    const handleDotClick = (index) => {
        setCurrentIndex(index);
    };

    return (
        <section>
            <div className="relative h-[400px] max-md:h-[200px]">
                <Button
                    className="absolute left-[10%] top-[76%] z-10 flex h-[54px] !w-[160px] max-w-full justify-center gap-2 rounded-[100%] bg-main-color !px-0 py-2 text-base font-bold leading-none text-white max-md:left-[10%] max-md:top-[72%] max-md:!h-[25px] max-md:min-h-[25px] max-md:!w-[80px] max-md:px-1 max-md:text-[8px]"
                    label="로고 만들기"
                    icon={images.arrowLWIcon}
                    widthIcon={8}
                    onClick={() => navigate("/logo")}
                />
                
                {/* Slideshow container */}
                <div className="h-full w-full overflow-hidden">
                    {imageSlideShow.length > 0 ? (
                        imageSlideShow.map((image, index) => (
                            <img
                                key={image.id}
                                loading="lazy"
                                src={image.filePath} // Giả sử API trả về trường url
                                alt={`Slide ${index + 1}`}
                                className={`absolute h-full w-full object-cover transition-opacity duration-1000 ${
                                    index === currentIndex 
                                        ? "opacity-100" 
                                        : "opacity-0"
                                }`}
                            />
                        ))
                    ) : (
                        // Fallback khi không có ảnh
                        <img
                            loading="lazy"
                            src={images.bannerImg}
                            alt="default banner"
                            className="h-full w-full object-cover"
                        />
                    )}
                </div>

                {/* Dots container */}
                <div className="absolute bottom-0 left-1/2 z-10 flex -translate-x-1/2 transform gap-2">
                    {imageSlideShow.map((_, index) => (
                        <button
                            key={index}
                            className={`h-2 w-2 rounded-full transition-colors ${
                                index === currentIndex
                                    ? "bg-white"
                                    : "bg-gray-400"
                            }`}
                            onClick={() => handleDotClick(index)}
                        />
                    ))}
                </div>
            </div>
        </section>
    );
}

export default HeroSection;