import React from 'react';

const PurchaseInfo = () => (
  <div className="flex flex-col py-8 mt-10 max-w-full text-base text-center rounded-md border border-gray-200 border-solid text-neutral-700">
    <p className="leading-6 max-md:max-w-full">
      파일 다운로드 버튼을 통해 구매하신 로고 이미지를 확인하실 수 있어요.
      <br />
      <span className="font-bold">[마이페이지 - 나의 제작물 목록]</span> 에서도 파일 다운로드 가능합니다.
    </p>
    <p className="mt-3.5 leading-7 max-md:max-w-full">
      (구매일로부터, 3일 동안 다운로드 가능)
    </p>
  </div>
);

export default PurchaseInfo;