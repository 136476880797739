import React from 'react';

const TabNavigation = ({ activeTab, setActiveTab }) => {
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <nav className="flex flex-wrap gap-4 justify-center items-center self-center mt-2.5 w-full text-xl leading-none whitespace-nowrap border-b border-slate-300   max-md:max-w-full">
      <div
        onClick={() => handleTabClick('logo')}
        className={`self-stretch px-6 pb-4 my-auto font-bold w-[90px] max-md:px-5 cursor-pointer ${activeTab === 'logo'
            ? 'border-b-2 border-slate-700 text-slate-700'
            : 'text-gray-500'
          }`}
      >
        로고
      </div>
      <div
        onClick={() => handleTabClick('businessCard')}
        className={`self-stretch px-6 pb-4 my-auto w-[90px] max-md:px-5 cursor-pointer ${activeTab === 'businessCard'
            ? 'border-b-2 border-slate-700 text-slate-700'
            : 'text-gray-500'
          }`}
      >
        명함
      </div>
    </nav>
  );
};

export default TabNavigation;
