// --notes
// l:left, r:right, b:bottom, t:top, w:white, b:black
// import use images
// import images from "../../images"

// ---
// Import IMAGES
import bannerImg from "./image/banner.png";
import ordersImg from "./image/orders.png";
import logo from "./image/logo.png";
import imgLogin from "./image/img-login.png";

// Import ICONS
import arrowLWIcon from "./icon/arrow-l-w.png";
import arrowBWIcon from "./icon/arrow-b-w.png";
import arrowTWIcon from "./icon/arrow-t-w.png";
import arrowCircleB from "./icon/arrow-circle-b.png";
import penCil from "./icon/penci.svg";
import arrowRWIcon from "./icon/icon-r-blue.svg";
import arrowTRIcon from "./icon/arrow-t-r.svg";
import heartIcon from "./icon/heart.svg";
import naverLogoG from "./icon/naverLogoG.svg";
import naverLogo from "./icon/naver-logo.svg";
import kakaoLogo from "./icon/kakao-logo.svg";
import googleLogo from "./icon/google-logo.svg";
import notiIcon from "./icon/noti-icon.svg";
import profileIcon from "./icon/profile-icon.svg";
import logoutIcon from "./icon/logout-icon.svg";
import closetIcon from "./icon/close-icon.svg";

//Make a business card - Check the applied business card draft
import businessCarFrontPreview from "./../images/businessCardImage/business_card_front_preview.png";
import businessCarBackPreview from "./../images/businessCardImage/business_card_back_preview.png";
import businessCardIcon from "./../images/businessCardImage/business_car_icon.png";
import businessCardPurchaseIcon from "./../images/businessCardImage/business_card_purchase_icon.png";
import businessCardPurchaseFooter from "./../images/businessCardImage/business_card_purchase_footer.png";
import purchaseCompleteFooter from "./../images/businessCardImage/purchase_complete_footer.png";
import businessCardLayoutFooter from "./../images/businessCardImage/business_card_layout_footer.png";

//Select print type - General business card

import cardPreviewBig from "./businessCardImage/card_preview_big.png";
import cardPreviewSmall from "./businessCardImage/card_preview_small.png";

import InquiryImage from "./image/inquiry_image.png";

import businessCardListTop from "./image/business-card-product.png";
import businessCardListBottom from "./image/business-card-product-b.png";

import GeneralImageBig from "./businessCardImage/generalImageBig.png";
import GeneralImageSmall from "./businessCardImage/generalImageSmall.png";
import GeneralImageSmall2 from "./businessCardImage/generalImageSmall2.png";
import GeneralImageSmall3 from "./businessCardImage/generalImageSmall3.png";
import GeneralImageSmall4 from "./businessCardImage/generalImageSmall4.png";

// Export your image
const images = {
    //images
    bannerImg,
    ordersImg,
    logo,
    imgLogin,
    //icons
    arrowLWIcon,
    arrowBWIcon,
    arrowTWIcon,
    arrowTRIcon,
    penCil,
    arrowCircleB,
    heartIcon,
    naverLogoG,
    naverLogo,
    kakaoLogo,
    googleLogo,
    notiIcon,
    profileIcon,
    logoutIcon,
    closetIcon,
    //
    businessCarFrontPreview,
    businessCarBackPreview,
    businessCardIcon,
    businessCardPurchaseIcon,
    businessCardPurchaseFooter,
    purchaseCompleteFooter,
    cardPreviewBig,
    cardPreviewSmall,
    businessCardLayoutFooter,
    InquiryImage,
    arrowRWIcon,
    businessCardListTop,
    businessCardListBottom,
    GeneralImageBig,
    GeneralImageSmall,
    GeneralImageSmall2,
    GeneralImageSmall3,
    GeneralImageSmall4,
};

export default images;
