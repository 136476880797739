import React from "react";

const FileUpload = () => {
  return (
    <>
      <div className="flex flex-col items-start mt-10 w-full leading-none max-md:max-w-full">
        <div className="flex flex-col max-w-full w-[600px]">
          <h3 className="text-base font-bold text-neutral-700">파일첨부</h3>
          <p className="mt-2.5 text-sm text-stone-500">
            이미지 파일만 첨부 가능합니다.
          </p>
        </div>
        <div className="flex flex-wrap gap-2.5 items-start mt-4 max-w-full w-[655px]">
          <label
            htmlFor="file-upload"
            className="flex gap-2.5 justify-center items-center text-base font-semibold text-indigo-600 rounded-md border border-indigo-600 border-solid min-h-[50px] w-[150px] cursor-pointer"
          >
            파일 선택
          </label>
          <input
            id="file-upload"
            type="file"
            className="hidden"
            accept="image/*"
          />
          <div className="overflow-hidden gap-3 self-stretch px-4 text-base font-medium rounded-md bg-stone-50 min-h-[50px] min-w-[240px] text-stone-500 w-[400px] flex items-center">
            선택된 파일이 없습니다.
          </div>
        </div>
      </div>
    </>
  );
};

export default FileUpload;
