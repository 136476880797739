import React from 'react';
import FileAttachment from './FileAttachment';
import FileListItem from './FileListItem';

function ConsultationDetails({ files }) {
    const requestOptions = [
        { icon: "https://cdn.builder.io/api/v1/image/assets/608912cecb5144cf887238b12afdb74b/0a10b5ee2c4c8b272926086ffcd39a9ef5ffd957e93576c18d3dd902722fccb3?apiKey=608912cecb5144cf887238b12afdb74b&", text: "컬러수정", active: false },
        { icon: "https://cdn.builder.io/api/v1/image/assets/608912cecb5144cf887238b12afdb74b/191420ba10ec964142e2ca1d3a0e1129c4c1261d92355624d5a4780fa5c5e928?apiKey=608912cecb5144cf887238b12afdb74b&", text: "벡터파일", active: true },
        { icon: "https://cdn.builder.io/api/v1/image/assets/608912cecb5144cf887238b12afdb74b/0a10b5ee2c4c8b272926086ffcd39a9ef5ffd957e93576c18d3dd902722fccb3?apiKey=608912cecb5144cf887238b12afdb74b&", text: "위치변경", active: false },
        { icon: "https://cdn.builder.io/api/v1/image/assets/608912cecb5144cf887238b12afdb74b/0a10b5ee2c4c8b272926086ffcd39a9ef5ffd957e93576c18d3dd902722fccb3?apiKey=608912cecb5144cf887238b12afdb74b&", text: "기타 요청 (상담 후 결정)", active: false },
    ];

    return (
        <section className="flex flex-col self-center px-12 py-10 mt-5 w-full border border-solid border-neutral-200 max-w-[1000px] max-md:px-5 max-md:max-w-full">
            <div className="flex flex-col justify-center w-full leading-none max-md:max-w-full">
                <h3 className="text-base font-bold text-neutral-700 max-md:max-w-full">간단 요청 사항</h3>
                <div className="flex flex-wrap gap-10 items-center self-start mt-4 text-lg text-center text-zinc-500 max-md:max-w-full">
                    {requestOptions.map((option, index) => (
                        <div key={index} className={`flex gap-2.5 items-center self-stretch my-auto whitespace-nowrap ${option.active ? 'font-bold text-zinc-800' : ''}`}>
                            <img loading="lazy" src={option.icon} alt="" className="object-contain shrink-0 self-stretch my-auto w-6 rounded-none aspect-square" />
                            <div className="self-stretch my-auto">{option.text}</div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="flex flex-col mt-10 w-full max-md:max-w-full">
                <label htmlFor="detailsTextarea" className="text-base font-bold leading-none text-neutral-700 max-md:max-w-full">
                    상세 내용
                </label>
                {/* {files.map((file, index) => (
                    <FileListItem key={index} fileName={file.name} />
                ))} */}
                <textarea
                    id="detailsTextarea"
                    className="gap-2.5 px-5 pt-5 pb-64 mt-4 w-full text-base leading-6 rounded-md border border-solid bg-stone-50 border-stone-50 min-h-[320px] text-zinc-500 max-md:pb-24 max-md:max-w-full"
                    placeholder="전문가에게 요청하실 내용을 입력해주세요.&#10;내용 확인 후 상담이 진행됩니다."
                ></textarea>
            </div>
            <FileAttachment files={files} />
        </section>
    );
}

export default ConsultationDetails;