import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LogoMatchingHeader from "./LogoMatchingHeader";
import LogoMatchingGrid from "./LogoGrid/LogoMatchingGrid";
import Pagination from "../../ViewPage/Pagination";
import logoItemsData from "../../../utils/constants/logoItems.json";
import Button from "../../../components/common/Button/Button";
import {
    useGetLogosListQuery,
    usePostOrdersLogosMutation,
} from "../../../services/apiSlice";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const LogoMatchingList = () => {
    const navigate = useNavigate();
    const accessToken = useSelector((state) => state.common.accessToken);
    const { userData } = useSelector((state) => state.common);

    const userId = userData?.userID || null;
    const { id } = useParams();
    const [
        postOrdersLogosMutation,
        { loading, error: errorPostOrder, data: dataPostOrder },
    ] = usePostOrdersLogosMutation();

    const { data, isLoading, error } = useGetLogosListQuery({
        logoId: id,
    });
    const [selectedLogo, setSelectedLogo] = useState(null);
    const goToCreateLogo = () => {
        navigate("/logo");
    };

    const handleLogoSelect = (logoData) => {
        setSelectedLogo(logoData);
    };
    useEffect(() => {}, [selectedLogo]);

    const goToBuyLogo = async () => {
        if (accessToken) {
            if (selectedLogo) {
                const response = await postOrdersLogosMutation({
                    userId: userId,
                    productId: selectedLogo?.id,
                    itemName: "string",
                    quantity: 1,
                    totalAmount: 0,
                    orderStatus: "NOT_STARTED",
                });

                if (response?.data?.order) {
                    toast.success("Create order Successfully!");
                    navigate(`/logo/order/${selectedLogo?.id}`);
                }
            } else {
                toast.warning("로고를 선택해주세요!");
            }
        } else {
            navigate(`/login?productId=${id}&orderType=LOGO`);
        }
    };

    return (
        <div>
            <main className="flex flex-col px-20 max-md:px-5">
                <LogoMatchingHeader />
                <LogoMatchingGrid
                    logoItems={data?.data || []}
                    onSelect={(e) => handleLogoSelect(e)}
                />
                <Pagination />
            </main>
            <div className="mt-10 flex w-full flex-wrap items-center justify-between overflow-hidden border-t-2 bg-white px-9 py-5 shadow-sm max-md:max-w-full max-md:px-5">
                <div className="my-auto flex min-w-[240px] flex-1 shrink basis-0 items-center gap-3 self-stretch text-lg leading-none text-neutral-600 max-md:max-w-full">
                    <div className="my-auto flex w-[691px] min-w-[240px] flex-wrap items-center gap-3 self-stretch">
                        <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/2fcf8c4d38e6934af0177f9b3600e1e4c59c58657a323985b1223071b8b77daf?placeholderIfAbsent=true&apiKey=3ac17b61e0c94c2b87d841ac8b4d3afd"
                            alt=""
                            className="my-auto aspect-square w-6 shrink-0 self-stretch object-contain"
                        />
                        <p className="my-auto self-stretch">
                            구매하고자 하는 시안을 선택하여 진행해주세요.
                        </p>
                    </div>
                </div>
                <div className="my-auto flex min-w-[240px] flex-wrap items-center gap-5 self-stretch text-center text-base font-bold leading-none max-md:max-w-full">
                    <Button
                        label="정보 다시 입력하기"
                        onClick={goToCreateLogo}
                        className="my-auto w-[300px] min-w-[240px] gap-2.5 self-stretch rounded-md border border-solid border-neutral-300 bg-white px-4 py-4 text-zinc-800"
                    />

                    <Button
                        label="선택한 시안으로 구매"
                        onClick={goToBuyLogo}
                        className="my-auto min-h-[50px] w-[300px] min-w-[240px] gap-2.5 self-stretch rounded-md bg-indigo-600 px-4 py-5 text-white"
                    />
                </div>
            </div>
        </div>
    );
};

export default LogoMatchingList;
