import React from "react";

const AILogoDesignNotice = () => {
  return (
    <section className="flex flex-col pt-6 pb-12 text-sm rounded-none max-md:px-5 w-full px-5">
      <h2 className="font-semibold leading-none text-neutral-500 max-md:max-w-full">
        [인공 지능 생성 로고 디자인 이용 시 주의 사항]
      </h2>
      <p className="mt-2 leading-6 text-neutral-500 max-md:max-w-full">
        ・다운로드한 내용을 상업적 목적의 웹사이트에 게시하거나 이를 통해 수익을
        얻는 행위를 금지합니다.
        <br />
        ・다운로드한 내용을 공모전 제출 또는 포트폴리오 용도로 사용하는 것을
        금지하며, 이를 위반할 경우 법적 책임 및 피해 보상을 요구할 수 있습니다.
      </p>
    </section>
  );
};

export default AILogoDesignNotice;
