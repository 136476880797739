import React from "react";
import HeroSection from "./HeroSection";
import LogoSection from "./LogoSection";
import FAQSection from "./FAQSection";

function MainLayout() {
    return (
        <main>
            <HeroSection />
            <LogoSection />
            <div className="mt-20 flex min-h-[1px] w-full bg-neutral-200 max-md:mt-10" />
            <FAQSection />
        </main>
    );
}

export default MainLayout;
