import React from "react";

function InquiryResponse() {
  return (
    <section className="flex flex-col pt-11 w-full max-md:max-w-full">
      <h2 className="w-full text-xl font-semibold leading-none whitespace-nowrap text-zinc-800 max-md:max-w-full">
        답변
      </h2>
      <div className="flex flex-col justify-center px-6 py-8 mt-5 w-full text-base leading-relaxed text-center text-gray-500 rounded-md bg-slate-100 max-md:px-5 max-md:max-w-full">
        <p className="w-full max-md:max-w-full">현재 답변 대기중입니다.</p>
      </div>
    </section>
  );
}

export default InquiryResponse;
