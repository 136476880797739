import React from "react";

function DetailContent({ content }) {
    return (
        <div className="flex flex-col mt-8 w-full max-md:max-w-full">
            <h2 className="text-base font-bold leading-none text-neutral-700 max-md:max-w-full">
                상세 내용
            </h2>
            <p className="flex-1 shrink gap-2.5 px-5 py-5 mt-4 w-full text-base leading-6 bg-white rounded-md border border-gray-200 border-solid min-h-[320px] text-zinc-800 max-md:max-w-full whitespace-pre-line">
                {content}
            </p>
        </div>
    );
}

export default DetailContent;