import React, { useState } from "react";
import InputField from "../../../components/common/Input/InputField";
import OptionSelector from "../../../components/common/OptionSelect/OptionSelector";
import ColorSelector from "../../../components/common/OptionSelect/ColorSelector";
import IndustrySelector from "../../../components/common/OptionSelect/IndustrySelector";
import Button from "../../../components/common/Button/Button";
import ImageComponent from "../../../components/FooterBanner";
import {
    businessCardImages,
    logoImages,
} from "../../../components/common/dataFake/imageData";
import colorsData from "../../../utils/constants/colors.json";
import logoOptions from "../../../utils/constants/logoOptions.json";
import { useNavigate } from "react-router-dom";
import Image from "../../../components/common/Image/Image";
import StepHeader from "../../LayoutCommon/StepHeader";

const CreateLogo = () => {
    const navigate = useNavigate();

    const [activeTab, setActiveTab] = useState("logo");
    const [selectedColor, setSelectedColor] = useState("Orange");
    const [selectedOption, setSelectedOption] = useState("콤비네이션 마크");
    const [selectedIndustries, setSelectedIndustries] = useState([]);

    const goToCreateLogo = () => {
        navigate("/logo-matching-list");
    };

    const handleSelectColor = (color) => {
        setSelectedColor(color);
    };

    const handleSelectOption = (option) => {
        setSelectedOption(option);
    };

    const handleSelectIndustries = (selectedIndustries) => {
        setSelectedIndustries(selectedIndustries);
    };
    const allImages = activeTab === "logo" ? logoImages : businessCardImages;

    return (
        <div>
            <main className="flex flex-col rounded-none px-20">
                <header className="flex min-h-[177px] w-full flex-col justify-center px-72 py-14 text-center leading-none max-md:max-w-full max-md:px-5">
                    <div className="flex max-w-full flex-col">
                        <h1 className="self-center text-3xl font-bold text-black">
                            로고 정보 입력하기
                        </h1>
                        <p className="mt-5 text-lg text-neutral-700 max-md:max-w-full">
                            디자인으로 머리 아프게 고민하셨다면 AI가 제안하는
                            시안으로 빠르고 간편하게 디자인 매칭을 경험해
                            보세요.
                        </p>
                    </div>
                </header>
                <section className="flex w-full flex-col self-center leading-none max-md:max-w-full">
                    <StepHeader
                        step="1"
                        title="로고에 들어갈 정보를 입력하세요."
                    />
                    <form className="mt-5 flex flex-col justify-center rounded-md border border-solid border-neutral-200 px-12 py-10 text-base max-md:max-w-full max-md:px-5">
                        <InputField
                            label="작업명"
                            description="작업명은 로고 구매 시, 구매하신 제작물을 구분하기 위해 등록됩니다."
                            placeholder="작업명을 입력해주세요. (최대 글자수 nn자 까지 입력가능)"
                        />
                        <div className="mt-10 flex w-full flex-wrap items-start gap-9 max-md:max-w-full">
                            <InputField
                                label="회사명"
                                placeholder="회사명을 입력해주세요. (최대 글자수 nn자 까지 입력가능)"
                                className="min-w-[240px] flex-1 shrink basis-0 max-md:max-w-full"
                            />
                            <InputField
                                label="브랜드 네임"
                                placeholder="브랜드 네임을 입력해주세요. (최대 글자수 nn자 까지 입력가능)"
                                className="min-w-[240px] flex-1 shrink basis-0 max-md:max-w-full"
                            />
                        </div>
                        <InputField
                            label="슬로건"
                            placeholder="슬로건을 입력해주세요. (최대 글자수 nn자 까지 입력가능)"
                            className="mt-10 w-full max-md:max-w-full"
                        />
                    </form>
                    <StepHeader
                        step="2"
                        title="로고 디자인에 들어갈 옵션을 선택하세요."
                        className="mt-14 max-md:mt-10"
                    />
                    <div className="mt-5 flex w-full flex-col rounded-md border border-solid border-neutral-200 px-12 pb-12 pt-10 max-md:max-w-full max-md:px-5">
                        <OptionSelector
                            label="로고 타입 선택"
                            options={logoOptions.logoTypes.map(
                                (item) => item.value,
                            )}
                            selectedOption={selectedOption}
                            onSelectOption={handleSelectOption}
                        />
                        <ColorSelector
                            label="색상"
                            colors={colorsData}
                            selectedColor={selectedColor}
                            onSelectColor={handleSelectColor}
                        />
                        <IndustrySelector
                            label="업종 선택"
                            onSelect={handleSelectIndustries}
                        />

                        <InputField
                            label="키워드"
                            placeholder="키워드를 입력해주세요."
                            className="mt-10 w-full max-md:max-w-full"
                        />
                    </div>

                    <div className="flex justify-center">
                        <Button
                            label="로고 만들기"
                            className={`mt-12 w-[320px] bg-zinc-800 text-white max-md:mt-10`}
                            onClick={goToCreateLogo}
                        />
                    </div>
                </section>
            </main>
            <footer className="relative mt-24 flex min-h-[200px] w-full flex-col max-md:mt-10 max-md:max-w-full">
                <Image src="https://cdn.builder.io/api/v1/image/assets/TEMP/1f42110afbe3de319d01b2bd84bcfda185716922034589a684e4aa55f683b52d?placeholderIfAbsent=true&apiKey=3ac17b61e0c94c2b87d841ac8b4d3afd" />
            </footer>
        </div>
    );
};

export default CreateLogo;
