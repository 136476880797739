import React from "react";
import FileUpload from "./FileUpload";
import InputField from "./../../../../components/common/Input/InputField";
import TextArea from "./../../../../components/common/Input/TextArea";
import Button from "../../../../components/common/Button/Button";

function InquiryForm() {
  return (
    <main className="flex flex-col rounded-none  py-10">
      <header className="flex flex-col justify-center self-start leading-none">
        <h1 className="text-2xl font-bold text-indigo-600">문의하기</h1>
        <h2 className="mt-2.5 text-xl font-semibold text-neutral-700">
          1:1 문의하실 사항을 작성해주세요.
        </h2>
      </header>

      <form className="flex flex-col px-12 py-10 mt-5 w-full bg-white border border-solid border-neutral-200 max-md:px-5 max-md:max-w-full">
        <InputField label="제목" placeholder="제목을 입력해주세요." />

        <TextArea
          label="상세 내용"
          placeholder="문의하실 내용을 입력해주세요."
        />

        <FileUpload />
      </form>
      <Button
        label={" 문의 보내기"}
        className="gap-2.5 self-center mb-10 px-4 py-4 mt-10 w-80 max-w-full text-base font-bold leading-none text-center text-white bg-indigo-600 rounded-md"
      />
    </main>
  );
}

export default InquiryForm;
