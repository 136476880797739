import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoutes = () => {
    let accessToken =
        sessionStorage.getItem("accessToken") ||
        sessionStorage.getItem("refreshToken") ||
        false;

    return accessToken ? <Outlet /> : <Navigate to="/home" />;
    // accessToken ? <Outlet /> : <Outlet />
};

export default PrivateRoutes;
