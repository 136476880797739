import React, { useState, useEffect } from "react";
import HeroSection from "./HeroSection";
import LogoSection from "./LogoSection";
import FAQSection from "./FAQSection";
import ModalComponent from "../../components/common/Modals/ModalComponent";
import { termsOfUse } from "../../components/common/data/Data";
import { useNavigate } from "react-router-dom";
// import { useTranslation } from "react-i18next";

function MainLayout() {
    const navigate = useNavigate();
    // const {t, i18n} = useTranslation()

    // const changeLanguage = (lang) => {
    //   i18n.changeLanguage(lang)
    // }
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const accessToken = urlParams.get("accessToken");

        if (accessToken) {
            sessionStorage.setItem("accessToken", accessToken);
            urlParams.delete("accessToken");
            navigate({ search: urlParams.toString() }, { replace: true });
        }
    }, [navigate]);
    return (
        <>
            <main>
                {/* <button onClick={()=>{changeLanguage("en")}}>en</button> */}
                {/* <button onClick={()=>{changeLanguage("vi")}}>vi</button> */}
                {/* {t('lang')} */}
                <HeroSection />
                <LogoSection />
                <div className="mt-20 flex min-h-[1px] w-full bg-neutral-200 max-md:mt-10 max-md:max-w-full" />
                <FAQSection />
                <ModalComponent
                    className="h-[700px] w-[800px]"
                    title={false ? "파일 첨부 시" : "개인정보 처리방침"}
                    open={false}
                    content={
                        <div className="h-[580px] overflow-y-auto">
                            {termsOfUse}
                        </div>
                    }
                />
            </main>
        </>
    );
}

export default MainLayout;
