import React from "react";
import { usePostOrdersMutation } from "../../../services/apiSlice";
import { toast } from "react-toastify"; // Import toast

function PurchaseInfo({ onBuyClick, onBackClick }) {
    const [postOrders, { loading, error, data }] = usePostOrdersMutation();

    return (
        <div className="flex flex-col rounded-xl border border-solid border-gray-200 bg-white px-8 py-9 max-md:max-w-full max-md:px-5">
            <div className="flex w-full flex-col">
                <div className="flex w-full flex-col rounded-xl bg-slate-50 px-5 py-6">
                    <h3 className="text-base font-bold leading-none text-neutral-700">
                        [상품 구매 시 안내사항]
                    </h3>
                    <div className="mt-4 flex w-full flex-col text-sm leading-5 text-zinc-800">
                        <p>
                            ・로고로지에서 구매하시는 상품의 경우 디지털
                            콘텐츠로 제공된 상품이기에 구매 후에는 환불이
                            불가합니다.
                        </p>
                        <p className="mt-2.5">
                            ・다만 제공된 상품의 문제가 발생 시
                            <span className="font-bold">
                                {" "}
                                [고객센터 - 문의하기]{" "}
                            </span>{" "}
                            에서 문의를 진행해주시기 바랍니다.
                        </p>
                    </div>
                </div>
                <div className="mt-4 flex items-center gap-2.5 self-start text-base leading-none text-neutral-700">
                    <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/cfbd5fb6c5dab876993085907861e2e1dc8c64332834ba352b1f0d21d63ab6a3?placeholderIfAbsent=true&apiKey=3ac17b61e0c94c2b87d841ac8b4d3afd"
                        alt="Checkbox icon"
                        className="my-auto aspect-square w-4 shrink-0 self-stretch rounded-none object-contain"
                    />
                    <label htmlFor="agreement" className="my-auto self-stretch">
                        주문 내용을 모두 확인했으며, 상품 결제 동의합니다.
                    </label>
                    <input type="checkbox" id="agreement" className="sr-only" />
                </div>
            </div>
            <div className="mt-9 flex w-full flex-col justify-center whitespace-nowrap text-center text-base font-bold leading-none">
                <button
                    onClick={onBuyClick}
                    className="min-h-[50px] w-full rounded-md bg-indigo-600 px-4 text-white"
                >
                    구매하기
                </button>
                <button
                    onClick={onBackClick}
                    className="mt-4 min-h-[50px] w-full rounded-md border border-solid border-neutral-300 bg-white px-4 text-zinc-800"
                >
                    이전으로
                </button>
            </div>
        </div>
    );
}

export default PurchaseInfo;
