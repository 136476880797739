import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

const TableCommon = ({ columns, data }) => {
  return (
    <TableContainer>
      <Table>
        <TableHead className="bg-[#F6F8FF]">
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.field}>
                <Typography component={"div"} variant="subtitle2" fontWeight="bold">
                  {column.label}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index}>
              {columns.map((column) => (
                <TableCell key={column.field}>{row[column.field]}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableCommon;
