import React, { useState } from "react";
import ImageCard from "./ImageCard";
import ModalImage from "../../components/common/Modals/ModalImage";

const ImageGrid = ({ images }) => {
    const [selectedImage, setSelectedImage] = useState(null);

    const openModal = (imageSrc) => {
        setSelectedImage(imageSrc);
    };

    const closeModal = () => {
        setSelectedImage(null);
    };

    return (
        <>
            <div className="container mx-auto border-b border-slate-300 px-4 pb-10">
                <div className="grid min-h-[219px] w-full grid-cols-1 gap-x-6 gap-y-[60px] sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4">
                    {images.map((image, index) => (
                        <div
                            key={index}
                            onClick={() => openModal(image.filePath)}
                            className="flex cursor-pointer items-center justify-center"
                        >
                            <ImageCard
                                src={image.filePath}
                                isSpecial={image.isSpecial}
                            />
                        </div>
                    ))}
                </div>
            </div>

            <ModalImage
                isOpen={!!selectedImage}
                onClose={closeModal}
                src={selectedImage}
            />
        </>
    );
};

export default ImageGrid;
