import React from 'react';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const SelectBox = ({ items, placeholder, value, onChange }) => {
  const theme = useTheme();

  return (
    <FormControl fullWidth variant="outlined">
      <Select
        value={value}
        onChange={onChange}
        displayEmpty
        input={<OutlinedInput />}
        renderValue={(selected) => selected || <em>{placeholder}</em>}
        MenuProps={MenuProps}
      >
        <MenuItem disabled value="">
          <em>{placeholder}</em>
        </MenuItem>
        {items.map((item, index) => (
          <MenuItem key={index} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectBox;
