import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/common/Button/Button";
import FileFormatOption from "./../../components/common/FileFormatOption/FileFormatOption";
import fileFormats from "./../../utils/constants/fileFormatOptionBusinessCard.json";

const ChoseBusinessCardType = () => {
  const navigate = useNavigate();

  const [selectedOption, setSelectedOption] = useState(0);

  const handleOptionChange = (index) => {
    setSelectedOption(index);
  };
  const goToBusinessCardLayout = () => {
    navigate("/card-layout");
  };

  return (
    <div className="flex flex-col bg-white px-20 max-md:px-5 py-10 mb-40">
      <main>
        <section className="flex flex-col justify-center py-14 w-full leading-none text-center min-h-[177px] max-md:px-5 max-md:max-w-full">
          <div className="flex flex-col max-w-full">
            <h1 className="self-center text-3xl font-bold text-black">
              명함 타입 선택하기
            </h1>
            <p className="mt-5 text-lg text-neutral-700 max-md:max-w-full">
              로고로지에서 각 타입별 명함 템플릿을 통해 빠르게 명함을 제작해
              보세요.
            </p>
          </div>
          <div className="flex flex-col mt-5 w-full max-md:max-w-full">
            <div className=" flex fle-row gap-5 w-full max-md:max-w-full max-md:flex max-md:flex-wrap max-md:justify-start">
              {fileFormats.map((option, index) => (
                <div key={index} className="w-full max-md:w-full">
                  <FileFormatOption
                    subtitle={option.subtitle}
                    title={option.title}
                    features={option.features}
                    isSelected={selectedOption === index}
                    onSelectionChange={() => handleOptionChange(index)}
                  />
                </div>
              ))}
            </div>
          </div>
        </section>
        <div className="flex justify-center">
          <Button
            className="gap-2.5 self-center px-4 mt-8 w-80 max-w-full text-base font-bold leading-none text-center text-white rounded-md bg-zinc-800 min-h-[50px]"
            label={"선택한 타입 샘플 확인하기"}
            onClick={goToBusinessCardLayout}
          />
        </div>
      </main>
    </div>
  );
};

export default ChoseBusinessCardType;
