import React from "react";
import { useNavigate } from "react-router-dom";
import LogoMatchingHeader from "./LogoMatchingHeader";
import LogoMatchingGrid from "./LogoGrid/LogoMatchingGrid";
import Pagination from "../../ViewPage/Pagination";
import logoItemsData from "../../../utils/constants/logoItems.json";
import Button from "../../../components/common/Button/Button";

const LogoMatchingList = () => {
  const navigate = useNavigate();

  // const [selectedLogo, setSelectedLogo] = useState(null);
  const goToCreateLogo = () => {
    navigate("/logo-creation");
  };

  const goToBuyLogo = () => {
    navigate("/buy-logo-creation");
  };

  const handleLogoSelect = (logoData) => {
    // setSelectedLogo(logoData);
  };
  return (
    <div>
      <main className="flex flex-col px-20">
        <LogoMatchingHeader />
        <LogoMatchingGrid
          logoItems={logoItemsData}
          onSelect={handleLogoSelect}
        />
        <Pagination />
      </main>
      <div className="flex overflow-hidden flex-wrap justify-between items-center px-9 py-5 mt-10 w-full bg-white shadow-sm max-md:px-5 max-md:max-w-full border-t-2">
        <div className="flex flex-1 shrink gap-3 items-center self-stretch my-auto text-lg leading-none basis-0 min-w-[240px] text-neutral-600 max-md:max-w-full">
          <div className="flex flex-wrap gap-3 items-center self-stretch my-auto min-w-[240px] w-[691px]">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/2fcf8c4d38e6934af0177f9b3600e1e4c59c58657a323985b1223071b8b77daf?placeholderIfAbsent=true&apiKey=3ac17b61e0c94c2b87d841ac8b4d3afd"
              alt=""
              className="object-contain shrink-0 self-stretch my-auto w-6 aspect-square"
            />
            <p className="self-stretch my-auto">
              구매하고자 하는 시안을 선택하여 진행해주세요.
            </p>
          </div>
        </div>
        <div className="flex flex-wrap gap-5 items-center self-stretch my-auto text-base font-bold leading-none text-center min-w-[240px] max-md:max-w-full ">
          <Button
            label="정보 다시 입력하기"
            onClick={goToCreateLogo}
            className="gap-2.5 self-stretch px-4 py-4 my-auto bg-white rounded-md border border-solid border-neutral-300 min-w-[240px] text-zinc-800 w-[300px]"
          />

          <Button
            label="선택한 시안으로 구매"
            onClick={goToBuyLogo}
            className="gap-2.5 self-stretch px-4 py-5 my-auto text-white bg-indigo-600 rounded-md min-h-[50px] min-w-[240px] w-[300px]"
          />
        </div>
      </div>
    </div>
  );
};

export default LogoMatchingList;
