import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
// import { useDispatch, useSelector } from "react-redux";
import Logo from "./Logo";
// import Content from "./Content";
import ButtonComponent from "../../components/common/Button/ButtonComponent";
import images from "../../images";
import Copyright from "../LayoutCommon/Copyright";
import { setStatusLoading } from "../../redux/featuresSlice/commonSlice";
import { useDispatch } from "react-redux";

const Login = () => {
    // const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleLogin = async (platform) => {
        console.log(process.env.REACT_APP_BASE_URL);
        dispatch(setStatusLoading(true));
        let loginUrl =
            `${process.env.REACT_APP_BASE_URL}/auth/${platform}` ?? "";

        window.location.href = loginUrl;
    };

    return (
        <div className="flex flex-col items-center bg-white">
            <Logo />
            {/* <Content /> */}
            <div className="flex w-full min-w-[280px] max-w-[320px] flex-col items-center">
                <ButtonComponent
                    icon={images.naverLogo}
                    variant="naver"
                    className="mt-4"
                    onClick={() => handleLogin("naver")}
                >
                    네이버 로그인
                </ButtonComponent>
                <ButtonComponent
                    icon={images.kakaoLogo}
                    variant="kakao"
                    className="mt-4"
                    onClick={() => handleLogin("kakao")}
                >
                    카카오톡 로그인
                </ButtonComponent>
                <ButtonComponent
                    icon={images.googleLogo}
                    variant="google"
                    className="mt-4"
                    onClick={() => handleLogin("google")}
                >
                    Google로 로그인
                </ButtonComponent>
            </div>
            <div className="my-10">
                <Copyright fontSize="text-xs" />
            </div>
        </div>
    );
};

export default Login;
