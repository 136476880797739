// --notes
// l:left, r:right, b:bottom, t:top, w:white, b:black
// import use images
// import images from "../../images"

// ---
// Import IMAGES
import bannerImg from "./image/banner.png";
import ordersImg from "./image/orders.png";
import logo from "./image/logo.png";
import imgLogin from "./image/img-login.png";

// Import ICONS
import arrowLWIcon from "./icon/arrow-l-w.png";
import arrowBWIcon from "./icon/arrow-b-w.png";
import arrowTWIcon from "./icon/arrow-t-w.png";
import arrowCircleB from "./icon/arrow-circle-b.png";
import penCil from "./icon/penci.svg";
import arrowRWIcon from "./icon/icon-r-blue.svg";
import arrowTRIcon from "./icon/arrow-t-r.svg";
import heartIcon from "./icon/heart.svg";
import naverLogoG from "./icon/naverLogoG.svg";
import naverLogo from "./icon/naver-logo.svg";
import kakaoLogo from "./icon/kakao-logo.svg";
import googleLogo from "./icon/google-logo.svg";
import notiIcon from "./icon/noti-icon.svg";
import profileIcon from "./icon/profile-icon.svg";
import logoutIcon from "./icon/logout-icon.svg";
import closeIcon from "./icon/close-icon.svg";
import arrowTopIcon from "./icon/arrowTopIcon.svg";
import arrowBottomIcon from "./icon/arrowBottomIcon.svg";
import startIcon from "./icon/startIcon.svg";
import arrowLeftIcon from "./icon/arrowLeftIcon.svg";
import bulletPointBIcon from "./icon/bullet-point-b-icon.svg";
import bulletPointWIcon from "./icon/bullet-point-w-icon.svg";
import closeIconB from "./icon/close-icon-b.svg";
import IConDownLoad from "./icon/iconDownload.png";
import IconNoticationActive from "./icon/noticatioActive.png";
import IconNoticationInActive from "./icon/noticatioInactive.png";

//Make a business card - Check the applied business card draft
import businessCarFrontPreview from "./../images/businessCardImage/business_card_front_preview.png";
import businessCarBackPreview from "./../images/businessCardImage/business_card_back_preview.png";
import businessCardIcon from "./../images/businessCardImage/business_car_icon.png";
import businessCardPurchaseIcon from "./../images/businessCardImage/business_card_purchase_icon.png";
import businessCardPurchaseFooter from "./../images/businessCardImage/business_card_purchase_footer.png";
import purchaseCompleteFooter from "./../images/businessCardImage/purchase_complete_footer.png";
import businessCardLayoutFooter from "./../images/businessCardImage/business_card_layout_footer.png";

//Select print type - General business card

import cardPreviewBig from "./businessCardImage/card_preview_big.png";
import cardPreviewSmall from "./businessCardImage/card_preview_small.png";

import InquiryImage from "./image/inquiry_image.png";

import businessCardListTop from "./image/business-card-product.png";
import businessCardListBottom from "./image/business-card-product-b.png";

import GeneralImageBig from "./businessCardImage/generalImageBig.png";
import GeneralImageSmall from "./businessCardImage/generalImageSmall.png";
import GeneralImageSmall2 from "./businessCardImage/generalImageSmall2.png";
import GeneralImageSmall3 from "./businessCardImage/generalImageSmall3.png";
import GeneralImageSmall4 from "./businessCardImage/generalImageSmall4.png";

import PremiumImageBig1 from "./businessCardImage/premiumBig1.png";
import PremiumImageSmall1 from "./businessCardImage/premiumSmall.png";
import PremiumImageSmall2 from "./businessCardImage/premiumSmall2.png";
import PremiumImageSmall3 from "./businessCardImage/premiumSmall3.png";
import PremiumImageSmall4 from "./businessCardImage/premiumSmall4.png";

import PremiumImageBig2 from "./businessCardImage/premiumBig2.png";
import PremiumImageSmall21 from "./businessCardImage/premiumSmall21.png";
import PremiumImageSmall22 from "./businessCardImage/premiumSmall22.png";
import PremiumImageSmall23 from "./businessCardImage/premiumSmall23.png";
import PremiumImageSmall24 from "./businessCardImage/premiumSmall24.png";

import PremiumImageBig3 from "./businessCardImage/premiumBig3.png";
import PremiumImageSmall31 from "./businessCardImage/premiumSmall31.png";
import PremiumImageSmall32 from "./businessCardImage/premiumSmall32.png";
import PremiumImageSmall33 from "./businessCardImage/premiumSmall33.png";
import PremiumImageSmall34 from "./businessCardImage/premiumSmall34.png";

import PremiumImageBig4 from "./businessCardImage/premiumBig4.png";
import PremiumImageSmall41 from "./businessCardImage/premiumSmall41.png";
import PremiumImageSmall42 from "./businessCardImage/premiumSmall42.png";
import PremiumImageSmall43 from "./businessCardImage/premiumSmall43.png";
import PremiumImageSmall44 from "./businessCardImage/premiumSmall44.png";

import PremiumImageBig5 from "./businessCardImage/premiumBig5.png";
import PremiumImageSmall51 from "./businessCardImage/premiumSmall51.png";
import PremiumImageSmall52 from "./businessCardImage/premiumSmall52.png";
import PremiumImageSmall53 from "./businessCardImage/premiumSmall53.png";
import PremiumImageSmall54 from "./businessCardImage/premiumSmall54.png";

import CustomImageBig from "./businessCardImage/customBig.png";
import CustomImageSmall1 from "./businessCardImage/customSmall1.png";
import CustomImageSmall2 from "./businessCardImage/customSmall2.png";
import CustomImageSmall3 from "./businessCardImage/customSmall3.png";
import CustomImageSmall4 from "./businessCardImage/customSmall4.png";

//Business card making
import Corporate1 from "./businessCardMaking/Corporate1.png";
import Corporate2 from "./businessCardMaking/Corporate2.png";
import Corporate3 from "./businessCardMaking/Corporate3.png";
import Corporate4 from "./businessCardMaking/Corporate4.png";
import Corporate5 from "./businessCardMaking/Corporate5.png";
import Corporate6 from "./businessCardMaking/Corporate6.png";
import CreatorFront1 from "./businessCardMaking/CreatorFront1.png";
import CreatorFront2 from "./businessCardMaking/CreatorFront2.png";
import CreatorFront3 from "./businessCardMaking/CreatorFront3.png";
import CreatorBack1 from "./businessCardMaking/CreatorBack1.png";
import CreatorBack2 from "./businessCardMaking/CreatorBack2.png";
import CreatorBack3 from "./businessCardMaking/CreatorBack3.png";
import StoreFront1 from "./businessCardMaking/StoreFront1.png";
import StoreFront2 from "./businessCardMaking/StoreFront2.png";
import StoreFront3 from "./businessCardMaking/StoreFront3.png";
import StoreBack1 from "./businessCardMaking/StoreBack1.png";
import StoreBack2 from "./businessCardMaking/StoreBack2.png";
import StoreBack3 from "./businessCardMaking/StoreBack3.png";

import ImageCard_1 from "./image/format/card/image_1.png";
import ImageCard_2 from "./image/format/card/image_2.png";
import ImageCard_3 from "./image/format/card/image_3.png";
import ImageCard_4 from "./image/format/card/image_4.png";
import ImageCard_5 from "./image/format/card/image_5.png";
import ImageCard_6 from "./image/format/card/image_6.png";

import ImageCard1_1 from "./image/format/card/image1_1.png";
import ImageCard1_2 from "./image/format/card/image1_2.png";
import ImageCard1_3 from "./image/format/card/image1_3.png";
import ImageCard1_4 from "./image/format/card/image1_4.png";
import ImageCard1_5 from "./image/format/card/image1_5.png";
import ImageCard1_6 from "./image/format/card/image1_6.png";

import ImageCard2_1 from "./image/format/card/image2_1.png";
import ImageCard2_2 from "./image/format/card/image2_2.png";
import ImageCard2_3 from "./image/format/card/image2_3.png";
import ImageCard2_4 from "./image/format/card/image2_4.png";
import ImageCard2_5 from "./image/format/card/image2_5.png";
import ImageCard2_6 from "./image/format/card/image2_6.png";

// Export your image
const images = {
    //images
    bannerImg,
    ordersImg,
    logo,
    imgLogin,
    //icons
    arrowLWIcon,
    arrowBWIcon,
    arrowTWIcon,
    arrowTRIcon,
    penCil,
    arrowCircleB,
    heartIcon,
    naverLogoG,
    naverLogo,
    kakaoLogo,
    googleLogo,
    notiIcon,
    profileIcon,
    logoutIcon,
    closeIcon,
    arrowBottomIcon,
    arrowTopIcon,
    startIcon,
    arrowLeftIcon,
    bulletPointBIcon,
    bulletPointWIcon,
    closeIconB,
    IConDownLoad,
    IconNoticationActive,
    IconNoticationInActive,
    //
    businessCarFrontPreview,
    businessCarBackPreview,
    businessCardIcon,
    businessCardPurchaseIcon,
    businessCardPurchaseFooter,
    purchaseCompleteFooter,
    cardPreviewBig,
    cardPreviewSmall,
    businessCardLayoutFooter,
    InquiryImage,
    arrowRWIcon,
    businessCardListTop,
    businessCardListBottom,
    GeneralImageBig,
    GeneralImageSmall,
    GeneralImageSmall2,
    GeneralImageSmall3,
    GeneralImageSmall4,

    PremiumImageBig1,
    PremiumImageSmall1,
    PremiumImageSmall2,
    PremiumImageSmall3,
    PremiumImageSmall4,
    PremiumImageBig2,
    PremiumImageSmall21,
    PremiumImageSmall22,
    PremiumImageSmall23,
    PremiumImageSmall24,
    PremiumImageBig3,
    PremiumImageSmall31,
    PremiumImageSmall32,
    PremiumImageSmall33,
    PremiumImageSmall34,
    PremiumImageBig4,
    PremiumImageSmall41,
    PremiumImageSmall42,
    PremiumImageSmall43,
    PremiumImageSmall44,
    PremiumImageBig5,
    PremiumImageSmall51,
    PremiumImageSmall52,
    PremiumImageSmall53,
    PremiumImageSmall54,
    CustomImageBig,
    CustomImageSmall1,
    CustomImageSmall2,
    CustomImageSmall3,
    CustomImageSmall4,
    //Business card making

    Corporate1,
    Corporate2,
    Corporate3,
    Corporate4,
    Corporate5,
    Corporate6,
    CreatorFront1,
    CreatorFront2,
    CreatorFront3,
    CreatorBack1,
    CreatorBack2,
    CreatorBack3,
    StoreFront1,
    StoreFront2,
    StoreFront3,
    StoreBack1,
    StoreBack2,
    StoreBack3,

    //
    ImageCard_1,
    ImageCard_2,
    ImageCard_3,
    ImageCard_4,
    ImageCard_5,
    ImageCard_6,

    ImageCard1_1,
    ImageCard1_2,
    ImageCard1_3,
    ImageCard1_4,
    ImageCard1_5,
    ImageCard1_6,

    ImageCard2_1,
    ImageCard2_2,
    ImageCard2_3,
    ImageCard2_4,
    ImageCard2_5,
    ImageCard2_6,
};

export default images;
