import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import PrivateRoutes from "./layouts/PrivateRoutes";
import PublicRoutes from "./layouts/PublicRoutes";
import Layout from "./layouts/Layout";
import Main from "./layouts/Main";
import MainLayout from "./layouts/LandingPage/MainLayout";
import Login from "./layouts/Login/Login";
import SNSInfomation from "./layouts/SNSInfomation/SNSInfomation";
import ViewPage from "./layouts/ViewPage/ViewPage";
import CreateLogo from "./layouts/Logo/CreateLogo/CreateLogo";
import LogoMatchingList from "./layouts/Logo/LogoMatchingList/LogoMatchingList";
import BuyLogoCreate from "./layouts/Logo/BuyLogo/PurchasePage";
import PurchaseCompletePage from "./layouts/Logo/PurchaseComplete/PurchaseCompletePage";
import PurchaseCompletePageVectorAI from "./layouts/Logo/PurchaseComplete/PurchaseCompletePageVectorAI";
import BusinessCardType from "./layouts/BusinessCard/ChoseBusinessCardType";
import BusinessCardLayout from "./layouts/BusinessCard/BusinessCardLayout";
import CheckApplyBusinessCard from "./layouts/BusinessCard/MakeBusinessCard/CheckApplyBusinessCard";
import BusinessCardPurchase from "./layouts/BusinessCard/MakeBusinessCard/BusinessCardPurchase";
import PurchaseCompleteBusinessCard from "./layouts/BusinessCard/MakeBusinessCard/PurchaseCompleteBusinessCardPDF";
import PurchaseCompleteBusinessCardVectorAI from "./layouts/BusinessCard/MakeBusinessCard/PurchaseCompleteBusinessCardVectorAI";
import GeneralBusinessCard from "./layouts/SelectPrintType/GeneralBusinessCard/GeneralBusinessCard";
import CardSprintPay from "./layouts/SelectPrintType/GeneralBusinessCard/CardSprintPay";
import PrintOrderComplete from "./layouts/SelectPrintType/GeneralBusinessCard/PrintOrderComplete";
import ConsultationRequestCompleted from "./layouts/SelectPrintType/GeneralBusinessCard/ConsultationRequestCompleted";
import CustomerSupportLayout from "./layouts/CustomerSupport/CustomerSupportLayout";
import InquiryForm from "./layouts/CustomerSupport/Inquiry/WriteInquiry/InquiryForm";
import InquiryDetailsLayout from "./layouts/CustomerSupport/Inquiry/InquiryDetails/InquiryDetailsLayout";

import CardCreate from "./layouts/BusinessCardCreate/CardCreate";
import ExpertConsultationList from "./layouts/HireAndConsult/experts/ExpertConsultationList";
import ExpertConsultationForm from "./layouts/HireAndConsult/ExpertConsultationForm";
import ConsultationRequestDetail from "./layouts/HireAndConsult/experts/detail/ConsultationRequestDetail";
import NotificationList from "./layouts/Notification/NotificationList";
import MyPageLayout from "./layouts/MyPage/MyPageLayout";
import PrintOrderHistoryDetails from "./layouts/MyPage/PrintOrderHistory/Details/PrintOrderHistoryDetails";
import PrintListByPurchasedProduct from "./layouts/MyPage/PrintListByPurchasedProduct/PrintListByPurchasedProduct";

//Notices

import NoticationDetailsLayout from "./layouts/CustomerSupport/NoticeFAQ/NoticationDetail/NoticationDetailLayout";

function App() {
    return (
        <Routes>
            <Route element={<Layout />}>
                <Route element={<Main />}>
                    <Route element={<PrivateRoutes />}>
                        <Route
                            path="/card-sprint/:id"
                            element={<GeneralBusinessCard />}
                        />
                        <Route
                            path="/card-sprint-pay/:id"
                            element={<CardSprintPay />}
                        />
                        <Route
                            path="/card-sprint-pay-confirm"
                            element={<PrintOrderComplete />}
                        />
                        <Route
                            path="/card-sprint-request-completed"
                            element={<ConsultationRequestCompleted />}
                        />
                        <Route
                            path="/customer-faq"
                            element={<CustomerSupportLayout />}
                        />
                        <Route path="/new-inquiry" element={<InquiryForm />} />
                        <Route
                            path="/details-inquiry/:id"
                            element={<InquiryDetailsLayout />}
                        />

                        <Route path="/my-page" element={<MyPageLayout />} />
                        <Route
                            path="/order-history-details/:id"
                            element={<PrintOrderHistoryDetails />}
                        />
                        <Route
                            path="/print-list-by-purchase"
                            element={<PrintListByPurchasedProduct />}
                        />
                        <Route
                            path="/experts/consultation"
                            element={<ExpertConsultationForm />}
                        />
                        <Route
                            path="/experts/consultation-list"
                            element={<ExpertConsultationList />}
                        />
                        <Route
                            path="/experts/consultation/:id"
                            element={<ConsultationRequestDetail />}
                        />
                        <Route
                            path="/notification"
                            element={<NotificationList />}
                        />
                        <Route
                            path="/notices/:id"
                            element={<NoticationDetailsLayout />}
                        />
                        {/* <Route path="/sandbox" element={<CheckoutPage />} />
                        <Route
                            path="/sandbox/success"
                            element={<SuccessPage />}
                        />
                        <Route path="/sandbox/fail" element={<FailPage />} /> */}
                        <Route path="*" element={<Navigate to="/home" />} />


                        <Route path="/logo" element={<Outlet />}>
                            <Route index element={<CreateLogo />} />
                            <Route
                                path="ai/:id"
                                element={<LogoMatchingList />}
                            />
                            <Route
                                path="order/:id"
                                element={<BuyLogoCreate />}
                            />
                            <Route
                                path="complete-image"
                                element={<PurchaseCompletePage />}
                            />
                            <Route
                                path="complete-ai"
                                element={<PurchaseCompletePageVectorAI />}
                            />
                        </Route>

                        <Route path="/card" element={<Outlet />}>
                            <Route index element={<BusinessCardType />} />
                            <Route
                                path="format/:id"
                                element={<BusinessCardLayout />}
                            />
                            <Route path="create/:id" element={<CardCreate />} />
                            <Route
                                path="review/:id"
                                element={<CheckApplyBusinessCard />}
                            />
                            <Route
                                path="order/:id"
                                element={<BusinessCardPurchase />}
                            />
                            <Route
                                path="complete-pdf"
                                element={<PurchaseCompleteBusinessCard />}
                            />
                            <Route
                                path="complete-ai"
                                element={
                                    <PurchaseCompleteBusinessCardVectorAI />
                                }
                            />
                        </Route>
                    </Route>
                    <Route element={<PublicRoutes />}>
                        <Route path="/home" element={<MainLayout />} />

                       

                        <Route path="/view-page" element={<ViewPage />} />

                        <Route path="/login" element={<Login />} />
                        <Route
                            path="/sns-infomation"
                            element={<SNSInfomation />}
                        />
                        <Route path="*" element={<Navigate to="/home" />} />
                    </Route>
                </Route>
            </Route>
        </Routes>
    );
}

export default App;
