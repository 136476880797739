import React from 'react';
import images from '../../../images';

const PurchaseCompleteTitle = () => (
  <section className="flex flex-col items-center mt-20 max-md:mt-10">
    <img
      loading="lazy"
      src={images.businessCardPurchaseIcon}
      alt="Purchase Complete Icon"
      className="object-contain aspect-[0.94] w-[47px]"
    />
    <h1 className="mt-6 text-3xl font-bold leading-none text-center text-main-color">
      구매완료!
    </h1>
    <p className="mt-3.5 text-2xl leading-none text-center text-zinc-800">
      구매가 정상적으로 완료되었습니다.
    </p>
  </section>
);

export default PurchaseCompleteTitle;