import React from 'react';

function InfoSection({ title, infoItems }) {
  return (
    <section className="flex flex-col justify-center mt-24 w-full leading-none text-neutral-700 max-md:mt-10 max-md:max-w-full">
      <h3 className="flex flex-col w-full text-2xl font-bold max-md:max-w-full">
        <div className="gap-2.5 self-start">{title}</div>
        <div className="flex mt-4 w-full bg-neutral-600 min-h-[2px] max-md:max-w-full" />
      </h3>
      <div className="flex gap-9 items-center self-start mt-5 text-base">
        <div className="flex flex-col self-stretch my-auto font-bold ">
          {infoItems.map((item, index) => (
            <div key={index} className={index > 0 ? 'mt-4 max-md:min-w-[100px]' : ''}>
              {item.label}
            </div>
          ))}
        </div>
        <div className="flex flex-col self-stretch my-auto ">
          {infoItems.map((item, index) => (
            <div key={index} className={index > 0 ? 'mt-4' : ''}>
              {item.value}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default InfoSection;
