import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/common/Button/Button";

export default function BusinessCardTitle() {
  const navigate = useNavigate();

  const backToBusinessType = () => {
    navigate("/card-type");
  };
  return (
    <header className="flex flex-col justify-center self-stretch px-72 py-14 w-full font-bold leading-none text-center min-h-[192px] max-md:px-5 max-md:max-w-full">
      <div className="flex flex-col items-center w-full  max-md:max-w-full">
        <h1 className="text-3xl text-black">기업형 타입 : 명함 샘플</h1>
        <Button
          onClick={backToBusinessType}
          className="flex gap-2 items-center px-4 py-2.5 mt-5 text-sm text-main-color border border-indigo-400 border-solid rounded-[100px]"
          label={"명함 타입 다시 고르기"}
          iconFirst={
            "https://cdn.builder.io/api/v1/image/assets/TEMP/2a537a1dbc9b41fd6a5ef0017fd2b0358b893f15cbfdb34df4406c53ff5b8d57?placeholderIfAbsent=true&apiKey=3ac17b61e0c94c2b87d841ac8b4d3afd"
          }
        />
      </div>
    </header>
  );
}
