import React from "react";

function InfoSection({ title, email, iconSrc }) {
  return (
    <section className="flex flex-col justify-center px-5 pt-5 pb-2.5 w-full text-base bg-white rounded-md border border-gray-200 border-solid">
      <h2 className="font-bold text-neutral-700">{title}</h2>
      <div className="flex gap-2 justify-center items-center py-2.5 mt-2 w-full whitespace-nowrap rounded-md text-zinc-800">
        <img loading="lazy" src={iconSrc} alt="" className="object-contain shrink-0 self-stretch my-auto w-4 aspect-square" />
        <span className="flex-1 shrink self-stretch my-auto basis-0">{email}</span>
      </div>
    </section>
  );
}

export default InfoSection;