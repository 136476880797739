import React from 'react';

function ProductImage({imageProduct}) {
  return (
    <div className="flex gap-10 justify-center items-center p-10 mt-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
      <img
        loading="lazy"
        src={imageProduct}
        alt="Product preview"
        className="object-contain self-stretch my-auto aspect-[1.47] min-w-[240px] w-[323px]"
      />
    </div>
  );
}

export default ProductImage;