import React from "react";
import InquiryDetailHeader from "./InquiryDetailHeader";
import Button from "../../../../components/common/Button/Button";
import InquiryContent from "./InquiryContent";
import InquiryResponse from "./InquiryResponse";
import images from "../../../../images";
import InquiryAnswer from "./InquiryAnswer";
import { useNavigate, useParams } from "react-router-dom";
import { useGetUserQueryDetailsQuery } from "../../../../services/apiSlice";
import { formatDateTime } from "../../../../utils/helper/formatters";

function InquiryPage() {
    const { id } = useParams();
    const navigate = useNavigate();

    const { data, isLoading, error } = useGetUserQueryDetailsQuery({
        id: id,
    });


    return (
        <main className="flex flex-col rounded-none px-20 max-md:px-5">
            <article className="flex w-full flex-col gap-2 border border-solid border-gray-200 px-12 py-10 max-md:max-w-full max-md:px-5">
                <InquiryDetailHeader
                    title="[1:1 문의]"
                    subtitle={data?.question ?? ""}
                    status={
                        data?.status === "IN_COMPLETED"
                            ? "답변대기"
                            : "답변완료"
                    }
                    date={formatDateTime(data?.createdAt) ?? ""}
                    statusClassName={
                        data?.status === "IN_COMPLETED"
                            ? "text-white bg-indigo-600"
                            : "bg-[#E5E4F7] text-[#70709F]"
                    }
                />
                <InquiryContent
                    imageSrc={data?.attachedImagePath}
                    imageAlt="문의 관련 이미지"
                    text={[data?.content]}
                />

                {data?.status === "IN_COMPLETED" ? (
                    <InquiryResponse />
                ) : (
                    <InquiryAnswer
                        answerTextLines={[data?.answer]}
                        fileSrc={data?.attachedImagePathByAdmin}
                        // fileAlt="Example Image"
                        // imageSrc={data?.attachedImagePathByAdmin}
                        imageAlt="Company logo"
                        date={formatDateTime(data?.answeredAt) ?? ""}
                    />
                )}
                {/* */}
            </article>
            <Button
                onClick={() => navigate("/customer-faq")}
                className="mb-20 mt-12 min-h-[50px] w-80 max-w-full gap-2.5 self-center whitespace-nowrap rounded-md border border-solid border-neutral-300 bg-white px-4 text-center text-base font-bold leading-none text-zinc-800 max-md:mt-10"
                label={"목록으로 돌아가기"}
            />
        </main>
    );
}

export default InquiryPage;
