import React, { useState } from "react";
import ProductImage from "../../BusinessCard/MakeBusinessCard/ProductImage";
import PaymentMethod from "../../../components/common/PaymentMethod/PaymentMethod";
import TotalPayment from "../../../components/common/PaymentMethod/TotalPayment";
import InfoSection from "../../../components/common/InfoSection/InfoSection";
import FormInput from "../../../components/common/FormInput/FormInput";
import ShippingForm from "./ShippingForm";

const CardSprintPay = () => {
  const infoItems = [
    { label: "인쇄타입", value: "고급명함(고급 용지 명함)" },
    { label: "용지", value: "엑스트라폴라" },
    { label: "인쇄도수", value: "4도 인쇄 + 양면" },
    { label: "코팅", value: "무광 코팅" },
    { label: "규격", value: "90X50" },
    { label: "후가공", value: "-" },
    { label: "수량", value: "200매" },
    { label: "결제금액", value: "8,900원" },
  ];
  const oderFormFields = [
    { label: "이름", placeholder: "이름을 입력해주세요." },
    { label: "이메일", placeholder: "이메일을 입력해주세요." },
    { label: "메시지", placeholder: "메시지를 입력해주세요." },
  ];
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(0);

  const handlePaymentSelect = (paymentMethod) => {
    setSelectedPaymentMethod(paymentMethod);
  };
  return (
    <main className="rounded-none px-20 py-10 max-md:px-5">
      <div className="flex gap-5 max-md:flex-col">
        <section className="flex flex-col w-[63%] max-md:ml-0 max-md:w-full">
          <div className="flex flex-col grow max-md:mt-10 max-md:max-w-full">
            <div className="flex flex-col w-full max-md:max-w-full">
              <h2 className="pb-4 w-full text-2xl font-bold leading-none border-b-2 border-neutral-800 text-zinc-800 max-md:max-w-full">
                구매 상품 정보
              </h2>
              <ProductImage />
            </div>
            <InfoSection title="인쇄 주문 정보" infoItems={infoItems} />

            <div className=" flex flex-col justify-center mt-24 w-full leading-none text-neutral-700 max-md:mt-10 max-md:max-w-full">
              <h3 className="flex flex-col w-full text-2xl font-bold max-md:max-w-full">
                <div className="gap-2.5 self-start">주문자 정보</div>
                <div className="flex mt-4 w-full bg-neutral-600 min-h-[2px] max-md:max-w-full" />
              </h3>
              <FormInput formFields={oderFormFields} />
            </div>
            <div className=" flex flex-col justify-center mt-24 w-full leading-none text-neutral-700 max-md:mt-10 max-md:max-w-full">
              <h3 className="flex flex-col w-full text-2xl font-bold max-md:max-w-full">
                <div className="gap-2.5 self-start">배송지 정보</div>
                <div className="flex mt-4 w-full bg-neutral-600 min-h-[2px] max-md:max-w-full" />
              </h3>
              <ShippingForm />
            </div>
            <PaymentMethod onPaymentSelect={handlePaymentSelect} />
            <TotalPayment amount={2999000} />
          </div>
        </section>

        <aside className="flex flex-col justify-between w-[37%] max-md:ml-0 max-md:w-full ">
          <div className="flex flex-col mt-14 w-full max-md:mt-10 max-md:max-w-full sticky top-0">
            <div className="flex flex-col px-8 py-9 bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
              <div className="flex flex-col items-start w-full">
                <div className="flex flex-col self-stretch px-5 py-6 w-full rounded-xl bg-slate-50">
                  <h3 className="text-base font-bold leading-none text-neutral-700">
                    [상품 구매 시 안내사항]
                  </h3>
                  <p className="mt-4 w-full text-sm leading-5 text-zinc-800">
                    이용약관 및 주문 내용을 확인하였으며, 결제 진행을 위해
                    개인정보 수집 및 이용에 동의합니다.
                  </p>
                </div>

                <div className="flex flex-col gap-4 mt-4 w-full">
                  <label className="flex gap-2.5 items-center text-base text-neutral-700 cursor-pointer">
                    <input type="checkbox" className="w-4 h-4" />
                    주문 내용을 모두 확인했으며, 상품 결제 동의합니다.
                  </label>
                  <label className="flex gap-2.5 items-center text-base text-neutral-700 cursor-pointer">
                    <input type="checkbox" className="w-4 h-4" />
                    개인정보 수집 및 이용에 동의합니다.
                  </label>
                </div>
              </div>

              <div className="flex flex-col justify-center mt-9 w-full text-base font-bold leading-none text-center">
                <button
                  type="submit"
                  className="px-4 w-full text-white bg-indigo-600 rounded-md min-h-[50px]"
                >
                  구매하기
                </button>
                <button
                  type="button"
                  className="px-4 mt-4 w-full bg-white rounded-md border border-solid border-neutral-300 min-h-[50px] text-zinc-800"
                >
                  이전으로
                </button>
              </div>
            </div>
          </div>

          <div className="gap-2.5 mb-40 self-start p-8 ml-5 text-2xl font-bold leading-9 text-white bg-black  max-md:px-5 max-md:mt-10 max-md:ml-2.5">
            ✅ 결제 수단 <br />
            <span className="text-xl">개발 시 토스페이먼츠 가이드대로 </span>
            <br />
            <span className="text-xl leading-7">디자인 변경하여 진행 필요</span>
          </div>
        </aside>
      </div>
    </main>
  );
};

export default CardSprintPay;
