import React from 'react';
import FileListItem from './FileListItem';

function FileAttachment({ files }) {
    return (
        <div className="flex flex-col mt-10 w-full max-md:max-w-full">
            <div className="flex flex-wrap gap-10 justify-between items-center w-full leading-none whitespace-nowrap max-md:max-w-full">
                <div className="flex gap-1 items-start self-stretch my-auto">
                    <label htmlFor="fileInput" className="text-base font-bold text-neutral-700">
                        파일첨부
                    </label>
                    <span className="text-lg font-medium">*</span>
                </div>
                <div className="self-stretch my-auto text-base text-right text-neutral-500">
                    (0/5)
                </div>
            </div>
            <div className="flex flex-col mt-2.5 w-full max-md:max-w-full">
                <label htmlFor="fileInput" className="gap-2.5 self-stretch px-5 py-4 w-full text-base font-bold leading-none text-main-color rounded-md border border-indigo-600 border-solid min-h-[50px] max-md:max-w-full cursor-pointer">
                    파일 첨부하기
                </label>
                <input type="file" id="fileInput" className="hidden" multiple />

                <div className="flex flex-col mt-3.5 w-full max-md:max-w-full">
                    <div className="flex flex-col items-start px-5 pt-5 pb-48 w-full text-sm leading-none rounded-md border border-solid border-zinc-200 min-h-[220px] text-stone-500 max-md:pb-24 max-md:max-w-full">
                        {
                            files && files.length > 0 ? files.map((file, index) => (
                                <FileListItem key={index} fileName={file.name} />
                            ))
                                :
                                <textarea
                                    id="detailsTextarea"
                                    className="gap-2.5 px-5 pt-5 pb-64 mt-4 w-full text-base leading-6  min-h-[320px] text-zinc-500 max-md:pb-24 max-md:max-w-full"
                                    placeholder="첨부된 파일이 없습니다."
                                ></textarea>
                        }
                    </div>
                    <div className="flex gap-1 items-center self-start mt-2.5 text-xs text-neutral-600">
                        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/608912cecb5144cf887238b12afdb74b/5683ff6f9e21a764d0b13f79a71a81ee0a7243b81f7c353b24b4f0faea55952d?apiKey=608912cecb5144cf887238b12afdb74b&" alt="" className="object-contain shrink-0 self-stretch my-auto aspect-square w-[15px]" />
                        <div className="self-stretch my-auto">최대 5MB 용량 업로드 가능</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FileAttachment;