import React, { useState } from "react";
import MyInfomation from "./MyInfomation/MyInfomation";
import PaymentHistory from "./PaymentHistory/PaymentHistory";
import PrintOrderHistory from "./PrintOrderHistory/PrintOrderHistory";
import MyProductLayout from "./MyProductList/MyProductLayout";

const tabs = [
  { label: "내 정보" },
  { label: "결제내역" },
  { label: "인쇄주문내역" },
  { label: "내 제작물 목록" },
];

const MyPageLayout = () => {
  const [activeTab, setActiveTab] = useState(tabs[0].label);

  const handleTabClick = (tabLabel) => {
    setActiveTab(tabLabel);
  };

  return (
    <main className="flex overflow-hidden flex-col bg-white">
      <section
        data-layername="타이틀"
        className="flex flex-col justify-center  py-14 w-full text-3xl font-bold leading-none text-center text-black whitespace-nowrap min-h-[139px] max-md:px-5 max-md:max-w-full"
      >
        <div className="flex flex-col items-center max-w-full ">
          <h1 data-layername="인쇄정보입력하기">마이페이지</h1>
        </div>
      </section>

      <nav className="flex flex-wrap gap-4 justify-center items-center self-center w-full text-xl leading-none text-gray-500 border-b border-slate-300 max-w-[1240px] max-md:max-w-full">
        {tabs.map((tab, index) => (
          <button
            key={index}
            onClick={() => handleTabClick(tab.label)}
            className={`self-stretch px-6 pb-4 my-auto ${
              tab.label === activeTab
                ? "font-bold border-b-2 border-slate-700 text-slate-700"
                : ""
            } border-dotted max-md:px-5`}
          >
            {tab.label}
          </button>
        ))}
      </nav>

      <section className="flex flex-col items-center mt-8  w-full">
        {activeTab === "내 정보" && (
          <>
            <MyInfomation />
          </>
        )}
        {activeTab === "결제내역" && (
          <>
            <PaymentHistory />
          </>
        )}
        {activeTab === "인쇄주문내역" && (
          <>
            <PrintOrderHistory />
          </>
        )}
        {activeTab === "내 제작물 목록" && (
          <>
            <MyProductLayout />
          </>
        )}
      </section>
    </main>
  );
};

export default MyPageLayout;
