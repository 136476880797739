import React from "react";

function AttachmentList({ files }) {
    return (
        <div className="flex flex-col mt-8 w-full whitespace-nowrap max-md:max-w-full">
            <div className="flex gap-10 items-center w-full leading-none max-md:max-w-full">
                <div className="flex flex-wrap flex-1 shrink gap-1 items-start self-stretch my-auto w-full basis-0 min-w-[240px] max-md:max-w-full">
                    <h2 className="text-base font-bold text-neutral-700">첨부파일</h2>
                </div>
            </div>
            <div className="flex flex-col mt-2.5 w-full max-md:max-w-full">
                <div className="flex flex-col px-5 pt-5 pb-24 w-full rounded-md border border-solid border-zinc-200 min-h-[220px] max-md:max-w-full">
                    {files.map((file, index) => (
                        <div key={index} className="flex flex-wrap gap-10 justify-between items-center mt-1.5 w-full min-h-[32px] max-md:max-w-full">
                            <div className="flex gap-2.5 items-start self-stretch my-auto text-base font-medium leading-none text-stone-500">
                                <div className="flex gap-1.5 items-center">
                                    <img loading="lazy" src={file.url} alt="" className="object-contain shrink-0 self-stretch my-auto w-6 aspect-square" />
                                    <div className="self-stretch my-auto">{file.name}</div>
                                </div>
                            </div>
                            <div className="flex gap-1 items-start self-stretch my-auto text-base font-semibold leading-none text-indigo-600">
                                <button>다운로드</button>
                                <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/608912cecb5144cf887238b12afdb74b/daaf857a82e9a1f5270f92df86272d81a51ef7702b2a7c31fd342ce983796e0b?apiKey=608912cecb5144cf887238b12afdb74b&" alt="" className="object-contain shrink-0 w-3.5 aspect-square" />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default AttachmentList;