import React from 'react';

const PurchaseInfoSecond = () => (
  <div className="flex flex-col py-8 mt-10 max-w-full text-base text-center rounded-md border border-gray-200 border-solid text-neutral-700">
    <p className="leading-6 max-md:max-w-full">
    벡터 파일 작업은 관리자가 결제 및 접수 확인 후 작업이 
      <br />
      진행되며 작업 진행 시  <span className="font-bold">[0.5일 ~ 3일의 작업 시간]</span> 간이 소요됩니다.
    </p>
    <p className="mt-3.5 leading-7 max-md:max-w-full">
    <span className="font-bold">[마이페이지 - 나의 제작물 목록]</span>에서 진행상태를 확인하실 수 있습니다.
    </p>
  </div>
);

export default PurchaseInfoSecond;
