import * as React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ marginTop: "30px" }}>
                    <Typography component="div">{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        "aria-controls": `full-width-tabpanel-${index}`,
    };
}

export default function CommonTabs({ labels, panels }) {
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box className="w-full">
            <AppBar
                position="static"
                className="mt-5 rounded-[10px]"
                elevation={0}
            >
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="fullWidth"
                    aria-label="common tabs"
                    textColor="inherit"
                    indicatorColor="unset"
                    className="rounded-[10px]"
                    sx={{
                        display: "flex", 
                        justifyContent: "space-between",
                        "& .MuiTab-root.Mui-selected": {
                            color: "white",
                            backgroundColor: "#3533FF",
                            borderRadius: "5px",
                        },
                        "& .MuiTab-root": {
                            padding: "5px",
                        },
                        color: "#4C4BC6",
                        bgcolor: "#F2F2FF",
                        padding: "10px",
                    }}
                >
                    {labels.map((label, index) => (
                        <Tab
                            key={index}
                            label={label}
                            sx={{
                                minHeight: "36px",
                                height: "36px",
                                flexBasis: "auto", 
                                [theme.breakpoints.down("md")]: {
                                    minWidth: "60px", // max-md: width 60px
                                    maxWidth: "60px",
                                },
                            }}
                            {...a11yProps(index)}
                        />
                    ))}
                </Tabs>
            </AppBar>
            {panels.map((panelContent, index) => (
                <TabPanel
                    key={index}
                    value={value}
                    index={index}
                    dir={theme.direction}
                    sx={{ padding: 0 }}
                >
                    {panelContent}
                </TabPanel>
            ))}
        </Box>
    );
}

CommonTabs.propTypes = {
    labels: PropTypes.arrayOf(PropTypes.string).isRequired,
    panels: PropTypes.arrayOf(PropTypes.node).isRequired,
};
