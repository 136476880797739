import React from 'react';
import Pagination from '@mui/material/Pagination';

const PaginationComponent = ({
    className = "",
    count = 1,
    showFirstButton = true,
    showLastButton = true,
    onPageChange = () => { },
    page = 1,
    variant = "text",
    color = "",
}) => {
    const handlePageChange = (event, value) => {
        if (onPageChange) {
            onPageChange(value);
        }
    };

    return (
        <Pagination
            className={className}
            count={count}
            page={page}
            onChange={handlePageChange}
            showFirstButton={showFirstButton}
            showLastButton={showLastButton}
            variant={variant}
            color={color}
            sx={{
                "& .MuiPagination-ul .MuiPaginationItem-root": {
                    fontSize: 16,
                    color: '#B3B3B3',
                    backgroundColor: "unset",
                },
                "& .MuiPagination-ul .Mui-selected": {
                    fontWeight: 800,
                    color: '#3533FF',
                },
            }}
        />
    );
};

export default PaginationComponent;
