import React, { useState } from "react";
import CheckboxGroup from "../../../components/common/CheckBox/CheckBox";

function CardTypeSelect({ onSelectType }) {
  const [cardTypes, setCardTypes] = useState([
    { name: "일반명함", icon: "ext_5-", active: true, id: 1, type: "A" },
    { name: "고급명함", icon: "ext_6-", active: false, id: 2, type: "B" },
    { name: "커스텀명함", icon: "ext_6-", active: false, id: 3, type: "C" },
  ]);

  const handleSelectType = (selectedType) => {
    const updatedTypes = cardTypes.map((type) => ({
      ...type,
      active: type.id === selectedType.id,
    }));
    setCardTypes(updatedTypes);

    onSelectType({
      name: selectedType.name,
      type: selectedType.type,
      id: selectedType.id,
    });
  };

  return (
    <section className="flex flex-col justify-center self-start leading-none">
      <h2 className="text-2xl font-bold text-main-color">STEP 1.</h2>
      <p className="mt-2.5 text-xl font-semibold text-neutral-700">
        명함 인쇄 타입을 선택하세요.
      </p>
      <CheckboxGroup cardTypes={cardTypes} handleSelectType={handleSelectType} />
    </section>
  );
}

export default CardTypeSelect;
