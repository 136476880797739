import React, { useState } from "react";
import CustomerSupportFAQSection from "./CustomerSupportFAQ/CustomerSupportFAQLayout";
import NotificationList from "./NoticeFAQ/NoticationList";
import InquiryList from "./Inquiry/InquiryList";
import InquiryForm from "./Inquiry/WriteInquiry/InquiryForm";
import UsageAndPriceGuideLayout from "./UsageAndPriceGuide/UsageAndPriceGuideLayout";

const tabs = [
  { label: "자주 묻는 질문" },
  { label: "공지사항" },
  { label: "1:1 문의" },
  { label: "이용 및 가격안내" },
];

const CustomerSupportLayout = () => {
  const [activeTab, setActiveTab] = useState(tabs[0].label);
  const [showNewInquiry, setShowNewInquiry] = useState(false);

  const handleTabClick = (tabLabel) => {
    setActiveTab(tabLabel);
    setShowNewInquiry(false);
  };

  const goToCreateInquiry = () => {
    setShowNewInquiry(true);
  };

  return (
    <main className="flex overflow-hidden flex-col bg-white">
      <section
        data-layername="타이틀"
        className="flex flex-col justify-center  py-14 w-full text-3xl font-bold leading-none text-center text-black whitespace-nowrap min-h-[139px] max-md:px-5 max-md:max-w-full"
      >
        <div className="flex flex-col items-center max-w-full ">
          <h1 data-layername="인쇄정보입력하기">고객지원</h1>
        </div>
      </section>

      <nav className="flex flex-wrap gap-4 justify-center items-center self-center w-full text-xl leading-none text-gray-500 border-b border-slate-300 max-w-[1240px] max-md:max-w-full">
        {tabs.map((tab, index) => (
          <button
            key={index}
            onClick={() => handleTabClick(tab.label)}
            className={`self-stretch px-6 pb-4 my-auto ${
              tab.label === activeTab
                ? "font-bold border-b-2 border-slate-700 text-slate-700"
                : ""
            } border-dotted max-md:px-5`}
          >
            {tab.label}
          </button>
        ))}
      </nav>

      <section className="flex flex-col items-center mt-8  w-full">
        {activeTab === "자주 묻는 질문" && (
          <>
            <CustomerSupportFAQSection />
          </>
        )}
        {activeTab === "공지사항" && (
          <>
            <NotificationList />
          </>
        )}
        {activeTab === "1:1 문의" && (
          <>
            {!showNewInquiry ? (
              <InquiryList onNewInquiry={goToCreateInquiry} />
            ) : (
              <InquiryForm />
            )}
          </>
        )}
        {activeTab === "이용 및 가격안내" && (
          <>
            <UsageAndPriceGuideLayout />
          </>
        )}
      </section>
    </main>
  );
};

export default CustomerSupportLayout;
