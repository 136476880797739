import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import InfoSection from "../../../components/common/InfoSection/InfoSection";
import ProductImage from "./ProductImage";
import FileFormatSelectionBusinessCard from "./FileFormatSelectionBusinessCard";
import PaymentMethod from "../../../components/common/PaymentMethod/PaymentMethod";
import TotalPayment from "../../../components/common/PaymentMethod/TotalPayment";
import PurchaseInfo from "../../Logo/BuyLogo/PurchaseInfo";
import PaymentMethodEnum from "../../../utils/constants/enum/paymentMethodEnum";

import {
    useGetBusinessCardsByIdQuery,
    usePostBusinessCardsOrdersMutation,
    usePostKakaoInitializeMutation,
    usePostNaverPayInitializeMutation,
    usePostTossPaymentInitializeMutation,
} from "../../../services/apiSlice";
import { toast } from "react-toastify";
const BusinessCardPurchase = () => {
    const { id: cardId } = useParams();
    const navigate = useNavigate();
    const [postKakaoInitialize, { data: dataKakao }] =
        usePostKakaoInitializeMutation();

    const [postTossPaymentInitialize, { data: dataTossPayment }] =
        usePostTossPaymentInitializeMutation();

    const [postNaverPayInitialize, { data: dataNaverPay }] =
        usePostNaverPayInitializeMutation();

    // Redux state
    const { userData } = useSelector((state) => state.common);
    const userId = userData?.userID || null;

    // Queries and Mutations
    const { data, isLoading } = useGetBusinessCardsByIdQuery({ cardId });
    const [postOrders] = usePostBusinessCardsOrdersMutation();

    // Local state
    const [dataOrderRespone, setDataOrderRespon] = useState([]);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
    const [selectedOption, setSelectedOption] = useState(null);

    // Extracted data
    const imageBussinessCardFront = data?.filePathFront;
    const imageBussinessCardBack = data?.filePathBack;

    const infoItems = [
        {
            label: "명함 타입",
            value:
                dataOrderRespone?.data?.businessCardEntity
                    ?.businessCardTypeTitle || "_",
        },
        {
            label: "작업명",
            value: dataOrderRespone?.data?.businessCardEntity?.workName || "_",
        },
        {
            label: "이름",
            value: dataOrderRespone?.data?.businessCardEntity?.name || "_",
        },
        {
            label: "직책",
            value: dataOrderRespone?.data?.businessCardEntity?.position || "_",
        },
        {
            label: "이메일",
            value: dataOrderRespone?.data?.businessCardEntity?.email || "_",
        },
        {
            label: "전화번호",
            value:
                dataOrderRespone?.data?.businessCardEntity?.phone ||
                dataOrderRespone?.data?.businessCardEntity?.snsPhoneNumber ||
                dataOrderRespone?.data?.businessCardEntity?.storePhoneNumber ||
                "_",
        },
        {
            label: "팩스",
            value: dataOrderRespone?.data?.businessCardEntity?.fax || "_",
        },
    ];

    const handleCreateOrders = useCallback(async () => {
        if (userId && data) {
            try {
                const response = await postOrders({
                    userId,
                    productId: data?.id,
                });
                setDataOrderRespon(response);
            } catch (error) {
                console.error("Error creating order:", error);
            }
        }
    }, [userId, data, postOrders]);

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://nsp.pay.naver.com/sdk/js/naverpay.min.js";
        script.async = true;

        script.onload = () => {
            window.oPay = window.Naver.Pay.create({
                mode: "development",
                clientId: "HN3GGCMDdTgGUfl0kFCo",
                chainId: "ckRDVXZuMmpBWGQ",
            });
        };

        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    useEffect(() => {
        handleCreateOrders();
    }, [handleCreateOrders]);

    const handleBuyClick = async () => {
        if (!selectedPaymentMethod?.name) {
            toast.error("결제수단을 선택해주세요");
            return;
        }

        const body = {
            orderId: dataOrderRespone?.data?.order?.id,
            userId,
            amount: selectedOption?.price,
            paymentMethod: "",
            paymentStatus: "PENDING",
        };

        const paymentMethods = {
            [PaymentMethodEnum.NAVER_PAY]: async () => {
                body.paymentMethod = "NAVER_PAY";
                const resNaver = await postNaverPayInitialize(body);
                if (!window.oPay && resNaver?.data.id) {
                    alert("Naver Pay SDK is not loaded yet.");
                    return;
                }
                window.oPay.open({
                    merchantPayKey: "6931022d-b25b-4233-89d9-9188c434aa2f",
                    productName: "Card",
                    productCount: "1",
                    totalPayAmount: body.amount,
                    taxScopeAmount: body.amount,
                    taxExScopeAmount: "0",
                    returnUrl: `${process.env.REACT_APP_BASE_URL}/payments/naver-pay/callback?paymentId=${resNaver?.id}`,
                });
            },
            [PaymentMethodEnum.GENERAL_CARD_PAYMENT]: async () => {
                body.paymentMethod = "TOSS_PAYMENT_CARD";
                body.tossPaymentMethod = "CARD";
                const resTossCard = await postTossPaymentInitialize(body);
                handleOpenNewTab(resTossCard?.data?.url);
            },
            [PaymentMethodEnum.REAL_TIME_ACCOUNT_TRANSFER]: async () => {
                body.paymentMethod = "TOSS_PAYMENT_TRANSFER";
                body.tossPaymentMethod = "TRANSFER";
                const resTossTransfer = await postTossPaymentInitialize(body);
                handleOpenNewTab(resTossTransfer?.data?.url);
            },
            [PaymentMethodEnum.KAKAO_PAY]: async () => {
                body.paymentMethod = "KAKAO_PAY";
                const resKakao = await postKakaoInitialize(body);
                handleOpenNewTab(resKakao?.data?.next_redirect_pc_url);
            },
        };

        const handleOpenNewTab = (url) => {
            if (url) {
                window.open(url, "_blank");
            } else {
                console.error("No URL returned from the response");
            }
        };

        try {
            const paymentAction = paymentMethods[selectedPaymentMethod.name];
            if (paymentAction) {
                await paymentAction();
            } else {
                console.error("Invalid payment method");
            }
        } catch (error) {
            console.error("Payment process error:", error);
        }
    };

    return (
        <main className="rounded-none px-20 pb-10 max-md:px-5">
            <div className="flex w-full flex-col justify-center px-72 py-14 text-center text-3xl font-bold leading-none text-black max-md:max-w-full max-md:px-5 max-md:py-0">
                <div className="flex w-[878px] max-w-full flex-col items-center">
                    <h1>구매하기</h1>
                </div>
            </div>
            <div className="flex gap-5 max-md:flex-col">
                <section className="flex w-[63%] flex-col max-md:ml-0 max-md:w-full">
                    <div className="flex grow flex-col max-md:mt-10 max-md:max-w-full">
                        <div className="flex w-full flex-col max-md:max-w-full">
                            <h2 className="w-full border-b-2 border-neutral-800 pb-4 text-2xl font-bold leading-none text-zinc-800 max-md:max-w-full">
                                구매 상품 정보
                            </h2>
                            <ProductImage
                                imageBussinessCardFront={
                                    imageBussinessCardFront
                                }
                                imageBussinessCardBack={imageBussinessCardBack}
                            />
                        </div>

                        <InfoSection
                            title="명함 입력 정보"
                            infoItems={infoItems}
                        />
                        <FileFormatSelectionBusinessCard
                            onOptionSelect={setSelectedOption}
                        />
                        <PaymentMethod
                            onPaymentSelect={setSelectedPaymentMethod}
                        />
                        <TotalPayment amount={selectedOption?.price || 0} />
                    </div>
                </section>

                <aside className="flex w-[37%] flex-col justify-between py-10 max-md:ml-0 max-md:w-full">
                    <div className="sticky top-10">
                        <PurchaseInfo onBuyClick={handleBuyClick} />
                    </div>

                    <div className="mb-40 ml-5 gap-2.5 self-start bg-black p-8 text-2xl font-bold leading-9 text-white max-md:ml-2.5 max-md:mt-10 max-md:px-5">
                        ✅ 결제 수단 <br />
                        <span className="text-xl">
                            개발 시 토스페이먼츠 가이드대로{" "}
                        </span>
                        <br />
                        <span className="text-xl leading-7">
                            디자인 변경하여 진행 필요
                        </span>
                    </div>
                </aside>
            </div>
        </main>
    );
};

export default BusinessCardPurchase;
