import React from "react";

function FileListItem({ fileName }) {
    return (
        <li className="flex overflow-hidden flex-wrap gap-10 justify-between items-center w-full rounded-md max-md:max-w-full mb-1.5 last:mb-0">
            <div className="flex gap-1.5 items-center self-stretch my-auto">
                <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/608912cecb5144cf887238b12afdb74b/74b7356f771d5316f8ccdb802cc71d0a23220f02b4cf6549e9b87bd4e537e9b0?apiKey=608912cecb5144cf887238b12afdb74b&" alt="" className="object-contain shrink-0 self-stretch my-auto w-6 aspect-square" />
                <span data-layername="logo001Png" className="self-stretch my-auto">
                    {fileName}
                </span>
            </div>
            <button aria-label="Remove file">
                <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/608912cecb5144cf887238b12afdb74b/24d05cc5cd59a2b7b868a3e64c0b554020cb41f65af892dd6f6b79e11686ce44?apiKey=608912cecb5144cf887238b12afdb74b&" alt="" className="object-contain shrink-0 self-stretch my-auto w-8 aspect-square" />
            </button>
        </li>
    );
}

export default FileListItem;