// endpoint authentication
export const AUTH_ENDPOINTS = {
    LOGIN: "/auth/login",
    LOGOUT: "/auth/logout",
    LOGIN_WITH_GOOGLE: "auth/google",
    GENERATE_OTP: "/auth/generate-otp",
    VERIFY_OTP: "/auth/verify-otp",
};

// endpoint other
export const ENDPOINTS = {
    BUSINESS_CARDS: "/business-cards",
    BUSINESS_CARDS_IMAGE: "/images",
    BUSINESS_CARDS_TYPE: "/business-card-orders/business-card-type",
    GET_FILE_FORMAT: "/business-card-orders/get-file-format",
    IMAGES_LIST_BY_TYPE: "/images/get-list-by-type",
    LOGOS: "/logos",
    LOGOS_LIST: "/logos/list-by-logo",
    LOGO_INFO_PAYMENTS: "/logos/info-payment",
    
    PAYMENTS_KAKAO_INITIALIZE: "/payments/kakao/initialize",
    PAYMENTS_TOSS_PAYMENT_INITIALIZE: "/payments/toss-payment/initialize",
    PAYMENTS_NAVER_PAY_INITIALIZE: "/payments/naver-pay/initialize",

    // order
    ORDERS: "/orders",
    ORDERS_LOGOS: "/orders/logos",
    ORDERS_BUSINESS_CARDS:"/orders/business-card",
    
    //images
    POST_IMAGE_TO_S3:"/images"
};
