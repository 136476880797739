import React from "react";
import Button from "../../../components/common/Button/Button";
import CommonTitleSection from "../../../components/common/CommonTitleComplete/CommonTitleComplete";
import images from "../../../images";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";

const PrintOrderComplete = () => {
      const navigate = useNavigate();
  
    return (
        <div className="align-items-center mb-40 flex flex-col justify-center text-center">
            <main className="mx-auto max-w-[500px]">
                <CommonTitleSection
                    icon={images.businessCardPurchaseIcon}
                    title="주문완료!"
                    subtitle="인쇄 주문이 정상적으로 완료되었습니다."
                    titleColor="text-indigo-600"
                    subtitleColor="text-zinc-800"
                />
                <div className="mt-10 w-[500px] max-w-full rounded-md border border-solid border-gray-200 py-8 text-center text-base leading-6 text-neutral-700 max-md:max-w-full">
                    인쇄 주문요청서가 접수되었습니다.
                    <br />
                    <span className="font-bold">
                        [마이페이지 - 인쇄주문내역]
                    </span>
                    에서 배송 여부를 확인하실 수 있습니다.
                </div>
                <div className="mt-9 flex max-w-full flex-col justify-center text-center text-base font-bold leading-none">
                    <div className="flex w-full flex-wrap items-start gap-2.5 max-md:max-w-full">
                        <Button
                             onClick={() => navigate(`/my-page`)}
                            label={"결제 내역 확인"}
                            className="min-h-[50px] w-[196px] min-w-[240px] shrink grow gap-2.5 self-stretch rounded-md border border-solid border-neutral-300 bg-white px-4 text-zinc-800"
                        />
                        <Button
                             onClick={() => navigate(`/my-page`)}
                            label={"파일 다운로드"}
                            className="min-h-[50px] w-[196px] min-w-[240px] shrink grow gap-2.5 self-stretch rounded-md bg-indigo-600 px-4 text-white"
                        />
                    </div>
                    <a href="/" className="mt-9 self-center text-zinc-800">
                        메인으로
                    </a>
                </div>
            </main>
        </div>
    );
};

export default PrintOrderComplete;
