import * as React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import ImageList from "./ImageList";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        "aria-controls": `full-width-tabpanel-${index}`,
    };
}

export default function TabsCreateLogo() {
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box>
            <h3 className="text-base font-bold leading-none text-neutral-700">
                로고 파일 등록하기
            </h3>
            <AppBar position="static" className="mt-5 rounded-[10px]">
                <Tabs
                    value={value}
                    // onChange={handleChange}
                    variant="fullWidth"
                    aria-label="full width tabs example"
                    textColor="inherit"
                    indicatorColor="unset"
                    className="rounded-[10px]"
                    sx={{
                        "& .MuiTab-root.Mui-selected": {
                            color: "white",
                            backgroundColor: "#3533FF",
                            borderRadius: "10px",
                        },
                        "& .MuiTab-root": {
                            padding: "5px",
                        },
                        color: "#4C4BC6",
                        bgcolor: "#F2F2FF",
                        padding: "10px",
                    }}
                >
                    <Tab
                        sx={{ minHeight: "36px", height: "36px" }}
                        label="내 제작물에서 로고 가져오기"
                        {...a11yProps(0)}
                    />
                    <Tab
                        sx={{ minHeight: "36px", height: "36px" }}
                        label="직접 파일 첨부하기"
                        {...a11yProps(1)}
                    />
                    <Tab
                        sx={{ minHeight: "36px", height: "36px" }}
                        label="로고없이 진행"
                        {...a11yProps(2)}
                    />
                </Tabs>
            </AppBar>
            <TabPanel
                value={value}
                index={0}
                dir={theme.direction}
                sx={{ padding: 0 }}
            >
                <div className="rounded-[10px] bg-[#F8F9FB] p-2">
                    <span className="font-bold">
                        로고로지에서 제작하여 JPG, PNG 로 구매한 로고를 불러오실
                        수 있습니다.{" "}
                    </span>
                    <ul className="ml-5 list-disc text-[#555555]">
                        <li>
                            단,{" "}
                            <span className="font-bold">
                                다운로드 가능 기간
                            </span>
                            까지만 가져오기 가능합니다.
                        </li>
                        <li>
                            <span className="font-bold">
                                벡터파일로 구매하시거나 로고로지 전문가를 통해
                                제작한 경우,
                            </span>{" "}
                            직접 파일 첨부하기로 진행해 주세요.
                        </li>
                        <li>
                            <span className="font-bold">
                                다운로드 기간이 지난 경우,
                            </span>{" "}
                            재구매하여 진행해 주세요.
                        </li>
                    </ul>
                </div>

                <ImageList />
            </TabPanel>
            <TabPanel
                value={value}
                index={1}
                dir={theme.direction}
                sx={{ padding: 0 }}
            >
                <div className="rounded-[10px] bg-[#F8F9FB] p-2">
                    <span className="font-bold">
                        직접 로고파일을 첨부하거나, 전문가를 통해 받은 파일을
                        첨부해주세요.
                    </span>
                    <ul className="ml-5 list-disc text-[#555555]">
                        <li>
                            jpg, png 확장자의 이미지 파일만 업로드 가능합니다.{" "}
                        </li>
                        <li>최대 5MB 용량까지 업로드 가능합니다.</li>
                    </ul>
                </div>
            </TabPanel>
            <TabPanel value={value} index={2} dir={theme.direction}>
                Item Three
            </TabPanel>
        </Box>
    );
}
