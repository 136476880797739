import React, { useState, useEffect } from "react";
import NotificationItem from "./NotificationItem";
import NotificationHeader from "./NotificationHeader";
import {
    useGetAlarmListMutation,
    useRealAllAlarmMutation,
    useDeleteAllAlarmMutation,
    useDeleteAlarmMutation,
} from "../../services/apiSlice";
import images from "../../images";
import { formatDateTime } from "../../utils/helper/formatters";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const NotificationList = () => {
    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(1);
    const [take, setTake] = useState(6);
    const { userData } = useSelector((state) => state.common);
    const userId = userData?.userID || null;

    const [getAlarmList, { data }] = useGetAlarmListMutation();
    const [realAllAlarm] = useRealAllAlarmMutation();
    const [deleteAllAlarm] = useDeleteAllAlarmMutation();
    const [deleteaAlarm] = useDeleteAlarmMutation();

    useEffect(() => {
        const fetchAlarmList = async () => {
            try {
                const result = await getAlarmList({ page, take, userId }).unwrap();
                setNotifications(result.data);
                setLoading(false);
            } catch (err) {
                setError("알림을 불러오는 데 실패했습니다.");
                setLoading(false);
            }
        };

        fetchAlarmList();
    }, [page, take, getAlarmList]);

    const handleMarkAllAsRead = async () => {
        if (notifications && Array.isArray(notifications)) {
            const idArray = notifications.map((item) => ({ id: item.id }));
            const body = { alarmIds: idArray };

            try {
                const response = await realAllAlarm(body).unwrap();

                const updatedNotifications = notifications.map(
                    (notification) => ({
                        ...notification,
                        isRead: true,
                    }),
                );
                setNotifications(updatedNotifications);
            } catch (err) {
                console.error("Error:", err);
            }
        }
    };

    const handleDeleteAll = async () => {
        if (notifications && Array.isArray(notifications)) {
            const idArray = notifications.map((item) => ({ id: item.id }));
            const body = { alarmIds: idArray };

            try {
                const response = await deleteAllAlarm(body).unwrap();

                setNotifications([]);
            } catch (err) {
                console.error("Error:", err);
            }
        }
    };

    const loadMoreNotifications = () => {
        setTake((prevTake) => prevTake + 6);
    };

    if (loading) {
        return (
            <div className="flex h-screen items-center justify-center">
                로딩 중...
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex h-screen items-center justify-center text-red-500">
                {error}
            </div>
        );
    }

    const deleteAlarm = async (id) => {
        try {
            await deleteaAlarm(id).unwrap();

            setNotifications((prevNotifications) =>
                prevNotifications.filter(
                    (notification) => notification.id !== id,
                ),
            );

            toast.success("성공적으로 삭제되었습니다");
        } catch (err) {
            toast.error("삭제 실패");
        }
    };
    return (
        <main className="flex flex-col">
            <section
                data-layername="알림목록"
                className="flex w-full flex-col overflow-hidden bg-white max-md:max-w-full"
            >
                <header
                    data-layername="타이틀"
                    className="flex min-h-[139px] w-full flex-col justify-center whitespace-nowrap px-72 py-14 text-center text-3xl font-bold leading-none text-black max-md:max-w-full max-md:px-5"
                >
                    <h1 data-layername="인쇄정보입력하기">알림</h1>
                </header>
                <div className="z-10 flex w-[828px] max-w-full flex-col items-end self-center pb-14">
                    <NotificationHeader
                        onMarkAllAsRead={handleMarkAllAsRead}
                        onDeleteAll={handleDeleteAll}
                    />
                </div>
                <div className="z-10 -mt-2.5 mb-0 ml-3.5 flex h-[600px] w-[828px] max-w-full flex-col items-end self-center overflow-x-scroll max-md:mb-2.5">
                    <div className="mr-7 mt-3.5 flex flex-col self-stretch max-md:mr-2.5 max-md:max-w-full">
                        {notifications.length > 0 ? (
                            notifications.map((notification) => (
                                <NotificationItem
                                    key={notification.id}
                                    title={notification?.title}
                                    content={notification?.content}
                                    date={formatDateTime(
                                        notification?.updatedAt,
                                    )}
                                    iconSrc={
                                        notification?.isRead === false
                                            ? images?.IconNoticationActive
                                            : images?.IconNoticationInActive
                                    }
                                    deleteAlarm={(id) =>
                                        deleteAlarm(notification?.id)
                                    }
                                    hrefAlarm={notification?.link} 
                                />
                            ))
                        ) : (
                            <div className="flex h-64 items-center justify-center text-gray-500">
                                알림이 없습니다.
                            </div>
                        )}
                    </div>
                </div>
                <div className="flex justify-center py-14">
                    <button
                        onClick={loadMoreNotifications}
                        className="min-h-[50px] max-w-[320px] flex-auto gap-2.5 self-start whitespace-nowrap rounded-md bg-slate-50 px-4 py-2 text-center text-base font-bold leading-none text-slate-500 transition-colors hover:bg-slate-100"
                    >
                        더보기
                    </button>
                </div>
            </section>
        </main>
    );
};

export default NotificationList;
