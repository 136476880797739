import React from "react";
import images from "../../images";
import { useNavigate } from "react-router-dom";

function Logo() {
    const navigate = useNavigate();

    return (
        <img
            onClick={() => {
                navigate("/home");
            }}
            loading="lazy"
            src={images.logo}
            alt="Company Logo"
            className="my-auto aspect-[4.44] w-[150px] max-w-full shrink-0 cursor-pointer object-contain"
        />
    );
}

export default Logo;
