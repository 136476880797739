import React, { useState } from "react";
import TableCommon from "../../../components/common/CommonTable/TableCommon";
import Button from "../../../components/common/Button/Button";
import PaginationComponent from "../../../components/common/Pagination/PaginationComponent";

const PaymentHistory = () => {
    const [page, setPage] = useState(1);
    const columns = [
        { label: "결제일", field: "date" },
        { label: "결제 상품 정보", field: "productInfo" },
        { label: "확장자", field: "fileType" },
        { label: "결제금액", field: "amount" },
        { label: "결제취소", field: "cancelable" },
    ];

    const paymentHistoryData = [
        {
            date: "2024-09-20 10:00",
            productInfo: "로고 - PNG로고이미지001",
            fileType: "PNG",
            amount: "29,900원",
            cancelable: false,
        },
        {
            date: "2024-09-20 10:00",
            productInfo: "로고 - PNG로고이미지001",
            fileType: "JPG",
            amount: "29,900원",
            cancelable: false,
        },
        {
            date: "2024-09-20 10:00",
            productInfo: "로고 - PNG로고이미지001",
            fileType: "ai",
            amount: "165,000원",
            cancelable: true,
        },
        {
            date: "2024-09-20 10:00",
            productInfo: "로고 - PNG로고이미지001",
            fileType: "PDF",
            amount: "18,000원",
            cancelable: false,
        },
        {
            date: "2024-09-20 10:00",
            productInfo: "로고 - PNG로고이미지001",

            fileType: "ai",
            amount: "24,000원",
            cancelable: false,
        },
        {
            date: "2024-09-20 10:00",
            productInfo: "로고 - PNG로고이미지001",
            fileType: "-",
            amount: "8,900원",
            cancelable: false,
        },
    ];

    return (
        <>
            <div className="w-full px-20 max-md:px-5">
                <TableCommon
                    columns={columns}
                    data={paymentHistoryData.map((item) => ({
                        ...item,
                        cancelable: (
                            <Button
                                label={"다운로드"}
                                className="min-h-[40px] w-[110px] max-w-full gap-1 self-stretch rounded-md border border-solid border-slate-200 bg-violet-100"
                            />
                        ),
                    }))}
                />
            </div>
            <div className="flex justify-center py-24">
                <PaginationComponent className="mt-5" count={10} page={page} />
            </div>
        </>
    );
};

export default PaymentHistory;
