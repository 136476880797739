import React, { useState } from "react";
import images from "../../../images";
import Button from "../../../components/common/Button/Button";

function FileFormatRecommendation() {
    const recommendations = [
        {
            title: "해상도에 영향 없음",
            description:
                "벡터 파일은 크기를 얼마든지 늘리거나 줄여도 품질이 떨어지지 않습니다.",
        },
        {
            title: "색상 변경 자유로움",
            description:
                "추후 로고의 색상을 변경하고 싶으실 때, 벡터 파일을 이용하면 간편하게 수정이 가능합니다.",
        },
        {
            title: "인쇄와 제작에 최적화",
            description:
                "명함, 팸플릿, 배너 등 다양한 사이즈와 매체에 인쇄할 때 선명하게 출력됩니다.",
        },
        {
            title: "다양한 파일 포맷 변환 용이",
            description:
                "벡터 파일은 JPG, PNG, PDF 등 다른 형식으로 손쉽게 변환이 가능합니다.",
        },
        {
            title: "수정 및 보완 편리",
            description:
                "추후에 로고 디자인을 수정하거나 보완할 경우 벡터 파일을 이용하면 보다 효율적이고 정확한 작업이 가능합니다.",
        },
    ];
    const [isShow, setIsShow] = useState(false);
    const handleShow = () => {
        setIsShow((prev) => !prev);
    };
    return (
        <div className="mt-5 flex w-full flex-col overflow-hidden rounded-md bg-slate-50 px-8 py-6 max-md:max-w-full max-md:px-5">
            <div className="flex w-full flex-wrap items-center justify-between max-md:max-w-full">
                <div className="my-auto flex min-w-[240px] flex-1 shrink basis-0 flex-wrap items-center gap-1.5 self-stretch max-md:max-w-full">
                    <div className="my-auto flex items-center self-stretch text-lg font-semibold leading-none text-main-color">
                        <img
                            loading="lazy"
                            src={images.startIcon}
                            alt="Recommendation icon"
                            className="my-auto aspect-square w-4 shrink-0 self-stretch object-contain"
                        />
                        <span className="my-auto self-stretch">
                            확장자 추천
                        </span>
                    </div>
                    <div className="my-auto self-stretch text-base leading-none text-zinc-800">
                        벡터 파일(.ai) 사용을 추천드립니다!
                    </div>
                </div>
                <div>
                    <Button
                        onClick={handleShow}
                        icon={
                            isShow
                                ? images.arrowTopIcon
                                : images.arrowBottomIcon
                        }
                    />
                </div>
            </div>
            {isShow === true && (
                <div className="mt-5 flex w-full flex-col text-sm max-md:max-w-full">
                    <div className="flex w-full flex-wrap items-start gap-3">
                        {recommendations.map((rec, index) => (
                            <div
                                key={index}
                                className="flex min-w-[240px] max-w-[45%] flex-1 shrink basis-1/2 flex-col"
                            >
                                <div className="font-bold leading-loose text-zinc-800">
                                    <span className="">・</span>
                                    {rec.title}
                                </div>
                                <div className="leading-5 text-stone-500">
                                    {rec.description}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
}

export default FileFormatRecommendation;
