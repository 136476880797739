import { Checkbox } from "@mui/material";
import React from "react";
import images from "../../../images";

function FileFormatOption({
    name,
    price,
    features,
    isSelected,
    onSelectionChange,
    title,
    subtitle,
    width,
}) {
    return (
        <div
            className={`flex flex-1 shrink flex-col items-start rounded-md border-2 px-8 py-9 ${isSelected ? "border-indigo-600 bg-indigo-600 text-white" : "border-gray-200 bg-white"} min-h-[385px] w-full max-w-[500px] basis-0 border-solid max-md:px-5`}
        >
            <div className="flex items-center">
                <Checkbox
                    checked={isSelected}
                    onChange={onSelectionChange}
                    sx={{
                        "&.Mui-checked": {
                            color: "#ffffff",
                        },
                        "& .MuiSvgIcon-root": { fontSize: 32 },
                    }}
                />
            </div>

            <div
                className={`mt-5 flex max-w-full flex-col ${width ? `w-${width}` : ""} `}
            >
                <div className="flex min-h-[54px] w-full flex-col justify-start whitespace-nowrap text-start font-bold leading-none">
                    <div className="text-base">{name}</div>
                    <div className="mt-3.5 text-2xl">{price}</div>
                    <div className="text-base">{title}</div>
                    <div className="mt-3.5 text-2xl">{subtitle}</div>
                </div>
                <div className="align-items-center mt-8 flex w-full flex-col text-start text-sm leading-5">
                    {features.map((feature, index) => (
                        <div
                            key={index}
                            className={`flex items-center gap-1.5 ${index > 0 ? "mt-4" : ""} w-full`}
                        >
                            <img
                                loading="lazy"
                                src={
                                    isSelected
                                        ? images.bulletPointWIcon
                                        : images.bulletPointBIcon
                                }
                                alt="Checkmark icon"
                                className="my-auto aspect-square w-2.5 shrink-0 self-stretch object-contain"
                            />
                            <div
                                className="my-auto self-stretch"
                                dangerouslySetInnerHTML={{ __html: feature }}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default FileFormatOption;
