import React, { useState, useEffect } from "react";
import NotificationItem from "./NoticationItem";
import PaginationComponent from "../../../components/common/Pagination/PaginationComponent";
import { useGetNoticesMutation } from "../../../services/apiSlice";
import { useNavigate, useParams } from "react-router-dom";

export default function NotificationList() {
    const [page, setPage] = useState(1);
    const [getNoticesList, { data }] = useGetNoticesMutation();
    const navigate = useNavigate();

    useEffect(() => {

        const fetchLogosList = async () => {

            await getNoticesList({
                page: page,
            });
        };
        fetchLogosList();
    }, [getNoticesList, page]);

    const totalPages = data ? data?.meta?.pageCount : 1;

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setPage(newPage);
        }
    };
  
    const handleToDetails = (id) => {  
        navigate(`/notices/${id}`);
     }
    return (
        <main className="flex w-full flex-col px-52 leading-none">
            <section className=" ">
                {data?.data.map((notification, index) => (
                    <NotificationItem
                        key={index}
                        handleToDetails={(notification)=>handleToDetails(notification?.id)}
                        number={notification.id}
                        title={notification.title}
                        date={notification.updatedAt}
                    />
                ))}
            </section>
            <div className="mb-24 flex justify-center">
                <PaginationComponent
                    className="mt-10"
                    count={totalPages}
                    page={page}
                    onPageChange={handlePageChange}
                    showFirstButton={true}
                    showLastButton={true}
                />
            </div>
        </main>
    );
}
