import React, { useState, useEffect } from "react";
import CardTypeSelect from "./CardTypeSelect";
import CardQuantitySelector from "./CardQuantitySelector";
import Button from "../../../components/common/Button/Button";
import images from "../../../images";
import { useGetBusinessCardsQuery } from "../../../services/apiSlice";

function GeneralBusinessCard() {
    const [selectedCardType, setSelectedCardType] = useState({});
    const { data, error, isLoading } = useGetBusinessCardsQuery("bulbasaur");

    useEffect(() => {
        const defaultCardType = {
            name: "일반명함",
            icon: "ext_5-",
            active: true,
            id: 1,
            type: "A",
        };
        setSelectedCardType(defaultCardType);
        console.log("data", data, isLoading, error);
    }, []);

    const handleCardTypeSelect = (selectedType) => {
        console.log("Selected Card Type:", selectedType);
        setSelectedCardType(selectedType);
    };

    return (
        <div>
            <main className="flex flex-col overflow-hidden bg-white">
                <section className="flex min-h-[139px] w-full flex-col justify-center px-72 py-14 text-center text-3xl font-bold leading-none text-black max-md:max-w-full max-md:px-5">
                    <h1 className="flex w-[878px] max-w-full flex-col items-center">
                        인쇄 주문하기
                    </h1>
                </section>
                <div className="flex w-full max-w-[1240px] flex-col self-center max-md:max-w-full">
                    <CardTypeSelect
                        onSelectType={handleCardTypeSelect}
                        defaultSelectedType={selectedCardType}
                    />
                    <hr className="mt-14 flex h-px shrink-0 border border-solid border-gray-200 max-md:mt-10 max-md:max-w-full" />
                    <CardQuantitySelector selectedCardType={selectedCardType} />
                    <Button
                        label={"바로 주문하기"}
                        className="mb-32 mt-16 min-h-[50px] w-80 max-w-full gap-2.5 self-center rounded-md bg-stone-950 px-4 text-center text-base font-bold leading-none text-white max-md:mt-10"
                    />
                </div>
            </main>
            <img
                loading="lazy"
                src={images.businessCardPurchaseFooter}
                alt=""
                className="mt-24 aspect-[7.19] w-full self-stretch object-contain max-md:mt-10 max-md:max-w-full"
            />
        </div>
    );
}

export default GeneralBusinessCard;
