import React, { useState } from 'react';
import InputField from '../../components/common/Input/InputField';

export default function BusinessCardForm() {
    const [formData, setFormData] = useState({
        name: '',
        position: '',
        email: '',
        phone: '',
        fax: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission
    };

    const formFields = [
        { name: 'name', label: '이름', placeholder: '이름을 입력해주세요.' },
        { name: 'position', label: '직책', placeholder: '직책을 입력해주세요.' },
        {},
        { name: 'email', label: '이메일', placeholder: '이메일 주소를 입력해주세요.' },
        { name: 'phone', label: '전화번호', placeholder: '전화번호를 입력해주세요.' },
        { name: 'fax', label: '팩스', placeholder: '팩스 번호를 입력해주세요.' }
    ];

    const formFields2 = [
        { name: 'name', label: '크리에이터명', placeholder: '크리에이터명을 입력해주세요.' },
        { name: 'position', label: '전화번호', placeholder: '전화번호를 입력해주세요.' },
        { name: 'position1', label: 'SNS 주소', placeholder: 'SNS 주소를 입력해주세요.' },
    ];

    return (
        <form
            onSubmit={handleSubmit}
            className="flex flex-col justify-center px-4 md:px-12 py-10 mt-5 leading-none rounded-md border border-solid border-neutral-200"
        >
            <div className="flex flex-col w-full bg-white rounded-xl">
                <div className="grid grid-cols-2 md:grid-cols-3 gap-9 items-start max-w-full">
                    {formFields.map((field) => (
                        field.name ?
                            <InputField
                                label={field.label}
                                placeholder={field.placeholder}
                            />
                            : <div></div>
                    ))}
                </div>
            </div>
        </form >
    );
}