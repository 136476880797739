import React from 'react';
import ConsultationRequestCard from './ConsultationRequestCard';

const ConsultationRequestList = ({ data, tab = 1 }) => {
    return (
        <main className="flex flex-col leading-none">
            <section className="grid grid-cols-1 gap-6 items-center lg:grid-cols-2 ">
                {data.map((request, index) => (
                    <ConsultationRequestCard tab={tab} key={index} {...request} />
                ))}
            </section>

        </main>
    );
};

export default ConsultationRequestList;