import React from 'react';

const LogoItem = ({ src, alt, onChange, isChecked }) => (
  <div className="relative mt-6 ">
    <img
      loading="lazy"
      src={src}
      alt={alt}
      className="object-contain grow shrink self-stretch my-auto rounded-none aspect-[1.33] min-w-[240px] w-full"
    />
    <div className="flex items-center">
      <input
        type="checkbox"
        checked={isChecked}
        onChange={onChange}
        className="w-6 h-6 text-main-color bg-gray-100 border-gray-300 rounded focus:ring-indigo-500 absolute top-2 left-2"
      />
    </div>
  </div>
);

export default LogoItem;
