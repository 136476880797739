import React from "react";
import images from "./../../../../images";
import { saveAs } from "file-saver";

function AttachmentList({ files }) {
    const handleDownload = async (url, name) => {
        try {
            const response = await fetch(url);
            const blob = await response.blob();
            saveAs(blob, name);
        } catch (error) {
            console.error("Download failed:", error);
        }
    };
    return (
        <div className="mt-8 flex w-full flex-col whitespace-nowrap max-md:max-w-full">
            <div className="flex w-full items-center gap-10 leading-none max-md:max-w-full">
                <div className="my-auto flex w-full min-w-[240px] flex-1 shrink basis-0 flex-wrap items-start gap-1 self-stretch max-md:max-w-full">
                    <h2 className="text-base font-bold text-neutral-700">
                        첨부파일
                    </h2>
                </div>
            </div>
            <div className="mt-2.5 flex w-full flex-col max-md:max-w-full">
                <div className="flex min-h-[220px] w-full flex-col rounded-md border border-solid border-zinc-200 px-5 pb-24 pt-5 max-md:max-w-full">
                    {files.map((file, index) => (
                        <div
                            key={index}
                            className="mt-1.5 flex min-h-[32px] w-full flex-wrap items-center justify-between gap-10 max-md:max-w-full"
                        >
                            <div className="my-auto flex items-start gap-2.5 self-stretch text-base font-medium leading-none text-stone-500">
                                <div className="flex items-center gap-1.5">
                                    {/* <img loading="lazy" src={file.url} alt="" className="object-contain shrink-0 self-stretch my-auto w-6 aspect-square" /> */}
                                    <div className="my-auto self-stretch">
                                        {file.name}
                                    </div>
                                </div>
                            </div>
                            <a
                                href={file.url}
                                target="_blank" 
                                rel="noopener noreferrer" 
                                className="my-auto flex items-start gap-1 self-stretch text-base font-semibold leading-none text-indigo-600"
                                download={file.url} 
                            >
                                <button type="button">다운로드</button>
                                <img
                                    loading="lazy"
                                    src={images.IConDownLoad}
                                    alt="다운로드 아이콘"
                                    className="aspect-square w-3.5 shrink-0 object-contain"
                                />
                            </a>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default AttachmentList;
