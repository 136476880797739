import React from "react";
import images from "../../images";

function Logo() {
    return (
        <div className="mb-8 flex w-full max-w-[320px] flex-col items-center">
            <img
                src={images.imgLogin}
                alt="LogoLogee icon"
                className="aspect-[1.3] w-full"
            />
        </div>
    );
}

export default Logo;
