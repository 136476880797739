import React from 'react';

const NotificationHeader = ({ onMarkAllAsRead, onDeleteAll }) => {
    return (
        <div className="flex gap-1.5 items-center mr-7 text-xs leading-none text-zinc-800 max-md:mr-2.5">
            <button
                onClick={onMarkAllAsRead}
                data-layername="전체알림읽음"
                className="self-stretch my-auto hover:underline focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
                전체 알림 읽음
            </button>
            <span data-layername="ㅣ" className="self-stretch my-auto">
                ㅣ
            </span>
            <button
                onClick={onDeleteAll}
                data-layername="전체알림삭제"
                className="self-stretch my-auto hover:underline focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
                전체 알림 삭제
            </button>
        </div>
    );
};

export default NotificationHeader;