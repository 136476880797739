import React from 'react';
import images from '../../../images';

function ProductImage({imageBussinessCardFront,imageBussinessCardBack}) {
  return (
    <div className="flex flex-col  gap-10 justify-center items-center p-10 mt-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
      <img
        loading="lazy"
        src={imageBussinessCardFront}
        alt="Product preview"
        className="object-contain mx-auto self-stretch my-auto aspect-[1.47] min-w-[240px] w-[323px]"
      />
       <img
        loading="lazy"
        src={imageBussinessCardBack}
        alt="Product preview"
        className="object-contain mx-auto self-stretch my-auto aspect-[1.47] min-w-[240px] w-[323px]"
      />
    </div>
  );
}

export default ProductImage;