import React, { useState } from "react";
import NotificationItem from "./NoticationItem";
import PaginationComponent from "../../../components/common/Pagination/PaginationComponent";

export default function NotificationList() {
    const [page, setPage] = useState(1);

    const notifications = [
        {
            number: 20,
            title: "이미지를 많이 넣고 싶은 데, 추가 이미지를 삽입할 수 있을까요?",
            date: "2024-09-20 10:00",
        },
        {
            number: 19,
            title: "이미지를 많이 넣고 싶은 데, 추가 이미지를 삽입할 수 있을까요?",
            date: "2024-09-20 10:00",
        },
        {
            number: 18,
            title: "이미지를 많이 넣고 싶은 데, 추가 이미지를 삽입할 수 있을까요?",
            date: "2024-09-20 10:00",
        },
        {
            number: 17,
            title: "이미지를 많이 넣고 싶은 데, 추가 이미지를 삽입할 수 있을까요?",
            date: "2024-09-20 10:00",
        },
        {
            number: 16,
            title: "이미지를 많이 넣고 싶은 데, 추가 이미지를 삽입할 수 있을까요?",
            date: "2024-09-20 10:00",
        },
        {
            number: 15,
            title: "이미지를 많이 넣고 싶은 데, 추가 이미지를 삽입할 수 있을까요?",
            date: "2024-09-20 10:00",
        },
    ];

    return (
        <main className="flex w-full flex-col px-52 leading-none">
            <section className=" ">
                {notifications.map((notification, index) => (
                    <NotificationItem
                        key={index}
                        number={notification.number}
                        title={notification.title}
                        date={notification.date}
                    />
                ))}
            </section>
            <div className="mb-24 flex justify-center">
                <PaginationComponent className="mt-10" count={10} page={page} />
            </div>
        </main>
    );
}
