import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "./LayoutCommon/Header";
import Footer from "./LayoutCommon/Footer";

const Main = () => {
    const screensHide = ["/login"];
    const location = useLocation();

    const isScreenShow = !location.pathname.includes(screensHide);

    return (
        <div className="flex min-h-screen flex-col justify-between">
            <div className="flex min-h-screen flex-col justify-between">
                {isScreenShow && <Header />}
                <div id="layoutSidenav" className="pt-32">
                    <Outlet />
                </div>
                {isScreenShow && <Footer />}
            </div>
        </div>
    );
};

export default Main;
