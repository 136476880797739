import React from "react";

const Button = ({
  label,
  className,
  icon,
  fontSize,
  background,
  textColor,
  fontWeight,
  borderColor,
  width,
  height,
  onMouseOver,
  onMouseOut,
  onClick,
  widthIcon,
  iconFirst,
  ...rest
}) => {
  return (
    <button
      {...rest}
      className={`
        ${fontSize ? `text-${fontSize}` : ""} 
        ${background ? `bg-${background}` : ""} 
        ${textColor ? `text-${textColor}` : ""} 
        ${fontWeight ? `font-${fontWeight}` : ""} 
        ${borderColor ? `border border-${borderColor}` : ""} 
        ${width ? `w-${width}` : ""} 
        ${height ? `h-${height}` : ""} 
        ${className} 
        flex items-center justify-center gap-2 px-4 rounded min-h-[50px]
      `}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      onClick={onClick}
    >
      {iconFirst && <img src={iconFirst} alt="" className="inline-block" />}
      {label && <span>{label}</span>}
      {icon && <img src={icon} alt="" className="inline-block" width={widthIcon} />}
    </button>
  );
};

export default Button;
