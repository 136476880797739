import React, { useState } from 'react';
import ProductImage from './ProductImage';
import LogoInputInfo from './LogoInputInfo';
import FileFormatSelection from './FileFormatSelection';
import FileFormatRecommendation from './FileFormatRecommendation';
import PaymentMethod from '../../../components/common/PaymentMethod/PaymentMethod';
import TotalPayment from '../../../components/common/PaymentMethod/TotalPayment';
import PurchaseInfo from './PurchaseInfo';

function PurchasePage() {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);

  const handlePaymentSelect = (paymentMethod) => {
    setSelectedPaymentMethod(paymentMethod);
  };

  return (
    <main className="rounded-none px-20 py-10 max-md:px-5">
      <div className="flex gap-5 max-md:flex-col">
        <section className="flex flex-col w-[63%] max-md:ml-0 max-md:w-full">
          <div className="flex flex-col grow max-md:mt-10 max-md:max-w-full">
            <div className="flex flex-col w-full max-md:max-w-full">
              <h2 className="pb-4 w-full text-2xl font-bold leading-none border-b-2 border-neutral-800 text-zinc-800 max-md:max-w-full">
                구매 상품 정보
              </h2>
              <ProductImage />
            </div>

            <LogoInputInfo />
            <FileFormatSelection />
            <FileFormatRecommendation />
            <PaymentMethod onPaymentSelect={handlePaymentSelect} />
            <TotalPayment amount={2999000} />
          </div>
        </section>
        
        <aside className="flex flex-col justify-between w-[37%] max-md:ml-0 max-md:w-full py-10">
          <div
            className="sticky top-10"
          >
            <PurchaseInfo />
          </div>

          <div className="gap-2.5 self-start mb-40  p-8 ml-5 text-2xl font-bold leading-9 text-white bg-black mt-[1515px] max-md:px-5 max-md:mt-10  max-md:mb-5 max-md:ml-2.5">
            ✅ 결제 수단 <br />
            <span className="text-xl">개발 시 토스페이먼츠 가이드대로 </span>
            <br />
            <span className="text-xl leading-7">디자인 변경하여 진행 필요</span>
          </div>
        </aside>
      </div>
    </main>
  );
}

export default PurchasePage;
