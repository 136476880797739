import React, { useState, useEffect } from "react";
import NotificationItem from "./NotificationItem";
import NotificationHeader from "./NotificationHeader";
import { fakeDataNoti } from "../../components/common/dataFake/imageData";

const NotificationList = () => {
    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                setNotifications(fakeDataNoti);
                setLoading(false);
            } catch (err) {
                setError("알림을 불러오는 데 실패했습니다.");
                setLoading(false);
            }
        };

        fetchNotifications();
    }, []);

    const handleMarkAllAsRead = () => {
        setNotifications((prevNotifications) =>
            prevNotifications.map((notification) => ({
                ...notification,
                isRead: true,
            })),
        );
    };

    const handleDeleteAll = () => {
        setNotifications([]);
    };

    const loadMoreNotifications = () => {
        const newNotifications = [
            {
                id: notifications.length + 1,
                title: "새로운 알림 타이틀",
                content:
                    "새로 로드된 알림의 내용입니다.\n자세한 정보를 확인하려면 클릭하세요.",
                date: "2024.06.10 14:55",
                iconSrc:
                    "https://cdn.builder.io/api/v1/image/assets/608912cecb5144cf887238b12afdb74b/587f130b68c2eec067af1cb26dc614c10eb9103617cb868735d670a9dfd0975c?apiKey=608912cecb5144cf887238b12afdb74b&",
                isRead: false,
            },
        ];
        setNotifications((prevNotifications) => [
            ...prevNotifications,
            ...newNotifications,
        ]);
    };

    if (loading) {
        return (
            <div className="flex h-screen items-center justify-center">
                로딩 중...
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex h-screen items-center justify-center text-red-500">
                {error}
            </div>
        );
    }

    return (
        <main className="flex flex-col">
            <section
                data-layername="알림목록"
                className="flex w-full flex-col overflow-hidden bg-white max-md:max-w-full"
            >
                <header
                    data-layername="타이틀"
                    className="flex min-h-[139px] w-full flex-col justify-center whitespace-nowrap px-72 py-14 text-center text-3xl font-bold leading-none text-black max-md:max-w-full max-md:px-5"
                >
                    <h1 data-layername="인쇄정보입력하기">알림</h1>
                </header>
                <div className="z-10 flex w-[828px] max-w-full flex-col items-end self-center">
                    <NotificationHeader
                        onMarkAllAsRead={handleMarkAllAsRead}
                        onDeleteAll={handleDeleteAll}
                    />
                </div>
                <div className="z-10 -mt-2.5 mb-0 ml-3.5 flex h-[600px] w-[828px] max-w-full flex-col items-end self-center overflow-x-scroll max-md:mb-2.5">
                    <div className="mr-7 mt-3.5 flex flex-col self-stretch max-md:mr-2.5 max-md:max-w-full">
                        {notifications.length > 0 ? (
                            notifications.map((notification) => (
                                <NotificationItem
                                    key={notification.id}
                                    {...notification}
                                />
                            ))
                        ) : (
                            <div className="flex h-64 items-center justify-center text-gray-500">
                                알림이 없습니다.
                            </div>
                        )}
                        <button class="bg-sky-700 px-4 py-2 text-white hover:bg-sky-800 sm:px-8 sm:py-3">
                            ...
                        </button>
                    </div>
                </div>
                <div className="flex justify-center">
                    <button
                        onClick={loadMoreNotifications}
                        className="min-h-[50px] flex-auto gap-2.5 self-start whitespace-nowrap rounded-md bg-slate-50 px-4 py-2 text-center text-base font-bold leading-none text-slate-500 transition-colors hover:bg-slate-100"
                    >
                        더보기
                    </button>
                </div>
            </section>
        </main>
    );
};

export default NotificationList;
