import React from "react";
import Button from "../../../../components/common/Button/Button";
import NoticationDetailHeader from "./NoticationDetailHeader";
import { useNavigate, useParams } from "react-router-dom";
import { useGetNoticesDetailsQuery } from "../../../../services/apiSlice";
import { formatDateTime } from "../../../../utils/helper/formatters";
import DOMPurify from "dompurify"; // Thêm thư viện sanitize HTML

function NoticationDetailLayout() {
    const { id } = useParams();
    const navigate = useNavigate();
    
    const { data, isLoading, error } = useGetNoticesDetailsQuery({
        id: id,
    });

    // Hàm sanitize HTML content (cho phép hiển thị hình ảnh)
    const createSanitizedHTML = (dirtyHTML) => ({
        __html: DOMPurify.sanitize(dirtyHTML || "", {
            ALLOWED_TAGS: ["p", "b", "i", "em", "strong", "ul", "ol", "li", "a", "br", "img"], // Thêm <img>
            ALLOWED_ATTR: ["href", "target", "style", "src", "alt"], // Cho phép src, alt
        })
    });

    return (
        <div className="flex flex-col items-center px-20 max-md:px-5">
            <article className="flex w-full flex-col justify-center rounded-3xl border border-solid border-gray-200">
                {data && (
                    <>
                        <NoticationDetailHeader
                            title={data?.title || ""}
                            date={data?.createdAt ? formatDateTime(data.createdAt) : "Unknown Date"}
                        />
                        
                        {/* Hiển thị nội dung có thể chứa hình ảnh */}
                        <div 
                            className="p-6 text-gray-700"
                            dangerouslySetInnerHTML={createSanitizedHTML(data.content)}
                        />
                    </>
                )}
            </article>

            <div className="mt-4 flex w-full justify-center py-20">
                <Button
                    onClick={() => navigate("/customer-faq")}
                    label={"목록으로"}
                    className="min-h-[50px] w-[196px] min-w-[240px] rounded-md border border-solid border-neutral-300 bg-white px-4 text-zinc-800"
                />
            </div>
        </div>
    );
}

export default NoticationDetailLayout;
