import React from 'react';
import CardSimple from './../../components/common/Card/CardSimple';
import BusinessCardTitle from './BussinessCardTitle';
import images from '../../images';


export default function BusinessCardLayout() {
  const cardSamples = [
    {
      id: 1,
      title: "기업형 001",
      description: "가로형 로고와 깔끔한 정보 배치로 심플하면서도 세련된 인상을 주는 명함입니다. 앞면과 뒷면 모두에 로고를 배치하여 브랜드의 존재감을 강조하였으며, 간결한 정보 구성을 통해 실용적이고 효율적인 디자인을 제공합니다.",
      frontImage: "https://cdn.builder.io/api/v1/image/assets/TEMP/a8539e6b562218100c20ea59f36ceea1e50743ec4357f1304cd1efe480b959f6?placeholderIfAbsent=true&apiKey=3ac17b61e0c94c2b87d841ac8b4d3afd",
      backImage: "https://cdn.builder.io/api/v1/image/assets/TEMP/66f6f6394641165dcd42ea066e4330cdb5d162d9158b8015bcc665c6952add8b?placeholderIfAbsent=true&apiKey=3ac17b61e0c94c2b87d841ac8b4d3afd"
    },
    {
      id: 2,
      title: "기업형 002",
      description: "심플하고 깔끔한 디자인으로, 로고를 중심에 배치하여 브랜드의 아이덴티티를 강조한 명함입니다. 간결한 정보 구성으로 실용성을 높였으며, 세련된 레이아웃을 통해 전문성을 전달합니다.",
      frontImage: "https://cdn.builder.io/api/v1/image/assets/TEMP/acfef452e505ad30ca04a5e5da9e203eb99e1f118329b8f43675844acc8ddce3?placeholderIfAbsent=true&apiKey=3ac17b61e0c94c2b87d841ac8b4d3afd",
      backImage: "https://cdn.builder.io/api/v1/image/assets/TEMP/e4317f7946777ccc5c412d5176d5f61763a5a3e53abdc2282f75bbf141f24295?placeholderIfAbsent=true&apiKey=3ac17b61e0c94c2b87d841ac8b4d3afd"
    },
    {
      id: 3,
      title: "기업형 003",
      description: "깔끔하고 현대적인 이미지를 담은 간결한 디자인으로 기업의 신뢰감을 높이고 전문성을 효과적으로 전달합니다. 세로형 로고를 활용한 기업에 특히 적합한 명함입니다.",
      frontImage: "https://cdn.builder.io/api/v1/image/assets/TEMP/e987a961eb773926f4ce54e26c4ce374de4cefff0f94537e8a414b74ea492139?placeholderIfAbsent=true&apiKey=3ac17b61e0c94c2b87d841ac8b4d3afd",
      backImage: "https://cdn.builder.io/api/v1/image/assets/TEMP/521f191913fe228e39839a2f0b5fa40afb5068452717c493d7fbc7a8f599b3e8?placeholderIfAbsent=true&apiKey=3ac17b61e0c94c2b87d841ac8b4d3afd"
    }
  ];

  return (
    <div className="flex overflow-hidden flex-col items-center bg-white">
      <BusinessCardTitle />
      <main className="w-full max-w-[1240px]">
        {cardSamples.map((sample, index) => (
          <React.Fragment key={sample.id}>
            <CardSimple {...sample} />
            {index < cardSamples.length - 1 && (
              <div className="flex shrink-0 mt-9 max-w-full h-px border border-gray-200 border-solid w-[1240px]" />
            )}
          </React.Fragment>
        ))}
      </main>
      <img
        loading="lazy"
        src={images.businessCardLayoutFooter}
        alt=""
        className="object-contain self-stretch mt-24 w-full aspect-[7.19] max-md:mt-10 max-md:max-w-full"
      />
    </div>
  );
}