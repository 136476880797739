import React from 'react';

function ProductImage() {
  return (
    <div className="flex gap-10 justify-center items-center p-10 mt-5 w-full bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/aec5050c8b73f47eb731ae0a9d2bf4f297f2b8c22dfa1189f17c66a7ea1ab7e7?placeholderIfAbsent=true&apiKey=3ac17b61e0c94c2b87d841ac8b4d3afd"
        alt="Product preview"
        className="object-contain self-stretch my-auto aspect-[1.47] min-w-[240px] w-[323px]"
      />
    </div>
  );
}

export default ProductImage;