import React from "react";

function ButtonComponent({ variant, children, className, onClick, icon }) {
    const baseClasses =
        "flex items-center justify-center px-8 py-2 w-full max-w-[300px] text-base font-medium rounded border border-solid";

    const variantClasses = {
        naver: "bg-green-500 text-white hover:bg-green-600",
        kakao: "bg-yellow-400 text-black hover:bg-yellow-500",
        google: "bg-white text-neutral-500 hover:bg-gray-100",
    };

    const combinedClasses = `${baseClasses} ${variantClasses[variant]} ${
        className || ""
    }`;

    return (
        <button className={combinedClasses} onClick={onClick}>
            {icon && (
                <img
                    width={20}
                    src={icon}
                    alt="Google logo"
                    className="mr-2 aspect-[1.03]"
                />
            )}
            {children}
        </button>
    );
}

export default ButtonComponent;
