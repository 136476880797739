import React from 'react';
import images from "../../images"

function FAQItem({ question, answer, isOpen, onClick }) {
  return (
    <article className="flex flex-col w-full max-w-full">
      <div className={`
        flex flex-col 
        ${isOpen ? 'pb-7' : ''} 
        w-full font-bold 
        ${isOpen ? '' : 'border-b'} 
        border-neutral-200
      `}>
        <div
          className="flex flex-wrap gap-5 justify-between items-center w-full cursor-pointer"
          onClick={onClick}
        >
          <div className="flex flex-wrap gap-2.5 items-start self-stretch py-7 my-auto min-w-[240px]">
            <span className="text-xl leading-tight text-main-color">Q.</span>
            <p className="text-base leading-relaxed text-zinc-800 flex-grow">
              {question}
            </p>
          </div>
          <img
            loading="lazy"
            src={isOpen ? images.arrowTWIcon : images.arrowBWIcon}
            alt={isOpen ? "Collapse" : "Expand"}
            className="object-contain shrink-0 self-stretch my-auto w-4 aspect-square"
          />
        </div>
        {isOpen && (
          <div className="self-stretch px-8 py-6 w-full text-base leading-6 bg-main-color rounded text-zinc-100">
            {answer}
          </div>
        )}
      </div>
    </article>
  );
}

export default FAQItem;