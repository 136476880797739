import React from 'react';

function FooterBanner({ src }) {
  return (
    <main>
      <img
        loading="lazy"
        src={src}
        className="object-contain w-full aspect-[7.19] max-md:mt-10 max-md:max-w-full"
        alt="Dynamic Image"
      />
    </main>
  );
}

export default FooterBanner;
