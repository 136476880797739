import React from 'react';
import { formatPrice } from '../../../utils/helper/formatters'
function TotalPayment({ amount }) {
  return (
    <section className="flex flex-col mt-24 w-full font-bold border-t-2 border-zinc-800 max-md:mt-10 max-md:max-w-full">
      <div className="flex flex-wrap gap-10 justify-between items-center py-9 w-full rounded-md max-md:max-w-full">
        <h2 className="self-stretch my-auto text-2xl leading-none text-zinc-800">
          총 결제 금액
        </h2>
        <div className="self-stretch my-auto text-2xl leading-none text-right text-main-color">
          {formatPrice(amount)}
        </div>
      </div>
    </section>
  );
}

export default TotalPayment;