import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/common/Button/Button";
import images from "../../images";

export default function BusinessCardTitle() {
    const navigate = useNavigate();

    const backToBusinessType = () => {
        navigate("/card");
    };
    return (
        <header className="flex min-h-[192px] w-full flex-col justify-center self-stretch px-72 py-14 text-center font-bold leading-none max-md:max-w-full max-md:px-5 max-md:py-0">
            <div className="flex w-full flex-col items-center max-md:max-w-full">
                <h1 className="text-3xl text-black">기업형 타입 : 명함 샘플</h1>
                <Button
                    onClick={backToBusinessType}
                    className="mt-5 flex items-center gap-2 rounded-[100px] border border-solid border-indigo-400 px-4 py-2.5 text-sm text-main-color"
                    label={"명함 타입 다시 고르기"}
                    iconFirst={images.arrowLeftIcon}
                />
            </div>
        </header>
    );
}
