import React from "react";
import { toast } from "react-toastify";

function FileUpload({ onFileUpload, fileName }) {

 
    const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
        if (file.size > 5 * 1024 * 1024) {
            toast.error("파일 크기는 5MB 이하여야 합니다.");
            return;
        }

        const allowedTypes = ["image/jpeg", "image/png", "image/gif", "application/pdf"];
        if (!allowedTypes.includes(file.type)) {
            toast.error("이미지 파일 또는 PDF 파일만 첨부 가능합니다.");
            return;
        }

        const reader = new FileReader();
        reader.onloadend = () => {
            const binaryData = reader.result;
            onFileUpload({
                binaryData,
                fileName: file.name,
                fileType: file.type,
            });
        };
        reader.readAsArrayBuffer(file); // Đọc file dưới dạng binary
    }
};
   

    return (
        <>
            <div className="mt-10 flex w-full flex-col items-start leading-none max-md:max-w-full">
                <div className="flex w-[600px] max-w-full flex-col">
                    <h3 className="text-base font-bold text-neutral-700">
                        파일첨부
                    </h3>
                    <p className="mt-2.5 text-sm text-stone-500">
                        이미지 파일만 첨부 가능합니다.
                    </p>
                </div>
                <div className="mt-4 flex w-[655px] max-w-full flex-wrap items-start gap-2.5">
                    <label
                        htmlFor="file-upload"
                        className="flex min-h-[50px] w-[150px] cursor-pointer items-center justify-center gap-2.5 rounded-md border border-solid border-indigo-600 text-base font-semibold text-indigo-600"
                    >
                        파일 선택
                    </label>
                    <input
                        id="file-upload"
                        type="file"
                        className="hidden"
                        accept="image/*,application/pdf" 

                        onChange={handleFileChange}
                    />
                    <div className="flex min-h-[50px] w-[400px] min-w-[240px] items-center gap-3 self-stretch overflow-hidden rounded-md bg-stone-50 px-4 text-base font-medium text-stone-500">
                        {fileName ? fileName : "선택된 파일이 없습니다."}
                    </div>
                </div>
            </div>
        </>
    );
}

export default FileUpload;
