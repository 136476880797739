
import React from "react";
import images from "../../images"

function LogoSection() {
  return (
    <section className="flex flex-col items-start self-center mt-20 w-full leading-none max-md:mt-10 max-md:max-w-full px-20">
      <h2 className="text-2xl text-neutral-700 max-md:max-w-full">
        <span className="font-bold text-zinc-800">로고로지</span>에서{" "}
        <span className="font-bold text-main-color">다양한</span>{" "}
        <span className="font-bold text-main-color">로고디자인</span>을
        확인해보세요.
      </h2>
      <div className="flex gap-4 items-center mt-5 text-base font-bold text-zinc-800">
        <div className="self-stretch my-auto">더 많은 샘플 이미지 보러가기</div>
        <img
          loading="lazy"
          src={images.arrowCircleB}
          alt=""
          className="object-contain shrink-0 self-stretch my-auto w-6 aspect-square"
        />
      </div>
      <img
        loading="lazy"
        src={images.ordersImg}
        alt="Logo samples"
        className="object-contain self-stretch mt-8 w-full aspect-[1.69] max-md:max-w-full"
      />
    </section>
  );
}

export default LogoSection;
