import React from 'react';

const LogoMatchingHeader = () => {
  return (
    <section className="flex flex-col justify-center px-72 py-14 w-full leading-none text-center min-h-[177px] max-md:px-5 max-md:max-w-full">
      <div className="flex flex-col max-w-full w-[878px]">
        <h1 className="self-center text-3xl font-bold text-black">로고 매칭 목록</h1>
        <p className="mt-5 text-lg text-neutral-700 max-md:max-w-full">
          디자인으로 머리 아프게 고민하셨다면 AI가 제안하는 시안으로 빠르고 간편하게 디자인 매칭을 경험해 보세요.
        </p>
      </div>
    </section>
  );
};

export default LogoMatchingHeader;