import React from "react";
import InputField from "../../components/common/Input/InputField";

export default function BusinessCardForm({
    selectedTab,
    formData,
    handleInputChange,
    onSubmit,
    formErrors,
}) {
    const formFields = [
        { name: "name", label: "이름", placeholder: "이름을 입력해주세요." },
        {
            name: "position",
            label: "직책",
            placeholder: "직책을 입력해주세요.",
        },
        {},
        {
            name: "email",
            label: "이메일",
            placeholder: "이메일 주소를 입력해주세요.",
        },
        {
            name: "phone",
            label: "전화번호",
            placeholder: "전화번호를 입력해주세요.",
        },
        {
            name: "fax",
            label: "팩스",
            placeholder: "팩스 번호를 입력해주세요.",
        },
    ];

    const formFields2 = [
        {
            name: "snsCreatorName",
            label: "크리에이터명",
            placeholder: "크리에이터명을 입력해주세요.",
        },
        {
            name: "snsPhoneNumber",
            label: "전화번호",
            placeholder: "전화번호를 입력해주세요.",
        },
        {
            name: "snsAddress",
            label: "SNS 주소",
            placeholder: "SNS 주소를 입력해주세요.",
        },
    ];

    const formFields3 = [
        {
            name: "storeName",
            label: "매장명",
            placeholder: "매장명을 입력해주세요.",
        },
        {
            name: "storePhoneNumber",
            label: "매장 전화번호",
            placeholder: "매장 전화번호를 입력해주세요.",
        },
        {
            name: "storeSnsLink",
            label: "매장 SNS 링크 ",
            placeholder: "매장 SNS 링크를 입력해주세요.",
        },
        {
            name: "storeAddress",
            label: "매장 주소",
            placeholder: "매장 주소를 입력해주세요.",
        },
    ];

    const selectedFields =
        selectedTab === 0
            ? formFields
            : selectedTab === 1
              ? formFields2
              : selectedTab === 2
                ? formFields3
                : [];

    return (
        <form
            onSubmit={onSubmit}
            className="mt-5 flex flex-col justify-center rounded-md border border-solid border-neutral-200 px-4 py-10 leading-none md:px-12"
        >
            <div className="flex w-full flex-col rounded-xl bg-white">
                {selectedTab !== 2 ? (
                    <div className="grid max-w-full grid-cols-2 items-start gap-9 md:grid-cols-3  max-md:grid-cols-1">
                        {selectedFields.map((field, index) =>
                            field.name ? (
                                <div key={field.name}>
                                    <InputField
                                        label={field.label}
                                        name={field.name}
                                        placeholder={field.placeholder}
                                        value={formData[field.name] || ""}
                                        onChange={handleInputChange}
                                    />
                                    {formErrors[field.name] && (
                                        <div className="mt-1 text-sm text-red-500">
                                            {formErrors[field.name]}
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div key={index}></div>
                            ),
                        )}
                    </div>
                ) : (
                    <div className="grid max-w-full gap-9 ">
                        {selectedFields.map((field, index) =>
                            field.name ? (
                                <div
                                    key={field.name}
                                    className={` ${index === 0 ? "col-span-2" : ""} ${index >= 1 && index <= 2 ? "w-full max-md:col-span-2 " : ""} ${index === 3 ? "col-span-2" : ""} `}
                                >
                                    <InputField
                                        label={field.label}
                                        name={field.name}
                                        placeholder={field.placeholder}
                                        value={formData[field.name] || ""}
                                        onChange={handleInputChange}
                                    />
                                    {formErrors[field.name] && (
                                        <div className="mt-1 text-sm text-red-500">
                                            {formErrors[field.name]}
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div key={index}></div>
                            ),
                        )}
                    </div>
                )}
            </div>
        </form>
    );
}
