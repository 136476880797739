import React from "react";
import RequestInfo from "./RequestInfo";
import DetailContent from "./DetailContent";
import AttachmentList from "./AttachmentList";
import Button from "../../../../components/common/Button/Button";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useGetExpertRequestsDetailsQuery } from "../../../../services/apiSlice";
import { formatDateTime } from "../../../../utils/helper/formatters";

function ConsultationRequestDetail() {
    const navigate = useNavigate();

    const { id } = useParams();
    const { data, isLoading, error } = useGetExpertRequestsDetailsQuery({
        id: id,
    });
    const backToList =()=>{
        navigate(`/experts/consultation-list`);
    }
    const attachments =
        data?.images?.map((attachment) => ({
            name: attachment.name,
            url: attachment.url,
        })) ?? [];

    return (
        <main className="flex flex-col items-center rounded-none">
            <header className="flex min-h-[139px] w-full flex-col justify-center self-stretch px-72 py-14 text-center text-3xl font-bold leading-none text-black max-md:max-w-full max-md:px-5">
                <h1>상담 요청 상세</h1>
            </header>
            <section className="flex w-full max-w-[1000px] flex-col border border-solid border-neutral-200 bg-white px-12 py-10 max-md:max-w-full max-md:px-5">
                <RequestInfo
                    requestType={data?.requestType ?? ""}
                    date={formatDateTime(data?.createdAt) ?? ""}
                    briefRequest="컬러수정, 위치변경"
                    company={data?.companyName ?? ""}
                    contactPerson={data?.contactName ?? ""}
                    email={data?.email ?? ""}
                    phone={data?.phoneNumber ?? ""}
                />
                <DetailContent content={data?.requestDetails ?? ""} />
                <AttachmentList files={attachments} />
            </section>
            <div className="mb-20 flex justify-center">
                <Button
                    className="mt-10 min-h-[50px] w-80 max-w-full gap-2.5 self-stretch whitespace-nowrap rounded-md border border-solid border-neutral-300 bg-white px-4 text-center text-base font-bold leading-none text-zinc-800"
                    label="목록으로"
                    onClick={backToList}
                />
            </div>
        </main>
    );
}

export default ConsultationRequestDetail;
