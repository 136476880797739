import React from "react";
import AIGeneratedLogoDesignCard from "./AIGeneratedLogoDesignCard";
import FileFormatRecommendation from "./FileFormatRecommendation";
import AILogoDesignNotice from "./AILogoDesignNotice";

const pricingData = [
  {
    title: "PNG / JPG",
    price: "29,900원",
    features: [
      "흰색 또는 투명한 배경의 로고 이미지 파일",
      "고해상도 이미지 파일 포맷",
      "개인 또는 상업적 용도로 사용 가능",
      "구매 후 로고 변경이 어려움",
      "바로 다운로드하여 사용 가능",
    ],
  },
  {
    title: "벡터 파일 (.ai)",
    price: "79,900원",
    features: [
      "ai가 생성한 레퍼런스를 전문 디자이너가 벡터화하여 제공",
      "작업 난이도에 따라 0.5일~3일의 시간이 소요",
      "1개의 시안 진행, 최대 2회까지 수정 요청 가능",
      "작업 요청 시 컬러, 위치, 크기 변경 요청 가능",
      "개인 또는 상업적 용도로 사용 가능",
      "구매 후 로고 변경이 어려움",
      "작업물은 이메일로 전송",
    ],
  },
  {
    title: "디자인 리터치 벡터 파일 (.ai)",
    price: "165,000원",
    features: [
      "ai가 생성한 레퍼런스를 전문 디자이너가 리터치하여 벡터파일로 제공",
      "작업 난이도에 따라 0.5일~3일의 시간이 소요",
      "1개의 시안 진행, 최대 2회까지 수정 요청 가능",
      "작업 요청 시 수정하고자 하는 아이디어 제시 가능",
      "개인 또는 상업적 용도로 사용 가능",
      "구매 후 로고 변경이 어려움",
      "작업물은 이메일로 전송",
    ],
  },
];

function AIGeneratedLogoDesign() {
  return (
    <section className="flex flex-col self-center mt-9 w-full max-w-[1240px] max-md:max-w-full">
      <div className="flex flex-col justify-center items-center px-2.5 pt-10 pb-8 w-full font-bold bg-indigo-700 rounded-xl max-md:max-w-full">
        <h2
          data-layername="인공지능생성로고디자인"
          className="text-2xl leading-none text-white"
        >
          인공 지능 생성 로고 디자인
        </h2>
        <p
          data-layername="로고로지에서Ai로쉽고빠르게완벽한디자인매칭을경험해보세요"
          className="mt-2.5 text-base leading-none text-neutral-200 max-md:max-w-full"
        >
          <span className="font-semibold text-neutral-200">로고로지에서</span>
          <span className="font-semibold text-neutral-200">
            {" "}
            AI로 쉽고 빠르게 완벽한 디자인 매칭을 경험해 보세요.
          </span>
        </p>
      </div>
      <div className="flex flex-col w-full border border-solid border-slate-100 max-md:max-w-full">
        <div className="flex gap-5 justify-center items-center px-12 pt-12 pb-9 w-full max-md:px-5 max-md:max-w-full">
          <div className="flex flex-wrap gap-5 justify-center self-stretch my-auto min-h-[438px] min-w-[240px] max-md:max-w-full">
            {pricingData.map((plan, index) => (
              <AIGeneratedLogoDesignCard  statusClassName="bg-yellow-200" key={index} {...plan} />
            ))}
            <FileFormatRecommendation />
            <AILogoDesignNotice />
          </div>
        </div>
      </div>
    </section>
  );
}

export default AIGeneratedLogoDesign;
