import * as React from "react";
import Input from "../../../components/common/FormInput/Input";
import Button from "../../../components/common/Button/Button";

export default function ShippingForm() {
  const [recipientName, setRecipientName] = React.useState("");

  const handleInputChange = (e) => {
    setRecipientName(e.target.value);
  };

  return (
    <form className="flex flex-col mt-5 w-full text-base max-md:max-w-full">
      <div className="flex flex-col w-full min-h-[80px] max-md:max-w-full">
        <label htmlFor="recipient-name" className="font-bold text-neutral-700">
          수령인 이름
        </label>
        <Input
          placeholder="수령인 이름을 입력해주세요."
          value={recipientName}
          onChange={handleInputChange}
        />
      </div>
      <div className="flex flex-col mt-8 w-full min-h-[80px] max-md:max-w-full">
        <label htmlFor="contact" className="font-bold text-neutral-700">
          연락처
        </label>
        <Input
          type="tel"
          id="contact"
          placeholder="연락처를 입력해주세요."
          value={recipientName}
          onChange={handleInputChange}
        />
      </div>
      <div className="flex flex-col mt-8 w-full max-md:max-w-full">
        <label htmlFor="address" className="font-bold text-neutral-700">
          주소
        </label>
        <div className="flex gap-2.5 items-start self-start mt-2.5">
          <Input
            value={recipientName}
            onChange={handleInputChange}
            type="text"
            className="gap-2.5 self-stretch px-4 rounded-md border border-solid bg-stone-50 border-stone-50 min-h-[50px] text-zinc-500 max-w-[223px]"
            id="postal-code"
            placeholder="우편 번호가 출력됩니다."
          />
          <Button
            label={" 주소 검색"}
            className="gap-2.5 self-stretch px-8 font-semibold text-white rounded-md bg-neutral-800 min-h-[50px] max-md:px-5"
          />
        </div>
        <Input
          value={recipientName}
          onChange={handleInputChange}
          type="text"
          id="address-base"
          placeholder="기본 주소가 출력됩니다."
        />
        <Input
          value={recipientName}
          onChange={handleInputChange}
          type="text"
          id="address-detail"
          placeholder="나머지 주소를 입력해주세요."
        />
      </div>
      <div className="flex flex-col mt-8 w-full min-h-[80px] max-md:max-w-full">
        <label
          htmlFor="shipping-message"
          className="font-bold text-neutral-700"
        >
          배송 메세지
        </label>
        <Input
          value={recipientName}
          onChange={handleInputChange}
          type="text"
          id="shipping-message"
          placeholder="배송메세지 입력해주세요. (최대 40자)"
          maxLength={40}
        />
      </div>
    </form>
  );
}
