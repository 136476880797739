import React, { useState, useEffect } from "react";
import ImageListItem from "./ImageListItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useSelector } from "react-redux";
import { useGetListHistoryOrderLogoMutation } from "../../services/apiSlice";
import { formatDateTime } from "../../utils/helper/formatters";

function ImageList({onSelectId}) {
    const renderRadio = () => {
        return (
            <Radio
                sx={{
                    color: "#3533FF",
                    "&.Mui-checked": {
                        color: "#3533FF",
                    },
                }}
            />
        );
    };

    const [selectedId, setSelectedId] = useState(null); 

    const [page, setPage] = useState(1);

    const { userData, accessToken } = useSelector((state) => state.common);
    const userId = userData?.userID || null;
    const [getListOrderLogo, { data }] = useGetListHistoryOrderLogoMutation();
    useEffect(() => {
        const fetchLogosList = async () => {
            await getListOrderLogo({
                page: page,
                userId: userId,
            });
        };
        fetchLogosList();
    }, [getListOrderLogo, page]);

    const handleRadioChange = (event) => {
        const id = event.target.value;
        setSelectedId(id); 
        if (onSelectId) onSelectId(id); 
    };

    const renderContentRadio = (imageSrc, title, date, extension) => {
        return (
            <div className="my-auto flex min-w-[240px] items-center gap-5 self-stretch text-base leading-none text-zinc-800">
                <img
                    src={imageSrc}
                    alt={title}
                    className="my-auto aspect-[1.33] w-[120px] shrink-0 self-stretch rounded object-contain"
                />
                <div className="my-auto flex items-center gap-5 self-stretch max-md:gap-2">
                    <div className="my-auto flex flex-col justify-center self-stretch font-bold max-md:min-w-[70px]">
                        <h3>작업명</h3>
                        <p className="mt-4">구매 날짜</p>
                        <p className="mt-4">확장자</p>
                    </div>
                    <div className="my-auto flex flex-col justify-center self-stretch">
                        <p className="truncate max-md:max-w-[100px]">{title}</p>
                        <p className="mt-4">{date}</p>
                        <p className="mt-4">{extension}</p>
                    </div>
                </div>
            </div>
        );
    };
    return (
        <section className="mt-5 flex max-w-[450px] flex-col">
            <FormLabel
                id="demo-radio-buttons-group-label"
                className="text-base font-bold leading-none text-neutral-700"
            >
                로고 파일 등록하기
            </FormLabel>

            <FormControl>
                <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue={1}
                    value={selectedId} 
                    onChange={handleRadioChange} 
                    name="radio-buttons-group"
                >
                    {(data?.data || []).map((item, index) => (
                        <div key={index}>
                        {item?.logo && <FormControlLabel
                                className="mt-4"
                                value={item?.logo?.id}
                                control={renderRadio()}
                                label={renderContentRadio(
                                    item?.logo?.filePath,
                                    item?.logo?.businessName ?? "_",
                                    formatDateTime(item?.order?.createdAt) ??
                                        "_",
                                    item?.order?.receivedOrderType ?? "_",
                                )}
                                onChange={() => {
                                    if (onSelectId) {
                                      onSelectId(item?.logo?.id); 
                                    }
                                  }}
                            />}
                        </div>
                    ))}
                </RadioGroup>
            </FormControl>
        </section>
    );
}


export default ImageList;
