import React from "react";

function FileFormatRecommendation() {
  const recommendations = [
    {
      title: "해상도에 영향 없음",
      description:
        "벡터 파일은 크기를 얼마든지 늘리거나 줄여도 품질이 떨어지지 않습니다.",
    },
    {
      title: "색상 변경 자유로움",
      description:
        "추후 로고의 색상을 변경하고 싶으실 때, 벡터 파일을 이용하면 간편하게 수정이 가능합니다.",
    },
    {
      title: "인쇄와 제작에 최적화",
      description:
        "명함, 팸플릿, 배너 등 다양한 사이즈와 매체에 인쇄할 때 선명하게 출력됩니다.",
    },
    {
      title: "다양한 파일 포맷 변환 용이",
      description:
        "벡터 파일은 JPG, PNG, PDF 등 다른 형식으로 손쉽게 변환이 가능합니다.",
    },
    {
      title: "수정 및 보완 편리",
      description:
        "추후에 로고 디자인을 수정하거나 보완할 경우 벡터 파일을 이용하면 보다 효율적이고 정확한 작업이 가능합니다.",
    },
  ];

  return (
    <div className="flex overflow-hidden flex-col px-8 py-6 mt-5 w-full rounded-md bg-slate-50 max-md:px-5 max-md:max-w-full">
      <div className="flex flex-wrap justify-between items-center w-full max-md:max-w-full">
        <div className="flex flex-wrap flex-1 shrink gap-1.5 items-center self-stretch my-auto basis-0 min-w-[240px] max-md:max-w-full">
          <div className="flex items-center self-stretch my-auto text-lg font-semibold leading-none text-main-color">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/2b5be0c3fb55f9dfd3705235f7251d4e0aa489827719b9e30303fbd0fac0df15?placeholderIfAbsent=true&apiKey=3ac17b61e0c94c2b87d841ac8b4d3afd"
              alt="Recommendation icon"
              className="object-contain shrink-0 self-stretch my-auto w-6 aspect-square"
            />
            <span className="self-stretch my-auto">확장자 추천</span>
          </div>
          <div className="self-stretch my-auto text-base leading-none text-zinc-800">
            벡터 파일(.ai) 사용을 추천드립니다!
          </div>
        </div>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/de2b5fb5e66daad1a08e1de786fa7dac9be501c823a9cb00df91588c28d1faf1?placeholderIfAbsent=true&apiKey=3ac17b61e0c94c2b87d841ac8b4d3afd"
          alt="Close icon"
          className="object-contain shrink-0 self-stretch my-auto w-6 aspect-square"
        />
      </div>
      <div className="flex flex-col mt-5 w-full text-sm max-md:max-w-full">
        <div className="flex flex-wrap gap-3 items-start w-full">
          {recommendations.map((rec, index) => (
            <div
              key={index}
              className="flex flex-col flex-1 shrink basis-1/2 min-w-[240px] max-w-[45%]"
            >
              <div className="font-bold leading-loose text-zinc-800">
                <span className="">・</span>
                {rec.title}
              </div>
              <div className="leading-5 text-stone-500">{rec.description}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default FileFormatRecommendation;
