import React from "react";
import images from "./../../../images";
import Button from "../../../components/common/Button/Button";

function CheckApplyBusinessCard() {
  return (
    <main className="flex overflow-hidden flex-col items-center bg-white max-md:px-5">
      <img
        loading="lazy"
        src={images.businessCardIcon}
        alt="Business Card Icon"
        className="object-contain mt-20 aspect-square w-[50px] max-md:mt-10"
      />
      <h1 className="mt-6 text-3xl font-bold leading-none text-center text-main-color">
        명함 시안 확인
      </h1>
      <p className="mt-3.5 text-2xl leading-none text-center text-zinc-800">
        입력하신 정보를 확인해보세요.
      </p>
      <section className="flex flex-col items-center max-md:px-5">
        <img
          loading="lazy"
          src={images.businessCarFrontPreview}
          alt="Business Card Front Preview"
          className="object-contain mt-10 max-w-full aspect-[1.8] w-[468px]"
        />
        <img
          loading="lazy"
          src={images.businessCarBackPreview}
          alt="Business Card Back Preview"
          className="object-contain mt-5 max-w-full aspect-[1.8] w-[468px]"
        />
        <div className="flex gap-2.5 items-start mt-10  max-w-full text-base font-bold leading-none text-center w-[468px]">
          <Button
            label={"정보 다시 입력"}
            className="flex-1 shrink gap-2.5 self-stretch px-4 bg-white rounded-md border border-solid border-neutral-300 min-h-[50px] text-zinc-800"
          />
          <Button
            label={"선택한 시안으로 구매"}
            className="flex-1 shrink gap-2.5 self-stretch px-4 text-white bg-indigo-600 rounded-md min-h-[50px]"
          />
        </div>
        <Button
          label={"다시 만들기"}
          className="mt-11 mb-20 text-base font-bold leading-none text-center text-zinc-800 max-md:my-10"
        />
      </section>
    </main>
  );
}

export default CheckApplyBusinessCard;
