import * as React from "react";

export default function NotificationItem({ number, title, date }) {
  return (
    <>
      <article className="flex flex-wrap gap-8 items-center p-8 w-full border-b border-neutral-200 max-md:px-5 max-md:max-w-full">
        <h2 className="self-stretch my-auto text-xl font-bold text-indigo-950">
          {number}
        </h2>
        <div className="flex flex-col self-stretch my-auto text-base min-w-[240px]">
          <h3 className="font-medium text-zinc-800">{title}</h3>
          <time className="mt-2.5 text-neutral-700">{date}</time>
        </div>
      </article>
    </>
  );
}
