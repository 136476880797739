import axios from "axios";
import { createApi } from "@reduxjs/toolkit/query/react";
import { authEndpoints } from "./endpoints/authEndpoints";
import { dataEndpoints } from "./endpoints/dataEndpoints";

const axiosInstance = axios.create();

const axiosBaseQuery =
    ({ baseUrl }) =>
    async ({ url, method, data, params }) => {
        const accessToken = sessionStorage.getItem("accessToken");
        const refreshToken = sessionStorage.getItem("refreshToken");

        try {
            const result = await axiosInstance({
                url: baseUrl + url,
                method,
                data,
                params,
                headers: {
                    Authorization: `Bearer ${accessToken ? accessToken : refreshToken}`,
                },
            });
            return { data: result.data };
        } catch (axiosError) {
            let err = axiosError;
            if (err.response?.status === 401 && refreshToken) {
                try {
                    const refreshResponse = await axiosInstance.post(
                        `${baseUrl}/auth/refresh-token`,
                        { token: refreshToken },
                    );

                    const newAccessToken = refreshResponse.data.accessToken;
                    sessionStorage.setItem("accessToken", newAccessToken);

                    const retryResult = await axiosInstance({
                        url: baseUrl + url,
                        method,
                        data,
                        params,
                        headers: {
                            Authorization: `Bearer ${newAccessToken}`,
                        },
                    });
                    return { data: retryResult.data };
                } catch (refreshError) {
                    return {
                        error: {
                            status: refreshError.response?.status || 401,
                            data:
                                refreshError.response?.data ||
                                "Failed to refresh token",
                        },
                    };
                }
            }

            return {
                error: {
                    status: err.response?.status,
                    data: err.response?.data || err.message,
                },
            };
        }
    };

export const apiSlice = createApi({
    reducerPath: "api",
    baseQuery: axiosBaseQuery({
        baseUrl: process.env.REACT_APP_BASE_URL,
    }),
    endpoints: (builder) => ({
        ...authEndpoints(builder),
        ...dataEndpoints(builder),
    }),
});

export const {
    // auth
    useLoginMutation,
    useLogoutMutation,
    useLoginWithGoogleMutation,
    useGenerateOTPMutation,
    useVerifyOTPMutation,
    // other
    useGetBusinessCardsQuery,
    useGetBusinessCardsImagesQuery,
    useLazyGetBusinessCardsImagesQuery,
    useGetImageListByTypeMutation,
    useGetLogoInfoPaymentQuery,
    usePostOrdersMutation,
    usePostLogosMutation,
    useGetLogosListQuery,
    useGetBusinessCardTypeQuery,
    useGetFileFormatQuery,
    //business cards
    usePostBusinessCardsMutation,
    useGetBusinessCardsByIdQuery,
    usePostBusinessCardImagesToS3Mutation,
    // order
    usePostBusinessCardsOrdersMutation,
    usePostOrdersLogosMutation,
    //payment
    usePostKakaoInitializeMutation,
    usePostTossPaymentInitializeMutation,
    usePostNaverPayInitializeMutation,
} = apiSlice;
