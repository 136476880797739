export const termsOfUse = `
제 1장. 총칙

제 1조. 목적이
약관은 로지디자인웍스 (이하 “회사”)가 운영하는 로고로지 인터넷 사이트(http://www.logologee.com )를 통하여 제공하는 전자상거래 관련 서비스(이하 “서비스”)를 이용함에 있어 회사와 이용자의 권리, 의무 및 책임사항을 규정함을 목적으로 합니다.
제2조. 정의
“서비스”란 로지디자인웍스가 운영하는 “로고로지”에서 재화 또는 용역(이하 “재화 등”이라 함)을 이용자에게 제공하기 위하여 컴퓨터 또는 모바일 등 정보통신 설비를 이용하여 재화 등을 거래할 수 있도록 설정한 가상의 영업장을 말하며, 아울러 사이버 회사을 운영하는 사업자의 의미로도 사용합니다.
“이용자”란 “서비스”에 접속하여 이 약관에 따라 회사가 제공하는 서비스를 받는 회원 및 비회원을 말합니다.
“디자인”이라 함은 회사가 “서비스”를 통해 제공하는 소프트웨어를 활용하여 제작된 결과물로서, 복수의 “사진, 이미지, 벡터, 영상, 아이콘, 도형, 폰트, 표, 음원, 애니메이션, 일러스트레이션 등 (이하 “디자인 요소”) 으로 구성 되어 있으며, “서비스” 내에서 제공되는 디자인을 편집하는 소프트웨어(이하 “편집기”라 한다)를 통해 편집이 가능합니다. 또한 이용자가 “편집기”를 통해 제작한 결과물도 디자인에 속하며, 이는 특별히 “사용자 디자인”이라고 칭합니다.
제
3조. 이용약관 효력 및 변경
“회사”는 이 약관의 내용과 상호 및 대표자 성명, 사업장 소재지 주소(소비자의 불만을 처리할 수 있는 곳의 주소를 포함), 전화번호, 팩스번호, 전자 우편주소, 사업자등록번호, 통신판매업신고번호, 개인정보관리 책임자 등을 이용자가 쉽게 알 수 있도록 로고로지 하단 서비스화면에 게시합니다. 다만, 약관의 내용은 이용자가 연결화면을 통하여 볼 수 있도록 할 수 있습니다.
“회사”는 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제에 관한 법률, 전자문서 및 전자거래기본법, 전자금융거래법, 전자서명법, 정보통신망 이용촉진 및 정보 보호등에 관한 법률, 방문판매 등에 관한 법률, 소비자보호법 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 적용일자 7일 이전부터 로고로지 홈페이지에 공지합니다. 다만, 이용자에게 불리하게 약관 내용을 변경하는 경우에는 최소 30일 이상의 사전 유예기간을 두고 공지합니다. 이 경우 회사는 개정 전 내용과 개정 후 내용을 명확하게 비교하여 이용자가 알기 쉽도록 표시합니다. 또한 가입 시 명기된 이메일주소를 통해 개정 내용을 공지할 수 있으며, 홈페이지 게재와 효력을 같이합니다.
이 약관에서 청하지 아니한 사항과 이 약관의 해석에 관하여는 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제 등에 관한 법률, 공정거래위원회가 정하는 전자상거래 등에서의 소비자 보호 지침 및 관계법령 또는 상관례에 따릅니다.
제 2장. 회원의 서비스제 4조. 서비스의 제공 및 변경
회사는 다음과 같은 서비스를 제공합니다.
1)재화 또는 용역에 대한 정보 제공 및 구매 계약의 체결
2)구매 계약이 체결된 재화 또는 용역의 배송
3)기타 회사가 정하는 업무
회사는 재화 또는 용역의 품절 또는 기술적 사양의 변경 등의 경우에는 장차 체결되는 계약에 의해 제공할 재화 또는 용역의 내용을 변경할 수 있습니다. 이 경우에는 변경된 재화 또는 용역의 내용 및 제공일자를 명시하여 현재의 재화 또는 용역의 내용을 게시한 곳에 즉시 공지합니다.
회사가 제공하기로 이용자와 계약을 체결한 서비스의 내용을 재화등의 품절 또는 기술적 사양의 변경 등의 사유로 변경할 경우에는 그 사유를 이용자에게 통지 가능한 방법으로 즉시 통`;

export const privacyPolicy = `
제 1장. 총칙

제 1조. 목적
이 약관은 로지디자인웍스 (이하 “회사”)가 운영하는 로고로지 인터넷 사이트(http://www.logologee.com )를 통하여 제공하는 전자상거래 관련 서비스(이하 “서비스”)를 이용함에 있어 회사와 이용자의 권리, 의무 및 책임사항을 규정함을 목적으로 합니다.

제 2조. 정의
“서비스”란 로지디자인웍스가 운영하는 “로고로지”에서 재화 또는 용역(이하 “재화 등”이라 함)을 이용자에게 제공하기 위하여 컴퓨터 또는 모바일 등 정보통신 설비를 이용하여 재화 등을 거래할 수 있도록 설정한 가상의 영업장을 말하며, 아울러 사이버 회사을 운영하는 사업자의 의미로도 사용합니다.
“이용자”란 “서비스”에 접속하여 이 약관에 따라 회사가 제공하는 서비스를 받는 회원 및 비회원을 말합니다.
“디자인”이라 함은 회사가 “서비스”를 통해 제공하는 소프트웨어를 활용하여 제작된 결과물로서, 복수의 “사진, 이미지, 벡터, 영상, 아이콘, 도형, 폰트, 표, 음원, 애니메이션, 일러스트레이션 등 (이하 “디자인 요소”) 으로 구성 되어 있으며, “서비스” 내에서 제공되는 디자인을 편집하는 소프트웨어(이하 “편집기”라 한다)를 통해 편집이 가능합니다. 또한 이용자가 “편집기”를 통해 제작한 결과물도 디자인에 속하며, 이는 특별히 “사용자 디자인”이라고 칭합니다.
제 3조. 이용약관 효력 및 변경
“회사”는 이 약관의 내용과 상호 및 대표자 성명, 사업장 소재지 주소(소비자의 불만을 처리할 수 있는 곳의 주소를 포함), 전화번호, 팩스번호, 전자 우편주소, 사업자등록번호, 통신판매업신고번호, 개인정보관리 책임자 등을 이용자가 쉽게 알 수 있도록 로고로지 하단 서비스화면에 게시합니다. 다만, 약관의 내용은 이용자가 연결화면을 통하여 볼 수 있도록 할 수 있습니다.
“회사”는 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제에 관한 법률, 전자문서 및 전자거래기본법, 전자금융거래법, 전자서명법, 정보통신망 이용촉진 및 정보 보호등에 관한 법률, 방문판매 등에 관한 법률, 소비자보호법 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 적용일자 7일 이전부터 로고로지 홈페이지에 공지합니다. 다만, 이용자에게 불리하게 약관 내용을 변경하는 경우에는 최소 30일 이상의 사전 유예기간을 두고 공지합니다. 이 경우 회사는 개정 전 내용과 개정 후 내용을 명확하게 비교하여 이용자가 알기 쉽도록 표시합니다. 또한 가입 시 명기된 이메일주소를 통해 개정 내용을 공지할 수 있으며, 홈페이지 게재와 효력을 같이합니다.
이 약관에서 청하지 아니한 사항과 이 약관의 해석에 관하여는 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제 등에 관한 법률, 공정거래위원회가 정하는 전자상거래 등에서의 소비자 보호 지침 및 관계법령 또는 상관례에 따릅니다.
제 2장. 회원의 서비스
제 4조. 서비스의 제공 및 변경
회사는 다음과 같은 서비스를 제공합니다.
1)재화 또는 용역에 대한 정보 제공 및 구매 계약의 체결
2)구매 계약이 체결된 재화 또는 용역의 배송
3)기타 회사가 정하는 업무
회사는 재화 또는 용역의 품절 또는 기술적 사양의 변경 등의 경우에는 장차 체결되는 계약에 의해 제공할 재화 또는 용역의 내용을 변경할 수 있습니다. 이 경우에는 변경된 재화 또는 용역의 내용 및 제공일자를 명시하여 현재의 재화 또는 용역의 내용을 게시한 곳에 즉시 공지합니다.
회사가 제공하기로 이용자와 계약을 체결한 서비스의 내용을 재화등의 품절 또는 기술적 사양의 변경 등의 사유로 변경할 경우에는 그 사유를 이용자에게 통지 가능한 방법으로 즉시 통`;
