import React from "react";
import images from "../../images"
import Button from "../../components/common/Button/Button";

function HeroSection() {
  return (
    <section>
      <div className="relative">
        <Button
          className="flex justify-center absolute top-[76%] left-[10%] py-2 !px-0 gap-2 max-w-full text-base font-bold leading-none text-white bg-main-color rounded-[100%] !w-[160px] h-[54px]"
          label="로고 만들기"
          icon={images.arrowLWIcon}
          widthIcon={10}
        />
        <img
          loading="lazy"
          src={images.bannerImg}
          alt="bannerImg"
          className="object-contain w-full rounded-none aspect-[2.5] max-md:max-w-full"
        />
      </div>
    </section>
  );
}

export default HeroSection;
