import React from "react";
import Button from "../../../components/common/Button/Button";
import CommonTitleSection from "../../../components/common/CommonTitleComplete/CommonTitleComplete";
import images from "../../../images";

const ConsultationRequestCompleted = () => (
  <div className="flex flex-col justify-center align-items-center text-center mb-40">
    <main className="max-w-[500px] mx-auto">
      <CommonTitleSection
        icon={images.businessCardPurchaseIcon}
        title="상담 신청 완료!"
        subtitle="커스텀 명함 상담 신청이 완료되었습니다."
        titleColor="text-indigo-600"
        subtitleColor="text-zinc-800"
      />
      <div className="py-8 mt-10 max-w-full text-base leading-6 text-center rounded-md border border-gray-200 border-solid text-neutral-700 w-[500px] max-md:max-w-full">
        전문가 상담 요청내용이 접수되어 확인 후 상담을 통해 진행됩니다.
        <br />
        <span className="font-bold">[마이페이지 - 인쇄주문내역]</span> 에서
        접수하신 내용을 확인하실 수 있습니다.
      </div>
      <div className="flex flex-col justify-center mt-9 max-w-full text-base font-bold leading-none text-center ">
        <div className="flex flex-wrap gap-2.5 items-start w-full max-md:max-w-full">
          <Button
            label={"파일 다운로드"}
            className="grow shrink gap-2.5 self-stretch px-4 text-white bg-indigo-600 rounded-md min-h-[50px] min-w-[240px] w-[196px]"
          />
        </div>
        <a href="#" className="self-center mt-9 text-zinc-800">
          메인으로
        </a>
      </div>
    </main>
  </div>
);

export default ConsultationRequestCompleted;
