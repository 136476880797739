import React from "react";
import InputField from "../../components/common/Input/InputField";
import Button from "../../components/common/Button/Button";
import { Tab, Tabs } from "@mui/material";
import { a11yProps } from "../../utils/helper/function";
import StepHeader from "../LayoutCommon/StepHeader";
import TabPanel from "../../components/common/Tabs";
import { useTheme } from "@emotion/react";
import ConsultationDetails from "./ConsultationDetails";

const files = [
    { name: "logo001.png" },
    { name: "logo002.png" },
    { name: "logo003.png" },
    { name: "logo004.png" },
    { name: "logo005.png" },
];

function ExpertConsultationForm() {
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    return (
        <main className="flex flex-col overflow-hidden bg-white px-20 py-24">
            <section className="flex min-h-[192px] w-full flex-col justify-center px-72 py-14 text-center font-bold leading-none max-md:max-w-full max-md:px-5">
                <div className="flex w-full flex-col items-center max-md:max-w-full">
                    <h1 className="text-3xl text-black">전문가 고용 및 상담</h1>
                    <div className="flex justify-center">
                        <Button
                            className={
                                "mt-5 w-[120px] gap-2 self-stretch rounded-[100px] border border-solid border-indigo-400 px-4 py-2.5 text-sm text-main-color"
                            }
                            label={"신청 내역 보기"}
                        />
                    </div>
                </div>
            </section>

            <div className="mt-2.5 flex w-full flex-col self-center max-md:max-w-full">
                <div className="flex justify-center border-b-[1px]">
                    <Tabs
                        value={value}
                        // onChange={handleChange}
                        variant="standard"
                        aria-label="full width tabs example"
                        textColor="inherit"
                        indicatorColor="primary"
                        className="rounded-[10px] pb-0"
                        sx={{
                            "& .MuiTab-root.Mui-selected": {
                                color: "#2D375B",
                                borderRadius: "10px",
                                fontWeight: 700,
                            },
                            "& .MuiTab-root": {
                                padding: "5px 20px",
                            },
                            "& .MuiTabs-indicator": {
                                backgroundColor: "#ffffff",
                                border: "1px dashed #2D375B",
                            },
                            color: "#666A79",
                            bgcolor: "#FFFFFF",
                            padding: "10px",
                        }}
                    >
                        <Tab
                            sx={{ minHeight: "36px", height: "36px" }}
                            label="전문가에게 상담하기"
                            {...a11yProps(0)}
                        />
                        <Tab
                            sx={{ minHeight: "36px", height: "36px" }}
                            label="전문가를 고용하기"
                            {...a11yProps(1)}
                        />
                    </Tabs>
                </div>
                <TabPanel
                    value={value}
                    index={0}
                    dir={theme.direction}
                    sx={{ padding: 0 }}
                >
                    <form className="flex justify-center">
                        <div className="max-w-[1000px]">
                            <div className="mt-5 flex w-full flex-col justify-center self-center rounded-md border border-solid border-neutral-200 px-12 py-10 text-base leading-none max-md:max-w-full max-md:px-5">
                                <div className="flex w-full flex-col rounded-xl bg-white max-md:max-w-full">
                                    <StepHeader
                                        step="1"
                                        title="연락받으실 정보를 입력하세요."
                                    />

                                    <div className="grid w-full grid-cols-2 items-start gap-9 max-md:max-w-full">
                                        <InputField
                                            label="회사명"
                                            placeholder="회사명을 입력해주세요."
                                        />
                                        <InputField
                                            label="이메일"
                                            placeholder="이메일 주소를 입력해주세요."
                                            type="email"
                                        />
                                        <InputField
                                            label="담당자 성함"
                                            placeholder="담당자 성함을 입력해주세요."
                                        />
                                        <InputField
                                            label="연락처"
                                            placeholder="연락처를 입력해주세요."
                                            type="tel"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="mt-5">
                                <StepHeader
                                    step="2"
                                    title="상담 요청할 내용을 작성하세요."
                                />
                                <ConsultationDetails files={files} />
                            </div>
                            <div className="flex justify-center">
                                <Button
                                    className={
                                        "mt-14 w-80 max-w-full gap-2.5 self-center whitespace-nowrap rounded-md bg-indigo-600 px-4 py-4 text-center text-base font-bold leading-none text-white max-md:mt-10"
                                    }
                                    label={"신청하기"}
                                />
                            </div>
                        </div>
                    </form>
                </TabPanel>
            </div>
        </main>
    );
}

export default ExpertConsultationForm;
