import React, { useState, useEffect } from "react";
import NavItem from "../LandingPage/NavItem";
import MobileMenu from "../LandingPage/MobileMenu";
import Logo from "../LandingPage/Logo";
import LoginButton from "../LandingPage/LoginButton";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { clearAccessToken } from "../../redux/featuresSlice/commonSlice";
import images from "../../images";

const navItems = [
    { label: "둘러보기", path: "/view-page", isActive: true },
    { label: "로고만들기", path: "/logo-creation", isActive: false },
    { label: "명함만들기", path: "/card-type", isActive: false },
    {
        label: "전문가 고용 및 상담",
        path: "/experts/consultation",
        isActive: false,
    },
    { label: "고객지원", path: "/customer-faq", isActive: false },
];

const Header = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const accessToken = useSelector((state) => state.common.accessToken);

    const handleItemClick = (index, path) => {
        setActiveIndex(index);
        setIsMobileMenuOpen(false);
        navigate(path);
    };

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    const handleLogout = () => {
        dispatch(clearAccessToken());
        navigate("/login");
    };

    return (
        <nav className="fixed z-50 w-full bg-white">
            <div className="relative mx-20 flex justify-between bg-white">
                <Logo />

                <div className="hidden flex-wrap items-center gap-10 text-lg font-bold leading-none text-zinc-800 lg:flex">
                    {navItems.map((item, index) => (
                        <NavItem
                            key={index}
                            {...item}
                            onClick={() => handleItemClick(index, item.path)}
                        />
                    ))}
                </div>
                <div className="flex items-center">
                    {accessToken ? (
                        <div className="flex gap-5">
                            <img
                                className="h-5 w-5 hover:cursor-pointer"
                                src={images.notiIcon}
                                alt={"notiIcon"}
                                onClick={() => navigate("/notification")}
                            />
                            <img
                                className="h-5 w-5 hover:cursor-pointer"
                                src={images.profileIcon}
                                alt={"profileIcon"}
                                // onClick={()=>navigate("/")}
                            />
                            <img
                                className="h-5 w-5 hover:cursor-pointer"
                                src={images.logoutIcon}
                                alt={"logoutIcon"}
                                onClick={handleLogout}
                            />
                        </div>
                    ) : (
                        <LoginButton />
                    )}
                    <div className="lg:hidden">
                        <MobileMenu
                            isOpen={isMobileMenuOpen}
                            toggleMenu={toggleMobileMenu}
                        />
                    </div>
                </div>

                {isMobileMenuOpen && (
                    <div className="absolute left-0 right-0 top-full bg-white shadow-md lg:hidden">
                        {navItems.map((item, index) => (
                            <NavItem
                                key={index}
                                {...item}
                                isActive={index === activeIndex}
                                onClick={() => handleItemClick(index)}
                                width="w-full"
                            />
                        ))}
                    </div>
                )}
            </div>
        </nav>
    );
};

export default Header;
