import React, { useState } from "react";
import PurchaseCompleteTitle from "./PurchaseCompleteTitle";
import PurchaseInfo from "./PurchaseInfo";
import Button from "../../../components/common/Button/Button";
import Image from "../../../components/common/Image/Image";
import PurchaseInfoSecond from "./PurchaseInfoSecond";
import images from "../../../images";

const PurchaseCompletePage = () => {
  const [state, setState] = useState(1);
  return (
    <div className="flex flex-col justify-center align-items-center text-center">
      <main className="max-w-[500px] mx-auto">
        <PurchaseCompleteTitle />
        {state == 1 ? <PurchaseInfo /> : <PurchaseInfoSecond />}
        <div className="flex flex-col justify-center mt-9 max-w-full text-base font-bold leading-none text-center ">
          <div className="flex flex-wrap gap-2.5 items-start w-full max-md:max-w-full">
            <Button
              label={"결제 내역 확인"}
              className="grow shrink gap-2.5 self-stretch px-4 bg-white rounded-md border border-solid border-neutral-300 min-h-[50px] min-w-[240px] text-zinc-800 w-[196px]"
            />
            <Button
              label={"파일 다운로드"}
              className="grow shrink gap-2.5 self-stretch px-4 text-white bg-indigo-600 rounded-md min-h-[50px] min-w-[240px] w-[196px]"
            />
          </div>
          <a href="#" className="self-center mt-9 text-zinc-800">
            메인으로
          </a>
        </div>
      </main>
      <div className="flex overflow-hidden flex-col self-stretch mt-40 w-full bg-stone-300 max-md:mt-10 max-md:max-w-full">
      <img
        loading="lazy"
        src={images.purchaseCompleteFooter}
        alt=""
        className="object-contain self-stretch w-full aspect-[7.19] max-md:mt-10 max-md:max-w-full"
      />
        <img
        loading="lazy"
        src={images.businessCardPurchaseFooter}
        alt=""
        className="object-contain self-stretch  w-full aspect-[7.19] max-md:mt-10 max-md:max-w-full"
      />
      </div>
    </div>
  );
};

export default PurchaseCompletePage;
