import React from 'react';
import LogoUploader from './LogoUploader';
import BusinessCardForm from './BusinessCardForm';
import images from '../../images';

export default function CardCreate() {
    return (
        <div className="flex flex-col min-h-screen">
            <main className="flex-1">
                <div className="flex flex-col justify-center px-72 py-14 w-full text-3xl font-bold leading-none text-center text-black min-h-[139px] max-md:px-5 max-md:max-w-full">
                    <div className="flex flex-col items-center max-w-full w-[878px]">
                        <h1>기업형 타입 : 명함 정보 입력</h1>
                    </div>
                </div>

                <div className="flex flex-col self-center w-full max-md:max-w-full px-20">
                    <div className="flex flex-col justify-center self-start leading-none">
                        <div className="text-2xl font-bold text-main-color">STEP 1.</div>
                        <div className="mt-2.5 text-xl font-semibold text-neutral-700">
                            명함에 들어갈 로고를 첨부해주세요.
                        </div>
                    </div>

                    <LogoUploader />

                    <div className="flex flex-col justify-center self-start mt-10 leading-none">
                        <div className="text-2xl font-bold text-main-color">STEP 2.</div>
                        <div className="mt-2.5 text-xl font-semibold text-neutral-700">
                            명함에 들어갈 정보를 입력해주세요.
                        </div>
                    </div>

                    <BusinessCardForm />

                    <div className="flex flex-wrap gap-2.5 items-start self-center mt-12 max-w-full text-base font-bold leading-none text-center w-[500px] max-md:mt-10">
                        <button
                            type="button"
                            className="grow shrink gap-2.5 self-stretch px-4 py-4 whitespace-nowrap bg-white rounded-md border border-solid border-neutral-300 min-h-[50px] min-w-[240px] text-zinc-800 w-[196px] hover:bg-gray-50 transition-colors"
                        >
                            이전으로
                        </button>
                        <button
                            type="button"
                            className="grow shrink gap-2.5 self-stretch px-4 py-4 text-white bg-indigo-600 rounded-md min-h-[50px] min-w-[240px] w-[196px] hover:bg-indigo-700 transition-colors"
                        >
                            시안에 정보 적용하기
                        </button>
                    </div>
                </div>
                <img
                    loading="lazy"
                    src={images.businessCardLayoutFooter}
                    alt=""
                    className="object-contain self-stretch mt-24 w-full aspect-[7.19] max-md:mt-10 max-md:max-w-full"
                />
            </main>

        </div>
    );
}