import React from "react";

function InputField({ label, description, value, placeholder ,onChange, type,duration,width}) {
  const inputId = label.toLowerCase().replace(/\s+/g, '-');

  return (
    <div className="flex flex-col mt-4 w-full">
      <label htmlFor={inputId} className="text-base font-bold text-neutral-700">
        {label}
      </label>
      {description && (
        <p className="mt-2.5 text-sm text-stone-500">{description}</p>
      )}
      <input
        type={type}
        id={inputId}
        className="gap-2.5 self-stretch px-4 mt-2.5 w-full text-base whitespace-nowrap rounded-md bg-stone-50 min-h-[50px] text-neutral-700"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        width={width}
      />
    </div>
  );
}

export default InputField;