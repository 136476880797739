import React from "react";

function InquiryAnswer({ answerTextLines, imageSrc, imageAlt, date }) {
  return (
    <article className="flex flex-col pt-11 max-w-[900px]">
      <h2 className="w-full text-xl font-semibold leading-none whitespace-nowrap text-zinc-800 max-md:max-w-full">
        답변
      </h2>
      <section className="flex flex-col px-6 py-8 mt-5 w-full text-base rounded-md bg-slate-100 max-md:px-5 max-md:max-w-full">
        <p className="w-full font-medium leading-6 text-zinc-800 max-md:max-w-full">
          {answerTextLines.map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </p>
        <img
          loading="lazy"
          src={imageSrc}
          className="object-contain mt-8 max-w-full aspect-[4.44] w-[200px]"
          alt={imageAlt}
        />
        <time className="mt-8 leading-none text-neutral-700">{date}</time>
      </section>
    </article>
  );
}

export default InquiryAnswer;
