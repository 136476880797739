import React from 'react';

function FileFormatOption({ name, price, features, isSelected, onSelectionChange, title, subtitle, width }) {
  return (
    <div className={`flex flex-col flex-1 shrink items-start px-8 py-9 rounded-md border-2 ${isSelected ? 'text-white bg-indigo-600 border-indigo-600' : 'bg-white border-gray-200'} border-solid basis-0 max-w-[500px] min-h-[385px] w-full max-md:px-5`}>
      <div className="flex items-center">
        <input
          type="checkbox"
          checked={isSelected}
          onChange={onSelectionChange}
          className="w-6 h-6 text-main-color bg-gray-100 border-gray-300 rounded focus:ring-indigo-500"
        />
      </div>

      <div className={`flex flex-col mt-5 max-w-full ${width ? `w-${width}` : ''} `}>
        <div className="flex flex-col justify-start text-start w-full font-bold leading-none whitespace-nowrap min-h-[54px]">
          <div className="text-base">{name}</div>
          <div className="mt-3.5 text-2xl">{price}</div>
          <div className="text-base">{title}</div>
          <div className="mt-3.5 text-2xl">{subtitle}</div>
        </div>
        <div className="flex flex-col mt-8 w-full text-sm leading-5 align-items-center">
          {features.map((feature, index) => (
            <div key={index} className={`flex gap-1.5 items-center ${index > 0 ? 'mt-4' : ''} w-full`}>
              <img
                loading="lazy"
                src={isSelected
                  ? "https://cdn.builder.io/api/v1/image/assets/TEMP/5f113e81084fa1f53bbb3efb973316f76b2694f4d67611d4299bd4ea5693e12f?placeholderIfAbsent=true&apiKey=3ac17b61e0c94c2b87d841ac8b4d3afd"
                  : "https://cdn.builder.io/api/v1/image/assets/TEMP/7053bdc12e161a81f61a0e7676c3b04b977a18924f3251b8bee83829c89392e5?placeholderIfAbsent=true&apiKey=3ac17b61e0c94c2b87d841ac8b4d3afd"}
                alt="Checkmark icon"
                className="object-contain shrink-0 self-stretch my-auto w-3.5 aspect-square"
              />
              <div className="self-stretch my-auto" dangerouslySetInnerHTML={{ __html: feature }} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default FileFormatOption;
