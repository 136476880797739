import React from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';

const CheckboxGroup = ({ cardTypes, handleSelectType }) => {
  return (
    <FormGroup className="flex flex-row gap-10 items-center self-start mt-6 text-lg leading-none text-center whitespace-nowrap text-zinc-500">
      {cardTypes.map((type, index) => (
       <FormControlLabel
          key={index}
          control={
            <Checkbox
              checked={type.active}
              onChange={() => handleSelectType(type)}
              sx={{
                '&.Mui-checked': {
                  color: '#3533ff', 
                },
                '& .MuiSvgIcon-root': { fontSize: 32 } 
              }}

            />
          }
          label={
            <span className={type.active ? "font-bold text-zinc-800" : ""}>
              {type.name}
            </span>
          }
        />
      ))}
    </FormGroup>
  );
};

export default CheckboxGroup;
