import React from "react";
import AIGeneratedLogoDesignCard from "./AIGeneratedLogoDesignCard";

const designServices = [
  {
    title: "전문 로고 디자인",
    price: "29,900원",
    features: [
      "1차 시안 2종",
      "수정 2회",
      "원본 데이터, 저작권 제공",
      "부가세 별도",
      "작업 예상 기한 5일 소요",
    ],
  },
  {
    title: "전문 로고 디자인 서비스",
    price: "1,500,000원",
    features: [
      "1차 시안 : 3종 / 2차 시안 : 2종 / 3차 시안 : 1종",
      "시뮬레이션 이미지 제공",
      "수정 3회",
      "명함 디자인 무료 제작",
      "원본 데이터, 저작권, 로고 가이드 파일 제공",
      "부가세 별도",
      "작업 예상 기한 10일 소요",
    ],
  },
  {
    title: "프리미엄 브랜딩 및 로고디자인",
    price: "견적문의",
    features: [
      "16년차 디자인 전문가 작업",
      "브랜드 전략 기획 및 디자인 제작",
      "오프라인 미팅 필수 진팽",
      "작업 예상 기한 - 기간 협의 필요",
      "견적 문의",
    ],
  },
];

function ProfessionalDesignSection() {
  return (
    <section className="flex flex-col self-center mt-14 w-full max-w-[1240px] max-md:mt-10 max-md:max-w-full">
      <div className="flex flex-col justify-center items-center px-2.5 pt-10 pb-8 w-full bg-indigo-700 rounded-xl max-md:max-w-full">
        <h2
          data-layername="전문로고디자인"
          className="text-2xl font-bold leading-none text-white"
        >
          전문 로고디자인
        </h2>
        <p
          data-layername="로고로지의디자인전문가가로고명함까지직접브랜딩하여디자인합니다"
          className="mt-2.5 text-base font-medium leading-none text-neutral-200 max-md:max-w-full"
        >
          <span className="font-semibold text-neutral-200">
            로고로지의 디자인 전문가
          </span>
          <span className="font-semibold">
            가 로고, 명함 까지 직접 브랜딩하여 디자인합니다.
          </span>
        </p>
      </div>
      <div className="flex flex-wrap justify-center self-stretch gap-5 py-12 pr-14 pl-20 w-full border border-solid bg-slate-50 border-slate-100 max-md:px-5 max-md:max-w-full">
        {designServices.map((service, index) => (
          <AIGeneratedLogoDesignCard
            key={index}
            {...service}
            statusClassName="bg-[#FAC5F5]"
          />
        ))}
      </div>
    </section>
  );
}

export default ProfessionalDesignSection;
