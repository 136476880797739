import React from "react";

const Button = ({
    label,
    className,
    icon,
    fontSize,
    background,
    textColor,
    fontWeight,
    borderColor,
    width,
    height,
    onMouseOver,
    onMouseOut,
    onClick,
    widthIcon,
    iconFirst,
    disabled,
    ...rest
}) => {
    return (
        <button
            {...rest}
            className={` ${fontSize ? `text-${fontSize}` : ""} ${background ? `bg-${background}` : ""} ${textColor ? `text-${textColor}` : ""} ${fontWeight ? `font-${fontWeight}` : ""} ${borderColor ? `border border-${borderColor}` : ""} ${width ? `w-${width}` : ""} ${height ? `h-${height}` : ""} ${disabled ? "cursor-not-allowed border-gray-400 bg-gray-300 text-gray-500 opacity-50" : ""} ${className} flex min-h-[50px] items-center justify-center gap-2 rounded px-4`}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
            onClick={onClick}
            disabled={disabled}
        >
            {iconFirst && (
                <img src={iconFirst} alt="" className="inline-block" />
            )}
            {label && <span>{label}</span>}
            {icon && (
                <img
                    src={icon}
                    alt=""
                    className="inline-block"
                    width={widthIcon}
                />
            )}
        </button>
    );
};

export default Button;
