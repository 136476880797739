import React from 'react';

const OptionSelector = ({ label, options, selectedOption, onSelectOption }) => {
  return (
    <div className="flex flex-col w-full font-bold max-md:max-w-full">
      <div className="text-base text-neutral-700 max-md:max-w-full">{label}</div>
      <div className="flex gap-3 items-center self-start mt-4 text-sm text-zinc-800">
        {options.map((option) => (
          <button
            key={option}
            className={`gap-2.5 self-stretch px-5 py-3.5 my-auto whitespace-nowrap bg-white rounded-md border border-solid ${option === selectedOption
                ? 'text-main-color border-2 border-indigo-600'
                : 'border-stone-300'
              }`}
            onClick={() => onSelectOption(option)}
          >
            {option}
          </button>
        ))}
      </div>
    </div>
  );
};

export default OptionSelector;
