/* eslint-disable no-restricted-globals */

import React from "react";

function NavItem({ label, path, onClick }) {
    return (
        <div
            className={`my-auto flex items-center justify-center gap-2.5 self-stretch cursor-pointer${
                location.pathname.includes(path)
                    ? "border-b-4 border-indigo-600 text-indigo-600"
                    : ""
            } min-h-[80px] whitespace-nowrap max-md:min-h-[40px] max-md:text-xs`}
            onClick={onClick}
        >
            {label}
        </div>
    );
}

export default NavItem;
