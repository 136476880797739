import React from "react";

function InquiryDetailHeader({
  title,
  subtitle,
  status,
  date,
  statusClassName,
}) {
  return (
    <header className="flex gap-8 items-center pb-9 w-full leading-none border-b border-neutral-200 min-h-[120px] max-md:max-w-full">
      <div className="flex flex-col flex-1 mb-10 shrink self-stretch my-auto w-full h-20 basis-0 min-w-[240px] max-md:max-w-full">
        <div className="flex flex-wrap gap-10 justify-between items-center w-full max-md:max-w-full">
          <div className="flex flex-col self-stretch my-auto min-w-[240px] max-md:max-w-full">
            <h2 className="text-base font-bold text-indigo-600">{title}</h2>
            <h1 className="mt-4 text-xl font-medium text-zinc-800 max-md:max-w-full">
              {subtitle}
            </h1>
          </div>
          <span
            className={`gap-2.5 self-stretch px-3 py-2.5 my-auto text-base font-medium text-white bg-indigo-600 rounded ${statusClassName}`}
          >
            {status}
          </span>
        </div>
        <time className="mt-8 text-base text-neutral-700">{date}</time>
      </div>
    </header>
  );
}

export default InquiryDetailHeader;
