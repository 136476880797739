import React, { useState } from "react";
import MyLogoProductList from "./MyLogoProductList";
import MyBusinessCardProductList from "./MyBusinessCardProductList";
import CommonTabs from "../../../components/common/Tabs/CommonTabs";

// const tabs = [{ label: "로고" }, { label: "명함" }];


const tabs = [{ label: "로고" }, { label: "명함" }];
const panels = [
  <MyLogoProductList key="logo" />, 
  <MyBusinessCardProductList key="businessCard" />
];

const MyProductLayout = () => {
  const [activeTab, setActiveTab] = useState(tabs[0].label);
  const [showNewInquiry, setShowNewInquiry] = useState(false);

  const handleTabClick = (tabLabel) => {
    setActiveTab(tabLabel);
    setShowNewInquiry(false);
  };

  return (
    <main className="flex overflow-hidden px-20 w-full flex-col bg-white">
      <nav className="flex flex-wrap p-2 w-full text-base leading-none whitespace-nowrap rounded-md min-h-[58px] max-md:max-w-full">
        <CommonTabs
          labels={tabs.map((tab) => tab.label)} 
          activeTab={activeTab} 
          onTabClick={handleTabClick} 
          panels={panels} 
        />
      </nav>

      <section className="flex flex-col items-center mt-8 w-full">
      </section>
    </main>
  );
};

export default MyProductLayout;
