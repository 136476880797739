import React, { useState, useEffect } from "react";
import NavItem from "../LandingPage/NavItem";
import Logo from "../LandingPage/Logo";
import LoginButton from "../LandingPage/LoginButton";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
    clearAccessToken,
    setImageTypesData,
    setUserData,
} from "../../redux/featuresSlice/commonSlice";
import images from "../../images";
import { setStatusLoading } from "./../../redux/featuresSlice/commonSlice";
import { useGetImageListByTypeMutation } from "../../services/apiSlice";
const navItems = [
    { label: "둘러보기", path: "/view-page", isActive: true },
    { label: "로고만들기", path: "/logo", isActive: false },
    { label: "명함만들기", path: "/card", isActive: false },
    {
        label: "전문가 고용 및 상담",
        path: "/experts/consultation",
        isActive: false,
    },
    { label: "고객지원", path: "/customer-faq", isActive: false },
];

const Header = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const accessToken = useSelector((state) => state.common.accessToken);
    const refreshToken = useSelector((state) => state.common.refreshToken);

    const handleItemClick = (index, path) => {
        navigate(path);
    };

    const handleLogout = () => {
        dispatch(setStatusLoading(true));

        setTimeout(() => {
            dispatch(setStatusLoading(false));
            dispatch(clearAccessToken());
            navigate("/login");
        }, 1000);
    };
    const renderMenu = () => {
        return navItems.map((item, index) => (
            <NavItem
                key={index}
                {...item}
                onClick={() => handleItemClick(index, item.path)}
            />
        ));
    };

    const [getImageListByType] = useGetImageListByTypeMutation();

    useEffect(() => {
        const handleUrlParams = () => {
            const urlParams = new URLSearchParams(window.location.search);
            const accessToken = urlParams.get("accessToken");
            const refreshToken = urlParams.get("refreshToken");
            const userData = urlParams.get("userData");

            if (accessToken) sessionStorage.setItem("accessToken", accessToken);
            if (refreshToken)
                sessionStorage.setItem("refreshToken", refreshToken);
            if (userData) {
                const parsedUserData = JSON.parse(decodeURIComponent(userData));
                localStorage.setItem(
                    "userData",
                    JSON.stringify(parsedUserData),
                );
                dispatch(setUserData(parsedUserData));
            }

            if (accessToken || refreshToken || userData) {
                ["accessToken", "refreshToken", "userData"].forEach((param) =>
                    urlParams.delete(param),
                );
                navigate({ search: urlParams.toString() }, { replace: true });
            }
        };
        handleUrlParams();
    }, []);

    useEffect(() => {
        const handleFetchImages = async () => {
            try {
                dispatch(setStatusLoading(true));
                const response = await getImageListByType({
                    imageTypes: [
                        "auth-header",
                        "business-card-footer",
                        "create-business-card-below-footer",
                        "create-business-card-footer",
                        "create-logo-below-footer",
                        "create-logo-footer",
                        "dashboard-body",
                        "dashboard-header",
                        "logo-footer",
                    ],
                });

                dispatch(setStatusLoading(false));

                if (response?.data) {
                    dispatch(setImageTypesData(response?.data));
                }
            } catch (error) {
                dispatch(setStatusLoading(false));
            }
        };

        handleFetchImages();
    }, [dispatch, getImageListByType]);

    return (
        <nav className="fixed z-50 w-full bg-white">
            <div className="relative mx-20 my-5 flex justify-between bg-white max-md:mx-5">
                <Logo />

                <div className="hidden flex-wrap items-center gap-10 text-lg font-bold leading-none text-zinc-800 sm:hidden lg:flex">
                    {renderMenu()}
                </div>
                <div className="flex items-center">
                    {accessToken || refreshToken ? (
                        <div className="flex gap-5">
                            <img
                                className="h-5 w-5 hover:cursor-pointer"
                                src={images.notiIcon}
                                alt={"notiIcon"}
                                onClick={() => navigate("/notification")}
                            />
                            <img
                                className="h-5 w-5 hover:cursor-pointer"
                                src={images.profileIcon}
                                alt={"profileIcon"}
                                // onClick={()=>navigate("/")}
                            />
                            <img
                                className="h-5 w-5 hover:cursor-pointer"
                                src={images.logoutIcon}
                                alt={"logoutIcon"}
                                onClick={handleLogout}
                            />
                        </div>
                    ) : (
                        <LoginButton />
                    )}
                </div>
            </div>
            <div className="hidden flex-wrap items-center justify-center gap-10 text-lg font-bold leading-none text-zinc-800 max-lg:flex max-lg:gap-3">
                {renderMenu()}
            </div>
        </nav>
    );
};

export default Header;
