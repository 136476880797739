import React from "react";
import LogoDesignData from "./LogoDesignData";
import BusinessCardData from "./BusinessCardData";
import PrintOrderData from "./PrintOrderData";

const PrintListByPurchasedProduct = () => {
    return (
        <>
            <main className="px-20 pb-20 max-md:px-5">
                <section className="w-full">
                    <h2 className="mt-20 text-2xl font-semibold leading-none max-md:mt-10">
                        1 ) 로고디자인 구매시
                    </h2>
                    <div className="mt-6">
                        <LogoDesignData />
                    </div>
                </section>
                <section className="w-full">
                    <h2 className="mt-20 text-2xl font-semibold leading-none max-md:mt-10">
                        2 ) 명함디자인 구매시
                    </h2>
                    <div className="mt-6">
                        <BusinessCardData />
                    </div>
                </section>
                <section className="w-full">
                    <h2 className="mt-20 text-2xl font-semibold leading-none max-md:mt-10">
                        3 ) 인쇄 주문 시
                    </h2>
                    <div className="mt-6">
                        <PrintOrderData />
                    </div>
                </section>
            </main>
        </>
    );
};

export default PrintListByPurchasedProduct;
