import React,{useState} from "react";
import TableCommon from "../../../components/common/CommonTable/TableCommon";
import Button from "../../../components/common/Button/Button";
import images from "../../../images";
import PaginationComponent from "../../../components/common/Pagination/PaginationComponent";

const MyBusinessCardProductList = () => {
  const [page, setPage] = useState(1);

  const columns = [
    { label: "작업물 이미지", field: "image" },
    { label: "작업물 정보", field: "date" },
    { label: "확장자", field: "fileType" },
    { label: "다운로드", field: "downloadPeriod" },
    { label: "상태", field: "status" },
    { label: "인쇄", field: "print" },
  ];

  const getStatusColor = (status) => {
    switch (status) {
      case "취소":
        return "#6b7280";
      case "진행중":
        return "#4f46e5";
      case "접수확인중":
        return "#dc2626";
      case "완료":
        return "#1f2937";
      default:
        return "#1f2937";
    }
  };

  const data = [
    {
      image: (
        <img
          src={images.InquiryImage}
          alt="Product"
          style={{ width: 50, height: 50 }}
        />
      ),
      title: "Sample Title",
      date: "2023-10-12",
      fileType: "PDF",
      status: "완료",
      downloadPeriod: (
        <Button
          label={"다운로드"}
          className="gap-1 self-stretch max-w-full bg-violet-100 rounded-md border border-solid border-slate-200 min-h-[40px] w-[110px]"
        />
      ),
      print: (
        <Button
          label={"인쇄주문"}
          className="gap-1 self-stretch max-w-full bg-white rounded-md border border-solid border-neutral-400 min-h-[40px] w-[110px]"
        />
      ),
    },

    {
      image: (
        <img
          src={images.InquiryImage}
          alt="Product"
          style={{ width: 50, height: 50 }}
        />
      ),
      title: "Sample Title",
      date: "2023-10-12",
      fileType: "PDF",
      status: "진행중",
      downloadPeriod: (
        <Button
          label={"다운로드"}
          className="gap-1 self-stretch max-w-full bg-violet-100 rounded-md border border-solid border-slate-200 min-h-[40px] w-[110px]"
        />
      ),
      print: (
        <Button
          label={"인쇄주문"}
          className="gap-1 self-stretch max-w-full bg-white rounded-md border border-solid border-neutral-400 min-h-[40px] w-[110px]"
        />
      ),
    },
    {
      image: (
        <img
          src={images.InquiryImage}
          alt="Product"
          style={{ width: 50, height: 50 }}
        />
      ),
      title: "Sample Title",
      date: "2023-10-12",
      fileType: "PDF",
      status: "접수확인중",
      downloadPeriod: (
        <Button
          label={"다운로드"}
          className="gap-1 self-stretch max-w-full bg-violet-100 rounded-md border border-solid border-slate-200 min-h-[40px] w-[110px]"
        />
      ),
      print: (
        <Button
          label={"인쇄주문"}
          className="gap-1 self-stretch max-w-full bg-white rounded-md border border-solid border-neutral-400 min-h-[40px] w-[110px]"
        />
      ),
    },
    {
      image: (
        <img
          src={images.InquiryImage}
          alt="Product"
          style={{ width: 50, height: 50 }}
        />
      ),
      title: "Sample Title",
      date: "2023-10-12",
      fileType: "PDF",
      status: "완료",
      downloadPeriod: (
        <Button
          label={"다운로드"}
          className="gap-1 self-stretch max-w-full bg-violet-100 rounded-md border border-solid border-slate-200 min-h-[40px] w-[110px]"
        />
      ),
      print: (
        <Button
          label={"인쇄주문"}
          className="gap-1 self-stretch max-w-full bg-white rounded-md border border-solid border-neutral-400 min-h-[40px] w-[110px]"
        />
      ),
    },
    {
      image: (
        <img
          src={images.InquiryImage}
          alt="Product"
          style={{ width: 50, height: 50 }}
        />
      ),
      title: "Sample Title",
      date: "2023-10-12",
      fileType: "PDF",
      status: "",
      downloadPeriod: (
        <Button
          label={"다운로드"}
          className="gap-1 self-stretch max-w-full bg-violet-100 rounded-md border border-solid border-slate-200 min-h-[40px] w-[110px]"
        />
      ),
      print: (
        <Button
          label={"인쇄주문"}
          className="gap-1 self-stretch max-w-full bg-white rounded-md border border-solid border-neutral-400 min-h-[40px] w-[110px]"
        />
      ),
    },
    {
      image: (
        <img
          src={images.InquiryImage}
          alt="Product"
          style={{ width: 50, height: 50 }}
        />
      ),
      title: "Sample Title",
      date: "2023-10-12",
      fileType: "PDF",
      status: "",
      downloadPeriod: (
        <Button
          label={" 재구매하기"}
          className="gap-1 self-stretch max-w-full bg-white rounded-md border border-indigo-600 border-solid min-h-[40px] w-[110px] text-base font-semibold leading-none text-indigo-600"
        />
      ),
      print: (
        <Button
          label={"인쇄주문"}
          className="gap-1 self-stretch max-w-full bg-white rounded-md border border-solid border-neutral-400 min-h-[40px] w-[110px]"
        />
      ),
    },
    {
      image: (
        <img
          src={images.InquiryImage}
          alt="Product"
          style={{ width: 50, height: 50 }}
        />
      ),
      title: "Sample Title",
      date: "2023-10-12",
      fileType: "PDF",
      status: "",
      downloadPeriod: (
        <Button
          label={"다운로드"}
          className="gap-1 self-stretch max-w-full bg-violet-100 rounded-md border border-solid border-slate-200 min-h-[40px] w-[110px]"
        />
      ),
      print: (
        <Button
          label={"인쇄주문"}
          className="gap-1 self-stretch max-w-full bg-white rounded-md border border-solid border-neutral-400 min-h-[40px] w-[110px]"
        />
      ),
    },
    // Add more rows as needed
  ];
  return (
    <>
    <div className="w-full pb-10">
      <TableCommon
        columns={columns}
        data={data.map((item) => ({
          ...item,
          status: (
            <span style={{ color: getStatusColor(item.status) }}>
              {item.status}
            </span>
          ),
          image: (
            <>
              <div className="flex flex-col justify-center items-center max-w-full w-[110px]">
                <img
                  src={images.businessCardListTop}
                  alt="Product"
                  className="object-contain mt-2.5 w-full aspect-[1.8]"
                  style={{ width: 50, height: 50 }}
                />
                <img
                  className="object-contain mt-2.5 w-full aspect-[1.8]"
                  src={images.businessCardListBottom}
                  alt="Product"
                  style={{ width: 50, height: 50 }}
                />
              </div>
            </>
          ),
        }))}
      />
    </div>

    <div className="flex justify-center mb-24">
        <PaginationComponent className="mt-5" count={10} page={page} />
      </div>
    </>
  );
};

export default MyBusinessCardProductList;
