import React from "react";

function AIGeneratedLogoDesignCard({
  title,
  price,
  features,
  statusClassName,
}) {
  return (
    <div className="flex flex-col pb-12 bg-white shadow-lg min-w-[350px] min-h-[450px] rounded-[30px] max-w-[350px]">
      <div className="flex flex-col w-full">
        <div
          className={`flex flex-col justify-center pt-12 pb-5 w-full font-bold leading-none text-center rounded-[30px_30px_0px_0px] ${statusClassName}`}
        >
          <div data-layername={title} className="text-base text-indigo-600">
            {title}
          </div>
          <div data-layername={price} className="mt-2.5 text-2xl text-zinc-800">
            {price}
          </div>
        </div>
        <div className="flex gap-8 items-start px-10 mt-8 w-full text-sm leading-none text-zinc-800 max-md:px-5">
          <div className="flex flex-col flex-1 shrink items-start w-full basis-0 min-w-[240px] max-w-[350px]">
            {features.map((feature, index) => (
              <div
                key={index}
                className="flex gap-1.5 items-center self-stretch mt-3.5 w-full"
              >
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/99aee48fa0ccfa87308d734296828301b2040123272558dd3f43dcf52930c34c?placeholderIfAbsent=true&apiKey=3ac17b61e0c94c2b87d841ac8b4d3afd"
                  alt=""
                  className="object-contain shrink-0 self-stretch my-auto w-3.5 aspect-square"
                />
                <div data-layername={feature} className="self-stretch my-auto">
                  {feature}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AIGeneratedLogoDesignCard;
