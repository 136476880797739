import React from "react";

function FileFormatRecommendation() {
  return (
    <section className="flex flex-col w-full px-5 max-md:max-w-full">
      <div
        data-layername="벡터"
        className="flex overflow-hidden flex-col w-full bg-white rounded-xl border border-violet-100 border-solid max-md:max-w-full"
      >
        <div className="flex flex-col w-full max-md:max-w-full">
          <div className="flex justify-between items-center px-9 pt-6 pb-5 w-full bg-indigo-700 max-md:px-5 max-md:max-w-full">
            <div className="flex flex-wrap flex-1 shrink gap-2.5 items-center self-stretch my-auto w-full basis-0 min-w-[240px] max-md:max-w-full">
              <div className="flex items-center self-stretch my-auto text-lg font-bold leading-none text-white">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/86e2f0b5d13827dcd2fbcfff7b78150eb9f0180576eb1e7295eb10f30fbeba39?placeholderIfAbsent=true&apiKey=3ac17b61e0c94c2b87d841ac8b4d3afd"
                  alt=""
                  className="object-contain shrink-0 self-stretch my-auto w-6 aspect-square"
                />
                <h3
                  data-layername="확장자추천"
                  className="self-stretch my-auto"
                >
                  확장자 추천
                </h3>
              </div>
              <p
                data-layername="벡터파일Ai사용을추천드립니다"
                className="self-stretch my-auto text-base font-semibold leading-none text-neutral-200"
              >
                벡터 파일(.ai) 사용을 추천드립니다!
              </p>
            </div>
          </div>
          <div className="flex flex-col px-9 pt-8 pb-9 w-full text-sm max-md:px-5 max-md:max-w-full">
            <div className="flex flex-col w-full max-md:max-w-full">
              <div className="flex flex-wrap gap-3 items-start w-full max-md:max-w-full">
                <div className="flex flex-col flex-1 shrink basis-0 min-w-[240px]">
                  <h4
                    data-layername="・해상도에영향없음"
                    className="font-bold leading-loose text-zinc-800"
                  >
                    <span className="">・</span>해상도에 영향 없음
                  </h4>
                  <p
                    data-layername="벡터파일은크기를얼마든지늘리거나줄여도품질이떨어지지않습니다"
                    className="leading-5 text-stone-500"
                  >
                    벡터 파일은 크기를 얼마든지 늘리거나 줄여도 <br />
                    품질이 떨어지지 않습니다.
                  </p>
                </div>
                <div className="flex flex-col flex-1 shrink basis-0 min-w-[240px]">
                  <h4
                    data-layername="・색상변경자유로움"
                    className="font-bold leading-loose text-zinc-800"
                  >
                    <span className="">・</span>색상 변경 자유로움
                  </h4>
                  <p
                    data-layername="추후로고의색상을변경하고싶으실때벡터파일을이용하면간편하게수정이가능합니다"
                    className="leading-5 text-stone-500"
                  >
                    추후 로고의 색상을 변경하고 싶으실 때, <br />
                    벡터 파일을 이용하면 간편하게 수정이 가능합니다.
                  </p>
                </div>
                <div className="flex flex-col flex-1 shrink basis-0 min-w-[240px]">
                  <h4
                    data-layername="・인쇄와제작에최적화"
                    className="font-bold leading-loose text-zinc-800"
                  >
                    <span className="">・</span>인쇄와 제작에 최적화
                  </h4>
                  <p
                    data-layername="명함팸플릿배너등다양한사이즈와매체에인쇄할때선명하게출력됩니다"
                    className="leading-5 text-stone-500"
                  >
                    명함, 팸플릿, 배너 등 다양한 사이즈와 매체에 인쇄할 때{" "}
                    <br />
                    선명하게 출력됩니다.
                  </p>
                </div>
              </div>
              <div className="flex flex-wrap items-start mt-5 w-full max-md:max-w-full">
                <div className="flex flex-col min-w-[240px] w-[345px]">
                  <h4
                    data-layername="・다양한파일포맷변환용이"
                    className="font-bold leading-loose text-zinc-800"
                  >
                    <span className="">・</span>다양한 파일 포맷 변환 용이
                  </h4>
                  <p
                    data-layername="벡터파일은JpgPngPdf등다른형식으로손쉽게변환이가능합니다"
                    className="leading-6 text-stone-500"
                  >
                    벡터 파일은 JPG, PNG, PDF 등 다른 형식으로 <br />
                    손쉽게 변환이 가능합니다.
                  </p>
                </div>
                <div className="flex flex-col min-w-[240px] w-[339px]">
                  <h4
                    data-layername="・수정및보완편리"
                    className="font-bold leading-loose text-zinc-800"
                  >
                    <span className="">・</span>수정 및 보완 편리
                  </h4>
                  <p
                    data-layername="추후에로고디자인을수정하거나보완할경우벡터파일을이용하면보다효율적이고정확한작업이가능합니다"
                    className="leading-5 text-stone-500"
                  >
                    추후에 로고 디자인을 수정하거나 보완할 경우 벡터 파일을{" "}
                    <br />
                    이용하면 보다 효율적이고 정확한 작업이 가능합니다.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FileFormatRecommendation;
