import React, { useState, useEffect } from "react";
import SelectBox from "../../../components/common/OptionSelect/SelectBox";
import CommonTabs from "../../../components/common/Tabs/CommonTabs";

const CardSpecificationsTypeSecond = ({ options, specsData, totalAmount }) => {
    const [activeOption, setActiveOption] = useState(options[0]?.label || "");
    const [specs, setSpecs] = useState(specsData[activeOption] || []);

    const handleOptionClick = (optionLabel) => {
        setActiveOption(optionLabel);
    };

    useEffect(() => {
        setSpecs(specsData[activeOption] || []);
    }, [activeOption, specsData]);

    const handleSelectChange = (index, selectedValue) => {
        const updatedSpecs = specs.map((spec, i) =>
            i === index ? { ...spec, selectedValue } : spec,
        );
        setSpecs(updatedSpecs);
    };

    const tabLabels = options?.map((option) => option.label) || [];
    const tabPanels =
        options?.map((option) => {
            const specsForOption = specsData[option.label] || [];
            return (
                <div key={option.label}>
                    {specsForOption.map((spec, index) => (
                        <div
                            key={index}
                            className="mt-2 flex min-h-[30px] w-full flex-wrap items-center gap-8 first:mt-0"
                        >
                            <div className="my-auto w-[60px] self-stretch font-bold text-neutral-700">
                                {spec.label}
                            </div>
                            <div className="my-auto self-stretch">
                                {spec.type === "select" ? (
                                    <SelectBox
                                        items={spec.value}
                                        value={
                                            spec.selectedValue || spec.value[0]
                                        }
                                        onChange={(e) =>
                                            handleSelectChange(
                                                index,
                                                e.target.value,
                                            )
                                        }
                                    />
                                ) : (
                                    <span className="text-neutral-600">
                                        {spec.value}
                                    </span>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            );
        }) || [];

    return (
        <div className="w-full">
            <header className="flex w-full flex-col max-md:max-w-full">
                <h1 className="text-2xl font-bold leading-none text-main-color max-md:max-w-full">
                    고급명함
                </h1>
                <p className="mt-2.5 text-base leading-6 text-neutral-700 max-md:max-w-full">
                    특별한 인상을 줄 수 있는 고급 종이와 인쇄 기법을 사용하여
                    <br />
                    명함의 질감과 디자인에 차별화를 더한 명함입니다.
                </p>
            </header>
            <div className="mt-8 flex min-h-[58px] w-full flex-wrap rounded-md p-2 font-semibold text-indigo-700 max-md:max-w-full ">
                <CommonTabs
                    labels={tabLabels} 
                    activeTab={activeOption} 
                    onTabClick={handleOptionClick} 
                    panels={tabPanels} 
                />
            </div>

            {/* <div className="flex w-full flex-col rounded px-5 py-8">
                {specs.map((spec, index) => (
                    <div
                        key={index}
                        className="mt-2 flex min-h-[30px] w-full flex-wrap items-center gap-8 first:mt-0"
                    >
                        <div className="my-auto w-[60px] self-stretch font-bold text-neutral-700">
                            {spec.label}
                        </div>
                        <div className="my-auto w-[180px] self-stretch">
                            {spec.type === "select" ? (
                                <SelectBox
                                    items={spec.value}
                                    value={spec.selectedValue || spec.value[0]}
                                    onChange={(e) =>
                                        handleSelectChange(
                                            index,
                                            e.target.value,
                                        )
                                    }
                                />
                            ) : (
                                <span className="text-neutral-600">
                                    {spec.value}
                                </span>
                            )}
                        </div>
                    </div>
                ))}
            </div> */}
            <div className="mt-8 flex min-h-[50px] w-full flex-wrap items-center justify-between gap-10 rounded-md bg-stone-50 px-5 text-lg font-bold leading-none max-md:max-w-full">
                <div className="my-auto self-stretch text-zinc-800">
                    {totalAmount.label}
                </div>
                <div className="my-auto self-stretch text-right text-main-color">
                    {totalAmount.value}
                </div>
            </div>
        </div>
    );
};

export default CardSpecificationsTypeSecond;
