import * as React from "react";

function ImageListItem({ checkboxSrc, imageSrc, title, date, extension }) {
    return (
        <article className="flex gap-5 items-center px-4 w-full">
            <div className="flex flex-col self-stretch my-auto w-4 rounded-[50px]">
                <img
                    src={checkboxSrc}
                    alt=""
                    className="object-contain shrink-0 self-stretch my-auto w-4 aspect-square rounded-[50px]"
                />
            </div>
            <div className="flex gap-5 items-center self-stretch my-auto text-base leading-none min-w-[240px] text-zinc-800">
                <img
                    src={imageSrc}
                    alt={title}
                    className="object-contain shrink-0 self-stretch my-auto rounded aspect-[1.33] w-[120px]"
                />
                <div className="flex gap-5 items-center self-stretch my-auto">
                    <div className="flex flex-col justify-center self-stretch my-auto font-bold">
                        <h3>작업명</h3>
                        <p className="mt-4">구매 날짜</p>
                        <p className="mt-4">확장자</p>
                    </div>
                    <div className="flex flex-col justify-center self-stretch my-auto">
                        <p>{title}</p>
                        <p className="mt-4">{date}</p>
                        <p className="mt-4">{extension}</p>
                    </div>
                </div>
            </div>
        </article>
    );
}

export default ImageListItem;