import React, { useState } from "react";
import FAQSidebar from "./FAQSidebar";
import FAQItem from "./FAQItem";

const initialFaqData = [
    {
        question:
            "이미지를 많이 넣고 싶은 데, 추가 이미지를 삽입할 수 있을까요?",
        answer: "네, 이미지 경우 따로 횟수 제한을 두고 있지 않습니다. \n다만, 서비스 이용 목적이 아닌 타 목적으로 활용하거나, 고의적으로 과도한 사용을 할 시 제재가 있을 수 있습니다.",
        isOpen: true,
        category: "전체",
    },
    {
        question: "로고 디자인 관련 질문입니다.",
        answer: "로고 디자인에 대한 답변입니다.",
        isOpen: false,
        category: "로고디자인",
    },
    {
        question: "명함 디자인 문의드립니다.",
        answer: "명함 디자인에 대한 답변입니다.",
        isOpen: false,
        category: "명함디자인",
    },
    {
        question: "인쇄 관련 문의사항이 있습니다.",
        answer: "인쇄 관련 답변입니다.",
        isOpen: false,
        category: "인쇄문의",
    },
    {
        question: "디자인 상담을 받고 싶습니다.",
        answer: "디자인 상담에 대한 답변입니다.",
        isOpen: false,
        category: "디자인상담문의",
    },
];

function FAQSection() {
    const [activeCategory, setActiveCategory] = useState("전체");
    const [faqData, setFaqData] = useState(initialFaqData);

    const handleCategoryChange = (category) => {
        setActiveCategory(category);
    };

    const toggleFAQ = (index) => {
        setFaqData(
            faqData.map((item, i) =>
                i === index ? { ...item, isOpen: !item.isOpen } : item,
            ),
        );
    };

    const filteredFAQs = faqData.filter((item) => item.category);

    return (
        <main className="mb-40 mt-20 flex flex-col gap-5 px-20 max-md:px-5 md:flex-row">
            <FAQSidebar
                activeCategory={activeCategory}
                onCategoryChange={handleCategoryChange}
            />
            <section className="flex w-full flex-col md:ml-5 md:w-[74%]">
                <div className="flex max-w-full grow flex-col">
                    {filteredFAQs.map((item, index) => (
                        <FAQItem
                            key={index}
                            {...item}
                            onClick={() => toggleFAQ(index)}
                        />
                    ))}
                </div>
            </section>
        </main>
    );
}

export default FAQSection;
