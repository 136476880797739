import React from "react";

function InfoSection({ title, infoList }) {
  return (
    <div className="flex w-full flex-col max-md:max-w-full">
      <h3 className="text-base font-semibold text-zinc-800">{title}</h3>
      <div className="mt-2.5 flex w-full flex-col bg-stone-50 text-sm text-neutral-700 max-md:max-w-full">
        <div className="flex w-full flex-col justify-center rounded bg-slate-50 p-5 max-md:max-w-full">
          <div className="flex w-full items-center justify-between gap-10">
            <div className="my-auto flex w-[73px] flex-col self-stretch whitespace-nowrap font-bold">
              {infoList.map((item) => (
                <div
                  key={item.label}
                  className={item.label !== infoList[0].label ? "mt-2.5" : ""}
                >
                  {item.label}
                </div>
              ))}
            </div>
            <div className="my-auto flex w-[97px] flex-col items-end justify-center self-stretch">
              {infoList.map((item) => (
                <div
                  key={item.label}
                  className={item.label !== infoList[0].label ? "mt-2.5" : ""}
                >
                  {item.value}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InfoSection;
