import React,{useState} from "react";
import OrderCard from "./OrderCard";
import images from "../../../images";
import PaginationComponent from "../../../components/common/Pagination/PaginationComponent";

const PrintOrderHistory = () => {
  const [page, setPage] = useState(1);

  const orders = [
    {
      orderDate: "2024-09-20 10:00",
      trackingInfo: { courier: "OO택배", trackingNumber: "584641143570" },
      recipientInfo: {
        name: "홍길동",
        address: "서울특별시 OO구 OO동 OOO로 14, OO에이스타워 3층 306호",
        phone: "01088883333",
      },
      imageUrl1: `${images.businessCarFrontPreview}`,
      imageUrl2: `${images.businessCarBackPreview}`,
    },
    {
      orderDate: "2024-09-20 10:00",
      trackingInfo: { courier: "OO택배", trackingNumber: "584641143570" },
      recipientInfo: {
        name: "홍길동",
        address: "서울특별시 OO구 OO동 OOO로 14, OO에이스타워 3층 306호",
        phone: "01088883333",
      },
      imageUrl1: `${images.businessCarFrontPreview}`,
      imageUrl2: `${images.businessCarBackPreview}`,
    },
    {
      orderDate: "2024-09-20 10:00",
      trackingInfo: { courier: "OO택배", trackingNumber: "584641143570" },
      recipientInfo: {
        name: "홍길동",
        address: "서울특별시 OO구 OO동 OOO로 14, OO에이스타워 3층 306호",
        phone: "01088883333",
      },
      imageUrl1: `${images.businessCarFrontPreview}`,
      imageUrl2: `${images.businessCarBackPreview}`,
    },
    {
      orderDate: "2024-09-20 10:00",
      trackingInfo: { courier: "OO택배", trackingNumber: "584641143570" },
      recipientInfo: {
        name: "홍길동",
        address: "서울특별시 OO구 OO동 OOO로 14, OO에이스타워 3층 306호",
        phone: "01088883333",
      },
      imageUrl1: `${images.businessCarFrontPreview}`,
      imageUrl2: `${images.businessCarBackPreview}`,
    },
    {
      orderDate: "2024-09-20 10:00",
      trackingInfo: { courier: "OO택배", trackingNumber: "584641143570" },
      recipientInfo: {
        name: "홍길동",
        address: "서울특별시 OO구 OO동 OOO로 14, OO에이스타워 3층 306호",
        phone: "01088883333",
      },
      imageUrl1: `${images.businessCarFrontPreview}`,
      imageUrl2: `${images.businessCarBackPreview}`,
    },
    {
      orderDate: "2024-09-20 10:00",
      trackingInfo: { courier: "OO택배", trackingNumber: "584641143570" },
      recipientInfo: {
        name: "홍길동",
        address: "서울특별시 OO구 OO동 OOO로 14, OO에이스타워 3층 306호",
        phone: "01088883333",
      },
      imageUrl1: `${images.businessCarFrontPreview}`,
      imageUrl2: `${images.businessCarBackPreview}`,
    },
  ];

  return (
    <main className="pb-20">
      <div className="mt-8 w-full max-w-[1240px] max-md:max-w-full">
        <div className="flex flex-row justify-center flex-wrap gap-5 max-md:flex-col mx-auto">
          {orders.map((order, index) => (
            <div key={index} className="flex flex-col max-md:ml-0 max-md:w-full">
              <OrderCard {...order} />
            </div>
          ))}
        </div>
      </div>
      <div className="flex justify-center mt-12 mb-24">
        <PaginationComponent className="mt-5" count={10} page={page} />
      </div>
    </main>
  );
};

export default PrintOrderHistory;
