import React from "react";
import images from "../../../../images";
import InfoSection from "./InfoSection";
import Button from "../../../../components/common/Button/Button";

function PrintOrderHistoryDetails() {
    const productDetails = [
        { label: "명함타입", value: "고급명함(고급 용지 명함)" },
        { label: "용지", value: "엑스트라폴라" },
        { label: "인쇄도수", value: "4도 인쇄 + 양면" },
        { label: "코팅", value: "무광 코팅" },
        { label: "규격", value: "90X50" },
        { label: "후가공", value: "-" },
        { label: "수량", value: "200매" },
    ];

    const customer = [
        { label: "이름", value: "홍숙자" },
        { label: "연락처", value: "01077776666" },
        { label: "이메일", value: "hong00@mail.com" },
    ];

    const shipping = [
        { label: "수령인", value: "홍길동" },
        { label: "연락처", value: "01088883333" },
        { label: "주소", value: "서울특별시 OO구 OO동 OOO로 14" },
        { label: "배송 메시지", value: "-" },
        { label: "배송정보", value: "OO택배 584641143570" },
    ];

    return (
        <main className="px-20 py-10 max-md:px-5">
            <section className="mt-12">
                <h2 className="text-xl font-bold leading-none text-zinc-800">
                    인쇄주문내역 상세
                </h2>
                <div className="mt-5 flex flex-wrap gap-8">
                    <div className="flex w-[750px] min-w-[240px] flex-col max-md:max-w-full">
                        <h3 className="text-base font-semibold leading-none text-zinc-800">
                            주문 이미지
                        </h3>
                        <div className="mt-2.5 flex w-[750px] max-w-full flex-1 flex-col items-center justify-center rounded-xl border border-solid border-gray-200 bg-white p-10 max-md:px-5">
                            <img
                                loading="lazy"
                                src={images.businessCarFrontPreview}
                                alt="Order image 1"
                                className="aspect-[1.8] w-[468px] max-w-full object-contain"
                            />
                            <img
                                loading="lazy"
                                src={images.businessCarBackPreview}
                                alt="Order image 2"
                                className="mt-5 aspect-[1.8] w-[468px] max-w-full object-contain"
                            />
                        </div>
                    </div>
                    <div className="flex min-w-[240px] flex-1 shrink basis-0 flex-col gap-4 self-start leading-none max-md:max-w-full">
                        <InfoSection
                            title="상품 정보"
                            infoList={productDetails}
                        />
                        <InfoSection title="주문자 정보" infoList={customer} />
                        <InfoSection title="배송지 정보" infoList={shipping} />
                    </div>
                </div>
            </section>
            <hr className="mt-10 flex h-px w-full shrink-0 border border-solid border-gray-200" />

            <div className="mx-auto flex justify-center">
                <Button
                    label="목록으로"
                    className="mt-10 min-h-[50px] w-80 max-w-full self-center whitespace-nowrap rounded-md border border-solid border-neutral-300 bg-white px-4 text-center text-base font-bold leading-none text-zinc-800"
                />
            </div>
        </main>
    );
}

export default PrintOrderHistoryDetails;
