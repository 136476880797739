import React from "react";

const sidebarItems = [
  { label: "전체", category: "전체" },
  { label: "로고디자인", category: "로고디자인" },
  { label: "명함디자인", category: "명함디자인" },
  { label: "인쇄문의", category: "인쇄문의" },
  { label: "디자인상담문의", category: "디자인상담문의" },
];

function FAQSidebar({ activeCategory, onCategoryChange }) {
  return (
    <nav className="w-full md:w-[26%] mb-5 md:mb-0">
      <ul className="flex flex-col justify-center w-full text-lg leading-none whitespace-nowrap text-neutral-500">
        {sidebarItems.map((item, index) => (
          <li
            key={index}
            className={`
              self-stretch py-5 pl-6 w-full cursor-pointer
              ${
                activeCategory === item.category
                  ? "font-bold border-l-4 border-indigo-600 text-zinc-800"
                  : "border-l-4 border-neutral-200"
              }
            `}
            onClick={() => onCategoryChange(item.category)}
          >
            {item.label}
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default FAQSidebar;
