import React from "react";
import images from "../../../../images";
import InfoSection from "./InfoSection";
import Button from "../../../../components/common/Button/Button";

function PrintOrderHistoryDetails() {
  const productDetails = [
    { label: "명함타입", value: "고급명함(고급 용지 명함)" },
    { label: "용지", value: "엑스트라폴라" },
    { label: "인쇄도수", value: "4도 인쇄 + 양면" },
    { label: "코팅", value: "무광 코팅" },
    { label: "규격", value: "90X50" },
    { label: "후가공", value: "-" },
    { label: "수량", value: "200매" },
  ];

  const customer = [
    { label: "이름", value: "홍숙자" },
    { label: "연락처", value: "01077776666" },
    { label: "이메일", value: "hong00@mail.com" },
  ];

  const shipping = [
    { label: "수령인", value: "홍길동" },
    { label: "연락처", value: "01088883333" },
    { label: "주소", value: "서울특별시 OO구 OO동 OOO로 14" },
    { label: "배송 메시지", value: "-" },
    { label: "배송정보", value: "OO택배 584641143570" },
  ];

  return (
    <main className="px-20 py-10">
      <section className="mt-12">
        <h2 className="text-xl font-bold leading-none text-zinc-800">
          인쇄주문내역 상세
        </h2>
        <div className="flex flex-wrap gap-8 mt-5">
          <div className="flex flex-col min-w-[240px] w-[750px] max-md:max-w-full">
            <h3 className="text-base font-semibold leading-none text-zinc-800">
              주문 이미지
            </h3>
            <div className="flex flex-col flex-1 justify-center items-center p-10 mt-2.5 max-w-full bg-white rounded-xl border border-gray-200 border-solid w-[750px] max-md:px-5">
              <img
                loading="lazy"
                src={images.businessCarFrontPreview}
                alt="Order image 1"
                className="object-contain max-w-full aspect-[1.8] w-[468px]"
              />
              <img
                loading="lazy"
                src={images.businessCarBackPreview}
                alt="Order image 2"
                className="object-contain mt-5 max-w-full aspect-[1.8] w-[468px]"
              />
            </div>
          </div>
          <div className="flex flex-col flex-1 gap-4 shrink self-start leading-none basis-0 min-w-[240px] max-md:max-w-full">
            <InfoSection title="상품 정보" infoList={productDetails} />
            <InfoSection title="주문자 정보" infoList={customer} />
            <InfoSection title="배송지 정보" infoList={shipping} />
          </div>
        </div>
      </section>
      <hr className="flex shrink-0 mt-10 w-full h-px border border-gray-200 border-solid" />

      <div className="flex justify-center mx-auto">
        <Button
          label="목록으로"
          className="self-center px-4 mt-10 w-80 max-w-full text-base font-bold leading-none text-center whitespace-nowrap bg-white rounded-md border border-solid border-neutral-300 min-h-[50px] text-zinc-800"
        />
      </div>
    </main>
  );
}

export default PrintOrderHistoryDetails;
