import React, { useState } from "react";
import { useTimer } from "react-timer-hook";

function InputCountdown({
  value,
  width,
  onChange,
  placeholder,
  label,
  type,
  inputId,
  initialDuration = 180, 
  startCountdown,
}) {
  const [isRunning, setIsRunning] = useState(false);

  const { seconds, minutes, start, restart } = useTimer({
    expiryTimestamp: new Date(new Date().getTime() + initialDuration * 1000),
    onExpire: () => setIsRunning(false), 
    autoStart: false,
  });

  const handleStart = () => {
    const time = new Date();
    time.setSeconds(time.getSeconds() + initialDuration);
    restart(time);
    setIsRunning(true);
  };

  React.useEffect(() => {
    if (startCountdown) {
      handleStart();
    }
  }, [startCountdown]);

  return (
    <div className="flex flex-col mt-4 w-full">
      <label htmlFor={inputId} className="text-base font-bold text-neutral-700">
        {label}
      </label>
      <div className="flex gap-10 mt-2.5 justify-between items-center px-4 max-w-xs leading-none whitespace-nowrap rounded-md bg-stone-50">
        <input
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          width={width}
          className="self-stretch my-auto text-base text-neutral-700 w-[220px] bg-transparent border-none focus:outline-none bg-stone-50 min-h-[50px]"
          type={type}
          id={inputId}
          label={label}
        />
        <p className="self-stretch my-auto text-base text-red-600">
          {isRunning ? `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}` : "00:00"}
        </p>
      </div>
    </div>
  );
}

export default InputCountdown;
