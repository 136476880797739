import React from 'react';

function PurchaseInfo() {
  return (
    <div className="flex flex-col px-8 py-9 bg-white rounded-xl border border-gray-200 border-solid max-md:px-5 max-md:max-w-full">
      <div className="flex flex-col w-full">
        <div className="flex flex-col px-5 py-6 w-full rounded-xl bg-slate-50">
          <h3 className="text-base font-bold leading-none text-neutral-700">
            [상품 구매 시 안내사항]
          </h3>
          <div className="flex flex-col mt-4 w-full text-sm leading-5 text-zinc-800">
            <p>
              ・로고로지에서 구매하시는 상품의 경우 디지털 콘텐츠로 
              제공된 상품이기에 구매 후에는 환불이 불가합니다.
            </p>
            <p className="mt-2.5">
              ・다만 제공된 상품의 문제가 발생 시 
              <span className="font-bold"> [고객센터 - 문의하기] </span> 에서 문의를 진행해주시기 바랍니다.
            </p>
          </div>
        </div>
        <div className="flex gap-2.5 items-center self-start mt-4 text-base leading-none text-neutral-700">
          <img 
            loading="lazy" 
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/cfbd5fb6c5dab876993085907861e2e1dc8c64332834ba352b1f0d21d63ab6a3?placeholderIfAbsent=true&apiKey=3ac17b61e0c94c2b87d841ac8b4d3afd" 
            alt="Checkbox icon"
            className="object-contain shrink-0 self-stretch my-auto w-4 rounded-none aspect-square" 
          />
          <label htmlFor="agreement" className="self-stretch my-auto">
            주문 내용을 모두 확인했으며, 상품 결제 동의합니다.
          </label>
          <input type="checkbox" id="agreement" className="sr-only" />
        </div>
      </div>
      <div className="flex flex-col justify-center mt-9 w-full text-base font-bold leading-none text-center whitespace-nowrap">
        <button className="px-4 w-full text-white bg-indigo-600 rounded-md min-h-[50px]">
          구매하기
        </button>
        <button className="px-4 mt-4 w-full bg-white rounded-md border border-solid border-neutral-300 min-h-[50px] text-zinc-800">
          이전으로
        </button>
      </div>
    </div>
  );
}

export default PurchaseInfo;