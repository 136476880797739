import React, { useState, useEffect } from "react";
import FileFormatOption from "../../../components/common/FileFormatOption/FileFormatOption";
import { formatPrice } from "../../../utils/helper/formatters";

function FileFormatSelection({ fileFormats, onSelect }) {
    const [selectedOption, setSelectedOption] = useState(0);

    useEffect(() => {
        if (fileFormats.length > 0) {
            onSelect(fileFormats[selectedOption]);
        }
    }, [fileFormats, selectedOption, onSelect]);

    const handleOptionChange = (index) => {
        const selectedData = fileFormats[index];
        setSelectedOption(index);
        onSelect(selectedData);
    };

    return (
        <section className="mt-24 flex w-full flex-col max-md:mt-10 max-md:max-w-full">
            <h2 className="flex w-full flex-col text-2xl font-bold leading-none text-neutral-700 max-md:max-w-full">
                <div className="gap-2.5 self-start">확장자 선택</div>
                <div className="mt-4 flex min-h-[2px] w-full bg-neutral-600 max-md:max-w-full" />
            </h2>
            <div className="mt-5 flex w-full flex-col max-md:max-w-full">
                <div className="grid w-full grid-cols-2 place-content-start gap-4 max-md:flex max-md:max-w-full max-md:flex-wrap max-md:justify-start">
                    {fileFormats.map((option, index) => (
                        <div key={index} className="w-full max-md:w-full">
                            <FileFormatOption
                                name={option.formatType}
                                price={formatPrice(option.price)}
                                features={option.description}
                                isSelected={selectedOption === index}
                                onSelectionChange={() =>
                                    handleOptionChange(index)
                                }
                            />
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default FileFormatSelection;
