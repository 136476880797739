import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PurchaseCompleteTitle from "./../../Logo/PurchaseComplete/PurchaseCompleteTitle";
import Button from "../../../components/common/Button/Button";
import ImageComponent from "../../../components/FooterBanner";
import PurchaseInfoSecond from "../../Logo/PurchaseComplete/PurchaseInfoSecond";
import { useNavigate } from "react-router-dom";

const PurchaseCompleteBusinessCardVectorAI = () => {
    const navigate = useNavigate();
    const imageTypesData = useSelector(
        (state) => state.common.imageTypesData || [],
    );

    const getImageByType = (type) => {
        return Array.isArray(imageTypesData)
            ? imageTypesData.filter((image) => image.imageType === type)
            : [];
    };

    const [images, setImages] = useState({
        images: { cardLayoutFooterImage: null },
    });

    const goToPrintBusinessCard = () => {
        navigate("/card-sprint");
    };
    useEffect(() => {
        if (Array.isArray(imageTypesData) && imageTypesData.length > 0) {
            const createCardImageFooter = getImageByType(
                "create-logo-below-footer",
            );
            if (createCardImageFooter.length > 0) {
                setImages((prevState) => ({
                    ...prevState,
                    images: {
                        ...prevState.images,
                        cardLayoutFooterImage:
                            createCardImageFooter[0]?.filePath,
                    },
                }));
            }
        }
    }, [imageTypesData]);

    return (
        <div className="align-items-center flex flex-col justify-center text-center">
            <main className="mx-auto max-w-[500px] max-md:px-5">
                <PurchaseCompleteTitle />
                <PurchaseInfoSecond />
                <div className="mt-9 flex max-w-full flex-col justify-center text-center text-base font-bold leading-none">
                    <div className="flex w-full flex-wrap items-start gap-2.5 max-md:max-w-full">
                        <Button
                            label={"결제 내역 확인"}
                            className="min-h-[50px] w-[196px] min-w-[240px] shrink grow gap-2.5 self-stretch rounded-md border border-solid border-neutral-300 bg-white px-4 text-zinc-800"
                        />
                        <Button
                            label={"파일 다운로드"}
                            className="min-h-[50px] w-[196px] min-w-[240px] shrink grow gap-2.5 self-stretch rounded-md bg-indigo-600 px-4 text-white"
                        />
                    </div>
                    <a href="#" className="mt-9 self-center text-zinc-800">
                        메인으로
                    </a>
                </div>

                <section className="mt-16 flex max-w-full flex-col rounded-md bg-indigo-600 px-9 py-9 leading-none text-white max-md:mt-10 max-md:px-5">
                    <h2 className="self-start text-2xl font-semibold leading-none">
                        명함 인쇄 도움이 필요하세요?
                    </h2>
                    <p className="mt-2 self-start text-xl">
                        로고로지에서 명함 인쇄 출력까지 연결해드립니다.
                    </p>

                    <>
                        <p className="mt-5 text-start text-sm leading-none text-violet-200 max-md:max-w-full">
                            ・벡터파일을 요청하신 경우, 작업이 완료된 후 <br />
                            <span className="font-bold text-violet-200">
                                [마이페이지- 내 제작물 목록]
                            </span>
                            에서 인쇄 주문 요청이 가능합니다.
                        </p>
                    </>

                    <Button
                        label={"바로 주문하기"}
                        onClick={goToPrintBusinessCard}
                        className="mt-12 min-h-[50px] gap-2.5 self-stretch rounded-md border border-solid border-white bg-white px-4 text-center text-base font-bold text-main-color max-md:mr-1 max-md:mt-10"
                    />
                </section>
            </main>

            <div className="mt-24">
                <ImageComponent src={images.images.cardLayoutFooterImage} />
            </div>

            {/* </div> */}
        </div>
    );
};

export default PurchaseCompleteBusinessCardVectorAI;
