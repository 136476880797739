// src/services/endpoints/dataEndpoints.js
import { ENDPOINTS } from "../config/apiconfig";
import { setStatusLoading } from "./../../redux/featuresSlice/commonSlice";

export const dataEndpoints = (builder) => ({
    getBusinessCards: builder.query({
        query: () => ({
            url: ENDPOINTS.BUSINESS_CARDS,
            method: "GET",
        }),
    }),
    getBusinessCardsById: builder.query({
        query: ({ cardId = "1" }) => ({
            url: `${ENDPOINTS.BUSINESS_CARDS}/${cardId}`,
            method: "GET",
        }),
        async onQueryStarted(arg, { dispatch, queryFulfilled }) {
            try {
                dispatch(setStatusLoading(true));
                await queryFulfilled;
            } catch (error) {
                console.error("Error fetching logo info payment:", error);
            } finally {
                dispatch(setStatusLoading(false));
            }
        },
    }),

    postBusinessCards: builder.mutation({
        query: (body) => ({
            url: ENDPOINTS.BUSINESS_CARDS,
            method: "POST",
            data: body,
        }),
    }),

    postOrders: builder.mutation({
        query: (body) => ({
            url: ENDPOINTS.ORDERS,
            method: "POST",
            data: body,
        }),
    }),

    postOrdersLogos: builder.mutation({
        query: (body) => ({
            url: ENDPOINTS.ORDERS_LOGOS,
            method: "POST",
            data: body,
        }),
    }),

    postBusinessCardsOrders: builder.mutation({
        query: (body) => ({
            url: ENDPOINTS.ORDERS_BUSINESS_CARDS,
            method: "POST",
            data: body,
        }),
    }),

    getBusinessCardsImages: builder.query({
        query: ({
            order = "DESC",
            page = 1,
            take = 10,
            imageType = "business-card",
        }) => ({
            url: ENDPOINTS.BUSINESS_CARDS_IMAGE,
            method: "GET",
            params: { order, page, take, imageType },
        }),
        async onQueryStarted(arg, { dispatch, queryFulfilled }) {
            try {
                dispatch(setStatusLoading(true));
                await queryFulfilled;
            } catch (error) {
                console.error("Error fetching business cards images:", error);
            } finally {
                dispatch(setStatusLoading(false));
            }
        },
    }),

    getImageListByType: builder.mutation({
        query: (credentials) => ({
            url: ENDPOINTS.IMAGES_LIST_BY_TYPE,
            method: "POST",
            data: credentials,
        }),
        async onQueryStarted(arg, { queryFulfilled }) {
            try {
                const { data } = await queryFulfilled;
            } catch (error) {
                console.error("Login error:", error);
            }
        },
    }),

    postLogos: builder.mutation({
        query: (body) => ({
            url: ENDPOINTS.LOGOS,
            method: "POST",
            data: body,
        }),
        async onQueryStarted(arg, { dispatch, queryFulfilled }) {
            try {
                await queryFulfilled;
            } catch (error) {
                console.error("Logout error:", error);
            }
        },
    }),

    getLogosList: builder.query({
        query: (params) => ({
            url: `${ENDPOINTS.LOGOS_LIST}/${params.logoId}`,
            method: "GET",
            data: params,
        }),
        async onQueryStarted(arg, { dispatch, queryFulfilled }) {
            try {
                dispatch(setStatusLoading(true));
                await queryFulfilled;
            } catch (error) {
                console.error("Error fetching business cards images:", error);
            } finally {
                dispatch(setStatusLoading(false));
            }
        },
    }),

    getLogoInfoPayment: builder.query({
        query: ({ imageId = "1" }) => ({
            url: `${ENDPOINTS.LOGO_INFO_PAYMENTS}/${imageId}`,
            method: "GET",
        }),
        async onQueryStarted(arg, { dispatch, queryFulfilled }) {
            try {
                dispatch(setStatusLoading(true));
                await queryFulfilled;
            } catch (error) {
                console.error("Error fetching logo info payment:", error);
            } finally {
                dispatch(setStatusLoading(false));
            }
        },
    }),

    getBusinessCardType: builder.query({
        query: () => ({
            url: `${ENDPOINTS.BUSINESS_CARDS_TYPE}`,
            method: "GET",
        }),
        async onQueryStarted(arg, { dispatch, queryFulfilled }) {
            try {
                dispatch(setStatusLoading(true));
                await queryFulfilled;
            } catch (error) {
                console.error("Error fetching:", error);
            } finally {
                dispatch(setStatusLoading(false));
            }
        },
    }),

    getFileFormat: builder.query({
        query: ({ businessCardsTypeId = "" }) => ({
            url: `${ENDPOINTS.GET_FILE_FORMAT}/${businessCardsTypeId}`,
            method: "GET",
        }),
        async onQueryStarted(arg, { dispatch, queryFulfilled }) {
            try {
                dispatch(setStatusLoading(true));
                await queryFulfilled;
            } catch (error) {
                console.error("Error fetching:", error);
            } finally {
                dispatch(setStatusLoading(false));
            }
        },
    }),

    //payment
    postKakaoInitialize: builder.mutation({
        query: (body) => ({
            url: ENDPOINTS.PAYMENTS_KAKAO_INITIALIZE,
            method: "POST",
            data: body,
        }),
        async onQueryStarted(arg, { dispatch, queryFulfilled }) {
            try {
                await queryFulfilled;
            } catch (error) {
                console.error("Logout error:", error);
            }
        },
    }),

    postTossPaymentInitialize: builder.mutation({
        query: (body) => ({
            url: ENDPOINTS.PAYMENTS_TOSS_PAYMENT_INITIALIZE,
            method: "POST",
            data: body,
        }),
        async onQueryStarted(arg, { dispatch, queryFulfilled }) {
            try {
                await queryFulfilled;
            } catch (error) {
                console.error("Logout error:", error);
            }
        },
    }),

    postNaverPayInitialize: builder.mutation({
        query: (body) => ({
            url: ENDPOINTS.PAYMENTS_NAVER_PAY_INITIALIZE,
            method: "POST",
            data: body,
        }),
        async onQueryStarted(arg, { dispatch, queryFulfilled }) {
            try {
                await queryFulfilled;
            } catch (error) {
                console.error("Logout error:", error);
            }
        },
    }),

    postBusinessCardImagesToS3: builder.mutation({
        query: (body) => ({
            url: ENDPOINTS.POST_IMAGE_TO_S3,
            method: "POST",
            data: body,
        }),
    }),

});
