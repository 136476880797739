import React, { useEffect, useState } from "react";
import imagesIcon from "../../images";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function LogoSection() {
    const navigate = useNavigate();
    const imageTypesData = useSelector(
        (state) => state.common.imageTypesData || [],
    );
    
    const [images, setImages] = useState({
        images: { cardLayoutFooterImage: null },
    });

    const getImageByType = (type) => {
        return Array.isArray(imageTypesData)
            ? imageTypesData.filter((image) => image.imageType === type)
            : [];
    };


    useEffect(() => {
        if (Array.isArray(imageTypesData) && imageTypesData.length > 0) {
            const createCardImageFooter = getImageByType(
                "dashboard-body",
            );
            if (createCardImageFooter.length > 0) {
                setImages((prevState) => ({
                    ...prevState,
                    images: {
                        ...prevState.images,
                        cardLayoutFooterImage:
                            createCardImageFooter[0]?.filePath,
                    },
                }));
            }
        }
    }, [imageTypesData]);

    return (
        <section className="mt-20 flex w-full flex-col items-start self-center px-20 leading-none max-md:mt-10 max-md:max-w-full max-md:px-5">
            <h2 className="text-2xl text-neutral-700 max-md:max-w-full">
                <span className="font-bold text-zinc-800">로고로지</span>에서{" "}
                <br />
                <span className="font-bold text-main-color">다양한</span>{" "}
                <span className="font-bold text-main-color">로고디자인</span>을
                확인해보세요.
            </h2>
            <div className="mt-5 flex items-center gap-4 text-base font-bold text-zinc-800">
                <div className="my-auto self-stretch">
                    더 많은 샘플 이미지 보러가기
                </div>
                <img
                    loading="lazy"
                    src={imagesIcon.arrowCircleB}
                    alt=""
                    onClick={() => navigate("/view-page")}
                    className="my-auto aspect-square w-6 shrink-0 self-stretch object-contain cursor-pointer"
                />
            </div>
            <div className="w-full flex justify-center">
                <img
                    loading="lazy"
                    src={images.images.cardLayoutFooterImage}
                    alt="Logo samples"
                    className="mt-8 w-[1240px] h-auto self-stretch object-contain max-md:max-w-full"
                />
            </div>

        </section>
    );
}

export default LogoSection;
