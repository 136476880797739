import React from "react";

function InquiryContent({ imageSrc, imageAlt, text }) {
  return (
    <section className="flex flex-col py-11 w-full text-base font-medium leading-6 border-b border-neutral-200 text-zinc-800 max-md:max-w-full">
      <img
        loading="lazy"
        src={imageSrc}
        alt={imageAlt}
        className="object-contain max-w-full aspect-[1.11] w-[301px]"
      />
      <p className="mt-8 w-full max-md:max-w-full">
        {text.map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ))}
      </p>
    </section>
  );
}

export default InquiryContent;
