import fileFormats from "../../../utils/constants/fileFormatOptionMakeBusiness.json";
import React, { useState } from "react";
import FileFormatOption from "../../../components/common/FileFormatOption/FileFormatOption";

function FileFormatSelectionBusinessCard() {
  const [selectedOption, setSelectedOption] = useState(0);

  const handleOptionChange = (index) => {
    setSelectedOption(index);
  };
  return (
    <section className="flex flex-col mt-24 w-full max-md:mt-10 max-md:max-w-full">
      <h2 className="flex flex-col w-full text-2xl font-bold leading-none text-neutral-700 max-md:max-w-full">
        <div className="gap-2.5 self-start">확장자 선택</div>
        <div className="flex mt-4 w-full bg-neutral-600 min-h-[2px] max-md:max-w-full" />
      </h2>
      <div className="flex flex-col mt-5 w-full max-md:max-w-full">
        <div className="w-full max-md:max-w-full grid grid-cols-2 gap-4 place-content-start max-md:flex max-md:flex-wrap max-md:justify-start">
          {fileFormats.map((option, index) => (
            <div key={index} className="w-full max-md:w-full ">
              <FileFormatOption
                name={option.name}
                price={option.price}
                features={option.features}
                isSelected={selectedOption === index}
                onSelectionChange={() => handleOptionChange(index)}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default FileFormatSelectionBusinessCard;
