import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { ScaleLoader } from "react-spinners";
import { useSelector } from "react-redux";
import { setAccessToken } from "../redux/featuresSlice/commonSlice";
// import { fetchDetailContact } from "../redux/actions/contact";

const override = {
    position: "absolute",
    top: "0",
    left: "0",
    textAlign: "center",
    right: "0",
    bottom: "0",
    backgroundColor: "rgb(0 0 0 / 30%)",
    zIndex: "9999",
};

const Layout = () => {
    const statusLoading = useSelector((state) => state.common.statusLoading);
    const dispatch = useDispatch();

    useEffect(() => {
        const token = sessionStorage.getItem("accessToken");
        if (token) {
            dispatch(setAccessToken(token));
        }
    }, [dispatch]);

    return (
        <div>
            <ScaleLoader
                loading={statusLoading}
                cssOverride={override}
                color="#3534ff"
                className="fixed"
            />
            <Outlet />
            <ToastContainer />
        </div>
    );
};

export default Layout;
