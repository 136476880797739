import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import CardSimple from "./../../components/common/Card/CardSimple";
import BusinessCardTitle from "./BussinessCardTitle";
import {
    useGetFileFormatQuery,
    useGetImageListByTypeMutation,
} from "../../services/apiSlice";
import ImageComponent from "../../components/FooterBanner";
import imagesCard from "../../images";

export default function BusinessCardLayout() {
    const { id } = useParams();
    const navigate = useNavigate();
    const businessCardsTypeId = id;
    const cardSamples = [
        {
            "id": 1,
            "businessCardsTypeId": "1",
            "title": "기업형 001",
            "leftImageUrl": imagesCard.ImageCard_1,
            "rightImageUrl": imagesCard.ImageCard_2,
            "description": "기업 환경에서 사용할 수 있도록 설계된 전문적이고 직관적인 디자인입니다."
        },
        {
            "id": 2,
            "businessCardsTypeId": "1",
            "title": "기업형 002",
            "leftImageUrl": imagesCard.ImageCard_3,
            "rightImageUrl": imagesCard.ImageCard_4,
            "description": "전문적인 브랜드와 일관성을 강조한 레이아웃 디자인입니다."
        },
        {
            "id": 3,
            "businessCardsTypeId": "1",
            "title": "기업형 003",
            "leftImageUrl": imagesCard.ImageCard_5,
            "rightImageUrl": imagesCard.ImageCard_6,
            "description": "깔끔하고 심플한 스타일로 기업 환경에 적합한 카드입니다."
        },
        {
            "id": 4,
            "businessCardsTypeId": "2",
            "title": "크리에이터형 001",
            "leftImageUrl": imagesCard.ImageCard1_1,
            "rightImageUrl": imagesCard.ImageCard1_2,
            "description": "창의적이고 독창적인 크리에이터 스타일의 명함입니다."
        },
        {
            "id": 5,
            "businessCardsTypeId": "2",
            "title": "크리에이터형 002",
            "leftImageUrl": imagesCard.ImageCard1_3,
            "rightImageUrl": imagesCard.ImageCard1_4,
            "description": "개성을 강조하며 자유로운 디자인을 담은 명함입니다."
        },
        {
            "id": 6,
            "businessCardsTypeId": "2",
            "title": "크리에이터형 003",
            "leftImageUrl": imagesCard.ImageCard1_5,
            "rightImageUrl": imagesCard.ImageCard1_6,
            "description": "프리랜서와 크리에이터를 위한 혁신적 레이아웃입니다."
        },
        {
            "id": 7,
            "businessCardsTypeId": "3",
            "title": "매장형 001",
            "leftImageUrl": imagesCard.ImageCard2_1,
            "rightImageUrl": imagesCard.ImageCard2_2,
            "description": "매장에서 사용하기 적합한 실용적 디자인입니다."
        },
        {
            "id": 8,
            "businessCardsTypeId": "3",
            "title": "매장형 002",
            "leftImageUrl": imagesCard.ImageCard2_3,
            "rightImageUrl": imagesCard.ImageCard2_4,
            "description": "고객과의 소통을 강조하는 직관적 명함입니다."
        },
        {
            "id": 9,
            "businessCardsTypeId": "3",
            "title": "매장형 003",
            "leftImageUrl": imagesCard.ImageCard2_5,
            "rightImageUrl": imagesCard.ImageCard2_6,
            "description": "매장을 위한 깔끔하고 효과적인 명함 디자인입니다."
        }
    ]
    
    // const {
    //     data: cardSamples,
    //     isLoading,
    //     isError,
    // } = useGetFileFormatQuery({
    //     businessCardsTypeId: id,
    // });
    const imageTypesData = useSelector(
        (state) => state.common.imageTypesData || [],
    );

    const getImageByType = (type) => {
        return Array.isArray(imageTypesData)
            ? imageTypesData.filter((image) => image.imageType === type)
            : [];
    };

    const [images, setImages] = useState({
        images: { cardLayoutFooterImage: null },
    });

    const handleCreateCard = (id, businessCardsTypeId) => {
        navigate(`/card/create/${id}`, { state: { businessCardsTypeId } });
    };

    useEffect(() => {
        if (Array.isArray(imageTypesData) && imageTypesData.length > 0) {
            const createCardImageFooter = getImageByType(
                "create-business-card-footer",
            );
            if (createCardImageFooter.length > 0) {
                setImages((prevState) => ({
                    ...prevState,
                    images: {
                        ...prevState.images,
                        cardLayoutFooterImage: createCardImageFooter[0]?.filePath,
                    },
                }));
            }
        }
    }, [imageTypesData]);

    return (
        <div className="flex flex-col items-center overflow-hidden bg-white">
            <BusinessCardTitle />
            <main className="w-full max-w-[1240px] max-md:px-10">
                {cardSamples ? (
                    cardSamples.map((card) => (
                        card.businessCardsTypeId === id && 
                        <React.Fragment key={card.id}>
                            <CardSimple
                                {...card}
                                handleCreate={() =>
                                    handleCreateCard(
                                        card.id,
                                        businessCardsTypeId,
                                    )
                                }
                            />
                            <div className="mt-9 flex h-px w-[1240px] max-w-full shrink-0 border border-solid border-gray-200" />
                        </React.Fragment>
                    ))
                ) : (
                    <p>No cards found for this group.</p>
                )}
            </main>
            <div className="w-full mt-24 cursor-pointer" onClick={() => navigate('/experts/consultation')}>
                <ImageComponent src={images.images.cardLayoutFooterImage} />
            </div>
        </div>
    );
}
