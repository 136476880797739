import React from "react";

function Copyright({ fontSize = "text-[14px]" }) {
    return (
        <footer
            className={`max-w-[454px] leading-none text-zinc-500 ${fontSize} max-md:text-center max-md:text-[12px]`}
        >
            <span className="uppercase text-zinc-500">Copyright </span>ⓒ 2024
            logologee.{" "}
            <span className="uppercase text-zinc-500">
                All rights reserved.
            </span>
        </footer>
    );
}

export default Copyright;
