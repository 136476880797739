import React from "react";
import images from "../../../images";

export default function CardPreview() {
    const smallImages = [
        images.GeneralImageSmall,
        images.GeneralImageSmall2,
        images.GeneralImageSmall3,
        images.GeneralImageSmall4,
    ];

    return (
        <div className="flex min-w-[240px] max-w-[480px] flex-col max-md:max-w-full max-md:w-full">
            <img
                loading="lazy"
                src={images.GeneralImageBig}
                alt="Business Card Preview"
                className="max-w-[480px] max-w-full object-contain max-md:w-full"
            />
            <div className="mt-3 flex max-w-full gap-3 max-md:gap-2 max-md:justify-between">
                {smallImages.map((image, index) => (
                    <img
                        key={index}
                        loading="lazy"
                        src={image}
                        alt={`Preview ${index + 1}`}
                        className="max-w-[111px] flex-1 object-contain max-md:max-w-[76px]"
                    />
                ))}
            </div>
        </div>
    );
}
