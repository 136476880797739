// endpoint authentication
export const AUTH_ENDPOINTS = {
    LOGIN: "/auth/login",
    LOGOUT: "/auth/logout",
    LOGIN_WITH_GOOGLE: "auth/google",
    GENERATE_OTP: "/auth/generate-otp",
    VERIFY_OTP: "/auth/verify-otp",
};

// endpoint other
export const ENDPOINTS = {
    BUSINESS_CARDS: "/business-cards",
    BUSINESS_CARDS_IMAGE: "/images",
};
