import React, { useState, useEffect } from "react";
import InfoSection from "../../SNSInfomation/InfoSection";
import InputField from "../../../components/common/Input/InputField";
import Button from "../../../components/common/Button/Button";
import images from "../../../images";
import { useSelector,useDispatch } from "react-redux";
import {
    useCancelUserInfoMutation,
    useGetUserInfoQuery,
    useUpdateUserInfoMutation,
} from "../../../services/apiSlice";
import { toast } from "react-toastify";
import ModalComponent from "../../../components/common/Modals/ModalComponent";
import { useNavigate } from "react-router-dom";
import { clearAccessToken } from "./../../../redux/featuresSlice/commonSlice";

const MyInfomation = () => {
    const { userData } = useSelector((state) => state.common);
    const userId = userData?.userID || null;
    const initModal = {
        status: false,
        type: "",
        title: "",
        content: "",
        subTitle: "",
        footerContent: "",
    };
    const dispatch = useDispatch();

    const [textValue, setTextValue] = useState("");
    const handleTextChange = (value) => setTextValue(value);
    const [modalSuccess, setModalSuccess] = useState(false);
    const navigate = useNavigate();

    const [modal, setModal] = useState(initModal);
    const { data, isLoading, isError, refetch } = useGetUserInfoQuery(
        { id: userId },
        { skip: !userId },
    );

    // Mutation to update user info
    const [updateUserInfo] = useUpdateUserInfoMutation();
    const [cancelUserInfo] = useCancelUserInfoMutation();

    const [formData, setFormData] = useState({
        email: "",
        userName: "",
        phone: "",
    });

    // Update formData when API data is fetched
    useEffect(() => {
        if (data) {
            setFormData({
                email: data.email || "",
                userName: data.userName || "",
                phone: data.phone || "",
            });
        }
    }, [data]);

    // Handle change in input fields
    const handleChange = (field, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [field]: value,
        }));
    };

    // Submit updated user info
    const handleUpdateUserInfo = async () => {
        try {
            const payload = {
                id: userId,
                userName: formData.userName,
                phone: formData.phone,
            };

            const responseUpdateUser = await updateUserInfo(payload).unwrap(); // Use unwrap to handle the response
            if (responseUpdateUser) {
                toast.success("사용자 업데이트 성공");
                refetch(); // Refetch user info after successful update
            }
        } catch (err) {
            toast.error("사용자 업데이트에 실패했습니다");
            console.error("Error updating user info:", err);
        }
    };

    const handleCancelUser = () => {
        setModal({
            ...modal,
            status: true,
            title: "정말로 탈퇴하시겠습니까?",
            subTitle: "탈퇴를 진행하실 경우",
            content:
                "가입하신 계정은 개인 정보 보관 기간동안 비활성화되어 처리됩니다.해당 기간 동안 재가입 하실 경우 기존 정보가 다시 활성화되어 진행되며 보관 기간이 지난 후, 재가입 시 개인 정보가 모두 파기되어 신규회원으로 진행됩니다",
            footerContent:
                "(*개인 정보 보관 기간 : 회원 탈퇴 후, 3년 동안 보관.)",
        });
    };

    const handleConfirmOrder = async () => {
        try {
            const payload = {
                id: userId,
                cancelReason: textValue,
            };

            const responseCancelUser = await cancelUserInfo(payload).unwrap();
            if (
                responseCancelUser?.message ===
                "Membership cancelled successfully."
            ) {
                setModal({
                    ...modal,
                    status: false,
                });
                setModalSuccess(true);
            }
        } catch (err) {
            toast.error("사용자 업데이트에 실패했습니다");
            console.error("Error updating user info:", err);
        }
    };

    const handleCloseModal = () => {
        setModal({
            ...modal,
            status: false,
        });
    };

    const handleConfirmSuccess = () => {
        setModalSuccess(false);
        dispatch(clearAccessToken());
        navigate("/login");
    };
    const sourceIcons = {
        Google: images.googleLogo,
        Naver: images.naverLogoG,
        Kakao: images.kakaoLogo,
    };
    
    return (
        <main className="align-items-center flex flex-col rounded-none leading-none">
            <div className="flex w-full flex-col items-center justify-center px-14 py-10">
                <div className="flex max-w-full flex-col gap-3 p-10">
                    <InfoSection
                        title="SNS 가입 정보"
                        email={formData.email}
                        iconSrc={sourceIcons[data?.snsType] || images.googleLogo} 
                    />
                    <InputField
                        label="이름"
                        description="상담 진행 또는 택배 배송 시 확인할 성함을 기재해주세요."
                        placeholder="홍길동"
                        value={formData.userName}
                        onChange={(e) =>
                            handleChange("userName", e.target.value)
                        }
                    />

                    <InputField
                        label="휴대전화 번호"
                        placeholder="01012345678"
                        value={formData.phone}
                        onChange={(e) => handleChange("phone", e.target.value)}
                    />
                    <Button
                        label="인증 번호 받기"
                        className="mt-2.5 bg-zinc-800 text-white"
                        onClick={handleUpdateUserInfo}
                    />
                    <div onClick={handleCancelUser} className="mx-auto mt-10">
                        <span className="border-b-2 border-[#555] cursor-pointer">
                            회원탈퇴하기
                        </span>
                    </div>
                </div>
            </div>

            <ModalComponent
                title={modal.title}
                titleClass="flex justify-center"
                open={modal.status}
                content={modal.content}
                subTitle={modal.subTitle}
                footerContent={modal.footerContent}
                contentClass="text-start"
                buttonClassContainer="flex flex-row-reverse"
                showTextArea={true} // Hiển thị textarea
                textAreaValue={textValue}
                onTextAreaChange={handleTextChange}
                textAreaPlaceholder="탈퇴 사유를 입력해주세요."
                buttonClose={{
                    buttonClass:
                        "bg-white text-black px-4 py-2 rounded border border-[##E8E8E8] min-w-[165px]",
                    label: "취소",
                    onClose: () => {
                        handleCloseModal();
                    },
                }}
                buttonSubmit={{
                    buttonClass:
                        "bg-[#3533FF] text-white px-4 py-2 rounded min-w-[165px]",
                    label: "다시 만들기",
                    onSubmit: () => {
                        handleConfirmOrder();
                    },
                }}
            />

            <ModalComponent
                title={"기본_모달_버튼하나"}
                titleClass="flex justify-center"
                open={modalSuccess}
                content={
                    "회원 정보가 비활성화 되었습니다. \n 이용해 주셔서 감사합니다."
                }
                contentClass="text-center"
                buttonClassContainer="flex flex-row-reverse"
                buttonSubmit={{
                    buttonClass:
                        "bg-[#3533FF] text-white px-4 py-2 rounded min-w-[165px]",
                    label: "다시 만들기",
                    onSubmit: () => {
                        handleConfirmSuccess();
                    },
                }}
            />
        </main>
    );
};

export default MyInfomation;
