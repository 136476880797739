import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import images from "../../../images";
import Button from "../../../components/common/Button/Button";
import { TroubleshootOutlined } from "@mui/icons-material";
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "10px",
};

export default function ModalComponent({
    open = false,
    className = "w-[450px]",
    title = "",
    titleClass = "",
    content = "",
    contentClass = "",
    hideIconClose = true,
    buttonSubmit = {
        buttonClass: "",
        label: "",
        onSubmit: () => {},
    },
    buttonClose = {
        buttonClass: "",
        label: "",
        onClose: () => {},
    },
}) {
    return (
        <div>
            <Modal
                open={open}
                onClose={buttonClose.onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className={className}>
                    <div className="flex justify-between">
                        {title && (
                            <Typography
                                id="modal-modal-title"
                                className={`w-full ${titleClass}`}
                                variant="h6"
                                component="h2"
                            >
                                {title}
                            </Typography>
                        )}
                        {!hideIconClose && (
                            <img
                                loading="lazy"
                                src={images.businessCardIcon}
                                alt="Business Card Icon"
                                className="aspect-square w-[20px] object-contain max-md:mt-10"
                            />
                        )}
                    </div>
                    {content && (
                        <Typography
                            id="modal-modal-description"
                            className={`${contentClass} whitespace-pre-line text-[#444444]`}
                            sx={{ mt: 2 }}
                        >
                            {content}
                        </Typography>
                    )}
                    {(buttonSubmit || buttonClose) && (
                        <div className="mt-6 flex w-full gap-2">
                            {buttonSubmit.label !== "" && (
                                <Button
                                    className={buttonSubmit.buttonClass}
                                    label={buttonSubmit.label}
                                    onClick={buttonSubmit.onSubmit}
                                />
                            )}
                            {buttonClose.label !== "" && (
                                <Button
                                    className={buttonClose.buttonClass}
                                    label={buttonClose.label}
                                    onClick={buttonClose.onSubmit}
                                />
                            )}
                        </div>
                    )}
                    <div></div>
                </Box>
            </Modal>
        </div>
    );
}
