import React, { useState, useEffect } from "react";
import paymentOptionsData from "../../../utils/constants/paymentOptions.json";

function PaymentMethod({ onPaymentSelect }) {
  const [paymentOptions, setPaymentOptions] = useState([]);

  useEffect(() => {
    setPaymentOptions(
      paymentOptionsData.map((option) => ({ ...option, isSelected: false }))
    );
  }, []);

  const handleOptionClick = (index) => {
    setPaymentOptions((prevOptions) =>
      prevOptions.map((option, i) => ({
        ...option,
        isSelected: i === index,
      }))
    );

    onPaymentSelect(paymentOptions[index]);
  };

  return (
    <section className="flex flex-col mt-24 w-full max-md:mt-10 max-md:max-w-full">
      <h2 className="flex flex-col w-full text-2xl font-bold leading-none text-neutral-700 max-md:max-w-full">
        <div className="gap-2.5 self-start">결제 수단</div>
        <div className="flex mt-4 w-full bg-neutral-600 min-h-[2px] max-md:max-w-full" />
      </h2>
      <div className="flex flex-col mt-5 w-full max-md:max-w-full">
        <div className="flex flex-wrap gap-5 items-start w-full max-md:max-w-full">
          {/* <div className="w-full max-md:max-w-full grid grid-cols-2 gap-4 place-content-start max-md:flex max-md:flex-wrap max-md:justify-start"> */}

          {paymentOptions.map((option, index) => (
            <div
              key={index}
              className={`flex flex-col justify-center items-start p-5 rounded-md border max-w-[234px] ${
                option.isSelected
                  ? "border-2 border-indigo-600"
                  : "border-gray-200"
              } border-solid w-full`}
              onClick={() => handleOptionClick(index)}
            >
              <div className="flex gap-2.5 items-center">
                {option.isSelected ? (
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/e90c38000c84cfcda576a3fd1b3ecc49cefad2fe3a245a4fc45ef6ea6f52ac91?placeholderIfAbsent=true&apiKey=3ac17b61e0c94c2b87d841ac8b4d3afd"
                    alt="Selected radio button"
                    className="object-contain shrink-0 self-stretch my-auto w-4 aspect-square rounded-[50px]"
                  />
                ) : (
                  <div className="flex flex-col self-stretch my-auto w-4 rounded-[50px]">
                    <div className="flex shrink-0 h-4 border border-solid border-zinc-500 rounded-[50px]" />
                  </div>
                )}
                <div className="flex gap-2 items-center self-stretch my-auto text-base leading-none whitespace-nowrap text-zinc-800">
                  {option.logo && (
                    <img
                      loading="lazy"
                      src={option.logo}
                      alt={option.logoAlt}
                      className="object-contain shrink-0 self-stretch my-auto rounded-sm aspect-[4.05] w-[81px]"
                    />
                  )}
                  <div className="self-stretch my-auto">{option.name}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default PaymentMethod;
