import React, { useState } from "react";
import MyLogoProductList from "./MyLogoProductList";
import MyBusinessCardProductList from "./MyBusinessCardProductList";
import CommonTabs from "../../../components/common/Tabs/CommonTabs";

// const tabs = [{ label: "로고" }, { label: "명함" }];

const tabs = [{ label: "로고" }, { label: "명함" }];
const panels = [
    <MyLogoProductList key="logo" />,
    <MyBusinessCardProductList key="businessCard" />,
];

const MyProductLayout = () => {
    const [activeTab, setActiveTab] = useState(tabs[0].label);
    const [showNewInquiry, setShowNewInquiry] = useState(false);

    const handleTabClick = (tabLabel) => {
        setActiveTab(tabLabel);
        setShowNewInquiry(false);
    };

    return (
        <main className="flex w-full flex-col overflow-hidden bg-white px-20 max-md:px-5">
            <nav className="flex min-h-[58px] w-full flex-wrap whitespace-nowrap rounded-md p-2 text-base leading-none max-md:max-w-full">
                <CommonTabs
                    labels={tabs.map((tab) => tab.label)}
                    activeTab={activeTab}
                    onTabClick={handleTabClick}
                    panels={panels}
                />
            </nav>

            <section className="mt-8 flex w-full flex-col items-center"></section>
        </main>
    );
};

export default MyProductLayout;
