import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import CardSimple from "./../../components/common/Card/CardSimple";
import BusinessCardTitle from "./BussinessCardTitle";
import {
    useGetFileFormatQuery,
    useGetImageListByTypeMutation,
} from "../../services/apiSlice";
import ImageComponent from "../../components/FooterBanner";

export default function BusinessCardLayout() {
    const { id } = useParams();
    const navigate = useNavigate();
    const businessCardsTypeId = id;
    const {
        data: cardSamples,
        isLoading,
        isError,
    } = useGetFileFormatQuery({
        businessCardsTypeId: id,
    });
    const imageTypesData = useSelector(
        (state) => state.common.imageTypesData || [],
    );

    const getImageByType = (type) => {
        return Array.isArray(imageTypesData)
            ? imageTypesData.filter((image) => image.imageType === type)
            : [];
    };

    const [images, setImages] = useState({
        images: { cardLayoutFooterImage: null },
    });

    const handleCreateCard = (id, businessCardsTypeId) => {
        navigate(`/card/create/${id}`, { state: { businessCardsTypeId } });
    };

    useEffect(() => {
        if (Array.isArray(imageTypesData) && imageTypesData.length > 0) {
            const createCardImageFooter = getImageByType(
                "create-business-card-footer",
            );
            if (createCardImageFooter.length > 0) {
                setImages((prevState) => ({
                    ...prevState,
                    images: {
                        ...prevState.images,
                        cardLayoutFooterImage: createCardImageFooter[0]?.filePath,
                    },
                }));
            }
        }
    }, [imageTypesData]);

    return (
        <div className="flex flex-col items-center overflow-hidden bg-white">
            <BusinessCardTitle />
            <main className="w-full max-w-[1240px] max-md:px-10">
                {cardSamples ? (
                    cardSamples.map((card) => (
                        <React.Fragment key={card.id}>
                            <CardSimple
                                {...card}
                                handleCreate={() =>
                                    handleCreateCard(
                                        card.id,
                                        businessCardsTypeId,
                                    )
                                }
                            />
                            <div className="mt-9 flex h-px w-[1240px] max-w-full shrink-0 border border-solid border-gray-200" />
                        </React.Fragment>
                    ))
                ) : (
                    <p>No cards found for this group.</p>
                )}
            </main>
            <div className="mt-24 w-full">
                <ImageComponent src={images.images.cardLayoutFooterImage} />
            </div>
        </div>
    );
}
