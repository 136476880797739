import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import InquiryQuestionItem from "./InquiryQuestionItem";
import images from "../../../images";
import Button from "../../../components/common/Button/Button";
import PaginationComponent from "../../../components/common/Pagination/PaginationComponent";

const questions = [
  {
    id: 20,
    title: "이미지를 많이 넣고 싶은 데, 추가 이미지를 삽입할 수 있을까요?",
    date: "2024-09-20 10:00",
    status: "waiting",
  },
  {
    id: 19,
    title: "이미지를 많이 넣고 싶은 데, 추가 이미지를 삽입할 수 있을까요?",
    date: "2024-09-20 10:00",
    status: "waiting",
  },
  {
    id: 18,
    title: "이미지를 많이 넣고 싶은 데, 추가 이미지를 삽입할 수 있을까요?",
    date: "2024-09-20 10:00",
    status: "waiting",
  },
  {
    id: 17,
    title: "이미지를 많이 넣고 싶은 데, 추가 이미지를 삽입할 수 있을까요?",
    date: "2024-09-20 10:00",
    status: "completed",
  },
  {
    id: 16,
    title: "이미지를 많이 넣고 싶은 데, 추가 이미지를 삽입할 수 있을까요?",
    date: "2024-09-20 10:00",
    status: "completed",
  },
  {
    id: 15,
    title: "이미지를 많이 넣고 싶은 데, 추가 이미지를 삽입할 수 있을까요?",
    date: "2024-09-20 10:00",
    status: "completed",
  },
];

function InquiryList({ onNewInquiry }) {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);

  const handleItemClick = () => {
    onNewInquiry();
  };
  const goToDetailsInquiry = (question) => {
    navigate("/details-inquiry", { state: { question } });
  };
  return (
    <main className="flex flex-col rounded-none">
      <section className="flex flex-col w-full leading-none max-md:max-w-full">
        {questions.map((question) => (
          <InquiryQuestionItem
            key={question.id}
            {...question}
            handleClick={() => goToDetailsInquiry(question)}
          />
        ))}
      </section>
      <div className="flex flex-wrap w-full gap-20 justify-between items-center self-end max-w-full my-20">
        <div className="flex-1 flex justify-center">
        </div>
        <div className="flex-2 flex justify-center content-center items-center text-center">
          <PaginationComponent count={10} page={page} />
        </div>
        <div className="flex-1 flex justify-center">
          <Button
            onClick={handleItemClick}
            iconFirst={images.penCil}
            label={"의하기"}
            className="flex gap-1 items-center pr-5 pl-3 text-base font-semibold leading-none whitespace-nowrap rounded-md border border-solid border-neutral-300 min-h-[40px] text-zinc-800"
          />
        </div>
      </div>
    </main>
  );
}

export default InquiryList;
