import React from "react";

const CommonTitleSection = ({
  icon,
  title,
  subtitle,
  titleColor = "text-indigo-600",
  subtitleColor = "text-zinc-800",
}) => (
  <section className="flex flex-col items-center mt-20 max-md:mt-10">
    {icon && (
      <img
        loading="lazy"
        src={icon}
        alt="Icon"
        className="object-contain aspect-[0.94] w-[47px]"
      />
    )}
    <h1
      className={`mt-6 text-3xl font-bold leading-none text-center ${titleColor}`}
    >
      {title}
    </h1>
    <p className={`mt-3.5 text-2xl leading-none text-center ${subtitleColor}`}>
      {subtitle}
    </p>
  </section>
);

export default CommonTitleSection;
