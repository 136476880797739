import React from "react";

function TextArea({ label, placeholder, value, onChange, className = "" }) {
  return (
    <div
      className={`flex flex-col mt-10 w-full max-md:max-w-full ${className}`}
    >
      {label && (
        <label className="text-base font-bold leading-none text-neutral-700 max-md:max-w-full">
          {label}
        </label>
      )}
      <textarea
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className="gap-2.5 px-5 pt-5 pb-64 mt-4 w-full text-base leading-6 rounded-md border border-solid bg-stone-50 border-stone-50 min-h-[320px] text-zinc-500 max-md:pb-24 max-md:max-w-full"
      />
    </div>
  );
}

export default TextArea;
