import React from 'react';
import InputField from '../../components/common/Input/InputField';
import TabsCreateLogo from './TabPanel';

export default function LogoUploader() {

    return (
        <section className="flex flex-col justify-center px-4 md:px-12 py-10 mt-5 rounded-md border border-solid border-neutral-200">
            <div className="flex flex-col w-full bg-white rounded-xl">
                <InputField
                    label="작업명"
                    description="작업명은 로고 구매 시, 구매하신 제작물을 구분하기 위해 등록됩니다."
                    placeholder="작업명을 입력해주세요. (최대 글자수 nn자 까지 입력가능)"
                />
                <div className="flex flex-col mt-10 w-full">
                    <TabsCreateLogo />
                </div>
            </div>
        </section>
    );
}