import React from "react";

function InputField({
    label = "", 
    description,
    value,
    placeholder,
    onChange,
    type,
    duration,
    width,
    name,
}) {
    const inputId = label ? label.toLowerCase().replace(/\s+/g, "-") : "";

    return (
        <div className="mt-4 flex w-full flex-col">
            <label
                htmlFor={inputId}
                className="text-base font-bold text-neutral-700"
            >
                {label}
            </label>
            {description && (
                <p className="mt-2.5 text-sm text-stone-500">{description}</p>
            )}
            <input
                type={type}
                id={inputId}
                className="mt-2.5 min-h-[50px] w-full gap-2.5 self-stretch whitespace-nowrap rounded-md bg-stone-50 px-4 text-base text-neutral-700"
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                width={width}
                name={name}
                required
            />
        </div>
    );
}

export default InputField;
