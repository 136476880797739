import React from "react";
import TableCommon from "../../../components/common/CommonTable/TableCommon";
import Button from "../../../components/common/Button/Button";

const PrintOrderData = () => {
  const columns = [
    { label: "결제일", field: "date" },
    { label: "결제 상품 정보", field: "productInfo" },
    { label: "확장자", field: "fileType" },
    { label: "결제금액", field: "price" },
    { label: "결제취소", field: "cancelable" },
  ];

  const printOrderData = [
    {
      date: "2024-09-20 10:00",
      productInfo: "인쇄 주문 - 일반명함 ",
      fileType: "-",
      price: "49,000원",
      cancelable: true,
    },
    {
      date: "2024-09-20 10:00",
      productInfo: "인쇄 주문 - 고급명함(고급 용지 명함) ",
      fileType: "-",
      price: "8,900원",
      cancelable: false,
    },
  ];
  return (
    <>
      <div>
        <TableCommon
          columns={columns}
          data={printOrderData.map((item) => ({
            ...item,
            cancelable: (
              <>
                {item.cancelable === true ? (
                  <Button
                    label={"결제 취소"}
                    className="gap-1 self-stretch max-w-full bg-violet-100 rounded-md border border-solid border-slate-200 min-h-[40px] w-[110px] text-indigo-600 font-semibold"
                  />
                ) : (
                  <Button
                    label={"결제 취소"}
                    className="gap-1 self-stretch max-w-full rounded-md border border-solid bg-neutral-200 border-stone-300 min-h-[40px] w-[110px] text-stone-500"
                  />
                )}
              </>
            ),
          }))}
        />
      </div>
    </>
  );
};

export default PrintOrderData;
