import React from 'react';

const StepHeader = ({ step, title, className = '' }) => {
  return (
    <div className={`flex flex-col justify-center self-start ${className}`}>
      <div className="text-2xl font-bold text-main-color">STEP {step}.</div>
      <div className="mt-2.5 text-xl font-semibold text-neutral-700">{title}</div>
    </div>
  );
};

export default StepHeader;